import tw, { styled } from 'twin.macro';
import Link from '../../atoms/Link';

export const MenuContainer = styled.div`
    ${tw`
  w-full
  flex
  items-start
  pb-[10px]
  justify-evenly
  border-b-2
  border-primary
`}
`;

export const MenuItem = styled(Link)`
    ${tw`
  text-15
  text-primary
  font-nunito
  font-normal
  cursor-pointer
`}
`;
