import { parse as parseHimalaya, stringify } from 'himalaya';
import { ICookieCtx } from '../../utils/cookie';
import {
    NEXT_PUBLIC_CONFIG_BLOCK,
    NEXT_PUBLIC_FOOTER_DELIVERY_BANNER,
    NEXT_PUBLIC_HALL_HOME,
    NEXT_PUBLIC_MY_ACCOUNT_BANNER,
    NEXT_PUBLIC_URL_API
} from '../../utils/envs';
import api, { apiAdminPublic } from '../api';
import { IBlock, IBlockConfig, IBlockConfigKey, ITagConfig } from '../block/types';

const getById = async (id: string, cookieCtx?: ICookieCtx): Promise<IBlock | null> => {
    const { data } = await apiAdminPublic.get<IBlock>('V1/cmsBlock/' + id);

    if (data && data.active) {
        return data;
    }

    return null;
};

const propertiesToString: IBlockConfigKey[] = [
    'regua_messagem_topo',
    'footer_endereco',
    'footer_copyright',
    'cadastro_mensagem',
    'login_mensagem',
    'detalhes_produto_entrega_mensagem',
    'meus_dados_mensagem',
    'esqueci_minha_senha_mensagem',
    'carrinho_cep_sem_cobertura',
    'detalhes_pedido_link_rastreio',
    'desconto_a_vista'
];
const propertiesIsTable: IBlockConfigKey[] = ['regua_messagem_topo', 'categorias_destaques'];

const parseBlockConfig = (content: string): IBlockConfig | null => {
    const parse = parseHimalaya(content);
    const table = parse.find((v: any) => v.tagName == 'table');
    if (!table) {
        return null;
    }
    const tbody = table.children.find((v: any) => v.tagName == 'tbody');
    if (!tbody) {
        return null;
    }
    const trs = tbody.children.filter((v: any) => v.tagName == 'tr').slice(1);
    const result: any = {};
    trs.map((tr: any) => {
        const [nameObj, value] = tr.children.filter((v: any) => v.tagName == 'td');
        // .map((td: any) => td.children[0]) as any[];

        const name = nameObj.children[0].content
            .replace(/(&NBSP;|&nbsp;)/g, '')
            .replace(/[\r\n]/g, '') as IBlockConfigKey;

        if (name === 'tags') {
            result.tags = parseTags(value);
        } else if (propertiesIsTable.includes(name)) {
            result[name] = parseBlockConfigSubTable(value.children, name);
        } else if (propertiesToString.includes(name)) {
            result[name] = stringify(value.children) || null;
        } else {
            result[name] = value.children[0].content.replace(/(&NBSP;|&nbsp;)/g, '').replace(/[\r\n]/g, '') || null;
        }
    });
    return result as IBlockConfig;
};

const parseTags = (content: any) => {
    const table = content.children.find((v: any) => v.tagName == 'table');
    if (!table) {
        return [];
    }
    const tbody = table.children.find((v: any) => v.tagName == 'tbody');
    if (!tbody) {
        return [];
    }
    const trs = tbody.children.filter((v: any) => v.tagName == 'tr').slice(1);
    let values: ITagConfig[] = [];

    trs.map((tr: any) => {
        const [name, category, color, colorText, font, weight, fontSize] = tr.children.filter(
            (v: any) => v.tagName == 'td'
        );

        values.push({
            name: name ? stringify(name.children) : '',
            category: category ? clearText(category.children[0].content) : '',
            color: color ? clearText(color.children[0].content) : '',
            colorText: colorText ? clearText(colorText.children[0].content) : '',
            font: font ? clearText(font.children[0].content) : '',
            weight: weight ? clearText(weight.children[0].content) : '',
            fontSize: fontSize ? clearText(fontSize.children[0].content) : ''
        });
    });

    return values;
};

const parseBlockConfigSubTable = (content: any, name?: IBlockConfigKey) => {
    const table = content.find((v: any) => v.tagName == 'table');
    if (!table) {
        return [];
    }
    const tbody = table.children.find((v: any) => v.tagName == 'tbody');
    if (!tbody) {
        return [];
    }
    const trs = tbody.children.filter((v: any) => v.tagName == 'tr');
    let values: string[] = [];

    trs.map((tr: any) => {
        const value = tr.children.find((v: any) => v.tagName == 'td');

        values.push(
            name && propertiesToString.includes(name)
                ? stringify(value.children)
                : value.children[0].content?.replace(/(&NBSP;|&nbsp;)/g, '')?.replace(/[\r\n]/g, '')
        );
    });

    return values;
};

const genericParse = <T>(content: string, columns: string[], columnsSplit: string[] = []): T | null => {
    const parse = parseHimalaya(content);
    const table = parse.find((v: any) => v.tagName == 'table');
    if (!table) {
        return null;
    }
    const tbody = table.children.find((v: any) => v.tagName == 'tbody');
    if (!tbody) {
        return null;
    }
    const trs = tbody.children.filter((v: any) => v.tagName == 'tr').slice(1);
    return trs.map((tr: any) => {
        const result: Record<string, string | string[]> = {};
        columns.map((column, index) => {
            const valueTd = tr.children.filter((td: any) => td.tagName == 'td')[index].children[0].content;
            const columnIsSplit = columnsSplit.includes(column);
            let value = valueTd;
            if (columnIsSplit) {
                value = valueTd
                    .replace(/(&NBSP;|&nbsp;)/g, '')
                    .replace(/[\r\n]/g, '')
                    .split(',')
                    .filter((v: string) => v.length);
            }
            result[column] = value;
        });
        return result;
    });
};

function clearText(text: string) {
    return text.replace(/(&NBSP;|&nbsp;)/g, '')?.replace(/[\r\n]/g, '');
}
const getGeneralInfos = async (): Promise<any> => {
    const blockConfigs = (await getById(NEXT_PUBLIC_CONFIG_BLOCK)) as IBlock;
    return parseBlockConfig(blockConfigs.content);
};

const getBlockTextById = async (id: number): Promise<IBlock> => {
    const { data } = await api.get(`V1/cmsBlock/${id}`);
    return data;
};

export interface IBlockFooterDeliveryBanner {
    image: string;
    title: string;
    description: string;
}

export interface IBlockAisles {
    title: string;
    categories: string[];
}

const getSrcImage = (image: any) => {
    return image?.attributes
        ?.find((a: any) => a.key == 'src')
        ?.value.replace(/&quot;/g, '"')
        .match(/\"(.*?)\"/)[1];
};

const getBlockFooterDeliveryBanner = async (): Promise<IBlockFooterDeliveryBanner[]> => {
    const data = await getById(NEXT_PUBLIC_FOOTER_DELIVERY_BANNER);

    if (!data) {
        return [];
    }

    try {
        const parse = parseHimalaya(data.content);
        const table = parse.find((v: any) => v.tagName == 'table');
        if (!table) {
            return [];
        }
        const tbody = table.children.find((v: any) => v.tagName == 'tbody');
        if (!tbody) {
            return [];
        }
        const trs = tbody.children.filter((v: any) => v.tagName == 'tr').slice(1);

        return trs.map((tr: any) => {
            const tds = tr.children.filter((v: any) => v.tagName == 'td');

            const image = tds[0].children[0];
            const title = tds[1];
            const description = tds[2];

            const imageSrc = getSrcImage(image);

            return {
                image: imageSrc ? `${NEXT_PUBLIC_URL_API}/media/${imageSrc}` : '',
                title: title ? stringify(title.children) : '',
                description: description ? stringify(description.children) : ''
            };
        });
    } catch (e) {
        console.error('parse images category', e);
        return [];
    }
};

const getBlockAisle = async (id: string): Promise<IBlockAisles[]> => {
    const data = await getById(id);

    if (!data) {
        return [];
    }

    try {
        const parse = parseHimalaya(data.content);
        const table = parse.find((v: any) => v.tagName == 'table');
        if (!table) {
            return [];
        }
        const tbody = table.children.find((v: any) => v.tagName == 'tbody');
        if (!tbody) {
            return [];
        }
        const trs = tbody.children.filter((v: any) => v.tagName == 'tr').slice(1);

        return trs.map((tr: any) => {
            const [title, categories] = tr.children.filter((v: any) => v.tagName == 'td');

            return {
                title: title ? stringify(title.children) : '',
                categories: parseBlockConfigSubTable(categories.children)
            };
        });
    } catch (e) {
        console.error('parse images category', e);
        return [];
    }
};

const getBlockMyAccountBanner = async (): Promise<string> => {
    const data = await getById(NEXT_PUBLIC_MY_ACCOUNT_BANNER);

    if (!data) {
        return '';
    }

    try {
        const parse = parseHimalaya(data.content);
        const table = parse.find((v: any) => v.tagName == 'table');
        if (!table) {
            return '';
        }
        const tbody = table.children.find((v: any) => v.tagName == 'tbody');
        if (!tbody) {
            return '';
        }
        const trs = tbody.children.filter((v: any) => v.tagName == 'tr').slice(1);

        const [image] = trs[0].children.filter((v: any) => v.tagName == 'td');

        return `${NEXT_PUBLIC_URL_API}/media/${getSrcImage(image.children[0])}`;
    } catch (e) {
        console.error('parse images category', e);
        return '';
    }
};

export default {
    getById,
    getGeneralInfos,
    genericParse,
    getBlockTextById,
    getBlockFooterDeliveryBanner,
    getBlockAisle,
    getBlockMyAccountBanner
};
