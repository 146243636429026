import { motion } from 'framer-motion';
import { FC } from 'react';
import tw, { styled } from 'twin.macro';

const Container = styled(motion.span)`
    line-height: 18px;
    ${tw`
        font-nunito
        text-15
        text-black
        font-normal
    `}
`;

const TextLatoRegular15: FC<any> = ({ children, ...props }) => {
    return <Container {...props}>{children}</Container>;
};

export default TextLatoRegular15;
