import tw, { styled } from 'twin.macro';
import { IStylesParams } from '.';
import { IconClose, TextLatoBold15, TextLatoBold20, TextLatoRegular15, TextLatoRegular18 } from '../../atoms';

export const Container = styled.div<IStylesParams>`
    ${(p) => {
        if (p.lastStatus) {
            return tw`
          h-[80px]  
        `;
        } else {
            return tw`
         h-[120px]
        `;
        }
    }}
    ${tw`
      w-full
      flex
      justify-center
    `}
`;

export const StatusTimeContainer = styled.div`
    ${tw`
      flex
      flex-col
      items-start
      min-w-[110px]
      mt-[10px]
    `}
`;

export const StatusTimeDate = styled(TextLatoRegular15)`
    ${tw`
      text-primary
      font-[600]
      opacity-80
    `}
`;

export const StatusTime = styled(TextLatoBold15)`
    ${tw`
      text-darkGray
    `}
`;

export const StatusIcons = styled.div`
    ${tw`
      mx-[20px]
      flex
      flex-col
      items-center
    `}
`;

export const StatusInfoContainer = styled.div`
    ${tw`
      flex
      flex-col
      w-[200px]
    `}
`;

export const StatusLabel = styled(TextLatoBold20)<IStylesParams>`
    ${tw`
      text-darkGray
      w-full
    `}
    ${(p) => {
        if (p.$active) return tw`text-primary`;
    }}
`;

export const StatusDescription = styled(TextLatoRegular18)`
    ${tw`
      w-full
      text-darkGray
    `}
`;

export const StatusCircle = styled.div<IStylesParams>`
    ${tw`
      flex
      relative
      w-[30px]
      h-[30px]
    `}
`;

export const StatusLine = styled.div<IStylesParams>`
    ${tw`
      flex-1
      w-[4px]
      my-[5px]
      rounded-[4px]
    `}
    ${(p) => {
        if (p.$active) return tw`bg-primary`;
        return tw`
        bg-gray`;
    }}
`;

export const StatusCirclePing = styled.div`
    ${tw`
      animate-ping
      absolute
      inline-flex
      h-full
      w-full 
      rounded-full 
      bg-primary 
      opacity-75
    `}
`;

export const StatusCircleColor = styled.div<IStylesParams>`
    ${tw`
    relative
    inline-flex
    rounded-full
    h-[30px]
    w-[30px]
    bg-gray
    `}

    ${(p) => {
        if (p.$active) return tw`bg-primary`;
    }}
`;

export const StatusCanceledIcon = styled(IconClose)`
    ${tw`
      h-[30px]
      w-[30px]
      fill-primary
    `}
`;
