import React, { FC, SVGProps } from 'react';

const IconDairy: FC<SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M27.59 21.702h-1.451c-.05-1.78-1.288-3.21-2.802-3.21-1.515 0-2.752 1.43-2.802 3.21h-.339c-.03-1.069-.488-2.012-1.178-2.594V8.083v-.008a.408.408 0 00-.102-.271l-2.524-2.987V2.468c0-.697-.568-1.265-1.266-1.265H6.36c-.698 0-1.266.568-1.266 1.265v2.349L2.564 7.81a.41.41 0 00-.096.265v1.938a.41.41 0 10.82 0V8.485h14.91V18.63a2.395 2.395 0 00-.804-.139c-1.514 0-2.752 1.43-2.802 3.21h-1.451a.41.41 0 100 .82h.647l.956 3.437H12.02a.947.947 0 00.006-.108v-.926c0-.28-.21-.51-.489-.535a.837.837 0 01-.763-.838c0-.438.328-.799.763-.839a.535.535 0 00.49-.534v-1.325a.54.54 0 00-.464-.534.385.385 0 010-.76.539.539 0 00.463-.535v-.63a.93.93 0 00-.929-.93h-7.81v-5.812a.41.41 0 10-.82 0v5.813h-.522A1.948 1.948 0 000 19.41v5.423a1.948 1.948 0 001.945 1.946h17.253a.815.815 0 00.783-.595l.384-1.381.385 1.38c.097.351.42.596.783.596h3.608a.815.815 0 00.783-.595l1.019-3.663h.647a.41.41 0 100-.82zM17.724 7.665H3.761l1.934-2.288h10.096l1.933 2.288zM6.36 2.023h8.767c.245 0 .445.2.445.445v2.088H5.914V2.468c0-.245.2-.445.446-.445zm16.977 17.29c1.064 0 1.934 1.061 1.98 2.389h-3.962c.047-1.328.918-2.39 1.982-2.39zm-5.943 0a1.662 1.662 0 01.999.34c.564.417.952 1.176.982 2.049h-3.962c.047-1.328.917-2.39 1.98-2.39zM1.945 25.958c-.62 0-1.125-.504-1.125-1.125v-5.423c0-.62.505-1.125 1.125-1.125h.195a1.933 1.933 0 00-.359 1.125v.965a.41.41 0 00.82 0v-.965c0-.62.505-1.125 1.125-1.125h7.371c.06 0 .109.049.109.109v.413a1.21 1.21 0 00-.794 1.132c0 .513.327.962.794 1.132v.868a1.653 1.653 0 00-1.252 1.612c0 .776.522 1.43 1.252 1.612v.687a.109.109 0 01-.102.108H3.726c-.62 0-1.125-.504-1.125-1.125v-2.817a.41.41 0 10-.82 0v2.817c0 .42.133.808.36 1.125h-.196zm23.19 0h-3.597l-.478-1.72a.56.56 0 00-.538-.408h-.313a.56.56 0 00-.538.408l-.479 1.72h-3.596l-.957-3.437h11.453l-.957 3.437z" />
            <path d="M4.697 21.102c.615 0 1.115-.5 1.115-1.115a1.116 1.116 0 00-2.23 0c0 .615.5 1.115 1.115 1.115zm0-1.41a.295.295 0 110 .59.295.295 0 010-.59zM7.476 25.025a1.57 1.57 0 001.568-1.568 1.57 1.57 0 00-1.568-1.568 1.57 1.57 0 00-1.568 1.568 1.57 1.57 0 001.568 1.568zm0-2.316a.749.749 0 11-.002 1.497.749.749 0 01.002-1.497zM6.801 14.596c1.211-.026 2.442.611 3.746 1.286 1.31.679 2.663 1.38 4.051 1.38l.115-.003a.734.734 0 00.716-.738v-2.875a.73.73 0 00-.222-.526.729.729 0 00-.537-.211c-1.21.026-2.442-.611-3.746-1.286-1.346-.697-2.738-1.418-4.166-1.377a.734.734 0 00-.715.738v2.875a.74.74 0 00.758.738zm.062-3.532h.012c1.186 0 2.395.626 3.672 1.287 1.31.678 2.663 1.379 4.05 1.379h.011v2.71h-.012c-1.186 0-2.394-.625-3.672-1.286-1.31-.678-2.663-1.379-4.05-1.379h-.011v-2.71z" />
        </svg>
    );
};

export default IconDairy;
