import { UseMutationOptions } from '@tanstack/react-query';
import { WishlistService } from '../../services';
import { IWishlistRemoveItem } from '../../services/wishlist/types';
import { useMutationCancelToken } from '../use-mutation-cancel-token';

export const useWishlistRemoveItem = (options?: UseMutationOptions<void, unknown, IWishlistRemoveItem>) => {
    return useMutationCancelToken<void, unknown, IWishlistRemoveItem>(
        (axiosParams, params) => WishlistService.remove(params, axiosParams),
        options
    );
};
