import Link from 'next/link';
import { useCallback } from 'react';

import { EstablishmentService, OrdersService } from '../../../services';
import { Order } from '../../../services/order/types';
import { toCurrency } from '../../../utils/currency';
import { formatDate } from '../../../utils/date';
import { ArrowRight } from '../Breadcrumb/styles';
import { Badge } from './styles';
import { getUniqueStatus } from '../../../services/order';

export interface IOrdersListProps {
    orders?: Order[];
}

const OrdersList: React.FC<IOrdersListProps> = ({ orders }) => {
    const headers = [
        'Número do Pedido',
        'Status',
        'Data de compra',
        'Vendido por',
        'Entregue por',
        'Quantidade de itens',
        'Valor',
        ''
    ];

    const renderRecipient = useCallback((order: Order) => {
        const address = order.extension_attributes?.shipping_assignments[0]?.shipping?.address;

        if (address) {
            const { firstname, lastname } = address;
            return `${firstname} ${lastname}`;
        }

        return '-';
    }, []);

    const buildOrderLink = useCallback((order: Order) => {
        return EstablishmentService.createUrl(`minha-conta/pedidos/${order.entity_id}`);
    }, []);

    if (!orders) {
        return null;
    }

    return (
        <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full align-middle sm:px-6 lg:px-8">
                    <div className="overflow-hidden ">
                        <div className="items-center hidden grid-cols-[repeat(8,1fr)] py-3 font-medium tracking-wider text-center text-gray500 bg-gray50 lg:grid">
                            {headers.map((header, index) => (
                                <div key={index} className={header === 'Valor' ? 'text-right' : ''}>
                                    {header}
                                </div>
                            ))}
                        </div>
                        {orders.map((order, index) => (
                            <Link href={buildOrderLink(order)} key={index}>
                                <div className="flex flex-col lg:grid lg:grid-cols-[repeat(8,1fr)] bg-[#f7f7f7d9] rounded-8 my-5 overflow-hidden shadow font-semibold place-items-center">
                                    <div className="flex items-center justify-between px-4 lg:px-0 w-full py-4 font-bold whitespace-nowrap lg:justify-center">
                                        <p className="text-12 lg:hidden">Número do Pedido</p>#{order.increment_id}
                                    </div>
                                    <div className="flex items-center justify-between px-4 lg:px-0 w-full py-4 whitespace-nowrap lg:justify-center">
                                        <p className="text-12 lg:hidden">Status do Pedido</p>
                                        <span className="min-w-[128px] lg:w-full flex lg:justify-center">
                                            <Badge>{getUniqueStatus(order.status)}</Badge>
                                        </span>
                                    </div>
                                    <div className="flex items-center justify-between px-4 lg:px-0 w-full py-4 whitespace-nowrap lg:justify-center">
                                        <p className="text-12 lg:hidden">Data da Compra</p>
                                        {formatDate(order.created_at)}
                                    </div>
                                    <div className="flex justify-between w-full items-center px-4 lg:px-0 py-4 whitespace-nowrap text-[#498122] lg:justify-center">
                                        <p className="text-12 text-[black] lg:hidden">Vendido por</p>
                                        {'Matcon.casa'}
                                    </div>
                                    <div className="flex justify-between w-full items-center px-4 lg:px-0 py-4 whitespace-nowrap text-[#498122] lg:justify-center">
                                        <p className="text-12 text-[black] lg:hidden">Entregue por</p>
                                        {'Matcon.casa'}
                                    </div>
                                    <div className="flex items-center justify-between px-4 lg:px-0 w-full py-4 whitespace-nowrap lg:justify-center">
                                        <p className="text-12 lg:hidden">Itens</p>
                                        {order.items.length} Itens
                                    </div>
                                    <div className="flex items-center justify-between px-4 lg:px-0 w-full py-4 whitespace-nowrap lg:justify-end">
                                        <p className="text-12 lg:hidden">Valor do Pedido</p>
                                        {toCurrency(order.base_subtotal + order.base_shipping_amount)}
                                    </div>
                                    <div className="hidden col-span-1 px-6 py-6 whitespace-nowrap lg:block">
                                        <div className="text-indigo-600 hover:text-indigo-900">
                                            <ArrowRight style={{ margin: 0, fill: '#81AB05' }} />
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrdersList;
