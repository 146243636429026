import tw, { styled } from 'twin.macro';
import { getIsBreakPoint } from '../../../utils/get-config-tailwind';
import { IconClose, IconPlus, Modal, TextLatoBold15, TextLatoRegular18 } from '../../atoms';

export const Container = styled(Modal).attrs(() => {
    const isMd = getIsBreakPoint('md');

    return {
        animate: {
            borderTopLeftRadius: '15px',
            borderTopRightRadius: '15px',
            borderBottomLeftRadius: isMd ? '15px' : 0,
            borderBottomRightRadius: isMd ? '15px' : 0,
            display: 'flex'
        },
        initial: {
            borderTopLeftRadius: '15px',
            borderTopRightRadius: '15px',

            display: 'flex'
        }
    };
})`
    ${tw`
        md:max-w-[400px]
        h-auto
        flex-col
        rounded-15
        overflow-hidden
    `}
`;

export const Header = styled.form`
    ${tw`
        px-[15px]
        py-[15px]
        gap-[15px]
        flex
        flex-row
        justify-between
        items-center
    `}
`;

export const TextTitle = styled(TextLatoRegular18)``;
export const Close = styled(IconClose)`
    ${tw`
    w-[20px]
    fill-black
    `}
`;
export const Plus = styled(IconPlus)`
    ${tw`
    w-[20px]
    fill-black
    `}
`;
