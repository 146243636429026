import { forwardRef, HTMLAttributes, Ref } from 'react';
import { ContainerIcon } from './styles';
interface ICarouselArrowRoundProps extends Partial<HTMLAttributes<HTMLDivElement>> {
    left?: boolean;
    round?: boolean;
}
const CarouselArrowRound = forwardRef(
    ({ onClick, left = false, round = false, ...rest }: ICarouselArrowRoundProps, ref: Ref<any>) => {
        if (round) {
            return (
                <ContainerIcon ref={ref} {...rest}>
                    {left ? (
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-10 h-10" viewBox="0 0 40 40" fill="none">
                            <rect width="40" height="40" rx="20" transform="matrix(-1 0 0 1 40 0)" fill="#9FBE43" />
                            <path
                                d="M23.3333 13.3334L16.6666 20.1026L23.3333 26.6667"
                                stroke="white"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    ) : (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-10 h-10 rotate-180"
                            viewBox="0 0 40 40"
                            fill="none"
                        >
                            <rect width="40" height="40" rx="20" transform="matrix(-1 0 0 1 40 0)" fill="#9FBE43" />
                            <path
                                d="M23.3333 13.3334L16.6666 20.1026L23.3333 26.6667"
                                stroke="white"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    )}
                </ContainerIcon>
            );
        }

        return (
            <ContainerIcon ref={ref} {...rest}>
                {left ? (
                    <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M9 1L1 9.12308L9 17"
                            stroke="#9FBE43"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                ) : (
                    <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0.999997 1L9 9.12308L0.999997 17"
                            stroke="#9FBE43"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                )}
            </ContainerIcon>
        );
    }
);

export default CarouselArrowRound;
