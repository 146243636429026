import tw, { styled } from 'twin.macro';

export const Container = styled.div`
    ${tw`
        flex
        flex-col
        flex-1
        relative
    `}
`;
export const Pin = styled.div`
    background-image: url(/images/pin.png);
    ${tw`
        w-[25px]
        h-[25px]
        bg-center
        bg-no-repeat
        absolute
        top-[50%]
        left-[50%]
        z-50
        
    `}
`;
