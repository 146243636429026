import { useEffect, useState } from 'react';
import { LayoutFitter } from '../../templates';
import { Container, Content, List } from './styles';
import block from '../../../services/block';

function ShippingBanner() {
    const [random] = useState(Math.floor(Math.random() * 4));
    const [blockContent, setBlockContent] = useState<string>('');

    const getBlockTextById = async (id: number) => {
        const response = await block.getBlockTextById(id);
        const modifiedContent = modifyBlockContent(response.content);
        setBlockContent(modifiedContent);
    };

    const modifyBlockContent = (content: string) => {
        const style = `
            <style>
                @media (max-width: 768px) {
                    tr {
                        all: unset !important;
                    }

                    #block-01, #block-02, #block-03, #block-04 {
                        display: none;
                    }

                    #block-0${random + 1} {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                    }
                }
            </style>
        `;

        return style + content;
    };

    useEffect(() => {
        getBlockTextById(29);
    }, []);
    return (
        <Container>
            <LayoutFitter className="!px-0 md:px-4">
                <Content>
                    <List dangerouslySetInnerHTML={{ __html: blockContent || '' }}></List>
                </Content>
            </LayoutFitter>
        </Container>
    );
}

export default ShippingBanner;
