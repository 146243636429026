import React, { FC, SVGProps } from 'react';

const IconHygiene: FC<SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M24.938 8.75H24.5v-.438a1.314 1.314 0 00-.875-1.237V5.687a.438.438 0 00-.438-.437V3.5h1.75a1.313 1.313 0 000-2.625h-4.375a1.314 1.314 0 00-1.312 1.313v.874a.437.437 0 00.438.438h.875v1.75a.437.437 0 00-.438.438v1.387a1.315 1.315 0 00-.875 1.237v.438h-.438a2.19 2.19 0 00-2.187 2.188v1.312H14V7h2.188a.438.438 0 00.437-.438v-5.25a.438.438 0 00-.438-.437h-3.5a1.314 1.314 0 00-1.312 1.313V12.25H9.253l.372-8.731a.437.437 0 00-.437-.457H1.313a.438.438 0 00-.438.457l.875 20.553v2.616a.438.438 0 00.438.437h22.75a2.19 2.19 0 002.187-2.188v-14a2.19 2.19 0 00-2.188-2.187zM22.75 6.125V7H21v-.875h1.75zm-2.625-3.938a.438.438 0 01.438-.437h4.375a.438.438 0 010 .875h-4.813v-.438zM21.438 3.5h.875v1.75h-.875V3.5zm-1.313 4.813a.438.438 0 01.438-.438h2.625a.438.438 0 01.437.438v.437h-3.5v-.438zm.438 14a1.314 1.314 0 01-1.313 1.312h-7a1.314 1.314 0 01-1.313-1.313v-2.625a1.314 1.314 0 011.313-1.312h7a1.314 1.314 0 011.313 1.313v2.625zM16.625 17.5H12.25a2.19 2.19 0 00-2.188 2.188v2.625c0 .473.154.934.44 1.312H9.187a.438.438 0 00-.438.438v.875c0 .473.154.934.439 1.312H7.875v-.438a.438.438 0 00-.875 0v.438H5.25V13.125H7v.438a.438.438 0 00.875 0v-.438h8.75V17.5zM15.75 2.625a.437.437 0 100 .875v.875a.437.437 0 100 .875v.875h-.95c.05-.14.075-.288.075-.438v-3.5c0-.149-.025-.297-.075-.437h.95v.875zm-3.5-.438a.438.438 0 01.438-.437h.874a.438.438 0 01.438.438v3.5a.438.438 0 01-.438.437H12.25V2.187zm0 4.813h.875v5.25h-.875V7zm-7.437 5.25a.438.438 0 00-.438.438v10.937H2.607L1.843 5.687h6.814l-.28 6.563H4.813zM8.73 3.937l-.037.876H1.806l-.037-.875h6.962zM2.625 24.5h1.75v1.75h-1.75V24.5zm8.313 1.75a1.314 1.314 0 01-1.313-1.313V24.5h12.25v.438a1.314 1.314 0 01-1.313 1.312h-9.625zm15.312-1.313a1.314 1.314 0 01-1.313 1.313h-2.626c.285-.378.44-.839.439-1.313v-.875a.438.438 0 00-.438-.437H21c.285-.378.439-.839.439-1.313v-2.625A2.19 2.19 0 0019.25 17.5H17.5v-6.563a1.314 1.314 0 011.313-1.312h6.125a1.314 1.314 0 011.312 1.313v14z" />
            <path d="M21.875 10.938c-1.717 0-3.063.96-3.063 2.187 0 1.227 1.346 2.188 3.063 2.188s3.063-.961 3.063-2.188c0-1.227-1.346-2.188-3.063-2.188zm0 3.5c-1.186 0-2.188-.602-2.188-1.313s1.002-1.313 2.188-1.313c1.186 0 2.188.602 2.188 1.313s-1.002 1.313-2.188 1.313zM7.438 14.875a.438.438 0 00-.438.438v.874a.438.438 0 00.875 0v-.875a.438.438 0 00-.438-.437zM7.438 17.5a.438.438 0 00-.438.438v.875a.438.438 0 00.875 0v-.875a.438.438 0 00-.438-.438zM7.438 20.125a.438.438 0 00-.438.438v.875a.438.438 0 00.875 0v-.875a.438.438 0 00-.438-.438zM7.438 22.75a.438.438 0 00-.438.438v.875a.438.438 0 00.875 0v-.875a.438.438 0 00-.438-.438z" />
        </svg>
    );
};

export default IconHygiene;
