import { useMutation, useQuery } from '@tanstack/react-query';
import { useCart } from '../../../../../hooks';
import { CartService, ElasticService } from '../../../../../services';
import { IProduct } from '../../../../../services/product/types';
import { UP_SELL_PRODUCTS } from '../../../../../utils/react-query-keys';
import UpSellDesktop from './Up-sell-desktop';
import UpSellMobile from './Up-sell-mobile';

interface UpSellProps {
    data: IProduct;
}

export default function UpSell({ data }: UpSellProps) {
    const product_links = data.product_links?.filter((link) => link.link_type === 'upsell');

    const { data: cart } = useCart();

    const { data: products, isLoading: isLoadingProducts } = useQuery(
        [UP_SELL_PRODUCTS, data.id],
        () =>
            ElasticService.getProducts({
                filter: {
                    bool: {
                        must: [
                            {
                                query_string: {
                                    query: product_links?.map((link) => link.linked_product_sku).join(' OR ') || '',
                                    fields: ['sku']
                                }
                            }
                        ]
                    }
                },
                limit: 10,
                page: 1
            }),
        { enabled: !!product_links?.length }
    );

    const { mutate: onClickAddCart, isLoading: isLoadingAddCart } = useMutation(async (products: IProduct[]) => {
        await Promise.all(
            products.map(async (product) => {
                const exists = cart && CartService.findProduct(cart?.items, undefined, product.sku);

                if (exists) {
                    return CartService.updateItem({
                        itemId: exists.item_id,
                        qty: 1 + exists.qty,
                        name: data.name,
                        isAdd: true,
                        wait: true,
                        price: data.price,
                        newQty: 1
                    });
                } else {
                    let options = undefined;
                    if (data.configurable_options) {
                        // options = {
                        //     extension_attributes: {
                        //         configurable_item_options: attrs
                        //     }
                        // };
                    }
                    return CartService.add(product.sku, 1, options);
                }
            })
        );
    });

    if (!product_links?.length || (!isLoadingProducts && !products?.items.length)) {
        return null;
    }

    const productsList = products?.items.filter((product) => product.inStock);

    if (!productsList?.length) return null;

    return (
        <div className="px-4 md:px-0">
            <UpSellMobile
                data={data}
                products={productsList}
                isLoadingProducts={isLoadingProducts}
                onAdd={onClickAddCart}
                isLoadingAddCart={isLoadingAddCart}
            />
            <UpSellDesktop
                data={data}
                products={productsList}
                isLoadingProducts={isLoadingProducts}
                onAdd={onClickAddCart}
                isLoadingAddCart={isLoadingAddCart}
            />
        </div>
    );
}
