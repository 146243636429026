import { Container, Item, Items, ItemText, TextTitle } from './styles';

interface IAttributeSelectValue {
    id: string;
    name: string;
    disabled?: boolean;
}

interface IAttributeSelectProps {
    title: string;
    items: IAttributeSelectValue[];
    selected?: IAttributeSelectValue['id'];
    onSelect?: (id: IAttributeSelectValue['id']) => void;
}

function AttributeSelect({ title, items, selected, onSelect }: IAttributeSelectProps) {
    return (
        <Container>
            <TextTitle>{title}</TextTitle>
            <Items>
                {items.map((item) => (
                    <Item
                        type="button"
                        key={item.id}
                        onClick={() => onSelect && onSelect(item.id)}
                        disabled={item.disabled}
                        animate={selected === item.id ? 'selected' : 'unselected'}
                        initial={selected === item.id ? 'selected' : 'unselected'}
                    >
                        <ItemText>{item.name}</ItemText>
                    </Item>
                ))}
            </Items>
        </Container>
    );
}

export default AttributeSelect;
