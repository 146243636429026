import { animate, motion } from 'framer-motion';
import tw, { styled } from 'twin.macro';

export const Container = styled.div`
    ${tw`
        flex
        flex-col
        gap-4
        md:gap-7
    `}
`;

export const Columns = styled.div`
    ${tw`
        flex
        flex-row
        overflow-x-auto
        gap-2
        md:gap-16
    `}
`;

export const Content = styled(motion.div).attrs(() => ({
    variants: {
        visible: {
            opacity: 1
        },
        hidden: {
            opacity: 0
        }
    },
    animate: 'visible',
    initial: 'hidden',
    exit: 'hidden'
}))`
    ${tw`
        
    `}
`;
