import tw, { styled } from 'twin.macro';

export const Container = styled.div`
    ${tw`mt-6 mb-4 lg:mt-10 lg:min-h-[280px]
    px-4`}
`;

export const TitlePage = styled.span`
    ${tw`flex items-center gap-6 mt-2 `}
`;
