import Link from 'next/link';
import { ProductService } from '../../../../../../../services';
import { IProduct } from '../../../../../../../services/product/types';
import { currencyBrl } from '../../../../../../../utils/getFormattedValue';
import { capitalizeEveryLetter } from '../../../../../../../utils/string';
import { CustomCheckboxSquare } from '../../../../../../atoms/CheckboxSquare';
import ProductImage from '../../../../../../atoms/Product-image';
import { PropsWithChildren } from 'react';

function LinkProduct({ href, children, isPrimary }: PropsWithChildren<{ href: string; isPrimary?: boolean }>) {
    if (isPrimary) return <>{children}</>;

    return (
        <Link href={href} passHref>
            {children}
        </Link>
    );
}

interface MainProductProps {
    data: IProduct;
    isPrimary?: boolean;
    isChecked?: boolean;
    onCheck?: (checked: boolean, data: IProduct) => void;
}

export default function MainProduct({ data, isPrimary, isChecked, onCheck }: MainProductProps) {
    const isPrimaryClass = isPrimary ? 'border-green500' : 'border-gray100';

    return (
        <div
            className={`flex flex-col relative flex-1 min-w-[257px] max-w-[257px] bg-white rounded-8 border-[0.5px] shrink-0 h-[296px] ${isPrimaryClass} px-8 py-4 gap-2 relative`}
        >
            <LinkProduct href={ProductService.getRouterProduct(data)} isPrimary={isPrimary}>
                <ProductImage
                    data={data}
                    width={192}
                    height={208}
                    className="rounded-8 shrink-0 !h-[208px] object-contain object-center"
                />
            </LinkProduct>
            <LinkProduct href={ProductService.getRouterProduct(data)} isPrimary={isPrimary}>
                <h3 className="text-12 font-semibold text-black line-clamp-3">{capitalizeEveryLetter(data.name)}</h3>
            </LinkProduct>
            {!isPrimary && (
                <div className="absolute top-2 right-2">
                    <CustomCheckboxSquare
                        isChecked={isChecked}
                        onCheckboxSquareChange={(checked) => onCheck?.(checked, data)}
                    />
                </div>
            )}
        </div>
    );
}
