import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { BlockService } from '../../services';
import { BLOCK_MY_ACCOUNT_BANNER } from '../../utils/react-query-keys';

export const useBlockMyAccountBanner = (params?: UseQueryOptions<string>) => {
    return useQuery<string>([BLOCK_MY_ACCOUNT_BANNER], () => BlockService.getBlockMyAccountBanner(), {
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        ...params
    });
};
