import React, { useCallback, useMemo, useState } from 'react';
import { useWishlistDelete } from '../../../hooks';
import { EstablishmentService, RouterService } from '../../../services';
import { Wishlist, WishListItem } from '../../../services/wishlist/types';
import { twColors } from '../../../utils/get-config-tailwind';
import { IconSpinner } from '../../atoms';
import { Spinner } from '../../atoms/Image/styles';
import ModalPortal from '../../atoms/Modal-portal';
import ModalConfirm from '../../organisms/Modal-confirm';
import {
    ButtonDetail,
    InfoData,
    InfoHeader,
    ListItemContainer,
    ListItemContent,
    ListItemHeader,
    ListItemInfoContainer,
    ListItemTitle,
    TrashIcon
} from './styles';
import * as NProgress from 'nprogress';

export interface IListItemProps {
    data: IWishListDetailedItem;
}

export interface IWishListDetailedItem extends Wishlist {
    item: WishListItem[];
}

const WishlistItem = ({ data }: IListItemProps) => {
    const [modalConfig, setModalConfig] = useState(false);
    const { name, item } = data;

    const { mutate: deleteWishlist, isLoading: isLoadingDelete } = useWishlistDelete();

    const handlePressTrash = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        setModalConfig(true);
    }, []);
    const qty = useMemo(() => {
        if (data.item && data.item.length) {
            return item?.map((elem) => parseInt(elem.qty))?.reduce((prev, next) => prev + next);
        }

        return 0;
    }, [data.item]);

    const onClick = () => {
        RouterService.toRoute({
            options: {
                url: EstablishmentService.createUrl(`/minha-conta/listas/${data.wishlist_id}`)
            }
        });
    };

    const IconTrash = useMemo(
        () =>
            isLoadingDelete ? (
                <IconSpinner stroke={twColors.primary} width={15} height={15} />
            ) : (
                <div onClick={handlePressTrash}>
                    <TrashIcon />
                </div>
            ),
        [isLoadingDelete, handlePressTrash]
    );

    const handleConfirmDelete = useCallback(() => {
        deleteWishlist(data.wishlist_id);
        setModalConfig(false);
    }, [data.wishlist_id]);

    const handleCloseModalConfig = useCallback(() => {
        setModalConfig(false);
    }, []);

    return (
        <>
            <ListItemContainer onClick={onClick}>
                <ListItemHeader>
                    <ListItemTitle>{name}</ListItemTitle>
                    {IconTrash}
                </ListItemHeader>
                <ListItemContent>
                    <ListItemInfoContainer title="Soma dos produtos">
                        <InfoHeader>Produtos</InfoHeader>
                        <InfoData>{item.length}</InfoData>
                    </ListItemInfoContainer>
                    <ListItemInfoContainer title="Soma da quantidade de todos os produtos">
                        <InfoHeader>Quantidade</InfoHeader>
                        <InfoData>{qty}</InfoData>
                    </ListItemInfoContainer>
                </ListItemContent>
                <div style={{ display: 'flex' }}>{/* <ButtonDetail text="Visualizar" variant={'extraSmall'} /> */}</div>
            </ListItemContainer>
            <ModalPortal>
                {modalConfig && (
                    <ModalConfirm
                        onClose={handleCloseModalConfig}
                        onPressConfirm={handleConfirmDelete}
                        onPressCancel={handleCloseModalConfig}
                        text="Deseja realmente excluir essa lista?"
                    />
                )}
            </ModalPortal>
        </>
    );
};

export default WishlistItem;
