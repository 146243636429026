import tw, { styled } from 'twin.macro';
import { NEXT_PUBLIC_HOME_LOGIN_SHOWBACKGROUND } from '../../../utils/envs';
import { ClickableAreaIcon, IconClose } from '../../atoms';

const height = 192;

const showBackground = NEXT_PUBLIC_HOME_LOGIN_SHOWBACKGROUND;

export const Container = styled.div`
    height: ${height}px;
    ${tw`
        w-full
        bg-cover
        /* blur-sm  */
        bg-white
        relative
        flex
        flex-col
    `}
`;

export const Overlay = styled.div`
    ${tw`
       bg-black
       opacity-50
       w-full
       absolute
    `}
    height: ${height}px;
`;

export const Content = styled.div`
    ${tw`
        flex-1
        flex
        flex-row
    `}
`;
export const ContainerLogo = styled.div`
    width: 242px;
    height: 126px;
    ${() =>
        showBackground
            ? tw`bg-primary`
            : tw`
    bg-transparent
    h-full
    `}
    ${tw`
      rounded-b-30
      flex
      flex-1
      flex-col
      justify-center
      items-center
    `}
`;

export const Bar = styled.div`
    ${tw`
        bg-primary
        h-[5px]
        bottom-0  
        absolute
        w-full
    `}
`;

export const ContainerIcon = styled(ClickableAreaIcon)`
    ${tw`
        absolute
        right-[20px]
        top-[20px]
    `}
`;
export const Icon = styled(IconClose)`
    ${tw`
        fill-white
        w-[20px]
        h-[20px]
        drop-shadow-md
    `}
`;
