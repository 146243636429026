import { EstablishmentService, UserService } from '../../services';

export const useIsCnpjAvailable = async () => {
    const estabilishment = await EstablishmentService.getEstablishmentsStorage();
    const user = UserService.getUserInfoStorage();
    const taxVat = user?.taxvat;

    // isCnpjAvailable é "1", "0" ou null
    const isCNpjDisabled = estabilishment?.list.find((item) => item.isCnpjAvailable)?.isCnpjAvailable !== '1';

    if (taxVat && isCNpjDisabled) {
        const cleanedTaxVat = taxVat.replace(/\D/g, '');

        if (cleanedTaxVat.length > 11) {
            return false;
        }
        return true;
    }
    return true;
};
