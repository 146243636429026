import React, { FC, useCallback, useEffect, useState } from 'react';

import { motion, AnimatePresence } from 'framer-motion';

import { useWindowWidth } from '../../../hooks';
import { MyAccMenuItem } from '../../molecules/My-account-menu-item';
import { MenuItem } from '../../molecules/My-account-menu-item/types';
import { Container, Icon, MenuItems, MenuHeader, CollapsableZone, MenuTitle } from './styles';
import { AuthService, UserService } from '../../../services';
import { useUserInfo } from '../../../hooks/use-user-info';

const LG_BREAKPOINT = 1024;

const MyAccountMenu: FC = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [code, setCode] = useState('');
    const userInfo = useUserInfo();
    const width = useWindowWidth();

    useEffect(() => {
        if (userInfo && userInfo.data?.id) {
            UserService.isAffiliate(userInfo.data?.id)
                .then((response) => setCode(response))
                .catch((error) => console.error(error));
        }
    }, [userInfo]);

    const items: MenuItem[] = [
        { label: 'Meus Pedidos', link: 'pedidos' },
        { label: 'Minhas informações', link: 'gerenciar-dados' },
        { label: 'Minhas listas', link: 'favoritos' },
        {
            label: 'Sair',
            onClick: async () => {
                await AuthService.logout();
                return true;
            }
        }
    ];

    const toggleMenuVisibility = useCallback(() => {
        if (width && width >= LG_BREAKPOINT) {
            return setIsOpen(true);
        }

        return setIsOpen((isOpen) => !isOpen);
    }, [width]);

    useEffect(() => {
        if (width && width >= LG_BREAKPOINT) {
            toggleMenuVisibility();
        }
    }, [width, toggleMenuVisibility]);

    return (
        <Container>
            <MenuTitle>Minha conta</MenuTitle>

            <MenuHeader onClick={toggleMenuVisibility}>
                <Icon />
            </MenuHeader>

            <CollapsableZone initial={false}>
                <AnimatePresence initial={false}>
                    {isOpen && (
                        <motion.article
                            key="content"
                            initial="collapsed"
                            animate="open"
                            exit="collapsed"
                            style={{ width: '100%' }}
                            transition={{
                                duration: 0.5,
                                ease: [0.04, 0.62, 0.23, 0.98]
                            }}
                            variants={{
                                open: { opacity: 1, height: 'auto' },
                                collapsed: { opacity: 0, height: 0 }
                            }}
                        >
                            <MenuItems>
                                {items.map((item, index) => (
                                    <MyAccMenuItem key={index} $isDanger={item.label === 'Sair'} {...item} />
                                ))}
                            </MenuItems>
                        </motion.article>
                    )}
                </AnimatePresence>
            </CollapsableZone>
        </Container>
    );
};

export default MyAccountMenu;
