import { CART } from '../../utils/react-query-keys';
import api from '../api';
import { Cart } from '../cart/types';
import { queryClient } from '../query-client';
import { Card, TtxPayCard, TtxPayCardData } from './types';

export interface CardResponse {
    id: string;
    brand: string;
    masked_number: string;
    created_at: string;
}

const getAll = async (): Promise<CardResponse[]> => {
    const { id: cartId } = queryClient.getQueryData<Cart>([CART]) as Cart;

    const body = {
        data: {
            cartId
        }
    };

    const {
        data: [cards]
    } = await api.post('V1/tatix/mundipagg/credit-card/list', body);
    if (cards.success === false) {
        return [];
    }

    return cards.data;
};

const getTtxPayCards = async (cardData: TtxPayCardData): Promise<Card[]> => {
    const body = {
        data: cardData
    };

    const data = await (
        await api.post<TtxPayCard[]>(`V1/api/ttxpay/card_token?`, body)
    ).data.map((data) => {
        return {
            ...data.card,
            id: data.id,
            birthday: data.customer.birthday,
            cpf: data.customer.documents?.find((item) => item.number)?.number,
            created_at: data.created_at
        };
    });

    return data;
};

const deleteTtxPayCard = async (id: number) => {
    const body = {
        data: {
            card_id: id
        }
    };

    const data = await await api.post(`V1/api/ttxpay/card_token/delete`, body);

    return data;
};

export default {
    getAll,
    getTtxPayCards,
    deleteTtxPayCard
};
