import { motion } from 'framer-motion';
import tw, { styled } from 'twin.macro';
import { IconSpinner, TextLatoRegular13, TextLatoRegular15 } from '../../atoms';

export const Container = styled(motion.div).attrs(() => ({
    initial: {
        height: '0'
    },
    exit: {
        height: '0'
    },
    animate: {
        height: 'auto'
    }
}))`
    ${tw`
    `}
`;

export const TextTitle = styled(TextLatoRegular13)`
    ${tw`
        
    `}
`;
export const TextSubTitle = styled(TextLatoRegular13)`
    ${tw`
        
    `}
`;

export const Checkbox = styled.input`
    ${tw`
    `}
`;
export const CntrSpinner = styled.div`
    ${tw`
        ml-[16px]
    `}
`;
export const Spinner = styled(IconSpinner)`
    ${tw`
        w-[15px]
        h-[15px]
        stroke-primary
    `}
`;

export const TextInfo = styled(TextLatoRegular13)`
    ${tw`
        text-[12px]
        text-darkGray
        mt-[10px]
    `}
`;
export const Separator = styled.div`
    ${tw`
        h-[1px]
        bg-gray
        my-[10px]
    `}
`;

export const CntrCheckbox = styled.div`
    ${tw`
        flex    
        flex-row
        justify-center
        mt-[10px]
    `}
`;
export const BagText = styled(TextLatoRegular15)`
    ${tw`
        flex-1
    `}
`;
