import { FC } from 'react';
import { RecoveryPasswordForm } from '../../organisms';
import Layout from '../Layout';
import LayoutFitter from '../Layout-fitter';
import { Container, FormContainer, PersonIcon, TitleContainer, TitleLabel } from './styles';

import { IPasswordRecoveryProps } from './types';

const PasswordRecovery: FC<IPasswordRecoveryProps> = ({ resetToken }) => {
    return (
        <Layout>
            <LayoutFitter>
                <Container>
                    <TitleContainer>
                        <PersonIcon />
                        <TitleLabel>Recuperação de senha</TitleLabel>
                    </TitleContainer>
                    <FormContainer>
                        <RecoveryPasswordForm resetToken={resetToken} />
                    </FormContainer>
                </Container>
            </LayoutFitter>
        </Layout>
    );
};

export default PasswordRecovery;
