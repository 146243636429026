import { capitalize } from '@mui/material';
import { useCategories } from '../../../../../hooks';
import { CategoryService, ProductService } from '../../../../../services';
import { IProduct } from '../../../../../services/product/types';
import { currencyBrl } from '../../../../../utils/getFormattedValue';
import { capitalizeEveryLetter } from '../../../../../utils/string';
import BadgeProductDiscount from '../../../../atoms/Badge-product-discount';
import { BadgeSize } from '../../../../atoms/Badge/types';
import { useQtyProduct } from '../../hooks/useQtyProduct';
import AttributeSelect from '../Attribute-select';
import Buttons from '../Buttons';
import CartQty from '../Cart-qty';
import ProductOutStock from '../Product-out-stock';
import {
    Attributes,
    Category,
    Container,
    Header,
    InstallmentsText,
    PriceContainer,
    PriceFrom,
    PriceText,
    TextTitle
} from './styles';
import InstallmentsModal from '../Installments-modal';

import ProductSoldBy from '../../../../atoms/Product-sold-by';

interface ITitleProps {
    data: IProduct;
}

function Title({ data }: ITitleProps) {
    const percentDiscount = data.discount_percentage;
    const { data: categories } = useCategories();
    const { setAttr, attrs } = useQtyProduct();

    const lastCategory =
        data.extension_attributes?.category_links?.[data.extension_attributes?.category_links?.length - 1];
    const category =
        lastCategory && categories && CategoryService.getCategoryFromCategories(lastCategory.category_id, categories);

    //     <div>
    //     {data.installments?.parcelas && (
    //         <InstallmentsText>
    //             {/* R$ {currencyBrl(data.installments.valor)} em até {data.installments.parcelas}x sem Juros */}
    //             Em até {data.installments.parcelas}x de R${currencyBrl(data.installments.valor)} sem
    //             juros
    //         </InstallmentsText>
    //     )}
    // </div>

    return (
        <Container>
            <Header>
                <div className="flex flex-col flex-1 gap-2">
                    <div className="flex flex-row items-center gap-4">
                        <Category>{capitalize(category?.name || '')}</Category>
                        {/* <Sku>SKU {data.sku}</Sku> */}
                    </div>
                    <ProductSoldBy className="hidden md:block !text-16" />
                </div>
                <div className="hidden md:flex">
                    {percentDiscount && percentDiscount > 0 && (
                        <BadgeProductDiscount size={BadgeSize.LARGE} value={-percentDiscount} />
                    )}
                </div>
            </Header>
            <TextTitle>{capitalizeEveryLetter(data.name)}</TextTitle>

            {data.inStock ? (
                <>
                    <PriceContainer>
                        <PriceFrom>
                            {data.special_price && data.special_price > 0 && (
                                <>de R$ {currencyBrl(data.special_price)}</>
                            )}
                        </PriceFrom>
                        <div className="flex flex-row justify-between gap-2">
                            <PriceText>R$ {currencyBrl(ProductService.getValue(data, 1))}</PriceText>
                            <CartQty />
                        </div>
                        {/* {data?.installments?.parcelas && (
                            <InstallmentsText>
                                até {data.installments.parcelas}x R$ {currencyBrl(data.installments.valor)}
                            </InstallmentsText>
                        )} */}
                    </PriceContainer>
                    {data.configurable_options && (
                        <div className="flex flex-col gap-3">
                            {Object.values(data.configurable_options).map((value) => (
                                <Attributes key={value.attribute_id}>
                                    <AttributeSelect
                                        items={value.options.map((v) => ({ id: v.option_id, name: v.option_name }))}
                                        title={capitalize(value.attribute_name)}
                                        selected={attrs
                                            .find((attr) => attr.option_id.toString() == value.attribute_id)
                                            ?.option_value.toString()}
                                        onSelect={(id) => setAttr(Number(value.attribute_id), Number(id))}
                                    />
                                </Attributes>
                            ))}
                        </div>
                    )}
                    <Buttons className="!hidden md:!flex" data={data} />
                    <InstallmentsModal data={data} />
                </>
            ) : (
                <ProductOutStock data={data} />
            )}
        </Container>
    );
}

export default Title;
