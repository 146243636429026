import { useMemo } from 'react';
import { ucfirst } from '../../../../../../utils/string';
import { Checkbox as CustomCheckbox } from '../../../../../atoms/Checkbox';
import { useProductSearchFilters } from '../../../hooks/useProductSearchFilters';
import MenuCollapse from '../Menu-collapse';
import { Container, Item, List } from './styles';

interface TipoProps {
    isMobile?: boolean;
}
function Modelo({ isMobile = false }: TipoProps) {
    const { filters, setFiltersDesktop, setFiltersMobile, aggregations } = useProductSearchFilters();

    function handleChange(id: string) {
        const { tipo } = filters;
        const index = tipo.indexOf(id);
        let newFilters = [];

        if (index === -1) {
            newFilters = [...tipo, id];
        } else {
            newFilters = tipo.filter((item) => item !== id);
        }

        if (isMobile) {
            setFiltersMobile({ tipo: newFilters });
            return;
        }

        setFiltersDesktop({ tipo: newFilters });
    }

    const items = useMemo(() => {
        return (
            aggregations?.Tipo.buckets
                .map((tipo: any) => {
                    return {
                        key: tipo.key,
                        name: ucfirst(tipo.key?.toLowerCase())
                    };
                })
                .filter((v) => v != null) || []
        );
    }, [aggregations?.Tipo.buckets]);

    if (!items.length) return null;

    return (
        <Container>
            <MenuCollapse title="Modelo" qty={filters.tipo.length || 0}>
                <List>
                    {items.map((tipo: any) => (
                        <Item key={tipo.key} onClick={() => handleChange(tipo.key)}>
                            <CustomCheckbox
                                name={tipo.key}
                                isChecked={filters.tipo.includes(tipo.key)}
                                withoutFormik
                                onCheckboxChange={() => handleChange(tipo.key)}
                            />
                            <div>{tipo.name}</div>
                        </Item>
                    ))}
                </List>
            </MenuCollapse>
        </Container>
    );
}

export default Modelo;
