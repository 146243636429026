const isObject = (item: any) => {
    return item && typeof item === 'object' && !Array.isArray(item);
};

export const mergeDeep = (target: any, ...sources: any): any => {
    if (!sources.length) return target;
    const source = sources.shift();
    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key])
                    Object.assign(target || {}, {
                        [key]: {}
                    });
                mergeDeep(target[key], source[key]);
            } else if (Array.isArray(source[key])) {
                if (!target[key]) {
                    target[key] = source[key];
                } else {
                    Array.prototype.push.apply(source[key], target[key] || []);
                }
            } else {
                Object.assign(target || {}, {
                    [key]: source[key]
                });
            }
        }
    }
    return mergeDeep(target, ...sources);
};
