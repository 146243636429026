import React, { FC } from 'react';
import { ClickableAreaIcon } from '../../atoms';
import { Container, Icon, TextTitle } from './styles';
import { IModaHeaderProps } from './types';

const ModaHeader: FC<IModaHeaderProps> = ({ title, showIconClose, onPressIconClose, ...rest }) => {
    return (
        <Container {...rest}>
            <TextTitle>{title}</TextTitle>
            {showIconClose && (
                <ClickableAreaIcon onClick={onPressIconClose}>
                    <Icon />
                </ClickableAreaIcon>
            )}
        </Container>
    );
};

export default ModaHeader;
