import tw, { styled } from 'twin.macro';
import { TextLatoExtraBold20 } from '../../atoms';

export const Container = styled.div`
    ${tw`
        flex-1
        flex
        flex-col
        justify-center
        items-center
    `}
`;

export const TextTitle = styled(TextLatoExtraBold20)`
    ${tw`
        mt-[18px]
        text-gray
    `}
`;
