import React, { FC } from 'react';
import { NEXT_PUBLIC_PAGE_TERMOS_DE_USO_SITE } from '../../../utils/envs';
import { PageBlock } from '../../organisms';
import Layout from '../Layout';
import LayoutFitter from '../Layout-fitter';
import { Container } from './styles';

interface ITermosECondicoes {}

const TermosECondicoes: FC<ITermosECondicoes> = ({}) => {
    return (
        <Layout>
            <LayoutFitter>
                <Container>
                    <PageBlock id={NEXT_PUBLIC_PAGE_TERMOS_DE_USO_SITE} />
                </Container>
            </LayoutFitter>
        </Layout>
    );
};

export default TermosECondicoes;
