import tw, { styled } from 'twin.macro';
import { Modal } from '../../atoms';
import { ModalHeader } from '../../molecules';

export const Container = styled(Modal)`
    ${tw`
        flex-col
        max-h-[714px]
        max-w-[922px]

        overflow-auto

    `}
`;
export const Content = styled.div`
    ${tw`
        w-full
        px-[20px]
        
        overflow-auto
    `}
`;

export const ContainerHeader = styled.div`
    ${tw`
        mt-5
        mb-[30px]
    `}
`;
