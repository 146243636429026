import tw, { styled } from 'twin.macro';

export const Container = styled.div`
    ${tw`
        flex
        h-full
        flex-col
        justify-between
    `}
`;
