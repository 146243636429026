import { useEffect, useState } from 'react';
import { IS_BROWSER } from '../../services/query-client';
import { getIsBreakPoint } from '../../utils/get-config-tailwind';

export const useBreakpointTailwind = (breakpoint: string) => {
    const [isBreakpoint, setisBreakpoint] = useState(getIsBreakPoint(breakpoint));

    useEffect(() => {
        if (IS_BROWSER) {
            const resize_ob = new ResizeObserver(function (entries) {
                let rect = entries[0].contentRect;
                let width = rect.width;
                setisBreakpoint(getIsBreakPoint(breakpoint));
            });
            resize_ob.observe(document.body);

            return () => {
                resize_ob.disconnect();
            };
        }
    }, [breakpoint]);

    return isBreakpoint;
};
