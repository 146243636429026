import React, { FC, SVGProps } from 'react';

const IconSpinner: FC<SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" {...props}>
            <circle
                cx="50"
                cy="50"
                fill="none"
                stroke="currentcolor"
                strokeWidth="10"
                r="45"
                strokeDasharray="212.05750411731105 72.68583470577035"
            >
                <animateTransform
                    attributeName="transform"
                    type="rotate"
                    repeatCount="indefinite"
                    dur="1s"
                    values="0 50 50;360 50 50"
                    keyTimes="0;1"
                />
            </circle>
        </svg>
    );
};

export default IconSpinner;
