import { FC, HTMLAttributes } from 'react';
import { RouterService } from '../../../services';
import { ILink } from '../../../services/router/types';

type ILinkProps = Partial<Omit<HTMLAttributes<HTMLDivElement>, 'onClick'>> & ILink;

const Link: FC<ILinkProps> = ({ options, onClick, children, ...rest }) => {
    // useEffect(() => {
    //     router.prefetch(href);
    // }, [href]);

    const handleClick = () => {
        RouterService.toRoute({ options, onClick });
    };

    return (
        <div onClick={handleClick} className="cursor-pointer" {...rest}>
            {children}
        </div>
    );
};

export default Link;
