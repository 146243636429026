import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { Input } from '../../molecules';

import { useMutation } from '@tanstack/react-query';
import { AuthService, RouterService, UserService } from '../../../services';
import { CARACTER_LOW, CARACTER_UPP, MIN, NUM, PASSWORD_MISMATCH, REQUIRED } from '../../../utils/errors';
import { EInputType } from '../../molecules/Input/types';
import { FormSubmitButton, Container, FormMandatoryLabel, SubmitForgetButton } from './styles';
import { IResetPasswordFormProps, IValuesForm } from './types';
import { REGEX_CARACTER_LOW, REGEX_CARACTER_UPP, REGEX_CARACTER_NUM } from '../../../utils/string';
import { toast } from 'react-toastify';
import { useRouter } from 'next/router';
import { InputPassword } from '../../molecules/Input-password';
import { ErrorText } from '../Register-address-form/styles';

const validationForm = Yup.object().shape({
    password: Yup.string()
        .required(REQUIRED)
        .min(8, MIN)
        .matches(REGEX_CARACTER_LOW, CARACTER_LOW)
        .matches(REGEX_CARACTER_UPP, CARACTER_UPP)
        .matches(REGEX_CARACTER_NUM, NUM)
        .required(REQUIRED),
    passwordConfirmation: Yup.string()
        .required(REQUIRED)
        .oneOf([Yup.ref('password'), null], PASSWORD_MISMATCH)
});

const ResetPasswordForm = ({ resetToken }: IResetPasswordFormProps) => {
    const router = useRouter();
    const { mutate, isLoading, isError } = useMutation<unknown, unknown, IValuesForm>(
        (form) => UserService.resetPassword({ newPassword: formik.values.password, resetToken }),
        {
            onSuccess: () => {
                toast.success('Senha alterada com sucesso!');
                setTimeout(() => {
                    router.push('/');
                }, 2000);
            }
        }
    );

    const formik = useFormik<IValuesForm>({
        initialValues: {
            password: '',
            passwordConfirmation: ''
        },
        validateOnMount: true,
        validationSchema: validationForm,
        onSubmit: (values) => {
            mutate(values);
        }
    });

    const passwordValidation = () => {
        const { password } = formik.values;

        if (!password) {
            return '';
        }

        const hasMin = password.length >= 8;
        const hasCaracterLow = REGEX_CARACTER_LOW.test(password);
        const hasCaracterUpp = REGEX_CARACTER_UPP.test(password);
        const hasCaracterNum = REGEX_CARACTER_NUM.test(password);

        if (!hasMin) {
            return 'A senha deve ter pelo menos 8 caracteres.';
        }
        if (!hasCaracterLow) {
            return 'A senha deve conter pelo menos uma letra minúscula.';
        }
        if (!hasCaracterUpp) {
            return 'A senha deve conter pelo menos uma letra maiúscula.';
        }
        if (!hasCaracterNum) {
            return 'A senha deve conter pelo menos um número.';
        }

        return '';
    };

    const isSamePassword = () => {
        const { password, passwordConfirmation } = formik.values;

        if (!password || !passwordConfirmation) {
            return false;
        }

        const isSame = password === passwordConfirmation;

        if (!isSame) {
            return 'As senhas não conferem.';
        }
    };

    return (
        <form onSubmit={formik.handleSubmit}>
            <Container>
                <FormikProvider value={formik}>
                    <span className="w-full ">
                        <InputPassword
                            label="Senha"
                            inputMode="password"
                            name="password"
                            type={EInputType.PASSWORD}
                            required
                        />
                        <ErrorText>{passwordValidation()}</ErrorText>
                    </span>

                    <span className="w-full mt-3">
                        <InputPassword
                            label="Confirmar Senha"
                            inputMode="password"
                            name="passwordConfirmation"
                            type={EInputType.PASSWORD}
                            required
                        />
                        <ErrorText>{isSamePassword()}</ErrorText>
                    </span>
                    {/* <InputPassword label="Senha" name="password" />
                    <br></br>
                    <InputPassword label="Confirmação de senha" name="passwordConfirmation" required /> */}
                </FormikProvider>
                {isError && <FormMandatoryLabel>Error interno</FormMandatoryLabel>}
                <SubmitForgetButton>
                    <FormSubmitButton text="Salvar" isLoading={isLoading} onClick={() => formik.handleSubmit()} />
                </SubmitForgetButton>
            </Container>
        </form>
    );
};

export default ResetPasswordForm;
