import { forwardRef, Ref, useImperativeHandle, useState } from 'react';
import ReactCardFlip from 'react-card-flip';
import { CreditCardFlipBack, CreditCardFlipFront } from '../../molecules';
import { ICreditCardFlipHandle, ICreditCardFlipProps } from './types';

const CreditCardFlip = forwardRef(
    ({ name, valid, number, cvv }: ICreditCardFlipProps, ref: Ref<ICreditCardFlipHandle>) => {
        const [flip, setFlip] = useState(false);

        useImperativeHandle(ref, () => ({
            flip: () => {
                setFlip((old) => !old);
            }
        }));

        return (
            <ReactCardFlip isFlipped={flip} flipDirection="vertical">
                <CreditCardFlipFront {...{ name, valid, number }} />
                <CreditCardFlipBack {...{ number, cvv }} />
            </ReactCardFlip>
        );
    }
);

export default CreditCardFlip;
