import { forwardRef, Ref, useImperativeHandle, useRef } from 'react';
import Modal from '../Modal';
import { IModalHandle } from '../Modal/types';
import { ButtonNotFound, Container, Content, TextDescription, TextSubTitle, TextTitle } from './styles';
import { IModalNoCoverageHandle, IModalNoCoverageProps } from './types';

const ModalNoCoverage = forwardRef(({ onPressChange }: IModalNoCoverageProps, ref: Ref<IModalNoCoverageHandle>) => {
    const refModal = useRef<IModalHandle>(null);

    useImperativeHandle(ref, () => ({
        show: () => refModal.current?.show(),
        close: () => refModal.current?.close()
    }));
    return (
        <Modal name="nocoverage" ref={refModal} className="w-6/12 rounded-2xl  bg-white overflow-hidden">
            <Container>
                <Content>
                    <TextTitle>CEP não atendido</TextTitle>
                    <TextSubTitle>Poxa, ainda não atendemos em sua região :(</TextSubTitle>
                    <TextDescription>Tente outro CEP ou aguarde, em breve chegaremos até você :)</TextDescription>
                </Content>
                <ButtonNotFound onClick={onPressChange} text="Alterar endereço" />
            </Container>
        </Modal>
    );
});
ModalNoCoverage.displayName = 'ModalNoCoverage';
export default ModalNoCoverage;
