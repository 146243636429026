import { useMutation } from '@tanstack/react-query';
import { MouseEvent, useState } from 'react';
import { AddressService } from '../../../services';
import { Address } from '../../../services/address/types';
import { Radio } from '../../atoms';
import ModalConfirm from '../../organisms/Modal-confirm';
import ModalPortal from '../../atoms/Modal-portal';
import NewAddressModal from '../../organisms/New-address-modal';
import {
    AddressDataContainer,
    AddressDataValue,
    AddressTitleWrapper,
    RegisteredAddressActions,
    RegisteredAddressContainer
} from './style';
import Button from '../Button';
import IconDeliver from '../../atoms/icons/Deliver';
import { IButtonVariant } from '../Button/types';
import { useAddress } from '../../../hooks';

export interface IRegisteredAddressProps {
    address: Address;
    onPressDefault?: () => void;
}

const RegisteredAddress = ({ address, onPressDefault }: IRegisteredAddressProps) => {
    const [showModalEdit, setShowModalEdit] = useState<Address>();
    const [showModalDelete, setShowModalDelete] = useState<Address>();
    const { prefix, default_billing, default_shipping, id } = address;
    const { isLoading, mutate } = useMutation(() => AddressService.deleteAddress(id));
    const { data } = useAddress({ refetchOnMount: false });

    const handleCheck = () => {
        if (!id) return;

        AddressService.setAddress(id);
        if (onPressDefault) onPressDefault();
    };

    const handleClickEdit = () => {
        // e.stopPropagation();
        setShowModalEdit(address);
    };
    const handleClickDelete = () => {
        // e.stopPropagation();
        setShowModalDelete(address);
    };

    return (
        <>
            <RegisteredAddressContainer
                key={id}
                layoutId={`RegisteredAddress-${address.id}`}
                $defaultAddress={default_billing || default_shipping}
                layout
                onClick={handleCheck}
            >
                {/* <Radio
                    className="bg-gray"
                    onChange={handleCheck}
                    checked={default_billing || default_shipping || false}
                /> */}
                <div className="flex flex-col ">
                    <AddressTitleWrapper>
                        <IconDeliver />
                        <strong>{`${prefix || 'Casa Nova'}`}</strong>
                    </AddressTitleWrapper>
                    {/* {prefix ? (
                        <AddressDataContainer>
                            <AddressDataValue>
                                <strong>{`${prefix}`}</strong>
                            </AddressDataValue>
                        </AddressDataContainer>
                    ) : null} */}
                    <AddressDataContainer layout>
                        <AddressDataValue layout>{AddressService.formatAddress(address)}</AddressDataValue>
                    </AddressDataContainer>
                </div>
                <RegisteredAddressActions>
                    {/* <AddressAction onClick={handleClickEdit}>Editar</AddressAction>
                    <AddressAction onClick={handleClickDelete}>Excluir</AddressAction> */}
                    <Button
                        text="Alterar"
                        onClick={handleClickEdit}
                        variant={IButtonVariant.OUTLINE}
                        style="
                            color: #81AB05; 
                            font-size: 16px;
                            font-weigth: bold;
                            border-color: #81AB05;
                            padding: 16px 32px;
                            max-height: 56px;
                            stroke: #81AB05;
                        "
                    />
                    {data && data?.addressList?.length > 1 && (
                        <Button
                            text="Excluir"
                            onClick={handleClickDelete}
                            variant={IButtonVariant.OUTLINE}
                            style="
                            color: #FF7373; 
                            font-size: 16px;
                            font-weigth: bold;
                            border-color: #FF7373;
                            padding: 16px 32px;
                            max-height: 56px;
                            stroke: #81AB05;
                        "
                        />
                    )}
                </RegisteredAddressActions>
            </RegisteredAddressContainer>
            <ModalPortal>
                {showModalEdit && (
                    <NewAddressModal addressEdit={showModalEdit} onClose={() => setShowModalEdit(undefined)} />
                )}
            </ModalPortal>
            <ModalPortal>
                {showModalDelete && (
                    <ModalConfirm
                        isLoadingConfirm={isLoading}
                        onPressConfirm={() => mutate()}
                        onPressCancel={() => setShowModalDelete(undefined)}
                        onClose={() => setShowModalDelete(undefined)}
                        text="Você tem certeza que deseja excluir?"
                    />
                )}
            </ModalPortal>
        </>
    );
};

export default RegisteredAddress;
