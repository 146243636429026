import { AnimatePresence } from 'framer-motion';
import { Ref, RefObject, forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { useOutsideClick } from '../../../../hooks';
import { Portal } from '../../../atoms';
import { Close, Container, Description, Title } from './styles';
import { Button } from '../../../molecules';
import { IButtonVariant } from '../../../molecules/Button/types';
import { useEstablishment } from '../../../../hooks/use-establishment';
import { EstablishmentService } from '../../../../services';

export interface IAlertRegionModalHandle {
    toggle: (x: number, y: number) => void;
    close: () => void;
}

export interface IAlertRegionModalProps {
    onClickChangeZipCode: () => void;
}

const AlertRegionModal = forwardRef(function AlertRegionModal(
    { onClickChangeZipCode }: IAlertRegionModalProps,
    ref: Ref<IAlertRegionModalHandle>
) {
    const [isOpen, setIsOpen] = useState<{ x: number; y: number } | undefined>(undefined);
    const refContainer = useRef<HTMLDivElement>(null);
    const { data: establishment } = useEstablishment();

    useImperativeHandle(ref, () => ({
        toggle: (x: number, y: number) =>
            setIsOpen((old) => {
                if (old) return undefined;

                return { x, y };
            }),
        close: () => setIsOpen(undefined)
    }));
    useOutsideClick([refContainer], () => {
        handleClose();
    });

    const handleClickChangeRegion = () => {
        setIsOpen(undefined);
        onClickChangeZipCode();
    };

    function handleClose() {
        setIsOpen(undefined);
        if (establishment) {
            EstablishmentService.setEstablishmentStorage({
                ...establishment,
                showAlertModal: false
            });
        }
    }

    return (
        <Portal>
            <AnimatePresence>
                {isOpen && (
                    <Container
                        style={{ left: isOpen.x, top: isOpen.y }}
                        ref={refContainer}
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                    >
                        <Title>Minha Região</Title>
                        <Description>
                            Para acessar produtos e ofertas da sua região, identificamos que você está em{' '}
                            <strong>{establishment?.region}</strong>
                        </Description>
                        <div className="flex flex-row w-full gap-4">
                            <Button
                                text="Inserir CEP"
                                variant={IButtonVariant.OUTLINE}
                                onClick={handleClickChangeRegion}
                                className="flex-1"
                            ></Button>
                            <Button
                                text="Continuar"
                                variant={IButtonVariant.PRIMARY_MEDIUM}
                                onClick={handleClose}
                                className="flex-1"
                            ></Button>
                        </div>
                        <Close onClick={handleClose}></Close>
                    </Container>
                )}
            </AnimatePresence>
        </Portal>
    );
});

export default AlertRegionModal;
