import { AnimatePresence } from 'framer-motion';
import { useRouter } from 'next/router';
import { KeyboardEventHandler, useEffect, useMemo, useRef, useState } from 'react';
import { useOutsideClick, useQueryCancelToken } from '../../../../hooks';
import { ElasticService, EstablishmentService, ProductService } from '../../../../services';
import { IBoolQuery } from '../../../../services/elastic/types';
import { SEARCH_BAR } from '../../../../utils/react-query-keys';
import { ClickableAreaIcon } from '../../../atoms';
import { ProductItemHorizontal, ProductItemHorizontalSkeleton } from '../../../molecules';
import { ISearchBarHandle } from '../../../molecules/Search-bar/types';
import { Container, ContainerSeeAll, Content, Input, Items, Modal, SearchIcon } from './styles';

function InputSearch() {
    const [showModal, setShowModal] = useState<{ x: number; y: number; width: number } | undefined>(undefined);
    const [text, setText] = useState('');

    const refInput = useRef<HTMLInputElement>(null);
    const refContainer = useRef<HTMLDivElement>(null);
    const refModal = useRef<HTMLDivElement>(null);
    const router = useRouter();
    function onSubmit(event?: React.FormEvent) {
        event?.preventDefault();
        const value = refInput.current?.value;
        if (!value) return;
        router.push(ProductService.getUrlSearchProduct(`?q=${value}&time=${Date.now()}`));
    }

    const handleToggleModal = (isOpen: boolean) => {
        // setShowModal(isOpen);
        if (isOpen && showModal !== undefined) return;

        if (isOpen) {
            const { x, y, height, width } = refContainer.current?.getBoundingClientRect() as any;

            setShowModal({ x, y: y + height + 8, width });
            // setText('');
            refInput.current?.focus();
        } else {
            setShowModal(undefined);
            setText('');
        }
    };

    const refSearchBar = useRef<ISearchBarHandle>(null);

    const { data, isLoading } = useQueryCancelToken(
        [SEARCH_BAR, text],
        (paramsAxios) => {
            let query: IBoolQuery = {
                bool: {
                    must: [
                        {
                            multi_match: {
                                query: text,
                                fields: ['name', 'sku', 'ean'],
                                analyzer: 'prefix_search',
                                fuzziness: '1'
                            }
                        }
                    ]
                    // filter: [{ term: { status_stock: 1 } }]
                }
            };

            // const isSku = /^[0-9]+$/.test(text);
            // if (isSku) {
            //     query = {
            //         bool: {
            //             must: [
            //                 {
            //                     multi_match: {
            //                         query: text,
            //                         fields: ['sku']
            //                     }
            //                 }
            //             ]
            //         }
            //     };
            // }

            return ElasticService.getProducts({ filter: query, limit: 20, page: 1, paramsAxios });
        },
        {
            enabled: text.length >= 1 && showModal !== undefined
        }
    );

    const listProducts = useMemo(() => {
        return data && data.items?.map((item) => <ProductItemHorizontal data={item} key={item.id} />);
    }, [data]);

    useOutsideClick([refModal, refContainer], () => {
        handleToggleModal(false);
    });

    const getContentList = () => {
        if (text.length <= 1) return null;

        if (data?.total_count == 0) return <div className="text-14">Nenhum item encontrado</div>;

        if (isLoading)
            return (
                <>
                    <ProductItemHorizontalSkeleton />
                    <ProductItemHorizontalSkeleton />
                </>
            );

        return listProducts;
    };

    const handleClickSeeAll = () => {
        handleToggleModal(false);
        refSearchBar.current?.searchRoute();
    };

    const searchRoute = () => {
        router.push({
            pathname: ProductService.getUrlSearchProduct(),
            query: { q: text }
        });
    };

    const onKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
        if (e.key === 'Enter') {
            handleToggleModal(false);

            searchRoute();
        }
    };

    useEffect(() => {
        setText('');
    }, [router.query]);

    useEffect(() => {
        handleToggleModal(text.length >= 1);
    }, [text]);

    useEffect(() => {
        setText(
            typeof router.query.product === 'string' ? router.query.product : router.query.product?.join(', ') || ''
        );
    }, [router.query]);

    return (
        <>
            <Container onSubmit={onSubmit}>
                <Content ref={refContainer}>
                    <Input
                        ref={refInput}
                        placeholder="O que você precisa hoje?"
                        onChange={(event) => setText(event.target.value || '')}
                        onKeyDown={onKeyDown}
                        value={text}
                    />
                    <SearchIcon className="cursor-pointer" onClick={onSubmit} />
                </Content>
                <AnimatePresence>
                    {showModal && (
                        <Modal ref={refModal}>
                            <div className="py-4 flex flex-col">
                                <Items style={{ paddingTop: text.length <= 2 || data?.total_count == 0 ? 0 : '15px' }}>
                                    {getContentList()}
                                </Items>
                                {data && data?.total_count !== undefined && data?.total_count > 0 && (
                                    <ContainerSeeAll>
                                        <ClickableAreaIcon onClick={handleClickSeeAll}>Ver mais</ClickableAreaIcon>
                                    </ContainerSeeAll>
                                )}
                            </div>
                        </Modal>
                    )}
                </AnimatePresence>
            </Container>
        </>
    );
}

export default InputSearch;
