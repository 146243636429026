import tw, { styled } from 'twin.macro';
import { motion } from 'framer-motion';
export const Container = styled(motion.div)<{ $withoutPaddingMobile: boolean }>`
    ${tw`
        flex
        items-center
        flex-col
        px-4
        // md:px-4
        2xl:px-0
        // pl-[10px]
        // pr-[10px]
        // md:pl-[15px]
        // md:pr-[15px]
        // xl:pl-[0px]
        // xl:pr-[0px]
    `}
    ${({ $withoutPaddingMobile }) => $withoutPaddingMobile && tw`!px-0 md:!px-4 2xl:!px-0`}
`;
export const Content = styled(motion.div)<{ $disabledOverflow?: boolean }>`
    ${tw`
        xl:max-w-[1136px]
        2xl:max-w-[1336px]
        overflow-y-visible
        w-full
        flex
        flex-col
    `}
    ${({ $disabledOverflow }) => !$disabledOverflow && tw`overflow-hidden`}
`;
