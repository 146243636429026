import { useField } from 'formik';
import { AnimatePresence, MotionConfig, useAnimationControls } from 'framer-motion';
import { ChangeEvent, forwardRef, MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useCombinedRefs, useOutsideClick } from '../../../../../hooks';
import getFormattedValue from '../../../../../utils/getFormattedValue';
import { Portal } from '../../../../atoms';
import SelectItem from '../../../Select-item';
import { TypeProps } from '../types';
import {
    Container,
    ContainerIcon,
    ContainerList,
    Icon,
    Input,
    List,
    variantsIcon,
    variantsInput,
    variantsList
} from './styles';

interface IIsOpen {
    top: string;
    left: string;
    width: string;
    maxHeight: string;
}

function InputSelect<T>({ mask, values, labelExtractor, keyExtractor, ...props }: TypeProps<T>, ref: any) {
    const [isOpen, setIsOpen] = useState<IIsOpen | undefined>();
    const [field, meta, helpers] = useField<T>(props.name);

    const modalizeRef = useRef(null);
    const listRef = useRef<HTMLDivElement>(null);
    const combinedRef = useCombinedRefs<HTMLDivElement>(ref, modalizeRef);
    const controlsInput = useAnimationControls();
    const controlsList = useAnimationControls();
    const { setValue } = helpers;
    const { error, value } = meta;

    const handleClose = () => {
        setIsOpen(undefined);
    };

    useOutsideClick([listRef, combinedRef], handleClose);

    useEffect(() => {
        if (!value && values) setValue(values[0]);
    }, [value]);

    const handleChange = useCallback(
        (value: T) => {
            setValue(value);
            handleClose();
        },
        [props.onChange]
    );
    // return <Input isError={Boolean(error)} {...props} value={value} onChange={handleChange} ref={ref} />;

    const handleToggle = (e: MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        if (isOpen) {
            return handleClose();
        }
        const viewportOffset = combinedRef.current?.getBoundingClientRect();
        const defaultView = combinedRef.current?.ownerDocument.defaultView;

        if (viewportOffset && defaultView) {
            const pageYOffset = defaultView.pageYOffset;
            const maxHeight = window.innerHeight - (viewportOffset.top + viewportOffset.height + 20);

            setIsOpen({
                top: `${pageYOffset + viewportOffset.top + viewportOffset.height}px`,
                width: `${viewportOffset?.width}px`,
                left: `${viewportOffset?.x}px`,
                maxHeight: `${maxHeight < 400 ? maxHeight : 400}px`
            });
        }
    };

    const sequenceAnimate = async () => {
        if (isOpen) {
            await controlsInput.start('open');
            await controlsList.start(['open', 'border']);
        } else {
            await controlsInput.start('close');
        }
    };

    useEffect(() => {
        sequenceAnimate();
    }, [isOpen]);

    return (
        <MotionConfig transition={{ duration: 0.15 }}>
            <Container ref={combinedRef}>
                <Input
                    $isError={Boolean(error)}
                    variants={variantsInput}
                    animate={controlsInput}
                    onClick={handleToggle}
                >
                    <div>{value && labelExtractor ? labelExtractor(value) : ''}</div>
                    <ContainerIcon variants={variantsIcon} initial="close" animate={isOpen ? 'open' : 'close'}>
                        <Icon />
                    </ContainerIcon>
                </Input>
                <Portal>
                    <AnimatePresence>
                        {isOpen && (
                            <ContainerList ref={listRef} style={isOpen}>
                                <List style={isOpen} initial="close" animate={controlsList} variants={variantsList}>
                                    {values?.map((value) => {
                                        const key = keyExtractor ? keyExtractor(value) : '';
                                        const title = labelExtractor ? labelExtractor(value) : '';
                                        return (
                                            <SelectItem onClick={() => handleChange(value)} title={title} key={key} />
                                        );
                                    })}
                                </List>
                            </ContainerList>
                        )}
                    </AnimatePresence>
                </Portal>
            </Container>
        </MotionConfig>
    );
}

export default forwardRef(InputSelect);
