import { useState } from 'react';
import { IconEye } from '../../atoms';
import IconEyeOff from '../../atoms/icons/Eye-off';
import Input from '../Input';
import { EInputType, IInputProps } from '../Input/types';
import { Container } from './styles';

export const InputPassword = (props: IInputProps<string>) => {
    const [isShowPassword, setIsShowPassword] = useState(false);

    const icon = isShowPassword ? <IconEye /> : <IconEyeOff />;

    return (
        <Input
            {...props}
            type={isShowPassword ? EInputType.DEFAULT : EInputType.PASSWORD}
            iconRight={<Container onClick={() => setIsShowPassword((old) => !old)}>{icon} </Container>}
        />
    );
};
