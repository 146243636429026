import { forwardRef, Ref, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { MapService } from '../../../services';
import { ICenter } from '../Map/types';
import ModalEstablishmentStepLoading from '../Modal-establishment-step-loading';
import ModalEstablishmentStepMap from '../Modal-establishment-step-map';
import { IModalHandle } from '../Modal/types';
import { Modal } from './styles';
import { IModalZipcodeHandle, IModalZipcodeProps } from './types';

const ModalZipcode = forwardRef(({ onCheckCoverage }: IModalZipcodeProps, ref: Ref<IModalZipcodeHandle>) => {
    const refModal = useRef<IModalHandle>(null);
    const [page, setPage] = useState('page_loading');
    const [show, setShow] = useState(false);
    const [center, setCenter] = useState<ICenter>({
        lat: '-22.676238685129558',
        lng: '-45.00532488531092'
    });

    const handleShow = () => {
        refModal.current?.show();
        setShow(true);
        setPage('page_loading');
    };
    const handleClose = () => {
        setShow(false);
        refModal.current?.close();
    };

    useImperativeHandle(ref, () => ({
        show: handleShow,
        close: handleClose
    }));

    useEffect(() => {
        (async () => {
            if (show) {
                try {
                    const {
                        coords: { latitude: lat, longitude: lng }
                    } = await MapService.getCurrentLocation();

                    const position = {
                        lat: lat.toString(),
                        lng: lng.toString()
                    };

                    setCenter(position);
                    setPage('page_mapa');
                } catch (e) {
                    console.error(e, 'errorrr');
                }
            }
        })();
    }, [show]);

    return (
        <Modal name="zipcode" ref={refModal} onClose={handleClose} closeOnOutSide={false}>
            {page == 'page_loading' && <ModalEstablishmentStepLoading />}
            {page == 'page_mapa' && <ModalEstablishmentStepMap onCheckCoverage={onCheckCoverage} center={center} />}
        </Modal>
    );
});
ModalZipcode.displayName = 'ModalZipcode';
export default ModalZipcode;
