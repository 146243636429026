import tw, { styled } from 'twin.macro';

export const Container = styled.div`
    ${tw`
		
		bg-primary
		
	`}
`;

export const Content = styled.div`
    ${tw`
		flex
		flex-col
		md:items-center
		md:justify-center
		px-4
		divide-y-[0.5px]
		divide-white
		py-5
		md:py-11
		md:gap-4
		// md:px-10
		xl:px-4
		md:flex-row
		md:divide-y-0
	`}
`;

export const Item = styled.div`
    ${tw`
		flex
		flex-row
		gap-4
		flex-1
		py-4
		items-center
		md:py-0
		md:justify-center
	`}
`;

export const ItemHeader = styled.div`
    ${tw`
		flex
		flex-col
		gap-2
		md:gap-1.5
	`}
`;

export const ItemTitle = styled.div`
    ${tw`
		text-white
        font-black
		text-16
		md:text-18
	`}
`;

export const ItemDescription = styled.div`
    ${tw`
		 font-normal
		 text-14
		 md:text-18
		 text-white
	`}
`;
