import { useField } from 'formik';
import { forwardRef, Ref, useImperativeHandle, useState } from 'react';
import { PaymentService, ReactGa4Service } from '../../../services';
import {
    IPaymentSelectedView,
    PaymentMethod,
    PaymentSelected,
    PAYMENTS_PIX,
    PaymentTypes
} from '../../../services/payment/types';
import { ErrorMsgField, FlagType } from '../../atoms';
import ModalPortal from '../../atoms/Modal-portal';
import { ModalCreditCardType, ModalPagseguroCreditCard, ModalTtxpayCreditCard } from '../../templates';
import { Container, ContainerInput, ContainerList, Input, Item, Label, TextAdd, TextTitle } from './styles';
import { ICheckoutSelectPaymentHandle, ICheckoutSelectPaymentProps } from './types';

const CheckoutSelectPayment = ({ onClick }: ICheckoutSelectPaymentProps, ref: Ref<ICheckoutSelectPaymentHandle>) => {
    const [showModalType, setShowModalType] = useState<PaymentMethod[] | undefined>();
    const [showModalPagseguroCreditCard, setShowModalPagseguroCreditCard] = useState<PaymentMethod | undefined>();
    const [showModalTtxpayCreditCard, setShowModalTtxpayCreditCard] = useState<PaymentMethod | undefined>();

    const [, { error, value, touched }, { setValue }] = useField('creditCard');
    const formated: IPaymentSelectedView | null = value ? PaymentService.getPaymentFromCheckout(value) : null;

    useImperativeHandle(ref, () => ({
        showModalCreditCardType: (values) => setShowModalType(values)
    }));

    const handleSubmitCreditCardType = (method: PaymentMethod) => {
        setShowModalType(undefined);

        const actions: Record<PaymentTypes, () => void> = {
            [PaymentTypes.TTXPAY_PIX]: () => {
                sendGoogleAssits(PaymentTypes.TTXPAY_PIX);
                setValue({ paymentMethod: PaymentTypes.TTXPAY_PIX, values: [] });
            },
            [PaymentTypes.PAGSEGURO_CREDIT_CARD]: () => setShowModalPagseguroCreditCard(method),
            [PaymentTypes.TTXPAY_CREDIT_CARD]: () => setShowModalTtxpayCreditCard(method),
            [PaymentTypes.MUNDIPAGG_BILLET]: () => {},
            [PaymentTypes.MERCPAGO_ADBPAYMENT]: () => {},
            [PaymentTypes.PAGBANK_MAGENTO]: () => {},
            [PaymentTypes.MERCADOPAGO_PAY]: () => {},
            [PaymentTypes.MUNDIPAGG_DEBIT]: () => {},
            [PaymentTypes.PAGARME_PIX]: () => {},
            [PaymentTypes.MUNDIPAGG_TWO_CREDIT_CARD]: () => {},
            [PaymentTypes.MONEY]: () => {},
            [PaymentTypes.MUNDIPAGG_CREDIT_CARD]: () => {},
            [PaymentTypes.PAGARME_CREDIT_CARD]: () => {},
            [PaymentTypes.PAGARME_BILLET]: () => {},
            [PaymentTypes.CASHNODELIVERY]: () => {
                sendGoogleAssits(PaymentTypes.CASHNODELIVERY);
                setValue({ paymentMethod: PaymentTypes.CASHNODELIVERY, values: [] });
            }
        };

        actions[method.code]();
    };

    const sendGoogleAssits = (paymentMethod: PaymentTypes) => {
        /* ReactGa4Service.onAddShippingInfo();*/
        /*ReactGa4Service.onAddPaymentInfo(paymentMethod);*/
    };

    const handleSubmitCreditCard = (values: PaymentSelected) => {
        sendGoogleAssits(values.paymentMethod);

        setValue(values);

        setShowModalPagseguroCreditCard(undefined);
        setShowModalTtxpayCreditCard(undefined);
    };

    return (
        <>
            <Container>
                <ContainerInput onClick={onClick} $error={Boolean(error && touched)}>
                    <Input>
                        <Label>Forma de Pagamento</Label>
                    </Input>
                    {!value && (
                        <ContainerList>
                            <TextAdd>Adicionar método de pagamento</TextAdd>
                        </ContainerList>
                    )}
                    {formated?.values?.length && (
                        <ContainerList>
                            {formated?.values.map((item, i) => {
                                const isCard = !PAYMENTS_PIX.includes(formated.method);
                                return (
                                    <Item key={i.toString()}>
                                        <FlagType
                                            type={item.type as string}
                                            width={isCard ? 26 : 26}
                                            height={isCard ? 16.39 : 26}
                                        />
                                        <TextTitle>{item.label}</TextTitle>
                                    </Item>
                                );
                            })}
                        </ContainerList>
                    )}
                </ContainerInput>
                {error && touched && <ErrorMsgField>{error}</ErrorMsgField>}
            </Container>
            <ModalPortal>
                {showModalType && (
                    <ModalCreditCardType
                        paymentMethod={showModalType}
                        onClose={() => setShowModalType(undefined)}
                        onSubmit={handleSubmitCreditCardType}
                    />
                )}
            </ModalPortal>
            <ModalPortal>
                {showModalPagseguroCreditCard && (
                    <ModalPagseguroCreditCard
                        onClose={() => setShowModalPagseguroCreditCard(undefined)}
                        onSubmit={handleSubmitCreditCard}
                    />
                )}
                {/* {showModalTtxpayCreditCard && (
                        <ModalCreditCardSelect
                            onClose={() => setShowModalTtxpayCreditCard(undefined)}
                            onSubmit={handleSubmitCreditCard}
                        />
                    )} */}
            </ModalPortal>
            {/*<ModalPortal>
                {showModalTtxpayCreditCard && (
                    <ModalTtxpayCreditCard
                        onClose={() => setShowModalTtxpayCreditCard(undefined)}
                        onSubmit={handleSubmitCreditCard}
                    />
                )}
            </ModalPortal>*/}
        </>
    );
};

export default forwardRef(CheckoutSelectPayment);
