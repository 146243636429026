import { capitalize } from '@mui/material';
import Router from 'next/router';

export const REGEX_CARACTER_LOW = /[a-z]+/;
export const REGEX_CARACTER_UPP = /[A-Z]+/;
export const REGEX_CARACTER_NUM = /[0-9]+/;
export const REGEX_CPF =
    /^([0-9]{3}\.?[0-9]{3}\.?[0-9]{3}\-?[0-9]{2}|[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}\-?[0-9]{2})$/;
export const REGEX_CELL = /\(\d{2}\)\s\d{4,5}-?\d{4}/g;
export const REGEX_FULL_DATE =
    /(((0[1-9]|[12][0-9]|3[01])([/])(0[13578]|10|12)([/])(\d{4}))|(([0][1-9]|[12][0-9]|30)([/])(0[469]|11)([/])(\d{4}))|((0[1-9]|1[0-9]|2[0-8])([/])(02)([/])(\d{4}))|((29)(\/)(02)([/])([02468][048]00))|((29)([/])(02)([/])([13579][26]00))|((29)([/])(02)([/])([0-9][0-9][0][48]))|((29)([/])(02)([/])([0-9][0-9][2468][048]))|((29)([/])(02)([/])([0-9][0-9][13579][26])))/g;
export const REGEX_CREDIT_VALID = /^(0?[1-9]|1[012])(\/)\d{2}/g;

export const unMask = (value: string): string => value.replace(/\W/g, '');
export const getRandomInt = (min: number, max: number) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const cpfValid = (string: string | undefined): boolean => {
    if (string === undefined || string.trim() == '') {
        return true;
    }
    var sum;
    var rest;
    sum = 0;

    if (string === '00000000000' || !string || string.length == 0) return false;
    const strCPF = unMask(string);

    if (
        [
            '00000000000',
            '11111111111',
            '22222222222',
            '33333333333',
            '44444444444',
            '55555555555',
            '66666666666',
            '77777777777',
            '88888888888',
            '99999999999'
        ].includes(strCPF)
    ) {
        return false;
    }

    for (let i = 1; i <= 9; i++) sum = sum + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    rest = (sum * 10) % 11;

    if (rest === 10 || rest === 11) rest = 0;
    if (rest !== parseInt(strCPF.substring(9, 10))) return false;

    sum = 0;
    for (let i = 1; i <= 10; i++) sum = sum + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    rest = (sum * 10) % 11;

    if (rest === 10 || rest === 11) rest = 0;
    if (rest !== parseInt(strCPF.substring(10, 11))) return false;
    return true;
};

export function formatZipCode(cep: string) {
    cep = cep.replace(/\D/g, '');

    cep = cep.replace(/(\d{5})(\d{3})/, '$1-$2');

    return cep;
}

export const replaceParams = (str: string, arr: any) => {
    return str.replace(/%(\d+)/g, function (_, m) {
        return arr[--m];
    });
};
export const ucfirst = (string?: string) => (string ? string.charAt(0).toUpperCase() + string.slice(1) : '');

export const getDatePt = (date: string) => {
    if (!date) {
        return null;
    }

    const newDate = date.split('-');

    return `${newDate[2]}/${newDate[1]}/${newDate[0]}`;
};
export const getDateUS = (date: string) => {
    if (!date) {
        return null;
    }

    const newDate = date.split('/');

    return `${newDate[2]}-${newDate[1]}-${newDate[0]}`;
};

export function dynamicSort(property: string) {
    var sortOrder = 1;
    if (property[0] === '-') {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a: any, b: any) {
        /* next line works with strings and numbers,
         * and you may want to customize it to your needs
         */
        var result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
        return result * sortOrder;
    };
}

export const replaceMsgError = (message: string, params: string[]) => {
    let count = 1;
    while (message.includes(`%${count}`)) {
        message = message.replace(new RegExp(`%${count}`, 'g'), params[count - 1]);
        count++;
    }
    return message;
};

export const listToMatrix = <T>(list: T[], elementsPerSubArray: number) => {
    var matrix: Array<T[]> = [],
        i,
        k;

    for (i = 0, k = -1; i < list.length; i++) {
        if (i % elementsPerSubArray === 0) {
            k++;
            matrix[k] = [];
        }

        matrix[k].push(list[i]);
    }

    return matrix;
};

export const addHash = (hash: string) => {
    const { query, pathname } = Router;
    query[hash] = '1';
    Router.replace({ pathname, query }, undefined, { shallow: true });
    // const exists = window.location.hash.split('#');
    // const set = new Set([...exists, hash]);
    // window.location.hash = Array.from(set)
    //     .filter((v) => v.length)
    //     .map((v) => '#' + v)
    //     .join('');
};

export const hasHash = (hash: string) => {
    // const exists = window.location.hash.split('#');

    // return exists.includes(hash);
    const { query } = Router;

    return hash in query;
};
export const removeHash = (hash: string) => {
    // const exists = window.location.hash.split('#');

    // const newHash = exists
    //     .filter((v) => v != hash)
    //     .filter((v) => v.length)
    //     .map((v) => '#' + v)
    //     .join();
    // window.location.hash = newHash.length ? newHash : '';
    const { pathname, query } = Router;
    delete query[hash];
    Router.replace({ pathname, query }, undefined, { shallow: true });
};
export const replaceMsgParams = (message: string, params?: Record<string, string>) => {
    if (params) {
        for (const key in params) {
            if (key in params) {
                const value = key in params ? (params[key] as string) : key;
                message = message.replace(new RegExp('\\${' + key + '}', 'g'), value);
            }
        }
    }

    return message;
};

export const findNodeRecursiveArray = (
    id: string | number,
    column: string,
    columnChidren: string,
    currentNode: any
): any => {
    for (let item of currentNode) {
        const result = findNodeRecursive(id, column, columnChidren, item);
        if (result) return result;
    }
};
export const findNodeRecursive = (
    id: string | number,
    column: string,
    columnChidren: string,
    currentNode: any
): any => {
    var i, currentChild, result;

    if (id == currentNode[column]) {
        return currentNode;
    } else {
        // Use a for loop instead of forEach to avoid nested functions
        // Otherwise "return" will not work properly
        if (columnChidren in currentNode) {
            for (i = 0; i < currentNode[columnChidren].length; i += 1) {
                currentChild = currentNode[columnChidren][i];

                // Search in the current child
                result = findNodeRecursive(id, column, columnChidren, currentChild);

                // Return the result if the node has been found
                if (result !== false) {
                    return result;
                }
            }
        }
        // The node has not been found and we have no more options
        return false;
    }
};

export function capitalizeEveryLetter(string: string) {
    return string
        ?.split(' ')
        ?.map((word) => capitalize(word))
        ?.join(' ');
}

export function replaceUndefinedWithNull(obj: any) {
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            if (obj[key] === undefined) {
                obj[key] = null;
            } else if (typeof obj[key] === 'object' && obj[key] !== null) {
                replaceUndefinedWithNull(obj[key]);
            }
        }
    }
    return obj;
}

export function generateUrlKey(name: string): string {
    return name
        ?.normalize('NFD') // Normalize to NFD Unicode format
        .replace(/[\u0300-\u036f]/g, '') // Remove diacritical marks
        .toLowerCase()
        .replace(/[^\w\s-]/g, '') // Remove special characters except word characters, spaces, and hyphens
        .trim()
        .replace(/\s+/g, '-') // Replace spaces with hyphens
        .replace(/-+/g, '-'); // Replace multiple hyphens with a single one
}
