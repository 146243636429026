import dynamic from 'next/dynamic';
import { HtmlHTMLAttributes, useRef, useState } from 'react';
import tw, { styled } from 'twin.macro';
import { useOutsideClick } from '../../../../hooks';
import { TextNunitoRegular16 } from '../../../atoms';
import IconMenu from '../../../atoms/icons/Menu';
export const Icon = styled(IconMenu)`
    ${tw`
        w-6
        h-4
        fill-[#9FBE43]
    `}
`;
const MenuDesktop = dynamic(() => import('./MenuDesktop'), { ssr: false });
const MenuMobile = dynamic(() => import('./MenuMobile'), { ssr: false });

function Menu({ className, ...props }: HtmlHTMLAttributes<HTMLDivElement>) {
    const [isOpen, setIsOpen] = useState(false);
    const refRoot = useRef<HTMLDivElement>(null);
    const refMenuDesktop = useRef<HTMLDivElement>(null);
    useOutsideClick([refRoot, refMenuDesktop], () => {
        setIsOpen(false);
    });

    return (
        <>
            <div
                className={`cursor-pointer gap-2 flex flex-row items-center ${className}`}
                id="header-menu"
                onClick={() => setIsOpen((old) => !old)}
                ref={refRoot}
                {...props}
            >
                <Icon />
                <TextNunitoRegular16 className="hidden md:flex">Departamentos</TextNunitoRegular16>
            </div>
            <MenuDesktop isOpen={isOpen} onClose={() => setIsOpen(false)} forwardedRef={refMenuDesktop} />
            <MenuMobile isOpen={isOpen} onClose={() => setIsOpen(false)} />
        </>
    );
}

export default Menu;
