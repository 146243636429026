import Badge from '../Badge';
import { BadgeColor, BadgeSize } from '../Badge/types';

interface BadgeProductDiscountProps {
    size: BadgeSize;
    value: number | string;
}

function BadgeProductDiscount({ size, value }: BadgeProductDiscountProps) {
    return (
        <Badge size={size} color={BadgeColor.RED}>
            {value}%
        </Badge>
    );
}

export default BadgeProductDiscount;
