import { FC, memo } from 'react';
import { Banner as BannerInterface } from '../../../services/banner';
import Banner from '../Banner';
import { Img } from './styles';
import { IBannerCarouselSlideShowThreeProps } from './types';

const BannerCarouselSlideShowThree: FC<IBannerCarouselSlideShowThreeProps> = ({
    id,
    className,
    widthImage,
    heightImage
}) => {
    const responsive = {
        0: { slidesPerView: 1 },
        500: { slidesPerView: 2 },
        795: { slidesPerView: 3 }
    };

    const renderImage = (banner: BannerInterface) => (
        <Img src={banner.image} width={widthImage} className={className} height={heightImage} />
    );

    return (
        <Banner
            bannerId={id}
            responsive={responsive}
            marginItem={15}
            numberSkeleton={3}
            renderImage={renderImage}
            slidesPerGroup={3}
            autoPlay
        />
    );
};

export default memo(BannerCarouselSlideShowThree);
