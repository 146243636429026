import tw, { styled } from 'twin.macro';
import { Button as DefaultButton } from '../../molecules';
import Link from 'next/link';

export const Container = styled.div`
    &.email-sent {
        ${tw`
        grid-rows-[100px]
        lg:grid-rows-[130px 77px 54px]
    `}
    }
    ${tw`
        grid
        grid-cols-[auto]
        grid-rows-[120px]
        
        lg:grid-cols-[40px 370px]
        lg:grid-rows-[130px]
        lg:mb-0
        `}
`;

export const TitleContainer = styled.div`
    ${tw`
        row-start-1
        flex
        flex-col
        items-start
        pt-[20px]
        
        lg:pt-[80px]
        lg:items-start
        lg:col-start-2
    `}
`;

export const TitleLabel = styled.div`
    ${tw`
        font-[400]
        text-[35px]
    `}
`;

export const PersonalInfoFormContainer = styled.div`
    ${tw`flex flex-col col-start-1 row-start-2 lg:col-start-2`}
`;

export const LoginFormHeader = styled.span`
    ${tw`
    w-full
    flex
    justify-between
    items-center
    mb-[20px]

    lg:flex-col
    lg:items-start
    lg:justify-between
    lg:mb-[7px]
    `}
`;

export const ContainerTitle = styled.span`
    ${tw`
    font-[400]
    text-[16px]  
    `}
`;

export const Button = styled((props) => DefaultButton(props))`
    ${tw`
        lg:mt-52
        mt-10
        sh:mt-10
        w-full
        max-w-[400px]
    `}
`;

export const FormBackButton = styled(Link)`
    ${tw`
        w-full
        text-center
        font-[400]
        text-[16px]
        text-primary
        cursor-pointer
        items-center
        flex
        justify-center
        h-[54px]

        lg:w-auto
        lg:ml-[40px]
        // lg:mr-[61px]
    `}
`;

export const ContainerDescription = styled.span`
    ${tw`
    font-[400]
    text-[16px]

    mt-[66px]

    sh:mt-10
    my-[10px]

    lg:min-h-[50px]
    lg:mb-[0px]
    lg:text-[15px]
    `}
`;
