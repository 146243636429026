import { SVGProps } from 'react';

const IconListAdd = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M19.9206 10.9921H15.9524V14.9603H13.9682V10.9921H9.99998V9.00793H13.9682V5.03967H15.9524V9.00793H19.9206V10.9921ZM11.9841 5.03967H0.0793457V6.03174H11.9841V5.03967ZM0.0793457 9.99999H8.01585V9.00793H0.0793457V9.99999ZM0.0793457 13.9682H8.01585V12.9762H0.0793457V13.9682Z" />
        </svg>
    );
};

export default IconListAdd;
