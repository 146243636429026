import { motion } from 'framer-motion';
import tw, { styled } from 'twin.macro';
import IconMenu from '../../atoms/icons/Menu';
import { TextLatoExtraBold25, TextNunitoBold22 } from '../../atoms';

export const Container = styled.div`
  ${tw`
        w-full
        flex
        flex-col
        bg-black
        justify-center
        items-center

        lg:bg-transparent
    `}
`;

export const CollapsableZone = styled(motion.div)`
  ${tw`
      w-full
    `}
`;

export const MenuHeader = styled.header`
  ${tw`
        flex
        justify-center
        cursor-pointer
        w-full
        pt-[15px]
        pb-[15px]

        lg:hidden
    `}
`;

export const MenuTitle = styled(TextNunitoBold22)`
  ${tw`
    hidden
    w-full
    text-left
    mb-[25px]
    
    lg:inline-block
  `}
`;

export const Icon = styled(IconMenu)`
  ${tw`
        fill-lightGray
    `}
`;

export const MenuItems = styled.ul`
  ${tw`
        flex
        flex-col
    `}
`;
