import { ICookieCtx } from '../../utils/cookie';
import axiosService from '../axios';

export let api = axiosService.getClientApi();
export let apiNext = axiosService.getClientApiNext();
export let apiAdmin = axiosService.getApiAdmin('default');
export let apiAdminPublic = axiosService.getApiAdminPublic();
export let apiElastic = axiosService.getApiElastic();

export const recreateApi = (ctxCookie?: ICookieCtx) => {
    // api = axiosService.getClientApi(ctxCookie);
    // apiAdmin = axiosService.getApiAdmin();
    // apiAdminPublic = axiosService.getApiAdminPublic();
};

export default api;
