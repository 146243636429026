import { destroyCookie, getCookie, ICookieCtx, setCookie } from '../../utils/cookie';
import {
    STORAGE_CART,
    STORAGE_CUSTOMER_ID,
    STORAGE_KEY_SHIPPING_METHOD,
    STORAGE_KEY_STORAGE,
    STORAGE_KEY_TOKEN,
    STORAGE_USERNAME_PASSWORD
} from '../../utils/cookies-keys';
import { StorageUsernamePassword, UserToken } from '../auth/types';
import { IEstablishment } from '../establishment/types';

const setEstablishmentStorage = (store: IEstablishment | null, options?: ICookieCtx) => {
    if (store) {
        setCookie(STORAGE_KEY_STORAGE, JSON.stringify(store), options);
    } else {
        destroyCookie(STORAGE_KEY_STORAGE, options);
    }
};

const getEstablishmentStorage = (options?: ICookieCtx): IEstablishment | null => {
    const valueStr = getCookie(STORAGE_KEY_STORAGE, options);

    if (valueStr) {
        return JSON.parse(valueStr as string);
    }
    return null;
};
const setShippingMethod = (value: string | null, options?: ICookieCtx) => {
    if (value !== null) {
        setCookie(STORAGE_KEY_SHIPPING_METHOD, value, options);
        return;
    }
    return destroyCookie(STORAGE_KEY_SHIPPING_METHOD, options);
};
const getShippingMethod = (options?: ICookieCtx): string | null => {
    const valueStr = getCookie(STORAGE_KEY_SHIPPING_METHOD, options) as string | undefined;
    if (valueStr) {
        return valueStr;
    }
    return null;
};

const setUserTokenStorage = (token: UserToken | null, params?: ICookieCtx) => {
    if (token) {
        setCookie(STORAGE_KEY_TOKEN, token, params);
        return;
    }

    return destroyCookie(STORAGE_KEY_TOKEN, params);
};
const getUserTokenStorage = (params?: ICookieCtx): null | UserToken => {
    const exists = getCookie(STORAGE_KEY_TOKEN, params) as UserToken;
    return exists || null;
};
const setUsernamePasswordStorage = (usernamePassword: StorageUsernamePassword | null, params?: ICookieCtx) => {
    if (usernamePassword) {
        return setCookie(STORAGE_USERNAME_PASSWORD, JSON.stringify(usernamePassword), params);
    }

    return destroyCookie(STORAGE_USERNAME_PASSWORD, params);
};
const getUserCustomerIdStorage = (params?: ICookieCtx): null | string => {
    const exists = getCookie(STORAGE_CUSTOMER_ID, params) as string;
    return exists || null;
};
const setUserCustomerIdStorage = (id: string | null, params?: ICookieCtx) => {
    if (id) {
        return setCookie(STORAGE_CUSTOMER_ID, id, params);
    }

    return destroyCookie(STORAGE_CUSTOMER_ID, params);
};
const getUsernamePasswordStorage = (params?: ICookieCtx): null | StorageUsernamePassword => {
    const result = getCookie(STORAGE_USERNAME_PASSWORD, params) as string;
    if (result) {
        return JSON.parse(result);
    }

    return null;
};

const setCartIdStorage = (id: string | null, params?: ICookieCtx) => {
    if (id) {
        return setCookie(STORAGE_CART, id, params);
    }
    return destroyCookie(STORAGE_CART, params);
};
const getCartIdStorage = (params?: ICookieCtx): string | null => {
    return getCookie(STORAGE_CART, params) as string | null;
};
export default {
    getEstablishmentStorage,
    setEstablishmentStorage,
    getUsernamePasswordStorage,
    setUserCustomerIdStorage,
    getUserTokenStorage,
    setUserTokenStorage,
    getUserCustomerIdStorage,
    setUsernamePasswordStorage,
    setCartIdStorage,
    getCartIdStorage,
    setShippingMethod,
    getShippingMethod
};
