interface ISortButtonProps {
    selected?: boolean;
    onClick?: () => void;
    text: string;
}
function SortButton({ selected, onClick, text }: ISortButtonProps) {
    return (
        <button
            type="button"
            className={`border-[0.5px] border-[#D9D9D9] rounded-28 py-4 px-3 text-16 ${
                selected && 'bg-[#F9FBF3]'
            } hover:bg-[#F9FBF3]`}
            onClick={onClick}
        >
            {text}
        </button>
    );
}

export default SortButton;
