import { FC } from 'react';
import tw, { styled } from 'twin.macro';

const Container = styled.span`
    line-height: 24px;
    ${tw`
        font-nunito
        text-20
        text-black
        font-bold
    `}
`;

const TextLatoBold20: FC<any> = ({ children, ...props }) => {
    return <Container {...props}>{children}</Container>;
};

export default TextLatoBold20;
