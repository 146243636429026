import tw, { styled } from 'twin.macro';
import { IconListView } from '../../atoms';
import { IContainerProps } from './types';

export const ListsContainer = styled.div<IContainerProps>`
    ${tw`
        grid
        w-full
        gap-[20px]
        grid-cols-1
        sm:grid-cols-2
        md:grid-cols-3
    
    `}
    ${({ $isEmpty }) =>
        $isEmpty &&
        tw`
            flex
            items-center
            justify-center
        `}
`;

export const IconEmpty = styled(IconListView)`
    ${tw`
        w-[73px]
        h-[73px]
        fill-gray

    `}
`;
