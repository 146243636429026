import { FC } from 'react';
import { TextLatoRegular15 } from '../..';
import { Container } from './styles';

const TableCell: FC<any> = ({ children }) => {
  return (
    <Container>
      <TextLatoRegular15>{children}</TextLatoRegular15>
    </Container>
  );
};

export default TableCell;
