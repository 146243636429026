import { SVGProps } from 'react';

const IconListView = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width="25" height="22" viewBox="0 0 25 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect x="9" width="16" height="7" rx="1" fill={props.fill} stroke={props.stroke} />
            <rect x="9" y="8" width="16" height="6" rx="1" fill={props.fill} stroke={props.stroke} />
            <rect x="9" y="15" width="16" height="7" rx="1" fill={props.fill} stroke={props.stroke} />
            <rect width="7" height="7" rx="1" fill={props.fill} stroke={props.stroke} />
            <rect y="8" width="7" height="6" rx="1" fill={props.fill} stroke={props.stroke} />
            <rect y="15" width="7" height="7" rx="1" fill={props.fill} stroke={props.stroke} />
        </svg>
    );
};

export default IconListView;
