import { FC, SVGProps } from 'react';

const IconTrash: FC<SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
            <path
                stroke="currentcolor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeOpacity={0.5}
                strokeWidth={1.5}
                d="M5 7h14M18 7v11a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2V7M15 3.75H9M10 11v5M14 11v5"
            />
        </svg>
    );
};

export default IconTrash;
