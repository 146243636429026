import tw, { styled } from 'twin.macro';

export const Container = styled.div`
    a {
        ${tw`
            text-[#444]
        `}
    }

    h1 {
        ${tw`
            text-[30px]
            font-extrabold
            mb-[20px]
        `}
    }

    ol {
        ${tw`
        block
        list-decimal
        pl-[45px]
        `}
    }

    ul {
        ${tw`
        block
        pl-[40px]
        `}
    }

    li {
        display: list-item;
        ${tw`
        list-item
        mb-[10px]
        `}
    }

    strong {
        ${tw`
            font-bold
        `}
    }

    ${tw`
    text-[13px]
    py-[30px]

    lg:px-[40px]
 `}
`;

export const Message = styled.div`
    ${tw`
        flex
        items-center
        text-[27px]
        text-[gray]
        flex-col
        text-center
        
    `}
`;
