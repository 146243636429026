import { FC, ReactElement } from 'react';
import { Container, TextTitle } from './styles';

interface ListEmptyProps {
    icon: ReactElement;
    text: string;
}

const ListEmpty: FC<ListEmptyProps> = ({ icon, text }) => {
    return (
        <Container>
            {icon}
            <TextTitle>{text}</TextTitle>
        </Container>
    );
};

export default ListEmpty;
