import { motion } from 'framer-motion';
import { FC, forwardRef, Ref } from 'react';
import tw, { styled } from 'twin.macro';

const Container = styled(motion.span)`
    line-height: 21.6px;
    ${tw`
        font-nunito
        text-18
        text-black
        font-extrabold
    `}
`;

const TextLatoExtraBold18 = forwardRef(({ children, ...props }: any, ref: Ref<any>) => {
    return (
        <Container {...props} ref={ref}>
            {children}
        </Container>
    );
});

export default TextLatoExtraBold18;
