import { AuthService, UserService } from '..';
import { USER_INFO } from '../../utils/react-query-keys';
import api from '../api';
import { queryClient } from '../query-client';

const addNewsletter = async (emailGuest?: string) => {
    const userInfo = await UserService.getInfo();
    const endpoint = userInfo ? `/V1/newsletter/customer/subscribe` : `/V1/newsletter/guest/subscribe`;

    // Configurar headers condicionalmente

    const response = await api.post(
        endpoint,
        !userInfo
            ? {
                  email: emailGuest
              }
            : undefined
    );
    await queryClient.resetQueries([USER_INFO]);
    return response;
};
const cancelNewsletter = async () => {
    const endpoint = `/V1/newsletter/customer/unsubscribe`;

    await api.post(endpoint);
    await queryClient.resetQueries([USER_INFO]);
};

export default {
    addNewsletter,
    cancelNewsletter
};
