import dynamic from 'next/dynamic';
import FooterCategorySection from './Footer-category-section';
import FooterDeliveryBanner from './Footer-delivery-banner';
import FooterDesktop from './Footer-desktop';
import FooterMiddleBanner from './Footer-middle-banner';
import FooterMobile from './Footer-mobile';
const Newsletter = dynamic(() => import('./Newsletter'), { ssr: false });
import { Container } from './styles';

const Footer = () => {
    return (
        <Container>
            <FooterDeliveryBanner className="!hidden md:!block" />
            <div className="md:hidden flex justify-center items-center p-9">
                <Newsletter />
            </div>
            <FooterCategorySection />
            <FooterMiddleBanner />
            <FooterDesktop />
            <FooterMobile />
        </Container>
    );
};

export default Footer;
