import VMasker from 'vanilla-masker';

export type Mask =
    | 'cpf'
    | 'mobileNumber'
    | 'cep'
    | 'date'
    | 'dateMMYY'
    | 'dateMMYYYY'
    | 'currency'
    | 'currencyDecimal'
    | 'number'
    | 'cvv'
    | 'weight'
    | 'cnpj'
    | 'creditCard';

type GetFormattedValueT = {
    value: string;
    mask: Mask;
    minimumFractionDigits?: number;
    maximumFractionDigits?: number;
};

export const masks: { [key in Mask]: string } = {
    cpf: '999.999.999-99',
    cnpj: '99.999.999/9999-99',
    mobileNumber: '99) 99999-9999',
    cep: '99999-999',
    creditCard: '9999 9999 9999 9999',
    date: '99/99/9999',
    dateMMYY: '99/99',
    dateMMYYYY: '99/9999',
    currency: '',
    currencyDecimal: '',
    number: '',
    weight: '',
    cvv: '9999'
};

const getFormattedValue = ({
    value,
    mask,
    minimumFractionDigits = 2,
    maximumFractionDigits = 2
}: GetFormattedValueT): string => {
    try {
        if (maximumFractionDigits < minimumFractionDigits) {
            maximumFractionDigits = minimumFractionDigits;
        }

        if (mask) {
            if (mask === 'currency' || mask === 'currencyDecimal') {
                // return VMasker.toMoney(value, {
                //     precision: 2,
                //     delimiter: '.',
                //     zeroCents: true
                // });
                return new Intl.NumberFormat('pt-BR', {
                    currency: 'BRL',
                    minimumFractionDigits,
                    maximumFractionDigits
                }).format(parseFloat(value));
            }
            if (mask === 'weight') {
                let newValue = parseFloat(value);
                if (newValue < 1) {
                    return `${newValue * 1000}g`;
                } else {
                    if (newValue % 1 == 0) {
                        return `${parseInt(newValue.toString())}Kg`;
                    }

                    const result = new Intl.NumberFormat('pt-BR', {
                        currency: 'BRL',
                        minimumFractionDigits,
                        maximumFractionDigits
                    }).format(parseFloat(value));
                    return `${result}Kg`;
                }
            }

            if (mask === 'number') {
                return VMasker.toNumber(value);
            }

            const formattedValue: string = VMasker.toPattern(value, masks[mask]);
            return formattedValue;
        }

        return value;
    } catch (error) {
        console.info('error', error);
        return value;
    }
};
export const currencyUnMask = (value?: any) => {
    if (typeof value != 'number' && typeof value != 'string') {
        return 0;
    }
    if (typeof value == 'number') {
        value = value.toString();
    }

    return Number(
        value
            .toString()
            .replace(/[^0-9\,-]+/g, '')
            .replace(/\,/, '.')
    );
};

export const currencyBrl = (value: string | number) => {
    if (typeof value === 'number') {
        value = value.toString();
    }

    return getFormattedValue({ value, mask: 'currency' });
};

export default getFormattedValue;
