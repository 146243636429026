import { motion } from 'framer-motion';
import tw, { styled } from 'twin.macro';
import TextLatoExtraBold13 from '../text/Lato-extra-bold-13';
import TextLatoExtraBold15 from '../text/Lato-extra-bold-15';

export const Container = styled(motion.div)`
    ${tw`
        flex
        flex-row
        justify-center
        items-center
        max-w-[148px]
        h-[27px]
        gap-[4px]
        w-full
    `}
`;
export const Button = styled.div<{ $disabled?: boolean }>`
    ${tw`
        bg-primary
        flex
        justify-center
        items-center
        w-[30px]
        h-[30px]
        rounded-10
        cursor-pointer
        shrink-0
    `}
    ${({ $disabled }) => $disabled && tw`bg-gray cursor-not-allowed`}
`;

export const ButtonText = styled(TextLatoExtraBold15)`
    ${tw`
        text-white
    `};
`;
export const TextQty = styled(TextLatoExtraBold15)`
    ${tw`
    min-w-[30px]
    text-center
    text-[14px]
    `}
`;

//button quando não tem produto no carrinho
export const ButtonRoot = styled(motion.button)`
    ${tw`
        w-[98px]
        h-[30px]
        bg-primary
        rounded-10
        justify-center
        flex
        items-center
        cursor-pointer
    `}
`;
export const TextAddCart = styled(TextLatoExtraBold13)`
    ${tw`
        text-white
        text-center
    `}
`;
