import { useMutation } from '@tanstack/react-query';
import { CartService } from '../../../services';
import { CartItem } from '../../../services/cart/types';
import { Button, Buttons, Container, ContainerModal, Header } from './styles';
interface IModalCheckoutProductOutStock {
    data: CartItem[];
    onPressConfirm: () => void;
    onPressClose: () => void;
}

const ModalCheckoutProductOutStock = ({ data, onPressConfirm, onPressClose }: IModalCheckoutProductOutStock) => {
    const { mutate, isLoading } = useMutation(
        async () => {
            return Promise.all(data.map((p) => CartService.remove(p.item_id)));
        },
        { onSuccess: () => onPressConfirm(), onError: (e) => console.info(e) }
    );
    return (
        <ContainerModal>
            <Header title="Produtos sem estoque" />

            <Container>
                Ops! Durante a sua compra, alguns produtos ficaram sem estoque, portanto será necessário remover esses
                produtos do carrinho.
            </Container>

            <Buttons>
                <Button text="Voltar" onClick={onPressClose} />
                <Button text="Remover" onClick={mutate} isLoading={isLoading} />
            </Buttons>
        </ContainerModal>
    );
};

export default ModalCheckoutProductOutStock;
