import { AnimatePresence } from 'framer-motion';
import { PropsWithChildren } from 'react';
import { Portal } from '..';

const ModalPortal = ({ children }: PropsWithChildren) => {
    const onHideModal = () => {
        setTimeout(() => {
            const modals = document.querySelectorAll('.modal');
            if (modals.length) return;

            const html = document.getElementsByTagName('body')[0];
            if (!html) return;
            html.style.overflow = '';
            html.style.paddingRight = '';
        }, 100);
    };

    return (
        <Portal>
            <AnimatePresence onExitComplete={onHideModal}>{children}</AnimatePresence>
        </Portal>
    );
};

export default ModalPortal;
