import { useMutation } from '@tanstack/react-query';
import creditCardType from 'credit-card-type';
import { FormikProvider, useFormik } from 'formik';
import { FC, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { PaymentPagseguroCreditCard } from '../../../factories/paymentPagseguroCreditCard';
import { useCart } from '../../../hooks';
import { CreditCardValues, InstallmentsResponse, PaymentTypes } from '../../../services/payment/types';
import { CPF, REQUIRED } from '../../../utils/errors';
import { currencyBrl } from '../../../utils/getFormattedValue';
import { cpfValid, REGEX_CREDIT_VALID, REGEX_FULL_DATE } from '../../../utils/string';
import { Input } from '../../molecules';
import { CreditCardFlip } from '../../organisms';
import { ICreditCardFlipHandle } from '../../organisms/Credit-card-flip/types';
import { ButtonAdd, Card, Container, Content, Footer, Form, Header, InputCvv, TextTitle } from './styles';
import { IModalPagseguroCreditCardProps } from './types';

const required = (value: any, options: any) => {
    const useSave = Boolean(options.from[0].value.useSave);
    if (useSave) {
        return true;
    } else if (!useSave && !value) {
        return false;
    }

    return true;
};
const validationSchema = Yup.object().shape({
    number: Yup.string().test('required', REQUIRED, required),
    valid: Yup.string().test('required', REQUIRED, required).matches(REGEX_CREDIT_VALID, 'Inválido'),
    birthday: Yup.string().test('required', REQUIRED, required).matches(REGEX_FULL_DATE, 'Inválido'),
    cvv: Yup.string().test('required', REQUIRED, required),
    name: Yup.string().test('required', REQUIRED, required),
    installments: Yup.object().required(REQUIRED),
    cpf: Yup.string().test('required', REQUIRED, required).test('cpf', CPF, cpfValid)
});

const ModalPagseguroCreditCard: FC<IModalPagseguroCreditCardProps> = ({ onClose, onSubmit }) => {
    const refCard = useRef<ICreditCardFlipHandle>(null);
    const { data } = useCart();

    const { isLoading, mutate: getInstallments } = useMutation<InstallmentsResponse[], unknown, CreditCardValues>(
        (data) => {
            const instance = new PaymentPagseguroCreditCard();
            return instance.getInstallments({
                type: PaymentTypes.PAGSEGURO_CREDIT_CARD,
                creditCardCvv: data.cvv,
                creditCardNumber: data.number,
                creditCardType: data.type,
                creditCardValid: data.valid,
                value: data.value
            });
        },
        {
            onSuccess: (dataInstallments) => {
                const [installments] = dataInstallments;

                const body: CreditCardValues = {
                    ...formik.values,
                    installments: installments
                };

                onSubmit({
                    paymentMethod: PaymentTypes.PAGSEGURO_CREDIT_CARD,
                    values: [body]
                });
            },
            onError: () => {
                toast.error('Algo aconteceu. Tente novamente');
            }
        }
    );

    const formik = useFormik<CreditCardValues>({
        initialValues: {
            useSave: undefined,
            number: undefined,
            valid: undefined,
            cvv: undefined,
            name: undefined,
            cpf: undefined,
            type: undefined,
            installments: {
                quantity: 1,
                totalWithTax: data?.info?.grand_total || 0,
                installmentTotal: data?.info?.grand_total || 0,
                label: '1x'
            },
            value: `R$ ${currencyBrl(Number(data?.info?.grand_total))}`,
            save: undefined,
            birthday: undefined
        },
        onSubmit: (values) => {
            getInstallments(values);
        },
        validationSchema,
        validateOnMount: false
    });

    const handleChangeCC = (name: string, value: string) => {
        if (value) {
            const [valueType] = creditCardType(value);
            const type = valueType && valueType.type && valueType.type;

            formik.setFieldValue('type', type);
            return;
        }
        formik.setFieldValue('type', '');
    };

    return (
        <Container onClose={onClose}>
            <Header title="Forma de pagamento" showIconClose onPressIconClose={onClose} />
            <Content>
                <Card>
                    <CreditCardFlip
                        ref={refCard}
                        name={formik.values.name}
                        number={formik.values.number}
                        cvv={formik.values.cvv}
                        valid={formik.values.valid}
                    />
                </Card>
                <Form>
                    <FormikProvider value={formik}>
                        <TextTitle>Preencha os dados</TextTitle>
                        <Input
                            mask="creditCard"
                            name="number"
                            pattern="\d*"
                            onChange={handleChangeCC}
                            autoComplete="cc-number"
                            label="Número do cartão*"
                            inputMode="numeric"
                        />
                        <InputCvv>
                            <Input
                                mask="dateMMYYYY"
                                pattern="\d*"
                                name="valid"
                                autoComplete="cc-exp"
                                label="Validade* (99/9999)"
                                inputMode="numeric"
                            />
                            <Input
                                mask="cvv"
                                name="cvv"
                                pattern="\d*"
                                autoComplete="cc-csc"
                                inputMode="numeric"
                                label="CVV*"
                                onFocus={() => refCard.current?.flip()}
                                onBlur={() => refCard.current?.flip()}
                            />
                        </InputCvv>
                        <Input name="name" label="Nome do Titular do Cartão*" />
                        <Input name="cpf" mask="cpf" pattern="\d*" label="CPF*" inputMode="numeric" />
                        <Input
                            name="birthday"
                            mask="date"
                            pattern="\d*"
                            label="Data de Nascimento*"
                            inputMode="numeric"
                        />
                    </FormikProvider>
                </Form>
            </Content>
            <Footer>
                <ButtonAdd
                    text="Confirmar"
                    disabled={!formik.isValid || isLoading}
                    isLoading={isLoading}
                    onClick={formik.submitForm}
                />
            </Footer>
        </Container>
    );
};

export default ModalPagseguroCreditCard;
