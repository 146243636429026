import tw, { styled } from 'twin.macro';
import { TextNunitoBold14, TextNunitoRegular14 } from '../../atoms';
import { motion } from 'framer-motion';

export const VARIANTS = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1
    }
};

export const Container = styled(motion.div)`
    ${tw`
        flex
        flex-col
        border-[#FF8943]
        border
        rounded-8
        gap-2
        bg-[rgba(255, 214, 190, 0.10)]
        py-4
        px-4
        md:px-6
    `}
`;

export const Title = styled(TextNunitoBold14)`
    ${tw`
        text-[#FF5F00]
    `}
`;

export const Description = styled(TextNunitoRegular14)`
    ${tw`
        text-[#FF5F00]
    `}
`;
