import { AxiosRequestConfig } from 'axios';
import { PaymentCashnodelivery } from '../../factories/paymentCashnodelivery';
import { PaymentMundipaggCreditCard } from '../../factories/paymentMundipaggCreditCard';
import { PaymentMundipaggDebit } from '../../factories/paymentMundipaggDebit';
import { PaymentMundipaggTwoCreditCard } from '../../factories/paymentMundipaggTwoCreditCard';
import { PaymentPagseguroCreditCard } from '../../factories/paymentPagseguroCreditCard';
import { PaymentTtxPayCreditCard } from '../../factories/paymentTtxPayCreditCard';
import { PaymentTtxPayPix } from '../../factories/paymentTtxPayPix';
import {
    GetInstallmentsParams,
    InstallmentsResponse,
    IPaymentInformation,
    IPaymentSelectedView,
    PaymentInterface,
    PaymentSelected,
    PaymentTypes
} from './types';
import api from '../api';

const STORAGE_LAST_METHOD = '@delivei:last_method';

const setLastMethod = (data: PaymentSelected | null) => {
    if (data) {
        return localStorage.setItem(STORAGE_LAST_METHOD, JSON.stringify(data));
    }
    return localStorage.removeItem(STORAGE_LAST_METHOD);
};
const getLastMethod = async (): Promise<PaymentSelected | null> => {
    const value = await localStorage.getItem(STORAGE_LAST_METHOD);
    if (value) {
        return JSON.parse(value);
    }
    return null;
};

export const paymentMethodsTitles = {
    [PaymentTypes.MUNDIPAGG_CREDIT_CARD]: { full: 'Cartão de crédito', small: 'Crédito' },
    [PaymentTypes.PAGSEGURO_CREDIT_CARD]: { full: 'Cartão de crédito', small: 'Crédito' },
    [PaymentTypes.PAGARME_BILLET]: { full: 'Boleto', small: 'Boleto' },
    [PaymentTypes.PAGARME_CREDIT_CARD]: { full: 'Cartão de crédito', small: 'Crédito' },
    [PaymentTypes.TTXPAY_CREDIT_CARD]: { full: 'Cartão de crédito', small: 'Crédito' },
    [PaymentTypes.TTXPAY_PIX]: { full: 'Pix', small: 'Pix' },
    [PaymentTypes.MONEY]: { full: 'Dinheiro', small: 'Dinheiro' },
    [PaymentTypes.MUNDIPAGG_TWO_CREDIT_CARD]: { full: 'Cartão de crédito', small: 'Crédito' },
    [PaymentTypes.MUNDIPAGG_DEBIT]: { full: 'Cartão de débito', small: 'Débito' },
    [PaymentTypes.MUNDIPAGG_BILLET]: { full: 'Boleto', small: 'Boleto' },
    [PaymentTypes.CASHNODELIVERY]: { full: 'Pagar na entrega', small: 'Pagar na entrega' },
    [PaymentTypes.MERCADOPAGO_PAY]: { full: 'Mercado Pago', small: 'Crédito' },
    [PaymentTypes.PAGBANK_MAGENTO]: { full: 'PagBank', small: 'Crédito' },
    [PaymentTypes.MERCPAGO_ADBPAYMENT]: { full: 'Parcelado em até 12x sem cartão com Mercado Pago', small: 'Crédito' },
};

const getPaymentClass = (method: PaymentTypes): PaymentInterface => {
    const classPayment: any = {
        [PaymentTypes.MUNDIPAGG_CREDIT_CARD]: () => new PaymentMundipaggCreditCard(),
        [PaymentTypes.MUNDIPAGG_TWO_CREDIT_CARD]: () => new PaymentMundipaggTwoCreditCard(),
        [PaymentTypes.MUNDIPAGG_DEBIT]: () => new PaymentMundipaggDebit(),
        [PaymentTypes.PAGSEGURO_CREDIT_CARD]: () => new PaymentPagseguroCreditCard(),
        [PaymentTypes.TTXPAY_PIX]: () => new PaymentTtxPayPix(),
        [PaymentTypes.TTXPAY_CREDIT_CARD]: () => new PaymentTtxPayCreditCard(),
        [PaymentTypes.CASHNODELIVERY]: () => new PaymentCashnodelivery()
    };

    return classPayment[method]();
};

//const getPaymentMethodTitle = (method: PaymentTypes, detail: 'full' | 'small') => paymentMethodsTitles[method][detail];

const getInstallments = async (
    params: GetInstallmentsParams,
    options: AxiosRequestConfig
): Promise<InstallmentsResponse[]> => {
    const classe = getPaymentClass(params.type);

    return classe.getInstallments(params, options);
};

const getPaymentFromCheckout = (data: PaymentSelected): IPaymentSelectedView => {
    const classe = getPaymentClass(data.paymentMethod);

    return classe.getFromCheckout(data);
};
// const getInstallmentsFormated = (data: PaymentSelected, item: CreditCardValues) => {
//     if (
//         data &&
//         item.installments?.quantity &&
//         [
//             PaymentTypes.MUNDIPAGG_CREDIT_CARD,
//             PaymentTypes.MUNDIPAGG_TWO_CREDIT_CARD,
//             PaymentTypes.PAGSEGURO_CREDIT_CARD
//         ].includes(data.paymentMethod)
//     ) {
//         return `${item.installments?.quantity}x `;
//     }

//     return '';
// };

// export const getPaymentCheckout = (data: PaymentSelected): PaymentCheckout[] => {
//     return data.values.map((value) => {
//         let flagName = '';
//         let numberCard = '';

//         if (data.paymentMethod !== PaymentTypes.PAGSEGURO_PIX) {
//             if (!value.useSave) {
//                 const [type] = creditCardType(value?.number);
//                 flagName = type?.type;
//                 numberCard = value?.number?.replace(/ /g, '')?.substr(-4) || '';
//             } else {
//                 flagName = value.useSave.brand;
//                 numberCard = value.useSave.masked_number.substr(-4);
//             }
//         }
//         const quantity = value.installments?.quantity?.toString() || '';
//         return {
//             flagName,
//             installments: getInstallmentsFormated(data, value),
//             method: getPaymentMethodTitle(data.paymentMethod, 'small'),
//             numberCard
//         };
//     });
// };

const getPaymentMethodsAvailable = async ()  => {
    try {
        const {data} = await api.get('V1/carts/mine/payment-information');

        return data;
    } catch (e) {
        console.info('getPaymentMethodsAvailable', e);
        throw e;
    }
};

export default {
    getPaymentClass,
    //getPaymentMethodTitle,
    setLastMethod,
    getInstallments,
    getLastMethod,
    getPaymentFromCheckout,
    getPaymentMethodsAvailable
};
