import tw, { styled } from 'twin.macro';

export const Container = styled.div`
    ${tw`
        
    `}
`;
export const ContainerTitle = styled.div`
    ${tw`
        flex
        flex-row
        justify-between
        items-center
        mb-[12px]
    `}
`;

export const TextTitle = styled.div`
    ${tw`
        animate-pulse 
        h-[18px]
        mt-[2px]
        w-[191px]
        mb-[2px]
        bg-gray
        rounded-5
    `}
`;
export const TextSeeAll = styled.div`
    ${tw`
        animate-pulse 
        h-[15px]
        mt-[1.5px]
        w-[70px]
        mb-[1.5px]
        bg-gray
        rounded-5
        `}
`;

export const List = styled.div`
    ${tw`
        flex
        flex-row
        gap-[20px]
        overflow-hidden
    `}
`;
