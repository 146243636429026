import tw, { styled } from 'twin.macro';
import { Image, TextNunitoBold22 } from '../../atoms';

export const Container = styled.div`
    ${tw`
        flex
        flex-col
        gap-1.5
        md:gap-6
    `}
    .swiper-wrapper {
        ${tw`
            items-center
            // px-6
        `}
    }
    .swiper {
        ${tw`
            px-10
        `}
    }
`;

export const Skeleton = styled.div`
    aspect-ratio: 16/8;
    ${tw`
    animate-pulse 
    bg-gray 
    w-full
    
    h-full
    rounded-5
    `}
`;

export const Title = styled.div`
    ${tw`
        h-12
        flex
        flex-col
        text-black
        text-16
        font-semibold
        justify-center
        md:items-center
        md:text-center
        md:text-22
        md:font-bold
    `}
`;
