import { SVGProps } from 'react';

const IconArrowDown = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width="15" height="7" viewBox="0 0 15 7" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M14.03 0.701123C14.0302 0.799975 14.0092 0.897734 13.9686 0.987887C13.9279 1.07804 13.8684 1.15852 13.794 1.22395L7.47269 6.8232C7.34422 6.93709 7.17826 7 7.00631 7C6.83437 7 6.66841 6.93709 6.53994 6.8232L0.218625 1.22395C0.0864873 1.09887 0.00831644 0.927497 0.000626199 0.746043C-0.00706404 0.564588 0.0563286 0.387259 0.177412 0.251518C0.298496 0.115776 0.467789 0.0322511 0.649528 0.0185876C0.831266 0.00492403 1.01122 0.062192 1.15137 0.178293L7.00631 5.3646L12.8613 0.178294C12.9622 0.0881238 13.0873 0.0290752 13.2213 0.00830384C13.3553 -0.0124675 13.4924 0.00592991 13.6161 0.0612666C13.7398 0.116603 13.8448 0.206504 13.9182 0.320073C13.9917 0.433643 14.0305 0.566006 14.03 0.701123V0.701123Z"
                fill={props.color}
            />
        </svg>
    );
};

export default IconArrowDown;
