import React, { FC, SVGProps } from 'react';

const IconFeature: FC<SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg" {...props}>
            <circle cx={25} cy={25} r={23.5} fill="transparent" stroke="currentColor" strokeWidth={3} />
            <path
                d="M22.697 37.999a8.805 8.805 0 01-3.674-.8 8.687 8.687 0 01-2.987-2.256 8.531 8.531 0 01-1.742-3.287 8.452 8.452 0 01-.17-3.704 8.506 8.506 0 011.434-3.429 8.654 8.654 0 012.768-2.511 8.817 8.817 0 017.318-.65 1.593 1.593 0 01.914.803 1.548 1.548 0 01-.262 1.734 1.587 1.587 0 01-1.112.507 1.61 1.61 0 01-.617-.099 5.597 5.597 0 00-3.43-.095 5.522 5.522 0 00-2.824 1.92 5.39 5.39 0 00-1.128 3.193 5.385 5.385 0 001.005 3.233 5.513 5.513 0 002.75 2.024c1.11.37 2.312.38 3.43.032a5.517 5.517 0 002.787-1.972 5.387 5.387 0 001.067-3.214V17.684a8.531 8.531 0 011.184-4.322 8.693 8.693 0 013.222-3.156 1.606 1.606 0 012.186.573 1.549 1.549 0 01-.61 2.146 5.531 5.531 0 00-2.054 2.007 5.429 5.429 0 00-.756 2.752v11.744a8.52 8.52 0 01-2.551 6.058A8.774 8.774 0 0122.697 38z"
                fill="currentColor"
            />
        </svg>
    );
};

export default IconFeature;
