import { memo, useEffect, useRef, useState } from 'react';
import { useBlockConfig } from '../../../hooks/use-block-config';
import { LayoutFitter } from '../../templates';
import { Container, Content, Text } from './styles';

const HomeBannerUpperCarousel = () => {
    const { data } = useBlockConfig({ refetchOnMount: false });
    const random = useRef(Math.floor(Math.random() * (data?.regua_messagem_topo as string[])?.length));

    const [currentMessage, setCurrentMessage] = useState(data?.regua_messagem_topo[random.current]);

    useEffect(() => {
        const id = setInterval(() => {
            const random = Math.floor(Math.random() * (data?.regua_messagem_topo as string[])?.length);

            setCurrentMessage(data?.regua_messagem_topo[random]);
        }, 5000);

        return () => clearInterval(id);
    }, [data?.regua_messagem_topo]);

    if (!data?.regua_messagem_topo && !data?.regua_messagem_topo.length) {
        return null;
    }

    return (
        <>
            <Container>
                <LayoutFitter>
                    <Content>
                        {data.regua_messagem_topo && <Text dangerouslySetInnerHTML={{ __html: currentMessage }}></Text>}
                    </Content>
                </LayoutFitter>
            </Container>
        </>
    );
};

export default memo(HomeBannerUpperCarousel);
