import tw, { styled } from 'twin.macro';
import { IStylesParams } from '.';
import { IconClose, TextLatoBold13, TextLatoBold20, TextLatoRegular13, TextLatoRegular18 } from '../../atoms';

export const Container = styled.div<IStylesParams>`
    &.statusOpen {
        ${tw`
      w-[150px]
      transition-all 
      duration-[400ms]
      `}
    }
    ${(p) => {
        return `width:calc(100%/${p.$statusesCount});`;
    }}
    ${tw`
      flex
      flex-col
      justify-start
      items-center
      cursor-pointer
      transition-all
      duration-[400ms]
    `}
`;

export const StatusTimeContainer = styled.div<IStylesParams>`
    ${tw`
      flex
      flex-col
      items-center
      mt-[10px]
      opacity-0
      transition-all
      duration-[300ms]
    `}
    ${(p) => {
        return `
        opacity: ${p.$isOpen ? 1 : 0};
      `;
    }}
`;

export const StatusTimeDate = styled(TextLatoRegular13)`
    ${tw`
      text-primary
      font-[600]
      opacity-80

    `}
`;

export const StatusTime = styled(TextLatoBold13)`
    ${tw`
      text-darkGray
    `}
`;

export const StatusIcons = styled.div`
    ${tw`
      mx-[20px]
      flex
      flex-col
      items-center

    `}
`;

export const StatusInfoContainer = styled.div<IStylesParams>`
    ${tw`
      flex
      flex-col
      items-center
      px-[10px]
      opacity-0
      transition-all
      duration-[300ms]
    `}
    ${(p) => {
        return `
        opacity: ${p.$isOpen ? 1 : 0};
      `;
    }}
`;

export const StatusLabel = styled(TextLatoBold20)<IStylesParams>`
    ${tw`
      text-darkGray
      w-full
      text-center
      mt-[5px]
    `}
    ${(p) => {
        if (p.$active) return tw`text-primary`;
    }}
`;

export const StatusDescription = styled(TextLatoRegular18)`
    ${tw`
      w-full
      text-darkGray
      text-center
    `}
`;

export const StatusCircle = styled.div<IStylesParams>`
    ${tw`
      flex
      relative
      w-[30px]
      h-[30px]
    `}
`;

export const StatusCirclePing = styled.div`
    ${tw`
      animate-ping
      absolute
      inline-flex
      h-full
      w-full 
      rounded-full 
      bg-primary 
      opacity-75
    `}
`;

export const StatusCircleColor = styled.div<IStylesParams>`
    ${tw`
    relative
    inline-flex
    rounded-full
    h-[30px]
    w-[30px]
    bg-gray
    `}

    ${(p) => {
        if (p.$active) return tw`bg-primary`;
    }}
`;

export const StatusCanceledIcon = styled(IconClose)`
    ${tw`
      h-[30px]
      w-[30px]
      bg-white
      fill-primary
      z-[1]
    `}
`;
