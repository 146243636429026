import { SVGProps } from 'react';

const IconMenu = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect width="24" height="4" rx="2" />
            <rect y="6" width="24" height="4" rx="2" />
            <rect y="12" width="24" height="4" rx="2" />
        </svg>
    );
};

export default IconMenu;
