import { IncomingMessage, ServerResponse } from 'http';
import { ICookieCtx } from '../../utils/cookie';
import { ADDRESS, STATES, USER_INFO } from '../../utils/react-query-keys';
import { Address, AddressMain, ICheckRedirectToSearchZipcode, State } from './types';
import api from '../api';
import authService from '../auth';
import cookieService from '../cookie';
import { queryClient } from '../query-client';
import { CustomerInfo } from '../user/types';

const getStates = async (): Promise<State[] | null> => {
    const { data } = await api.get(`V1/directory/countries/BR`);

    if (data) {
        return data.available_regions;
    }
    return null;
};

const setStatesStorage = async (states: State[] | null) => {
    return queryClient.setQueryData<State[] | null>([STATES], states);
};
const getStatesStorage = async (): Promise<State[] | null> => {
    const result = queryClient.getQueryData<State[]>([STATES]);
    if (!result) return null;

    return result;
};
const getStateByCodeStorage = async (code: string): Promise<State | null> => {
    const result = await getStatesStorage();
    if (result) {
        return result.find((value) => value.code == code) || null;
    }

    return null;
};

const initStates = async (): Promise<State[] | null> => {
    const statesStorage = await getStatesStorage();
    if (!statesStorage || !statesStorage.length) {
        const states = await getStates();
        await setStatesStorage(states);

        return states;
    }
    return statesStorage;
};

const getAddressStorage = async (): Promise<null | Address> => {
    const address = queryClient.getQueryData<AddressMain>([ADDRESS]);

    return address?.address || null;
};

const getAddressListStorage = (): Address[] => {
    const address = queryClient.getQueryData<AddressMain>([ADDRESS]);

    return address?.addressList || [];
};
const getUser = async () => {
    return queryClient.getQueryData<CustomerInfo>([USER_INFO]);
};
const addAddress = async (address: Address) => {
    let addresses = getAddressListStorage();
    const user: any = await getUser();
    address.default_shipping = true;
    address.default_billing = true;

    if (addresses) {
        addresses = addresses.map((ad) => {
            if (ad.id !== address.id) {
                return {
                    ...ad,
                    default_shipping: false,
                    default_billing: false
                };
            }
            return ad;
        });
    }
    let newAddresses = [address, ...addresses];

    const { data } = await api.put<any>('V1/customers/me', {
        customer: {
            ...user,
            addresses: newAddresses
        }
    });

    const addresesDefault = data.addresses.find((a: any) => a.default_shipping);
    updateQueryData({
        addressList: sortAddress(data.addresses),
        address: addresesDefault
    });

    return data.addresses;
};
const edit = async (id: number, values: any) => {
    let addresses = await getAddressListStorage();
    const user: any = await getUser();

    const addressIndex = addresses?.findIndex((a) => a.id == id);

    addresses[addressIndex] = {
        ...addresses[addressIndex],
        ...values
    };

    const { data } = await api.put<any>('V1/customers/me', {
        customer: {
            ...user,
            addresses: addresses
        }
    });
    const addressDefault = data.addresses.find((a: any) => a.default_shipping);

    updateQueryData({
        addressList: data.addresses,
        address: addressDefault
    });

    return data.addresses;
};
const sortAddress = (address: Address[], id?: number) => {
    let newAddress = address;

    if (id) {
        newAddress = newAddress.map((ad) => {
            const setDefault = ad.id == id;
            return {
                ...ad,
                default_shipping: setDefault,
                default_billing: setDefault
            };
        });
    }

    return newAddress.sort((a, b) => (a.default_shipping === b.default_shipping ? 0 : a.default_shipping ? -1 : 1));
};
const setAddress = async (id: number) => {
    let [addresses, user]: any[] = await Promise.all([getAddressListStorage(), getUser()]);
    addresses = sortAddress(addresses, id);
    api.put('V1/customers/me', {
        customer: {
            ...user,
            addresses
        }
    });
    const addressDefault = addresses.find((ad: any) => ad.id == id);
    updateQueryData({
        addressList: addresses,
        address: addressDefault
    });

    // const test = establishmentService.getByKey('caxias');
    // establishmentService.setEstablishmentStorage(test || null);
    // recreateApi();

    return {
        address: addressDefault,
        addressList: addresses
    };
};

const deleteAddress = async (id: any) => {
    try {
        const user: any = await getUser();
        let addresses: any = await getAddressListStorage();
        const address = addresses.find((a: any) => a.id == id);
        let isDefault = address.default_shipping;

        if (addresses.length == 1) {
            throw {
                error: true,
                message: 'Precisa ter pelo menos um endereço cadastrado'
            };
        }

        let restAddress = addresses.filter((a: any) => a.id != id);
        if (isDefault) {
            restAddress[0] = {
                ...restAddress[0],
                default_shipping: true,
                default_billing: true
            };
        }

        const { data } = await api.put('V1/customers/me', {
            customer: {
                ...user,
                addresses: restAddress
            }
        });

        updateQueryData({
            addressList: restAddress,
            ...(isDefault ? { address: restAddress[0] } : {})
        });

        return true;
    } catch (e: any) {
        if (e.response) {
            throw {
                error: true,
                message: e.response.data.message
            };
        }
        throw e;
    }
};

const getAll = async (): Promise<Address[]> => {
    const { data } = await api.get('V1/customers/me', {
        params: {
            fields: 'addresses'
        }
    });
    if (data) {
        return data.addresses;
    }
    return [];
};
const initAddress = async (): Promise<AddressMain> => {
    const token = authService.getUserTokenStorage();
    let addressList: Address[] = [];
    let address = null;
    const zipcode = await getZipcodeStorage();
    if (token) {
        addressList = await getAll();
    }

    if (addressList && addressList.length) {
        addressList = sortAddress(addressList);
        address = addressList.find((address: Address) => address.default_shipping) || null;
    }
    return {
        addressList,
        address,
        zipcode: 'teste'
    };
};
const updateQueryData = (param: Partial<AddressMain>) => {
    queryClient.setQueryData(
        [ADDRESS],
        (old: AddressMain | undefined) =>
            ({
                ...old,
                ...param
            }) as AddressMain
    );
};
const refetchAddress = async (): Promise<void> => {
    const address = await initAddress();
    updateQueryData(address);
};

const formatAddress = (address: Address) => {
    const aux = [address.street[0], address.street[1], address.street[3]].filter((v) => v).join(', ');
    const aux1 = [address.street[2], address.region.region_code].filter((v) => v).join(' - ');

    return `${aux} - ${aux1}`;
};

const checkRedirectToLogin = ({ url, req, res }: ICheckRedirectToSearchZipcode) => {
    const urlsEnabled = ['/entrar', '/esqueci-minha-senha', '/cadastrar', 'recuperar-senha'];
    const zipcode = getZipcodeStorage({ req, res });
    const isLogged = Boolean(cookieService.getUserTokenStorage({ req, res }));

    if (isLogged) return false;

    if (url === '/') return true;

    return !urlsEnabled.some((u) => url.indexOf(u) !== -1);
};
const redirectToLogin = (req: IncomingMessage, res: ServerResponse) => {
    const redirect = checkRedirectToLogin({ url: req.url as string, req, res });

    if (redirect) {
        return {
            redirect: {
                permanent: false,
                destination: '/entrar'
            }
        };
    }
};

const setZipcodeStorage = (zipcode: string | null, ctx?: ICookieCtx) => {
    // cookieService.setZipcodeStorage(zipcode, ctx);
    // updateQueryData({ zipcode });
};
const getZipcodeStorage = (ctx?: ICookieCtx) => {
    // return cookieService.getZipcodeStorage(ctx);
};

export default {
    setZipcodeStorage,
    getZipcodeStorage,
    getAddressStorage,
    redirectToLogin,
    getAddressListStorage,
    addAddress,
    formatAddress,
    setStatesStorage,
    updateQueryData,
    getStatesStorage,
    sortAddress,
    initStates,
    refetchAddress,
    getStateByCodeStorage,
    setAddress,
    getStates,
    deleteAddress,
    edit,
    initAddress
};
