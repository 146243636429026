import { SVGProps } from 'react';

const IconOptions = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M10.0001 12.0834C11.1507 12.0834 12.0834 11.1507 12.0834 10.0001C12.0834 8.84949 11.1507 7.91675 10.0001 7.91675C8.84949 7.91675 7.91675 8.84949 7.91675 10.0001C7.91675 11.1507 8.84949 12.0834 10.0001 12.0834Z" />
            <path d="M10.0001 4.79167C11.1507 4.79167 12.0834 3.85893 12.0834 2.70833C12.0834 1.55774 11.1507 0.625 10.0001 0.625C8.84949 0.625 7.91675 1.55774 7.91675 2.70833C7.91675 3.85893 8.84949 4.79167 10.0001 4.79167Z" />
            <path d="M10.0001 19.3749C11.1507 19.3749 12.0834 18.4422 12.0834 17.2916C12.0834 16.141 11.1507 15.2083 10.0001 15.2083C8.84949 15.2083 7.91675 16.141 7.91675 17.2916C7.91675 18.4422 8.84949 19.3749 10.0001 19.3749Z" />
        </svg>
    );
};

export default IconOptions;
