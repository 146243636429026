import React from 'react';
import {
    Container,
    Header,
    Modal,
    ConfirmButton,
} from './styles';
import { IModalWishlistItemsToCartProps } from './types';

const ModalWishlistItemsToCart = ({ onClose, onPressConfirm, outOfStockProducts }: IModalWishlistItemsToCartProps) => {
    if (!outOfStockProducts) return <></>;
    return (
        <Modal onClose={onClose}>
            <Header title="Produtos sem estoque" showIconClose onPressIconClose={onClose} />
            <Container>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <span>{outOfStockProducts.length === 1 ? 'O seguinte produto não possui ': 'Os seguintes produtos não possuem '}estoque no momento:</span>
                    {outOfStockProducts.map((elem, i) => (
                        <b key={elem.name + i}>{`- ${elem.name}${outOfStockProducts.length - 1 === i ? '' : ', '}`}</b>
                    ))}
                    <span>Você deseja adicionar os demais itens ao carrinho?</span>
                </div>
            </Container>
            <ConfirmButton onClick={onPressConfirm} text="Confirmar" />
        </Modal>
    );
};

export default ModalWishlistItemsToCart;
