import tw, { styled } from 'twin.macro';

export const Container = styled.div`
    ${tw`
        hidden
        md:flex
        max-w-[320px]
        w-full
        rounded-16
        border-[0.5px]
        shrink-0
        border-[#D9D9D9]
    `}
`;
