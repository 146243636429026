import { Container, NewAddressButton } from './styles';

import { useRef, useState } from 'react';
import { Address } from '../../../services/address/types';
import ModalPortal from '../../atoms/Modal-portal';
import MyAddressesRegisteredContainer from '../../organisms/My-addresses-registered-address';
import NewAddressModal from '../../organisms/New-address-modal';
import { INewAddressModalHandler } from '../../organisms/New-address-modal/types';
const MyAddresses = () => {
    const [showModalRegister, setShowModalRegister] = useState<boolean>(false);
    // const [addresses, setShowModalRegister] = useState<boolean>(false)
    const modalRef = useRef<INewAddressModalHandler>(null);
    const selectedAddressRef = useRef<Address | null>(null);

    const setSelectedAddresRef = (newSelectedAddress: Address) => {
        selectedAddressRef.current = newSelectedAddress;
        // modalRef.current?.show(newSelectedAddress)
        // setShowModalRegister(newSelectedAddress)
    };

    const handleClickNew = () => {
        setShowModalRegister(true);
    };

    return (
        <Container>
            <h1 className="font-bold text-[18px] mb-8">Endereços</h1>

            <NewAddressButton onClick={handleClickNew} text="Criar novo endereço" />

            <ModalPortal>
                {showModalRegister && <NewAddressModal onClose={() => setShowModalRegister(false)} />}
            </ModalPortal>

            <MyAddressesRegisteredContainer />
        </Container>
    );
};

export default MyAddresses;
