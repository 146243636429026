import { SVGProps } from 'react';

const IconPersonRounded = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width="48" height="50" viewBox="0 0 48 50" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M24 0C10.752 0 0 11.2 0 25C0 38.8 10.752 50 24 50C37.248 50 48 38.8 48 25C48 11.2 37.248 0 24 0ZM24 7.5C27.984 7.5 31.2 10.85 31.2 15C31.2 19.15 27.984 22.5 24 22.5C20.016 22.5 16.8 19.15 16.8 15C16.8 10.85 20.016 7.5 24 7.5ZM24 43C18 43 12.696 39.8 9.6 34.95C9.672 29.975 19.2 27.25 24 27.25C28.776 27.25 38.328 29.975 38.4 34.95C35.304 39.8 30 43 24 43Z" />
        </svg>
    );
};

export default IconPersonRounded;
