import { motion } from 'framer-motion';
import tw, { styled } from 'twin.macro';

export const Container = styled(motion.div)<{ $borderTop: boolean }>`
    ${tw`
   flex
    flex-col
    border-b
    border-t-[#232020]
    border-b-[#232020]
    overflow-hidden
  `}
    ${({ $borderTop }) => $borderTop && tw`border-t`}
`;

export const Header = styled.div`
    ${tw`
   flex
   flex-row
   justify-between
   items-center
   py-4
   px-6
   leading-6
   text-16
   font-semibold
   text-[#232020]
   cursor-pointer
  `}
`;

export const ContainerIcon = styled(motion.div).attrs({
    variants: {
        close: { rotate: 0 },
        open: { rotate: 180 }
    }
})``;
export const Content = styled(motion.div).attrs({
    initial: { opacity: 0, height: 0 },
    animate: { opacity: 1, height: 'auto' },
    exit: { opacity: 0, height: 0 }
})``;
