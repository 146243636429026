import tw, { styled } from 'twin.macro';

export const Container = styled.div`
    ${tw`
    flex
    items-center
    justify-center
    w-screen	
        h-screen	
    `}
`;
