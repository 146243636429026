import { SVGProps } from 'react';

const IconFlyer = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width="197" height="233" viewBox="0 0 197 233" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M2.28555 2.02089L0.675781 3.25917V100.464C0.675781 196.803 0.675781 197.607 1.91406 198.846C3.09043 200.022 3.95723 200.084 24.8223 200.084H46.4922V214.82C46.4922 229.431 46.4922 229.617 47.9162 230.917L49.3402 232.279H121.532H193.786L195.024 230.67L196.324 228.998V132.474V35.9498L194.776 34.4639L193.29 32.916H171.868H150.508V18.3662V3.75449L148.96 2.26855L147.474 0.720698H75.7156C4.01914 0.720698 3.95723 0.720698 2.28555 2.02089ZM84.1359 21.3381C64.8807 27.901 48.4734 33.7828 47.7924 34.4019L46.4922 35.5783V113.528V191.416L28.1037 191.292L9.65332 191.106L9.46758 100.217L9.34375 9.38867L64.2615 9.45058H119.241L84.1359 21.3381ZM141.84 22.081V32.916L110.14 32.8541H78.3779L109.954 22.081C127.352 16.1373 141.592 11.308 141.716 11.308C141.778 11.2461 141.84 16.1373 141.84 22.081ZM187.532 132.412L187.347 223.302L121.284 223.487L55.1602 223.611V132.598V41.584H121.408H187.656L187.532 132.412Z"
                fill={props.color}
            />
            <path
                d="M73.8582 59.1056C72.4961 60.5297 72.4961 60.9631 72.4961 77.9275C72.4961 94.0871 72.558 95.3873 73.6725 96.7494L74.8488 98.2353L92.5563 98.4211L110.264 98.6068L111.812 97.059L113.359 95.5111V77.9275C113.359 60.4058 113.359 60.3439 111.935 59.0437C110.511 57.6816 110.14 57.6816 92.8658 57.6816C75.2203 57.6816 75.1584 57.6816 73.8582 59.1056ZM104.691 77.8037V89.2578H92.9277H81.1641V77.8037V66.3496H92.9277H104.691V77.8037Z"
                fill={props.color}
            />
            <path
                d="M130.386 65.4209C128.652 67.0926 128.528 68.0213 129.519 70.3122C130.757 73.0364 132.429 73.2221 150.446 73.0364L167.348 72.8506L168.525 71.3647C170.196 69.2596 170.011 66.7211 168.091 65.1733C166.482 63.935 165.677 63.8731 149.146 63.8731H131.872L130.386 65.4209Z"
                fill={props.color}
            />
            <path
                d="M130.138 84.6762C128.466 86.8432 128.528 88.3291 130.386 90.1865L131.872 91.7344H149.455C165.677 91.7344 167.225 91.6725 168.525 90.6199C170.258 89.1959 170.382 86.1002 168.773 84.3047C167.72 83.1283 166.915 83.0664 149.517 83.0664H131.376L130.138 84.6762Z"
                fill={props.color}
            />
            <path
                d="M74.0439 113.714L72.4961 115.2V132.783C72.4961 150.305 72.4961 150.367 73.9201 151.667C75.3441 153.029 75.7156 153.029 92.9896 153.029C110.635 153.029 110.697 153.029 111.997 151.605C113.359 150.181 113.359 149.748 113.359 132.66V115.2L111.812 113.714L110.326 112.166H92.9277H75.5299L74.0439 113.714ZM104.691 132.598V144.361L93.1135 144.237L81.4736 144.052L81.2879 133.217C81.226 127.273 81.2879 122.072 81.4117 121.639C81.6594 121.02 84.4455 120.834 93.2373 120.834H104.691V132.598Z"
                fill={props.color}
            />
            <path
                d="M130.386 120.524C128.528 122.382 128.466 123.868 130.138 126.035L131.376 127.645H149.517C166.915 127.645 167.72 127.583 168.773 126.406C170.382 124.611 170.258 121.515 168.525 120.091C167.225 119.038 165.677 118.977 149.455 118.977H131.872L130.386 120.524Z"
                fill={props.color}
            />
            <path
                d="M131.191 138.48C128.281 140.089 128.343 144.609 131.315 146.219C132.986 147.086 165.429 147.024 167.41 146.157C170.816 144.609 170.692 140.089 167.225 138.356C164.872 137.117 133.482 137.303 131.191 138.48Z"
                fill={props.color}
            />
            <path
                d="M73.7344 168.508C72.558 169.684 72.4961 170.551 72.4961 187.33C72.4961 203.737 72.558 204.975 73.6725 206.337L74.8488 207.823H92.9277H111.007L112.183 206.337C113.297 204.975 113.359 203.737 113.359 187.33C113.359 170.551 113.297 169.684 112.121 168.508C110.945 167.331 110.078 167.27 92.9277 167.27C75.7775 167.27 74.9107 167.331 73.7344 168.508ZM104.691 187.701V199.465L93.1135 199.341L81.4736 199.155L81.2879 187.515L81.1641 175.937H92.9277H104.691V187.701Z"
                fill={props.color}
            />
            <path
                d="M131.191 174.39C128.281 175.999 128.343 180.519 131.315 182.129C132.986 182.996 165.429 182.934 167.41 182.067C170.816 180.519 170.692 175.999 167.225 174.266C164.872 173.027 133.482 173.213 131.191 174.39Z"
                fill={props.color}
            />
            <path
                d="M130.138 194.264C129.395 195.193 128.838 196.369 128.838 196.988C128.838 197.607 129.395 198.784 130.138 199.712L131.376 201.322H149.27C165.677 201.322 167.225 201.26 168.525 200.208C169.454 199.403 169.887 198.35 169.887 196.988C169.887 195.626 169.454 194.574 168.525 193.769C167.225 192.716 165.677 192.654 149.27 192.654H131.376L130.138 194.264Z"
                fill={props.color}
            />
        </svg>
    );
};

export default IconFlyer;
