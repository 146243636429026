import tw, { styled } from 'twin.macro';
import { Modal, TextLatoBold20, TextLatoRegular18 } from '../../atoms';
import {Button as ButtomMolecule} from '../../molecules';

export const Container = styled(Modal)`
    ${tw`
        h-auto
        flex
        flex-col
        p-[20px]
        md:max-w-[400px]
    `}
`;

export const TextTitle = styled(TextLatoBold20)`
    ${tw`
        text-primary
        text-center
    `}
`;

export const TextDescription = styled(TextLatoRegular18)`
    ${tw`
        text-center
        my-[20px]
    `}
`;
export const Buttons = styled.div`
    >.Button{
        flex: 1;
    }
    ${tw`
        flex
        flex-row
        gap-[15px]
        mt-[10px]
    `}
`;

export const Button = styled(props => <ButtomMolecule {...props}/>)`
    ${tw`
        flex-1
    `}
`;
