import Image from 'next/image';
import tw, { styled } from 'twin.macro';

export const Container = styled.div`
    ${tw`
        border-[#E6E6E6]
        border-[0.5px]
        rounded-8
        p-4  
        mx-4
        gap-4
        flex
        flex-col
        md:mx-0
        md:gap-6
    `}
`;

export const ImageTop = styled(Image)`
    ${tw`
        object-cover
    `}
`;

export const Description = styled.div`
    ${tw`
        text-16
        font-light

    `}
`;
