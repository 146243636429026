export enum FilterField {
    CATEGORY = 'category_id',
    NAME = 'name',
    FEATURED = 'featured'
}

export interface ProductTierPrices {
    customer_group_id: number;
    qty: number;
    value: number;
    extension_attributes: {
        website_id: number;
    };
}

export interface ProductCustomAttributes {
    attribute_code:
        | string
        | 'tipo_medida'
        | 'url_key'
        | 'meta_title'
        | 'meta_keyword'
        | 'meta_description'
        | 'special_from_date'
        | 'description'
        | 'special_to_date';
    value: string;
}

export enum EProductTypeUnit {
    UNIT = '1',
    WEIGHT = '2'
}

export type IMediaProductType = 'image' | 'small_image' | 'thumbnail' | 'swatch_image';

export interface IMediaProduct {
    id: number;
    media_type: string;
    label: string;
    position: number;
    disabled: boolean;
    types: IMediaProductType[];
    file: string;
}

export interface IProductOptionValue {
    title?: string;
    sort_order?: number;
    price?: number;
    price_type?: string;
    option_type_id?: number;
}
export interface IProductOption {
    product_sku: string;
    option_id: number;
    title: string;
    type: string;
    sort_order: number;
    is_require?: boolean;
    price?: number;
    price_type?: string;
    sku?: string;
    max_characters?: number;
    image_size_x?: number;
    image_size_y?: number;
    values?: IProductOptionValue[];
}

export type IProductLinksType = 'related' | 'crosssell' | 'upsell';
export interface IProductLinks {
    sku?: string;
    link_type?: IProductLinksType;
    linked_product_sku?: string;
    linked_product_type?: string;
    position?: number;
}
export interface IProductStockItem {
    item_id: number;
    product_id: number;
    stock_id: number;
    qty: number;
    is_in_stock: boolean;
    is_qty_decimal: boolean;
    show_default_notification_message: boolean;
    use_config_min_qty: boolean;
    min_qty: number;
    use_config_min_sale_qty: number;
    min_sale_qty: number;
    use_config_max_sale_qty: boolean;
    max_sale_qty: number;
    use_config_backorders: boolean;
    backorders: number;
    use_config_notify_stock_qty: boolean;
    notify_stock_qty: number;
    use_config_qty_increments: boolean;
    qty_increments: number;
    use_config_enable_qty_inc: boolean;
    enable_qty_increments: boolean;
    use_config_manage_stock: boolean;
    manage_stock: boolean;
    low_stock_date: string;
    is_decimal_divided: boolean;
    stock_status_changed_auto: number;
}

export interface IProductExtensionAttributesLinks {
    position: number;
    category_id: number;
}

export interface IProductConfigurable {
    attribute_id: string;
    attribute_name: string;
    options: {
        option_id: string;
        option_name: string;
    }[];
}

export interface ProductBanner {
    bannerMobile: string | null;
    bannerDesktop: string | null;
}

export interface IProduct {
    id: number;
    sku: string;
    name: string;
    images: string[];
    attribute_set_id: number;
    price: number;
    special_price?: number;
    discount_percentage?: number;
    status?: number;
    visibility?: number;
    type_id?: string;
    banner_sku?: string;
    created_at?: string;
    updated_at?: string;
    weight?: string;
    ean?: string;
    configurable_options?: Record<string, IProductConfigurable>;
    var_kg?: number;
    installments?: { parcelas: number; valor: string };
    extension_attributes?: {
        website_ids?: number[];
        unit_weight?: string;
        category_links?: IProductExtensionAttributesLinks[];
        stock_item?: IProductStockItem;
    };
    attributes?: any[];
    product_links?: IProductLinks[];
    media_gallery_entries?: IMediaProduct[];
    options?: IProductOption[];

    tier_prices?: ProductTierPrices[];
    custom_attributes: ProductCustomAttributes[];
    inStock?: boolean;
}

export interface IProductAlertStock {
    productId: number;
    name: string;
    email: string;
}

export interface IProductEstimateShipping {
    carrier_code: string;
    method_code: string;
    carrier_title: string;
    method_title: string;
    amount: number;
    base_amount: number;
    available: boolean;
    error_message: string;
    price_excl_tax: number;
    price_incl_tax: number;
}
