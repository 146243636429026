import { ListEmpty, WishlistItem, WishlistItemSkeleton } from '../../molecules';
import { useWishlist } from '../../../hooks';
import { IconEmpty, ListsContainer } from './styles';

const WishlistList = () => {
    const { data, isLoading } = useWishlist();

    const isEmpty = data?.length === 0;

    const getContent = () => {
        if (isLoading) {
            return (
                <>
                    <WishlistItemSkeleton />
                    <WishlistItemSkeleton />
                    <WishlistItemSkeleton />
                </>
            );
        }

        if (isEmpty) return <ListEmpty icon={<IconEmpty />} text="Não há listas" />;

        return data && data.map((elem) => <WishlistItem key={elem.wishlist_id} data={elem} />);
    };

    return (
        <>
            <ListsContainer $isEmpty={isEmpty}>{getContent()}</ListsContainer>
        </>
    );
};

export default WishlistList;
