import { FC } from 'react';
import paymentService from '../../../services/payment';
import { PaymentSelected, PAYMENTS_PIX, PaymentTypes } from '../../../services/payment/types';
import { FlagType } from '../../atoms';
import { Container, Content, ContentFlag, Item, TextTitle } from './styles';

interface PaymentSelectedProps {
    data: PaymentSelected | null;
}

const CheckoutConfirmPaymentSelected: FC<PaymentSelectedProps> = ({ data }) => {
    if (!data) return null;
    const formated = paymentService.getPaymentFromCheckout(data);

    return (
        <Container>
            {formated.values.map((item, i) => {
                const isCard =
                    !PAYMENTS_PIX.includes(formated.method) && formated.method !== PaymentTypes.CASHNODELIVERY;

                return (
                    <Item key={i}>
                        <ContentFlag>
                            <FlagType type={item.type as string} width={32} height={isCard ? 20 : 32} />
                        </ContentFlag>
                        <Content>
                            <TextTitle>{item.label}</TextTitle>
                        </Content>
                    </Item>
                );
            })}
        </Container>
    );
};

export default CheckoutConfirmPaymentSelected;
