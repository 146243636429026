import tw, { styled } from 'twin.macro';

import { TextLatoRegular15 } from '../../atoms';
import { Button } from '../../molecules';

export const Container = styled.article`
    ${tw`
    w-full
		flex
		flex-col
		mb-[50px]
  `}
`;

export const NavigateBackContainer = styled.div`
    ${tw`
    w-full
    mt-[30px]
  `}
`;

export const NavigateBack = styled(Button)`
    ${tw`
    text-primary
    cursor-pointer
  `}
`;

export const BuyAgain = styled(Button)`
    ${tw`
    text-primary
    cursor-pointer
    mb-4
    -mt-6
    
  `}
`;

export const HeaderButton = styled(Button)`
    ${tw`
		h-[24px]
		rounded-[8px]
	`}

    & span {
        font-size: 13px;
    }
`;
