export default function MainProductSkeleton() {
    return (
        <div
            className={`flex flex-col relative flex-1 min-w-[257px] max-w-[257px] bg-white rounded-8 border-[0.5px] shrink-0 h-[296px] border-gray animate-pulse px-8 py-4 gap-2`}
        >
            <div className="rounded-8 shrink-0 border-[0.5px] !h-[208px] w-[192px] border-gray animate-pulse " />
            <div className="w-full h-[24px] bg-gray animate-pulse w-full rounded-md" />
            <div className="h-2 w-full bg-gray animate-pulse rounded-md" />
        </div>
    );
}
