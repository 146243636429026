import { FC, forwardRef, Ref } from 'react';
import tw, { styled } from 'twin.macro';
import { motion } from 'framer-motion';
const Container = styled(motion.span)`
    line-height: 18px;
    ${tw`
        font-nunito
        text-13
        text-black
        font-bold
    `}
`;

const TextLatoBold13: FC<any> = forwardRef(({ children, ...props }: any, ref: Ref<any>) => {
    return (
        <Container {...props} ref={ref}>
            {children}
        </Container>
    );
});

export default TextLatoBold13;
