import { FC } from 'react';
import { TextLatoBold15 } from '../..';
import { Container } from './styles';

// ! Consertar essas props (ou não)
const TableHeaderCell: FC<React.HTMLProps<HTMLTableCellElement>> = ({ children, ...props }) => {
  return (
    <Container {...(props as any)}>
      <TextLatoBold15>{children}</TextLatoBold15>
    </Container>
  );
};

export default TableHeaderCell;
