import { useWishlistWithProducts } from '../../../hooks';
import { ProductItem, ProductItemSkeleton } from '../../molecules';
import { Container } from './styles';
import { IWishlistListProps } from './types';

const WishlistDetailList = ({ id }: IWishlistListProps) => {
    const { data: list, isLoading } = useWishlistWithProducts(id);

    const getContent = () => {
        if (isLoading) {
            return (
                <>
                    <ProductItemSkeleton />
                    <ProductItemSkeleton />
                    <ProductItemSkeleton />
                    <ProductItemSkeleton />
                </>
            );
        }
        if (list) {
            return list.products.map(({ product }) => (
                <ProductItem wishListId={id} key={product.id.toString()} data={product} />
            ));
        }
    };

    return <Container>{getContent()}</Container>;
};

export default WishlistDetailList;
