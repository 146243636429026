import tw, { styled } from 'twin.macro';

export const Container = styled.div`
    ${tw`
      flex
      flex-wrap
      gap-[15px]
      justify-between
      animate-pulse
    `}
`;

export const SkeletonItem = styled.div`
    ${tw`
      w-[162px]
      h-[32px]
      rounded-lg
      bg-gray
    `}
`;