import tw, { styled } from 'twin.macro';
import { TextLatoRegular20 } from '../../atoms';

export const Container = styled.h2`
  ${tw`
        flex
        items-end
        mt-[50px]
        mb-[30px]
        
        lg:mb-[40px]
        lg:mt-[0px]
    `}
`;

export const IdPlaceholder = styled(TextLatoRegular20)`
  ${tw`
        ml-[3px]
    `}
`;
