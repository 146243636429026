import React, { HTMLAttributes } from 'react';
interface ILogoFavIconProps extends Partial<HTMLAttributes<HTMLDivElement>> {
    color: 'white' | 'primary';
}

const LogoFavIcon = ({ className, color }: ILogoFavIconProps) => {
    return (
        <div
            style={{
                backgroundImage: `url('/images/favicon-${color}.svg')`
            }}
            className={`bg-no-repeat bg-contain bg-center ${className}`}
        />
    );
};

export default LogoFavIcon;
