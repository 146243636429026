import Link from 'next/link';
import { ProductService } from '../../../../../../../services';
import { IProduct } from '../../../../../../../services/product/types';
import { currencyBrl } from '../../../../../../../utils/getFormattedValue';
import { capitalizeEveryLetter } from '../../../../../../../utils/string';
import { CustomCheckboxSquare } from '../../../../../../atoms/CheckboxSquare';
import ProductImage from '../../../../../../atoms/Product-image';

interface SecondaryProductProps {
    data: IProduct;
    isChecked?: boolean;
    onCheck: (checked: boolean) => void;
}

export default function SecondaryProduct({ data, isChecked, onCheck }: SecondaryProductProps) {
    return (
        <div className="flex flex-row px-4 gap-2 py-6 relative">
            <Link href={ProductService.getRouterProduct(data)} passHref>
                <ProductImage data={data} width={80} height={80} className="rounded-8 shrink-0" />
            </Link>
            <div className="flex flex-col gap-2 flex-1">
                <Link href={ProductService.getRouterProduct(data)} passHref>
                    <h3 className="text-12 font-semibold text-black line-clamp-2">
                        {capitalizeEveryLetter(data.name)}
                    </h3>
                </Link>
                {data.discount_percentage && data.special_price && (
                    <p className="text-12 text-black font-light line-through">
                        de R$ {currencyBrl(data.special_price)}
                    </p>
                )}
                <p className="text-14 text-green500 font-bold leading-6">
                    R$ {currencyBrl(ProductService.getValue(data, 1))}
                </p>
            </div>
            <div className="flex items-center">
                <CustomCheckboxSquare isChecked={isChecked} onCheckboxSquareChange={onCheck} />
            </div>
        </div>
    );
}
