import { Logo, TextNunitoRegular12 } from '../../../atoms';
import { LogoColor } from '../../../atoms/Logo';
import { LayoutFitter } from '../../../templates';
import AboutUs from '../About-us';
import Certificate from '../Certificate';
import ContactUs from '../Contact-us';
import Copyright from '../Copyright';
import Payments from '../Payments';
import SocialMedia from '../Social-media';
import { Content, ContentMenus, LeftColumn, RightColumn } from '../styles';
import dynamic from 'next/dynamic';
const Newsletter = dynamic(() => import('../Newsletter'), { ssr: false });

function FooterDesktop() {
    return (
        <LayoutFitter className="!hidden md:!flex">
            <Content>
                <LeftColumn>
                    <Logo widthPortrait={320} heightPortrait={56} color={LogoColor.SECONDARY} />
                    <Newsletter className="!hidden md:!flex" />
                </LeftColumn>
                <RightColumn>
                    <ContentMenus>
                        <AboutUs title />
                        <ContactUs title />
                        <Payments title />
                        <SocialMedia title />
                        <Certificate title />
                    </ContentMenus>
                    <Copyright />
                </RightColumn>
            </Content>
        </LayoutFitter>
    );
}

export default FooterDesktop;
