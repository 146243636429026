import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { Image } from '../../atoms';
import { Input } from '../../molecules';

import { useMutation } from '@tanstack/react-query';
import { UserService } from '../../../services';
import { EMAIL, REQUIRED } from '../../../utils/errors';

import { Button, ContainerDescription, LoginFormHeader, PersonalInfoFormContainer } from './styles';

import { useState } from 'react';
import { useBlockConfig } from '../../../hooks/use-block-config';
import IconArrowLeftLong from '../../atoms/icons/Arrow-left-long';
import { LoginFormContainer } from '../Login/styles';
import { IForgotPasswordFormProps, IValuesForm } from './types';

const validationForm = Yup.object().shape({
    email: Yup.string().email(EMAIL).required(REQUIRED)
});

const ForgotPassword = ({ toggleForgottenPassword }: IForgotPasswordFormProps) => {
    const [emailSent, setEmailSent] = useState(false);
    const { data: config } = useBlockConfig({ refetchOnMount: false });

    const { mutate, isLoading, isError } = useMutation<unknown, unknown, IValuesForm>(
        (form) => UserService.forgotPassword(form.email),
        {
            onSuccess: () => {
                setEmailSent(true);
            },
            onError: () => {
                formik.setFieldError('email', 'Usuário não cadastrado');
            }
        }
    );

    const formik = useFormik<IValuesForm>({
        initialValues: {
            email: ''
        },
        validateOnMount: false,
        validationSchema: validationForm,
        onSubmit: (values) => {
            mutate(values);
        }
    });

    return (
        <LoginFormContainer>
            <div className="flex items-center justify-center w-full">
                <Image src="/images/logo.png" alt="logo" width={248} height={69} />
            </div>

            <div className="flex items-center gap-3 mt-10 sh:mt-2">
                <button onClick={toggleForgottenPassword}>
                    <IconArrowLeftLong width={30} color="#424242" />
                </button>
                Esqueci minha senha
            </div>

            <div className={emailSent ? 'email-sent' : ' '}>
                <PersonalInfoFormContainer>
                    <LoginFormHeader>
                        <ContainerDescription
                            dangerouslySetInnerHTML={{
                                __html: emailSent
                                    ? 'Enviamos um e-mail para o endereço informado com instruções para recuperação da sua senha. Não esqueça de verificar a sua caixa de spam.'
                                    : (config?.esqueci_minha_senha_mensagem as unknown as string)
                            }}
                        ></ContainerDescription>
                    </LoginFormHeader>
                    {emailSent ? (
                        <></>
                    ) : (
                        <div className="mt-16 sh:mt-10">
                            <FormikProvider value={formik}>
                                <Input label="E-mail*" name="email" />
                            </FormikProvider>
                        </div>
                    )}
                </PersonalInfoFormContainer>

                {emailSent ? (
                    <></>
                ) : (
                    <Button onClick={() => formik.handleSubmit()} isLoading={isLoading} text="Enviar" />
                )}
            </div>
        </LoginFormContainer>
    );
};

export default ForgotPassword;
