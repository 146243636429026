import React, { FC, SVGProps } from 'react';

const FlagElo: FC<SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg viewBox="0 0 42 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M3.076 0h36.399c1.2 0 2.173.985 2.173 2.202v21.525c0 1.216-.973 2.202-2.173 2.202h-36.4c-1.2 0-2.172-.986-2.172-2.202V2.202C.903.985 1.875 0 3.076 0z"
                fill="#000"
            />
            <path
                d="M9.09 9.392c.37-.127.766-.193 1.18-.193 1.803 0 3.308 1.3 3.65 3.022l2.553-.528c-.586-2.929-3.14-5.136-6.204-5.136-.7 0-1.374.115-2.004.33l.825 2.505z"
                fill="#FFF100"
            />
            <path
                d="M6.075 17.782L7.803 15.8a3.787 3.787 0 01-1.255-2.83c0-1.123.484-2.136 1.255-2.824L6.075 8.17a6.44 6.44 0 00-2.135 4.806 6.45 6.45 0 002.135 4.806z"
                fill="#00A3DF"
            />
            <path
                d="M13.92 13.73c-.348 1.723-1.847 3.016-3.65 3.016-.414 0-.81-.066-1.185-.192l-.826 2.504c.63.215 1.31.33 2.01.33 3.064 0 5.618-2.201 6.205-5.13l-2.554-.528z"
                fill="#EE4023"
            />
            <path
                d="M24.98 15.502a2.244 2.244 0 01-1.624.66 2.188 2.188 0 01-1.173-.357l-.848 1.365c.582.369 1.26.589 2 .6a3.847 3.847 0 002.76-1.112l-1.114-1.156zM23.45 9.936c-2.13-.033-3.89 1.696-3.923 3.854-.01.81.218 1.569.625 2.196l6.997-3.033c-.39-1.7-1.89-2.984-3.7-3.017zm-2.32 4.162a2.264 2.264 0 01-.016-.275c.021-1.272 1.053-2.29 2.309-2.268a2.238 2.238 0 011.7.82l-3.993 1.723zm8.22-5.923v7.558l1.293.545-.614 1.492-1.282-.54a1.436 1.436 0 01-.63-.539c-.142-.22-.25-.528-.25-.936v-7.58h1.483zM34.014 11.67c.228-.077.467-.116.723-.116 1.102 0 2.015.793 2.227 1.845l1.56-.325c-.36-1.79-1.919-3.133-3.787-3.133-.43 0-.842.072-1.223.199l.5 1.53zm-1.842 5.114l1.054-1.205a2.31 2.31 0 01-.766-1.729c0-.688.3-1.305.766-1.723l-1.053-1.206a3.933 3.933 0 00-1.304 2.934c0 1.168.505 2.213 1.303 2.93zm4.792-2.466c-.212 1.051-1.13 1.844-2.227 1.844-.25 0-.495-.044-.723-.121l-.505 1.53a3.78 3.78 0 001.228.204c1.868 0 3.428-1.343 3.786-3.132l-1.559-.325z"
                fill="#fff"
            />
        </svg>
    );
};

export default FlagElo;
