import tw, { styled } from 'twin.macro';

export const Container = styled.div`
    ${tw`
        flex
        flex-row
        gap-4
        pt-4
    `}
`;
