import { FC, SVGProps } from 'react';

const IconDeliver: FC<SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <circle cx="20" cy="20" r="19.75" fill="#F7F7F7" stroke="#E1E1E1" strokeWidth="0.5" />
            <path
                d="M30 21.9959H26.5C26.2239 21.9959 26 21.7721 26 21.4959V17.9959H29.2"
                stroke="#323232"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M26.5567 26.4393C26.9858 26.8683 27.1141 27.5134 26.882 28.074C26.6498 28.6345 26.1029 29 25.4962 29C24.8895 29 24.3425 28.6346 24.1103 28.0741C23.8781 27.5136 24.0064 26.8684 24.4354 26.4394L24.4355 26.4393C25.0213 25.8535 25.971 25.8535 26.5567 26.4393V26.4393"
                stroke="#323232"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.5567 26.4393C15.9858 26.8683 16.1141 27.5134 15.882 28.074C15.6498 28.6345 15.1029 29 14.4962 29C13.8895 29 13.3425 28.6346 13.1103 28.0741C12.8781 27.5136 13.0064 26.8684 13.4354 26.4394V26.4394L13.4357 26.4391C14.0214 25.8534 14.9709 25.8534 15.5566 26.4391L15.5567 26.4393V26.4393"
                stroke="#323232"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M24.0812 27H15.9102" stroke="#323232" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M26.9104 27H29C29.5523 27 30 26.5523 30 26V20.3813C30 20.127 29.9515 19.875 29.857 19.6389L28.5023 16.253C28.1985 15.4938 27.4632 14.9959 26.6454 14.9959H23"
                stroke="#323232"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.0816 27H11C10.4477 27 10 26.5523 10 26V20"
                stroke="#323232"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10 22.9959H22C22.5523 22.9959 23 22.5482 23 21.9959V13.9959C23 13.4436 22.5523 12.9959 22 12.9959H20"
                stroke="#323232"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10 13.5C10 15.1385 11.788 16.8888 12.808 17.7544C13.2108 18.0819 13.7882 18.0819 14.191 17.7544C15.212 16.8888 17 15.1385 17 13.5"
                stroke="#323232"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.501 13.24C13.6445 13.2406 13.7604 13.3572 13.76 13.5007C13.7596 13.6441 13.6431 13.7602 13.4997 13.76C13.3562 13.7598 13.24 13.6435 13.24 13.5C13.2396 13.4308 13.267 13.3643 13.3161 13.3154C13.3652 13.2665 13.4318 13.2394 13.501 13.24"
                stroke="#323232"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10 13.5V13.5C10 11.567 11.567 10 13.5 10V10C15.433 10 17 11.567 17 13.5V13.5"
                stroke="#323232"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default IconDeliver;
