import { AxiosRequestConfig } from 'axios';
import { WISHLIST } from '../../utils/react-query-keys';
import api from '../api';
import { queryClient } from '../query-client';
import userService from '../user';
import { AddRemoveResponse, IWishlist, IWishlistAdd, IWishlistRemoveItem, Wishlist, WishlistResponse } from './types';

const getAll = async (paramsAxios?: AxiosRequestConfig): Promise<Wishlist[]> => {
    const { id } = userService.getUserInfoStorage();
    const { data } = await api.get<WishlistResponse[]>(`V1/tatix/wishlist/list/${id}`, paramsAxios);
    return data[0].data || [];
};

const add = async (
    { item, name, wishlistId, resetQuery = false }: IWishlistAdd,
    axiosConfig?: AxiosRequestConfig
): Promise<AddRemoveResponse> => {
    const { id } = userService.getUserInfoStorage();
    let allList = queryClient.getQueryData<Wishlist[]>([WISHLIST]);
    if (!allList) {
        allList = await queryClient.fetchQuery([WISHLIST], getAll);
    }

    if (allList) {
        const list = allList.find((list) => list.wishlist_id == wishlistId);
        const exists = list?.item.find((product) => product.product_id == item.id.toString());

        if (exists) {
            if (item.quantity > 0) {
                await api.post<AddRemoveResponse>(
                    `V1/tatix/wishlist/updateItemWishlist`,
                    {
                        wishlist_id: wishlistId,
                        items: [
                            {
                                quantity: item.quantity,
                                sku: item.sku
                            }
                        ]
                    },
                    axiosConfig
                );
            } else {
                await remove({ sku: item.sku, wishlistId, quantity: 0 }, axiosConfig);
                // queryClient.removeQueries([WISHLIST, wishlistId]);
                queryClient.invalidateQueries([WISHLIST, wishlistId]);
            }
            queryClient.invalidateQueries([WISHLIST]);
            return { success: true };
        }
    }
    const { data } = await api.post<AddRemoveResponse>(
        `V1/tatix/wishlist/add`,
        {
            customer_id: id,
            wishlist_name: name,
            wishlist_id: wishlistId,
            items: [
                {
                    quantity: item.quantity || 1,
                    sku: item.sku
                }
            ]
        },
        axiosConfig
    );
    await queryClient.invalidateQueries([WISHLIST]);
    return data;
};

const remove = async (
    { sku, wishlistId, quantity = 1, resetQuery = false }: IWishlistRemoveItem,
    axiosConfig?: AxiosRequestConfig
): Promise<void> => {
    const { id } = userService.getUserInfoStorage();

    await api.post<AddRemoveResponse[]>(
        `V1/tatix/wishlist/delete`,
        {
            customer_id: id,
            wishlist_id: wishlistId,
            items: [{ quantity: quantity, sku }]
        },
        axiosConfig
    );
    await queryClient.invalidateQueries([WISHLIST]);
};

const toggle = async ({ name, item, wishlistId }: IWishlistAdd, axiosConfig?: AxiosRequestConfig) => {
    let allList = queryClient.getQueryData<Wishlist[]>([WISHLIST]);
    if (!allList) {
        allList = await queryClient.fetchQuery([WISHLIST], getAll);
    }

    if (allList) {
        const list = allList.find((list) => list.wishlist_id == wishlistId);
        const exists = list?.item.find((product) => product.product_id == item.id.toString());

        if (exists) {
            await remove({ sku: item.sku, wishlistId, quantity: 0 }, axiosConfig);
        } else {
            await add({ item, name, wishlistId }, axiosConfig);
        }
    }
    queryClient.invalidateQueries([WISHLIST]);
};

const deleteWishlist = async (wishlistId: string, axiosConfig?: AxiosRequestConfig) => {
    await api.post<AddRemoveResponse[]>(
        `V1/tatix/wishlist/deletewishlist`,
        {
            wishlist_id: wishlistId
        },
        axiosConfig
    );
    queryClient.invalidateQueries([WISHLIST]);
};

const moveToCart = async (itemId: number) => {
    const { data } = await api.post<Wishlist>(`/V1/wishlist/moveToCart`, {
        itemId: itemId
    });

    return data;
};

const all = async (): Promise<IWishlist[]> => {
    const { data } = await api.get<IWishlist[]>(`/V1/wishlists/mine`);
    return data;
};

const create = async (name: string) => {
    const { id } = userService.getUserInfoStorage();

    const body = {
        name,
        customerId: id
    };

    const { data } = await api.post(`V1/wishlist/create`, body);
    return data;
};

const addItem = async (wishlistId: number, sku: number) => {
    const { data } = await api.post(`/V1/wishlist/add`, {
        wishlistId,
        sku
    });

    return data;
};

const getOne = async (wishlistId: number) => {
    const { data } = await api.get<Wishlist>(`/V1/wishlists/${wishlistId}`);
    return data;
};

const removeItem = async (itemId: number) => {
    const { data } = await api.post<Wishlist>(`/V1/wishlist/deleteItem`, {
        itemId: itemId
    });

    return data;
};

const removeWishlist = async (wishlistId: number) => {
    const { id } = userService.getUserInfoStorage();
    const { data } = await api.post<Wishlist>(`/V1/wishlist/delete`, {
        wishlistId: wishlistId,
        customerId: id
    });

    return data;
};

export default {
    all,
    create,
    addItem,
    removeItem,
    moveToCart,
    getOne,
    removeWishlist,
    getAll,
    add,
    remove,
    toggle,
    deleteWishlist
};
