import tw, { styled } from 'twin.macro';
import { Button } from '../../molecules';

export const Container = styled.div`
    ${tw`
        w-full 
        bg-[#FDFDFD]
        border-[0.5px]
        border-[#E6E6E6]
        rounded-8
        p-4
`}
`;

export const NewAddressButton = styled(Button)`
    ${tw`
        bg-primary
        text-white
        font-bold
        rounded-8
        w-full
        mb-4
        py-10
    `}
`;
