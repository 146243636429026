import tw, { styled } from 'twin.macro';

export const Container = styled.div`
    ${tw`
        rounded-t-[24px]
        shadow-[0px -4px 30px 0px rgba(0, 0, 0, 0.10);]
        px-4
        py-3
        flex
        flex-col
        sticky
        bottom-0
        left-0
        right-0
        bg-white
        z-[4]
        md:hidden
    `}
`;

export const Row = styled.div`
    ${tw`
        flex
        flex-row
        gap-2
        justify-between
    `}
`;

export const Seller = styled.div`
    ${tw`
        text-[#636363]
        text-12
        font-normal
        max-w-[170px]
    `}

    strong {
        ${tw`
            text-[#95C230]
            font-bold
        `}
    }
`;

export const Price = styled.div`
    ${tw`
        leading-6
        text-black
        text-20
        font-bold
        text-right
    `}
`;

export const Installments = styled.div`
    ${tw`
        text-12
        text-[#5E5E5E]
        font-normal
        text-right
    `}
`;

export const Shipping = styled.div`
    ${tw`
        text-12
        font-normal
        text-black
    `}
`;
