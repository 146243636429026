import { FC, HTMLAttributes } from 'react';
import { Footer, Header } from '../../organisms';
import { Container } from './styles';
interface ILayoutProps extends Partial<HTMLAttributes<HTMLDivElement>> {
    detectedIp?: string;
}

const Layout: FC<ILayoutProps> = ({ children, detectedIp }) => {
    return (
        <Container>
            <Header detectedIp={detectedIp} />
            <main>{children}</main>
            <Footer />
        </Container>
    );
};

export default Layout;
