import tw, { styled } from 'twin.macro';
import { IContainerProps } from './types';

export const Container = styled.div<IContainerProps>`
    ${({ left }) => {
        if (left) {
            return tw`
                left-0
            `;
        }
        return tw`
            right-0
        `;
    }}
    ${tw`
        z-[2]
        /* xl:right-[-16px] */

        top-[calc(50% - 24px)]
        cursor-pointer
        absolute
        flex
        items-center
        justify-center
        transition-all
    `}
`;
