import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AddressService } from '../../services';
import { AddressMain } from '../../services/address/types';
import { ADDRESS } from '../../utils/react-query-keys';

export const useAddress = (params?: UseQueryOptions<AddressMain>) => {
    return useQuery<AddressMain>([ADDRESS], () => AddressService.initAddress(), {
        refetchOnMount: true,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        ...params
    });
};
