import tw, { styled } from 'twin.macro';
import { TextNunitoBold12, TextNunitoBold18 } from '../../../atoms';

export const Container = styled.div`
    ${tw`
        bg-black
        flex
        flex-col
        items-stretch
        md:items-center
    `}
`;

export const Content = styled.div`
    ${tw`
        py-4
        items-center
        gap-6
        flex-row
        flex-wrap
        md:flex
        md:gap-8
        lg:gap-16
    `}
`;

export const Item = styled.div`
    ${tw`
        flex
        items-center
        flex-col
        gap-2
        md:gap-1
        md:flex-row
    `}
`;

export const ItemTitle = styled(TextNunitoBold12)`
    ${tw`
        text-white
        text-center
        md:text-left
    `}
`;
