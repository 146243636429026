import { FC, SVGProps } from 'react';

const IconNotebook: FC<SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.0025 21.0037H5.99748C4.89202 21.0037 3.99664 20.1083 3.99664 19.0029V4.99705C3.99664 3.89159 4.89202 2.99622 5.99748 2.99622H18.0025C19.1079 2.99622 20.0033 3.89159 20.0033 4.99705V19.0029C20.0033 20.1083 19.1079 21.0037 18.0025 21.0037Z"
                stroke="currentcolor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.99875 7.99832H16.0017"
                stroke="currentcolor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.99875 16.0016H16.0017"
                stroke="currentcolor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.99875 12H16.0017"
                stroke="currentcolor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M2.49603 7.99832H5.49728"
                stroke="currentcolor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M2.49603 16.0016H5.2982"
                stroke="currentcolor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M2.49603 12H5.2982"
                stroke="currentcolor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default IconNotebook;
