import { useFormikContext } from 'formik';
import { Container, Radio } from './styles';

interface CustomCheckboxProps {
    onCheckboxChange?: (checked: boolean) => void;
    name?: string;
    withoutFormik?: boolean;
    isChecked?: boolean;
}

export const Checkbox: React.FC<CustomCheckboxProps> = ({
    onCheckboxChange,
    name,
    withoutFormik = false,
    isChecked
}) => {
    let propsIsChecked: any;
    let propsOnChange: any = () => {};

    if (!withoutFormik && name) {
        const { values, setFieldValue } = useFormikContext<any>();
        propsIsChecked = values?.[name] ? true : false;
        propsOnChange = (value: boolean) => {
            onCheckboxChange?.(value);
            setFieldValue(name as string, value);
        };
    } else {
        propsIsChecked = isChecked;

        propsOnChange = (value: boolean) => {
            onCheckboxChange?.(value);
        };
    }
    const animate = propsIsChecked ? 'selected' : 'unselected';

    return (
        <Radio onClick={() => propsOnChange(!propsIsChecked)}>
            <Container initial="unselected" animate={animate} />
        </Radio>
    );
};
