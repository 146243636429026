import tw, { styled } from 'twin.macro';

export const Container = styled.div`
    ${tw`
      flex
      flex-col
      items-center
      pt-[10px]
      cursor-pointer
      transition-all
      outline-none
    `}
`;

export const DateLetter = styled.span`
    ${tw`
      transition-all
    `}
`;

export const DateNumber = styled.button`
    ${tw`
      flex
      justify-center
      items-center
      mt-[10px]
      p-[20px]
      h-[15px]
      w-[15px]
      rounded-md
      border-[1px]
      border-gray
      transition-all
      duration-500
      outline-none
    `}
`;