import tw, { styled } from 'twin.macro';
import { IconClose, Modal, TextNunitoBold18, TextNunitoRegular14 } from '../../atoms';

export const Container = styled(Modal)`
    ${tw`
        flex
        flex-col
        items-center
        justify-between
        max-w-[767px]
        max-h-[98vh]
        pt-4
        pr-3.5
        pl-4
        font-nunito
        text-xs
        text-[black]
        font-normal
        z-50
        md:hidden
        overflow-y-auto
    `}
`;

export const CloseTitle = styled.div`
    ${tw`
        text-[#FF5F00]
        text-center
        mb-2
        cursor-pointer
    `}
`;

export const Header = styled.div`
    ${tw`
        text-center
        mb-6
    `}
`;

export const Title = styled.div`
    ${tw`
        h-8
        flex
        flex-col
        justify-center
        text-[22px]
        font-bold
        text-[#424242]
    `}
`;

export const SelectWrapper = styled.div<{ isOpen: boolean }>`
  position: relative;
  display: inline-block;
  width: 100%;

  .select-arrow {
    ${tw`absolute right-4 top-1/2 transform -translate-y-1/2 pointer-events-none`}
    transition: transform 0.2s;
    transform: rotate(${({ isOpen }) => (isOpen ? '180deg' : '0deg')});
  }
`;

export const Select = styled.select`
  ${tw`
    w-full
    h-[56px]
    bg-white
    rounded-[28px] p-4
    border-[0.5px]
    border-[#DBDBDB]
  `}
  padding-right: 2.5rem;
  appearance: none;
  background-image: none;
`;

export const Option = styled.option`
  ${tw`
    font-nunito
    text-xs
    text-black
    font-normal
  `}
`;

export const Fields = styled.form`
    ${tw`
        flex
        flex-col
        justify-center
        gap-4
    `}
`;

export const Close = styled(IconClose)`
    ${tw`
        stroke-[#323232]
        w-6
        h-6
        absolute
        top-6
        right-4
        cursor-pointer
        md:top-4
        md:right-6
    `}
`;
