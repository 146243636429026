import mundipaggService from '../services/mundipagg';
import { Order } from '../services/order/types';
import { CreditCardValues, PaymentInterface, PaymentSelected, PaymentTypes } from '../services/payment/types';
import { currencyUnMask } from '../utils/getFormattedValue';
import { ucfirst, unMask } from '../utils/string';
import { Mundipagg } from './Mundipagg';

export class PaymentMundipaggTwoCreditCard extends Mundipagg implements PaymentInterface {
    async getTokenCard(card: CreditCardValues) {
        const tokenCard = {
            type: 'card',
            card: {
                holder_name: card.name as string,
                number: card.number?.replace(/ /g, '') as string,
                exp_month: card.valid?.split('/')[0] as string,
                exp_year: card.valid?.split('/')[1] as string,
                cvv: card?.cvv as string,
                holder_document: unMask(card?.cpf as string) as string
            }
        };
        const { id } = await mundipaggService.tokenCard(tokenCard);
        return id;
    }

    async getCheckout(valuesCheckout: CreditCardValues[]) {
        const [card1, card2] = valuesCheckout;

        return {
            method: PaymentTypes.MUNDIPAGG_TWO_CREDIT_CARD,
            additional_data: {
                cc_cid_first: card1.useSave ? null : card1.cvv,
                cc_exp_month_first: card1.useSave ? null : card1.valid?.split('/')[0],
                cc_exp_year_first: card1.useSave ? null : card1.valid?.split('/')[1],
                cc_first_card_amount: card1.value?.replace('R$ ', ''),
                cc_first_card_tax_amount: '0',
                cc_installments_first: card1.installments?.quantity,
                cc_last_4_first: card1.useSave ? null : card1.name?.substr(-4),
                cc_number_first: card1.useSave ? null : card1.number?.replace(/ /g, ''),
                cc_owner_first: card1.useSave ? null : card1.name,
                cc_savecard_first: card1.save,
                cc_saved_card_first: card1.useSave ? card1.useSave.id : null,
                cc_token_credit_card_first: card1.useSave ? null : await this.getTokenCard(card1),
                cc_type_first: card1.useSave ? card1.useSave.brand : card1.type,

                cc_cid_second: card2.useSave ? null : card2.cvv,
                cc_exp_month_second: card2.useSave ? null : card2.valid?.split('/')[0],
                cc_exp_year_second: card2.useSave ? null : card2.valid?.split('/')[1],
                cc_second_card_amount: card2.value?.replace('R$ ', ''),
                cc_second_card_tax_amount: '0',
                cc_installments_second: card2.installments?.quantity,
                cc_last_4_second: card2.useSave ? null : card2.name?.substr(-4),
                cc_number_second: card2.useSave ? null : card2.number?.replace(/ /g, ''),
                cc_owner_second: card2.useSave ? null : card2.name,
                cc_savecard_second: card2.save,
                cc_saved_card_second: card2.useSave ? card2.useSave.id : null,
                cc_token_credit_card_second: card2.useSave ? null : await this.getTokenCard(card2),
                cc_type_second: card2.useSave ? card2.useSave.brand : card2.type
            }
        };
    }

    getFromCheckout(data: PaymentSelected) {
        const paymentService = require('../services/payment').default;
        const method = PaymentTypes.MUNDIPAGG_TWO_CREDIT_CARD;
        const methodName = paymentService.getPaymentMethodTitle(method, 'small');
        return {
            method,
            values:
                data.values?.map((card) => {
                    const installments = card.installments?.quantity;
                    const type = card.type || card.useSave?.brand;
                    const number = card.number
                        ? card.number?.replace(/ /g, '').slice(12)
                        : card.useSave?.masked_number.slice(15);
                    return {
                        installments,
                        value: card.value,
                        type: type,
                        number: number,
                        label: `${ucfirst(type)} - ${installments}x ${methodName} - **** ${number}`
                    };
                }) || []
        };
    }
    getFromOrder(order: Order) {
        const paymentService = require('../services/payment').default;

        const card1Number = order.extension_attributes.payment_additional_info?.find(
            (v) => v.key == 'cc_last_4_first'
        )?.value;
        const card1Installments = parseInt(
            order.extension_attributes.payment_additional_info?.find((v) => v.key == 'cc_installments_first')?.value ||
                '1'
        );
        const card1Type = order.extension_attributes.payment_additional_info?.find(
            (v) => v.key == 'cc_type_first'
        )?.value;
        const methodName = paymentService.getPaymentMethodTitle(PaymentTypes.MUNDIPAGG_TWO_CREDIT_CARD, 'small');

        const card2Installments = parseInt(
            order.extension_attributes.payment_additional_info?.find((v) => v.key == 'cc_installments_second')?.value ||
                '1'
        );
        const card2Type = order.extension_attributes.payment_additional_info?.find(
            (v) => v.key == 'cc_type_second'
        )?.value;
        const card2Number = order.extension_attributes.payment_additional_info?.find(
            (v) => v.key == 'cc_last_4_second'
        )?.value;
        return {
            method: order.payment.method,
            values: [
                {
                    installments: card1Installments,
                    value: currencyUnMask(
                        order.extension_attributes.payment_additional_info?.find((v) => v.key == 'cc_first_card_amount')
                            ?.value
                    ).toString(),
                    type: card1Type,
                    number: card1Number,
                    label: `${ucfirst(card1Type)} - ${card1Installments}x ${methodName} - **** ${card1Number}`
                },
                {
                    installments: card2Installments,
                    value: currencyUnMask(
                        order.extension_attributes.payment_additional_info?.find((v) => v.key == 'cc_first_card_second')
                            ?.value
                    ).toString(),
                    type: card2Type,
                    number: card2Number,
                    label: `${ucfirst(card2Type)} - ${card2Installments}x ${methodName} - **** ${card2Number}`
                }
            ]
        };
    }
}
