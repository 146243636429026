import Image from 'next/image';
import Link from 'next/link';
import { Categories } from '../../../services/category/types';
import Carousel from '../Carousel';
import { Menu, MenuImgContainer, MenuTitle } from './styles';
import { ProductService } from '../../../services';

interface IHallCategoriesProps {
    categories: Categories[];
    noMargin?: boolean;
}

function HallCategories({ categories, noMargin = false }: IHallCategoriesProps) {
    const items = categories.filter((item) => item.images?.image);

    if (!items.length) return null;

    return (
        <Carousel marginItem={32} arrows={false}>
            {!noMargin && <div></div>}
            {items.map((item, index) => (
                <Link href={ProductService.getUrlSearchProduct(item.url)} key={index.toString()}>
                    <Menu key={index.toString()}>
                        <MenuImgContainer>
                            {item.images?.image && (
                                <div className="w-[25px] shrink-0 relative h-[25px] md:w-[49px] md:h-[49px] ">
                                    <Image
                                        src={item.images?.image}
                                        sizes="49px"
                                        fill
                                        className="object-contain object-center"
                                        alt={item.name || 'categoria'}
                                    />
                                </div>
                            )}
                        </MenuImgContainer>
                        <MenuTitle>{item.name}</MenuTitle>
                    </Menu>
                </Link>
            ))}
            {!noMargin && <div></div>}
        </Carousel>
    );
}

export default HallCategories;
