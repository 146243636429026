import { SVGProps } from 'react';

const IconOrder = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect
                x="8"
                y="3"
                width="8"
                height="4"
                rx="1"
                strokeWidth="1.5"
                stroke="currentcolor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16 5H18C19.1046 5 20 5.89543 20 7V19C20 20.1046 19.1046 21 18 21H6C4.89543 21 4 20.1046 4 19V7C4 5.89543 4.89543 5 6 5H8"
                strokeWidth="1.5"
                stroke="currentcolor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M13 12H16" strokeWidth="1.5" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13 12H16" strokeWidth="1.5" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M8 11.5L9 12.5"
                strokeWidth="1.5"
                stroke="currentcolor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.5 11L9 12.5"
                strokeWidth="1.5"
                stroke="currentcolor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M13 16H16" strokeWidth="1.5" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8 16H16" strokeWidth="1.5" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default IconOrder;
