import { useQtyProduct } from '../../hooks/useQtyProduct';
import { Button, Container } from './styles';

function CartQty() {
    const { qty, setQty } = useQtyProduct();

    const handleIncrement = () => {
        setQty(qty + 1);
    };

    const handleDecrement = () => {
        if (qty > 1) {
            setQty(qty - 1);
        }
    };
    return (
        <Container>
            <Button
                type="button"
                whileTap={{
                    scale: 0.8
                }}
                onClick={handleDecrement}
            >
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M4.71344 9.00009H13.2849"
                        stroke="#323232"
                        strokeWidth="1.07143"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </Button>
            <div>{qty}</div>
            <Button
                type="button"
                whileTap={{
                    scale: 0.8
                }}
                onClick={handleIncrement}
            >
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M4.71344 9.00009H13.2849"
                        stroke="#323232"
                        strokeWidth="1.07143"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M8.99917 13.2859V4.71448"
                        stroke="#323232"
                        strokeWidth="1.07143"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </Button>
        </Container>
    );
}

export default CartQty;
