import { motion } from 'framer-motion';
import tw, { styled } from 'twin.macro';
import { IconSpinner, TextLatoExtraBold18, TextLatoRegular15, TextLatoRegular18 } from '../../atoms';
import ModalOrgn from '../../atoms/Modal';
import { ModalHeader } from '../../molecules';

import { IModalProps } from './types';

export const Modal = styled(ModalOrgn)<IModalProps>`
    ${tw`
    flex
    flex-col
    justify-start
    overflow-hidden
  `}
`;

export const Header = styled(ModalHeader)`
    ${tw`
      mt-[10px]  
    `}
`;

export const TextTitle = styled(TextLatoExtraBold18)`
    ${tw`
        mb-[15px]

    `}
`;

export const Content = styled.div`
    ${tw`
        pt-[10px]  
        flex
        flex-col
        lg:flex-row
        h-[calc(100% - 40px)]
        overflow-y-auto
        lg:overflow-y-hidden
    `}
`;
export const Column = styled.div`
    ${tw`
        p-[20px]
        flex
        flex-col
        lg:h-full
        lg:flex-1
        lg:overflow-y-auto

    `}
`;
export const ColumnInfo = styled(Column)`
    ${tw`
        lg:order-1
        lg:overflow-hidden
        px-0
        py-0
        relative
    `}
`;
export const ContainerSubmit = styled.div`
    ${tw`
        px-[20px]
        flex
        relative
        flex-col
        pb-[20px]

        md:bottom-0
        md:right-0
        md:left-0
        md:bg-white
        md:shadow-md
        md:rounded-t-30
        md:absolute
    `};
`;

export const Scroll = styled(motion.div)`
    scroll-behavior: smooth;
    ${tw`
        lg:overflow-y-auto
        px-[20px]
    `}
`;

export const ColumnProducts = styled(Column)`
    ${tw`
        lg:order-2
        p-0
    `}
`;

export const Separator = styled.div`
    ${tw`
        w-[1px]
        bg-gray
    `}
`;

export const ContainerInput = styled.div`
    ${tw`
        mb-[25px]
        flex
        flex-col
    `}
`;
export const ContainerPayment = styled.div`
    ${tw`
        flex
        flex-col
    `}
`;

export const TotalSubTotal = styled.div`
    ${tw`
        flex
        flex-row
        justify-between
        mt-[10px]
    `}
`;
export const TotalItemText = styled(TextLatoRegular18)`
    ${tw`
        text-darkGray
    `}
`;
export const TotalText = styled(TextLatoRegular18)`
    ${tw`
    `}
`;
export const TotalItemValue = styled(TextLatoRegular15)`
    ${tw`
        text-darkGray
    `}
`;
export const TotalTax = styled.div`
    ${tw`
        pt-[10px]
        flex
        flex-row
        justify-between
    `}
`;
export const TotalAll = styled.div`
    ${tw`
        pb-[10px]
        flex
        flex-row
        justify-between
    `}
`;
export const SeparatorTotal = styled.div`
    ${tw`
         flex
        h-[1px]
        bg-gray
        w-full
        shrink-0
        mt-[10px]
        mb-[10px]
    `}
`;

export const ContainerLoading = styled.div`
    ${tw`
        flex
        justify-center
        items-center
        flex-1
    `}
`;
export const IconLoading = styled(IconSpinner)`
    ${tw`
        stroke-primary
        w-[40px]
        h-[50px]
    `}
`;
