import { motion } from 'framer-motion';
import tw, { styled } from 'twin.macro';
import { IconEstablishment, IconTruck, TextLatoBold15, TextLatoRegular15 } from '../../atoms';
import { IItemProps } from './types';

export const Container = styled.div`
    ${tw`
        flex
        flex-row
        gap-[15px]
    `}
`;

export const Item = styled(motion.div)<IItemProps>`
    > span > span {
        ${tw`
            text-primary
        `}
    }
    ${({ selected }) => {
        if (selected) {
            return tw`
                shadow-md 
                bg-primary
                `;
        } else {
            return tw``;
        }
    }}
    ${tw`
        border
        border-gray
        rounded-15
        flex
        flex-col
        p-[15px]
        gap-[10px]
        justify-center
        items-center
        w-full
        cursor-pointer
    `}
`;
export const TextItem = styled(TextLatoRegular15)`
    ${tw`
        text-center
    `}
`;
export const TextItemSelected = styled(TextLatoBold15)`
    ${tw`
        text-white
        text-center
    `}
`;
export const IconDelivery = styled(IconTruck)`
    ${tw`
  w-[24px]
  h-[24px]
  fill-black
`}
`;

export const IconDeliverySelected = styled(IconTruck)`
    ${tw`
  w-[24px]
  h-[24px]
  fill-white
`}
`;
export const IconPickup = styled(IconEstablishment)`
    ${tw`
  w-[24px]
  h-[24px]
  fill-black
`}
`;

export const IconPickupSelected = styled(IconEstablishment)`
    ${tw`
  w-[24px]
  h-[24px]
  fill-white
`}
`;
