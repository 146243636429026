import { Radio as RatioMaterial } from '@mui/material';
import { FC, HTMLAttributes } from 'react';
import { twColors } from '../../../utils/get-config-tailwind';

interface IRadioProps extends Partial<HTMLAttributes<HTMLDivElement>> {
    onChange: () => void;
    checked: boolean;
}

const Radio: FC<IRadioProps> = ({ className, checked, onChange }) => {
    return (
        // <div className={className}>
        <RatioMaterial
            onChange={onChange}
            checked={checked}
            size={'small'}
            sx={{
                color: twColors.primary,
                '&.Mui-checked': {
                    color: twColors.primary
                }
            }}
        />
        // </div>
    );
};

export default Radio;
