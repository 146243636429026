import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { BlockService } from '../../services';
import { IBlockFooterDeliveryBanner } from '../../services/block';
import { BLOCK_FOOTER_DELIVERY } from '../../utils/react-query-keys';

export const useBlockFooterDelivery = (params?: UseQueryOptions<IBlockFooterDeliveryBanner[]>) => {
    return useQuery<IBlockFooterDeliveryBanner[]>(
        [BLOCK_FOOTER_DELIVERY],
        () => BlockService.getBlockFooterDeliveryBanner(),
        {
            refetchOnMount: false,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
            ...params
        }
    );
};
