import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { motion } from 'framer-motion';
import Link from 'next/link';
import { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { CartService, ProductService, ReactGa4Service } from '../../../services';
import { CartItem as CartItemType, CartItemWithElastic } from '../../../services/cart/types';
import { IOnCartAddOrRemoveParams } from '../../../services/react-ga4/types';
import { NEXT_PUBLIC_URL_IMAGES } from '../../../utils/envs';
import { currencyBrl } from '../../../utils/getFormattedValue';
import { Image, ProductOutStock } from '../../atoms';
import { CartItemDetails, Container, DescriptionProduct, ProductTitle, Trash } from './styles';
import { ICartItemVariant } from './types';
import ProductImage from '../../atoms/Product-image';

interface Product {
    data: CartItemWithElastic;
    variant?: ICartItemVariant;
}

const CartItem: FC<Product> = ({ data, variant = ICartItemVariant.DEFAULT }) => {
    const [product, setProduct] = useState<CartItemType | null>();

    useEffect(() => {
        const unSubscribe = CartService.createSubscribe(
            (dataCart) => {
                const newProduct = dataCart?.items
                    ? CartService.findProduct(dataCart.items, undefined, data.sku)
                    : null;
                if (product?.qty != newProduct?.qty || product === undefined) {
                    setProduct(newProduct);
                }
            },
            { staleTime: 0 }
        );

        return () => {
            unSubscribe();
        };
    }, [product]);

    const onError = (error: AxiosError<any>) => {
        if (error.response?.status == 400) {
            // NotificationService.showTopNotificationError(error.response.data?.message || 'Produto sem estoque');
            toast.error(error.response.data?.message);

            return;
        }
        toast.error('Erro interno');
        // NotificationService.showTopNotificationError();
    };

    const handleClickSub = () => {
        add(-1);
    };

    const { mutate: add } = useMutation(
        async (qty: number) => {
            if (product) {
                const newQty = product.qty + qty;
                const price = product.price;

                return CartService.updateItem({
                    itemId: product.item_id,
                    qty: newQty,
                    // option: optionProductSelected,
                    // pageOrigin,
                    price: price,
                    name: product.name,
                    isAdd: qty > 0,
                    fromCart: true
                });
            }
            return null;
        },
        {
            onError
        }
    );

    const { mutate: remove } = useMutation(
        async () => {
            if (product) {
                const removeProduct: IOnCartAddOrRemoveParams = {
                    isAdd: false,
                    product: {
                        item_id: product?.item_id.toString(),
                        quantity: 1,
                        price: product?.price,
                        item_name: product?.name
                    }
                };

                ReactGa4Service.onCartAddOrRemove(removeProduct);
                return CartService.remove(product.item_id);
            }
            return null;
        },
        {
            onError
        }
    );

    return (
        <Container variant={variant}>
            <Link className="w-full" href={ProductService.getRouterProduct(data.elastic)}>
                <CartItemDetails>
                    <motion.div className="flex relative border-[0.5px] overflow-hidden border-[#E0E0E0] rounded-8 min-w-[88px] max-h-[96px] object-cover items-center justify-center">
                        <ProductImage
                            data={data.elastic}
                            fill
                            sizes="auto"
                            className="object-cover object-center"
                            alt="product image"
                        />
                    </motion.div>
                    <DescriptionProduct variant={variant}>
                        <p className="mb-2 text-12 text-[#636363]">{data.sku}</p>
                        <div className="flex flex-row items-center gap-4 ">
                            <ProductTitle className=" font-semibold text-14">{data.name}</ProductTitle>
                            <Trash
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    remove();
                                }}
                            />
                        </div>
                        <div className="flex justify-between mt-4">
                            <span className="flex flex-col text-12">
                                <div className="text-[#636363]">1 un R$ {currencyBrl(product?.price ?? 0)}</div>
                                <div className="ms-34">
                                    {product?.qty} un R$ {currencyBrl((product?.price || 0) * (product?.qty || 1))}
                                </div>
                            </span>
                            <span className="flex items-center gap-4">
                                <button
                                    className="w-10 h-10 text-[#323232] rounded-full border-[0.36px]"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        handleClickSub();
                                    }}
                                >
                                    -
                                </button>
                                <p className="font-bold text-18">{product?.qty}</p>
                                <button
                                    className="w-10 h-10 text-[#323232] rounded-full border-[0.36px]"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        add(1);
                                    }}
                                >
                                    +
                                </button>
                            </span>
                        </div>
                    </DescriptionProduct>
                </CartItemDetails>
            </Link>

            {!product?.available && <ProductOutStock showTrash={true} itemId={product?.item_id} horizontal={false} />}
        </Container>
    );
};

export default CartItem;
