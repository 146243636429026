import { AnimatePresence } from 'framer-motion';
import { MouseEvent, PropsWithChildren, useState } from 'react';
import { Container, ContainerIcon, Content, Header } from './styles';
import IconArrowDown from '../../../../../atoms/icons/Arrow-down';
import AnimatedCounter from '../../../../../atoms/Animated-counter';

interface IMenuCollapseProps {
    title: string;
    qty: number;
}

const MenuCollapse = ({ title, children, qty }: PropsWithChildren<IMenuCollapseProps>) => {
    const [isOpen, setIsOpen] = useState(true);

    const handleToggle = (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        setIsOpen(!isOpen);
    };

    return (
        <Container>
            <Header onClick={handleToggle}>
                <span className="flex flex-row gap-1">
                    {title}
                    {qty > 0 && <div>({qty})</div>}
                </span>
                <ContainerIcon animate={isOpen ? 'open' : 'close'}>
                    <IconArrowDown style={{ fill: '#212226' }} />
                </ContainerIcon>
            </Header>
            <AnimatePresence>
                {isOpen && (
                    <Content>
                        <div className="pb-4">{children}</div>
                    </Content>
                )}
            </AnimatePresence>
        </Container>
    );
};

export default MenuCollapse;
