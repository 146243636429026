import { AxiosRequestConfig } from 'axios';
import { Order } from '../services/order/types';
import {
    CreditCardValues,
    GetInstallmentsParams,
    InstallmentsResponse,
    PaymentInterface,
    PaymentMethod,
    PaymentSelected,
    PaymentTypes
} from '../services/payment/types';
import { currencyUnMask } from '../utils/getFormattedValue';
import { ucfirst } from '../utils/string';

import { loadMercadoPago } from '@mercadopago/sdk-js';

import { EstablishmentService } from '../services';
import axios from '../services/axios';
import { NEXT_PUBLIC_MERCADOPAGO_PUBLICK_KEY } from '../utils/envs';

export class PaymentTtxPayCreditCard extends PaymentMethod implements PaymentInterface {
    async isMercadoPago() {
        // const establishmentKey = EstablishmentService.getEstablishmentStorage()?.key as string;
        const apiAdmin = axios.getApiAdmin('establishmentKey');
        const { data } = await apiAdmin.get('V1/ttxpay/getconfig/getway_payment_cc');
        return data == 'mercado_pago';
    }

    async getCheckout(valuesCheckout: CreditCardValues[]) {
        const [card] = valuesCheckout;

        let cardInfo;

        const [birthDay, bithMonth, bithYear] = !card.card_hash
            ? (card.birthday?.split('/') as string[])
            : ['', '', ''];
        const [expMonth, expYear] = !card.card_hash ? (card.valid?.split('/') as string[]) : ['', '', ''];
        let number = card.number?.replace(/ /g, '') as string;

        if (await this.isMercadoPago()) {
            await loadMercadoPago();
            const mp = new window.MercadoPago(NEXT_PUBLIC_MERCADOPAGO_PUBLICK_KEY);
            const { id } = await mp.createCardToken({
                cardNumber: number,
                cardholderName: card.name,
                identificationType: 'CPF',
                identificationNumber: card.cpf?.replaceAll('.', '').replaceAll('-', ''),
                securityCode: card.cvv,
                cardExpirationMonth: expMonth,
                cardExpirationYear: expYear
            });
            number = id;
        }

        cardInfo = {
            cc_installments: card.installments?.quantity,
            cc_number: `${number}`,
            cc_owner_birthday: !card.card_hash ? `${bithYear}-${bithMonth}-${birthDay}` : '',
            cc_owner_cpf: card.cpf,
            cc_owner: card.name,
            cc_cvv: card.cvv,
            cc_type: card.type,
            cc_exp_month: expMonth,
            cc_exp_year: expYear
        };

        return {
            method: PaymentTypes.TTXPAY_CREDIT_CARD,
            additional_data: cardInfo
        };
    }

    getFromOrder(order: Order) {
        const paymentService = require('../services/payment').default;

        const type = order.extension_attributes.payment_additional_info?.find((v) => v.key == 'cc_type')?.value;
        const installments = parseInt(
            order.extension_attributes.payment_additional_info?.find((v) => v.key == 'cc_installments')?.value || '1'
        );
        const method = paymentService.getPaymentMethodTitle(PaymentTypes.TTXPAY_CREDIT_CARD, 'small');
        const number = order.extension_attributes.payment_additional_info?.find((v) => v.key == 'cc_last_4')?.value;
        return {
            method: PaymentTypes.TTXPAY_CREDIT_CARD,
            values: [
                {
                    installments,
                    value: currencyUnMask(order.base_grand_total).toString(),
                    type,
                    number,
                    label: `${ucfirst(type)} - ${installments}x ${method} - **** ${number}`
                }
            ]
        };
    }

    getFromCheckout(data: PaymentSelected) {
        const paymentService = require('../services/payment').default;

        const method = PaymentTypes.TTXPAY_CREDIT_CARD;
        const methodName = paymentService.getPaymentMethodTitle(method, 'small');

        return {
            method,
            values:
                data.values?.map((card) => {
                    const installments = card.installments?.quantity;
                    const type = card.type || card.useSave?.brand;
                    const number = card.number
                        ? card.number?.replace(/ /g, '').slice(12)
                        : card.useSave?.masked_number.slice(15);
                    return {
                        installments,
                        value: card.value,
                        type: type,
                        number: number,
                        label: `${ucfirst(type)} - ${installments}x ${methodName} - **** ${number}`
                    };
                }) || []
        };
    }

    async getInstallments(params: GetInstallmentsParams, config?: AxiosRequestConfig): Promise<InstallmentsResponse[]> {
        const newValue = currencyUnMask(params.value);

        return [
            {
                quantity: 1,
                installmentTotal: newValue,
                label: `1x de ${params.value}`,
                totalWithTax: newValue
            }
        ];
    }
}
