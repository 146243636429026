import React, { FC } from 'react';
import { CreditCardFlag } from '../../atoms';
import { BarGray, Container, Cvv, CvvFlip, FlagFlip, Header, NumberFlip } from './styles';
import { ICreditCardFlipBackProps } from './types';

const CreditCardFlipBack: FC<ICreditCardFlipBackProps> = ({ number, cvv }) => {
    return (
        <Container>
            <Header>
                <NumberFlip>{number}</NumberFlip>
            </Header>
            <BarGray>
                <CvvFlip>
                    <Cvv>{cvv}</Cvv>
                </CvvFlip>
            </BarGray>
            <FlagFlip>
                <CreditCardFlag color="#fff" number={number} />
            </FlagFlip>
        </Container>
    );
};

export default CreditCardFlipBack;
