import React, { FC, SVGProps } from 'react';

const FlagDiscover: FC<SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg viewBox="0 0 42 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M3.475 0C1.888 0 .602 1.275.602 2.847v20.235c0 1.572 1.286 2.847 2.873 2.847h35c1.585 0 2.872-1.275 2.872-2.847V2.847C41.347 1.274 40.061 0 38.474 0h-35z"
                fill="#4D4D4D"
            />
            <path
                d="M17.692 8.378c.462 0 .849.095 1.32.325V9.92c-.446-.42-.834-.596-1.346-.596-1.006 0-1.797.803-1.797 1.82 0 1.073.767 1.827 1.847 1.827.487 0 .867-.166 1.296-.58v1.217c-.488.22-.884.308-1.346.308-1.633 0-2.903-1.207-2.903-2.765 0-1.54 1.303-2.772 2.929-2.772zm-5.073.033A2.46 2.46 0 0114.236 9l-.562.708c-.28-.302-.544-.429-.865-.429-.463 0-.8.254-.8.587 0 .286.19.438.833.667 1.22.43 1.583.811 1.583 1.653 0 1.026-.783 1.74-1.898 1.74-.816 0-1.41-.31-1.904-1.009l.693-.643c.247.46.66.707 1.171.707.479 0 .833-.318.833-.748 0-.222-.107-.413-.322-.548-.107-.064-.321-.159-.741-.301-1.008-.35-1.354-.723-1.354-1.453 0-.867.743-1.519 1.716-1.519zm12.261.093h1.172l1.467 3.559 1.486-3.56h1.164l-2.377 5.435h-.577L24.88 8.504zm-20.756.008h1.575c1.74 0 2.953 1.09 2.953 2.653 0 .78-.371 1.533-.999 2.034-.528.422-1.13.611-1.962.611H4.124V8.512zm5.022 0h1.073v5.298H9.146V8.512zm21.508 0h3.043v.898h-1.971v1.176h1.898v.897h-1.898v1.43h1.97v.897h-3.042V8.512zm3.753 0h1.591c1.238 0 1.947.572 1.947 1.564 0 .811-.445 1.344-1.253 1.502l1.731 2.232h-1.32l-1.484-2.128h-.14v2.128h-1.072V8.512zm1.072.834v1.605h.313c.686 0 1.049-.287 1.049-.82 0-.515-.364-.785-1.032-.785h-.33zM5.196 9.41v3.503h.288c.693 0 1.131-.128 1.468-.42.372-.32.595-.827.595-1.336 0-.508-.223-1-.595-1.319-.354-.309-.775-.428-1.468-.428h-.288z"
                fill="#fff"
            />
            <path
                d="M22.287 8.343c1.616 0 2.926 1.22 2.926 2.728v.001c0 1.508-1.31 2.73-2.926 2.73-1.617 0-2.927-1.222-2.927-2.73v-.001c0-1.508 1.31-2.728 2.927-2.728zm19.058 6.58c-1.36.949-11.548 7.729-29.187 11.004h26.315c1.586 0 2.872-1.274 2.872-2.846v-8.158z"
                fill="#F47216"
            />
        </svg>
    );
};

export default FlagDiscover;
