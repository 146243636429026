import { useMemo } from 'react';
import { ucfirst } from '../../../../../../utils/string';
import { Checkbox as CustomCheckbox } from '../../../../../atoms/Checkbox';
import { useProductSearchFilters } from '../../../hooks/useProductSearchFilters';
import MenuCollapse from '../Menu-collapse';
import { Container, Item, List } from './styles';

interface PotenciaProps {
    isMobile?: boolean;
}
function Potencia({ isMobile = false }: PotenciaProps) {
    const { filters, setFiltersDesktop, setFiltersMobile, aggregations } = useProductSearchFilters();

    function handleChange(id: string) {
        const { potencia } = filters;
        const index = potencia.indexOf(id);
        let newFilters = [];

        if (index === -1) {
            newFilters = [...potencia, id];
        } else {
            newFilters = potencia.filter((item) => item !== id);
        }

        if (isMobile) {
            setFiltersMobile({ potencia: newFilters });
            return;
        }

        setFiltersDesktop({ potencia: newFilters });
    }

    const items = useMemo(() => {
        return (
            aggregations?.Potencia.buckets
                .map((potencia: any) => {
                    return {
                        key: potencia.key,
                        name: ucfirst(potencia.key?.toLowerCase())
                    };
                })
                .filter((v) => v != null) || []
        );
    }, [aggregations?.Potencia.buckets]);

    if (!items.length) return null;

    return (
        <Container>
            <MenuCollapse title="Potência" qty={filters.potencia.length || 0}>
                <List>
                    {items.map((potencia: any) => (
                        <Item key={potencia.key} onClick={() => handleChange(potencia.key)}>
                            <CustomCheckbox
                                name={potencia.key}
                                isChecked={filters.potencia.includes(potencia.key)}
                                withoutFormik
                                onCheckboxChange={() => handleChange(potencia.key)}
                            />
                            <div>{potencia.name}</div>
                        </Item>
                    ))}
                </List>
            </MenuCollapse>
        </Container>
    );
}

export default Potencia;
