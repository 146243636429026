import { useRouter } from 'next/router';
import { forwardRef, Ref, useImperativeHandle, useRef } from 'react';
import { ProductService } from '../../../services';
import { SubCategories, SubCategoriesList, SubCategory, SubCategoryLabel } from './styles';
import { ICategoriesModal, ISubcategoryModalHandle } from './types';

const SubcategoriesModal = forwardRef(
    ({ category, show, categoryParent, closeModal }: ICategoriesModal, ref: Ref<ISubcategoryModalHandle>) => {
        const router = useRouter();

        const refRootDiv = useRef<HTMLDivElement>(null);

        const mountSubcategories = () => {
            const subcategoriesList: JSX.Element[] = [];
            category.forEach((elem) => {
                {
                    subcategoriesList.push(
                        <SubCategory
                            onClick={(event) => {
                                router.push(
                                    ProductService.getUrlSearchProduct(`?categories=${elem.id}&time=${Date.now()}`)
                                );
                                closeModal(event);
                            }}
                            key={`${elem.name}-${elem.id}`}
                        >
                            <SubCategoryLabel>{elem.name}</SubCategoryLabel>
                        </SubCategory>
                    );
                }
            });
            return subcategoriesList;
        };

        const showList = () => {
            refRootDiv.current?.classList.add('show');
        };

        const hideList = () => {
            refRootDiv.current?.classList.remove('show');
        };
        useImperativeHandle(ref, () => ({
            show: showList,
            close: hideList
        }));

        return (
            <SubCategoriesList className={show ? 'show' : ''} ref={refRootDiv} style={{ left: '299px' }}>
                <SubCategories>{mountSubcategories()}</SubCategories>
            </SubCategoriesList>
        );
    }
);

export default SubcategoriesModal;
