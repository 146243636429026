import tw, { styled } from 'twin.macro';

export const Container = styled.div`
    ${tw`
        flex
        flex-row
        gap-[10px]
        items-center
        py-[10px]
        px-[15px]
        bg-white
    `}
`;

export const TextTitle = styled.div`
    ${tw`
        bg-gray
        w-[70%]
        h-[15px]
        py-[1.5px]
        rounded-5
        animate-pulse 
    `}
`;
export const Checkbox = styled.div`
    ${tw`
        rounded-5
        animate-pulse 
        bg-gray
        w-[15px]
        h-[15px]
    `}
`;
