import { useEffect } from 'react';
import { AuthService } from '../../services';
import { IS_BROWSER } from '../../services/query-client';

export const useVersion = () => {
    useEffect(() => {
        if (IS_BROWSER) {
            const newVersion = '1.18';
            const version = window.localStorage.getItem('version');
            if (version !== null && version != newVersion) {
                AuthService.logout();
                window.localStorage.setItem('version', newVersion);
            } else if (version === null) {
                window.localStorage.setItem('version', newVersion);
            }
        }
    }, []);
};
