import tw, { styled } from 'twin.macro';

export const Container = styled.div`
    ${tw`
        pt-4 
        pb-[94px] 
        font-nunito
    `}
`;

export const WelcomeHeader = styled.div`
    ${tw``}
`;

export const CurrentPageLabel = styled.span`
    ${tw`
        text-12 
        font-light 
        text-[black]
    `}
`;

export const ExitButton = styled.div`
    ${tw`
        flex 
        items-center 
        justify-center 
        px-[66px] 
        py-[17px] 
        border-[0.5px] 
        border-[#323232] 
        cursor-pointer
        font-bold 
        text-16
        rounded-full
    `}
`;

export const MainMenuMyAccount = styled.div`
    ${tw`
        flex
        flex-col
        w-full 
        gap-4 
        pt-6
        lg:pt-12 
        lg:flex-row
    `}
`;
