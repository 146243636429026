import tw, { styled } from 'twin.macro';
import { IConfigBar, ITextProps } from './types';

export const Container = styled.div`
    ${tw`
        max-w-[364px]
        flex
        w-full
        rounded-[15px]
        h-[50px]
        bg-white
        items-center
        px-[3px]
        shadow-md
        gap-[18px]
        relative
    `}
`;

export const Bar = styled.div<IConfigBar>`
    ${tw`
        absolute
        rounded-15
        w-[100px]
        h-[44px]
        bg-primary
        transition-all
        duration-300
    `}
    transform: translateX(${(props) => props.x}px);
    width: ${(props) => props.width}px;
`;
export const Button = styled.div`
    ${tw`
        flex    
        px-[3px]
        h-[44px]
        justify-center
        items-center
        flex-1
        cursor-pointer
        font-medium
        text-[13px]
        transition-all
        relative

    `}
`;
export const TextDelivery = styled.span<ITextProps>`
    ${({ isDelivery }) => (isDelivery ? tw`text-white` : tw`text-gray`)}
    ${tw`
        font-medium
        text-[20px]
        transition-all
        duration-300
        text-center
    `}
`;
export const TextPickUp = styled.span<ITextProps>`
    ${({ isDelivery }) => (isDelivery ? tw`text-gray` : tw`text-white`)}
    ${tw`
        duration-300
        text-center
    `}
`;
