import { AxiosRequestConfig } from 'axios';
import { Order } from '../services/order/types';
import {
    CreditCardValues,
    GetInstallmentsParams,
    InstallmentsResponse,
    IPaymentSelectedView,
    PaymentInterface,
    PaymentMethod,
    PaymentSelected,
    PaymentTypes
} from '../services/payment/types';
import { currencyUnMask } from '../utils/getFormattedValue';

export class PaymentTtxPayPix extends PaymentMethod implements PaymentInterface {
    async getCheckout(valuesCheckout: CreditCardValues[]) {
        return {
            method: PaymentTypes.TTXPAY_PIX,
            additional_data: {}
        };
    }

    getFromOrder(order: Order) {
        return {
            method: PaymentTypes.TTXPAY_PIX,
            values: [
                {
                    installments: 1,
                    value: currencyUnMask(order.base_grand_total).toString(),
                    type: 'Pix',
                    number: '',
                    label: 'PIX'
                }
            ]
        };
    }
    getFromCheckout(data: PaymentSelected): IPaymentSelectedView {
        return {
            method: PaymentTypes.TTXPAY_PIX,
            values: [
                {
                    installments: 1,
                    value: '',
                    type: 'Pix',
                    number: '',
                    label: 'PIX'
                }
            ]
        };
    }

    async getInstallments(params: GetInstallmentsParams, config?: AxiosRequestConfig): Promise<InstallmentsResponse[]> {
        return [] as InstallmentsResponse[];
    }
}
