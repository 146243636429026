import { useUserInfo } from '../../../hooks/use-user-info';

interface IColumnUserInfoProps {
    column: string;
}
function ColumnUserInfo({ column }: IColumnUserInfoProps) {
    const { data: userData } = useUserInfo();

    const columnValue = getNestedValue(userData, column);

    return <span>{columnValue}</span>;
}

function getNestedValue(obj: any, path: string) {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
}
export default ColumnUserInfo;
