import { motion } from 'framer-motion';
import tw, { styled } from 'twin.macro';

import MagnifyingGlassIcon from '../../atoms/icons/Magnifying-glass';

export const Container = styled(motion.div)`
    ${tw`
  w-full
  flex
  p-2
  items-center
  cursor-pointer
  rounded-10
  flex-row
  justify-between
  bg-[#F3f3f3]
`}
`;

export const SearchProductInput = styled.input`
    ${tw`
  flex-1
  font-[#838383]
  outline-none
  pl-[5px]
  bg-[#FFF0]
`}
`;

export const SearchIcon = styled(MagnifyingGlassIcon)`
    ${tw`
  w-6
  h-6
  cursor-pointer
  mr-[5px]
  fill-darkGray
`}
`;
