import { FC } from 'react';
import tw, { styled } from 'twin.macro';

const Container = styled.span`
    ${tw`
        font-nunito
        text-25
        font-normal
        text-black
        leading-[24.55px]
    `}
`;

const TextNunitoRegular18: FC<any> = ({ children, ...props }) => {
    return <Container {...props}>{children}</Container>;
};

export default TextNunitoRegular18;
