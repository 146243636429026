import { FC, HTMLAttributes } from 'react';
import { Container, Pulse } from './styles';
import { DotState } from './types';

export interface IPulseDotProps extends Partial<HTMLAttributes<HTMLDivElement>> {
    state?: DotState;
}

const PulseDot: FC<IPulseDotProps> = ({ state, ...props }) => (
    <Container active={state === 'CURRENT'} {...props}>
        <Pulse state={state} />
    </Container>
);

export default PulseDot;
