import { FC } from 'react';
import tw, { styled } from 'twin.macro';

const Container = styled.span`
    ${tw`
        font-nunito
        text-22
        font-bold
        text-black
        leading-[30.01px]
    `}
`;

const TextNunitoBold22: FC<any> = ({ children, ...props }) => {
    return <Container {...props}>{children}</Container>;
};

export default TextNunitoBold22;
