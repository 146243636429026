import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { CategoryService } from '../../services';
import { Categories } from '../../services/category/types';
import { CATEGORIES } from '../../utils/react-query-keys';

export const useCategories = (params?: UseQueryOptions<Categories[] | undefined>) => {
    return useQuery<Categories[] | undefined>([CATEGORIES], () => CategoryService.initCategory(), {
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        ...params
    });
};
