import tw, { styled } from 'twin.macro';
import IconMenu from '../../atoms/icons/Menu';

export const LogoCategoriesContainer = styled.div`
    ${tw`
  flex
  items-center
  ml-5
  mr-0
  flex-row-reverse
  justify-end
  gap-5
  // lg:h-[150px]
  lg:m-0
  lg:flex-col
  lg:items-center
  lg:justify-between
  lg:pt-[7px]
`}
`;

export const Logo = styled.img`
    ${tw`
  w-[75px]
  ml-7
  mb-2
  object-contain
  cursor-pointer

  lg:w-full
  lg:h-[80px]
  lg:m-0
`}
`;

export const CategoriesButton = styled.div`
    ${tw`
  w-9
  h-9
  bg-white
  flex
  items-center
  rounded-xl
  justify-evenly
  cursor-pointer

  lg:w-[180px]
  lg:h-12
  lg:mb-0
  lg:bg-primary
  lg:hover:rounded-b-[0px]  
`}
`;

export const CategoriesIcon = styled(IconMenu)`
    ${tw`
  w-12
  fill-primary
  h-full

  md:flex
  
  lg:fill-white
  lg:w-6
  lg:h-6
`}
`;

export const CategoriesLabel = styled.span`
    ${tw`
  text-white
  hidden
  text-15
  font-nunito
  font-black

  lg:flex
`}
`;
