import tw, { styled } from 'twin.macro';

export const Container = styled.div`
    ${tw`
        p-4
        w-full
        divide-y-[0.5px]
        divide-[#D8D8D8]
    `}
`;
