import { PropsWithChildren } from 'react';

interface InstallmentButtonProps extends PropsWithChildren {
    selected: boolean;
    onClick: () => void;
}

export default function InstallmentButton({ children, selected, onClick }: InstallmentButtonProps) {
    const classRoot = selected ? 'border-primary text-primary' : 'border-[#E6E6E6] text-black';
    return (
        <button
            type="button"
            className={`${classRoot} border-[0.5px] text-xs px-3 flex justify-center items-center min-w-[80px] h-6 rounded-16 transition-colors`}
            onClick={onClick}
        >
            {children}
        </button>
    );
}
