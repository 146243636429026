export enum ConditionType {
    LTEQ = 'lteq',
    LTE = 'lte',
    LT = 'lt',
    GTEQ = 'gteq',
    GTE = 'gte',
    GT = 'gt',
    EQ = 'eq',
    IN = 'in',
    LIKE = 'like'
}
export interface Order {
    field: string;
    direction: 'desc' | 'asc';
}

export interface FilterGroup {
    filters: { condition_type: ConditionType; field: string; value: string | number }[];
}

export interface SearchCriteria {
    page_size: number;
    current_page: number;
    filter_groups?: FilterGroup[];
    sortOrders: Order[];
}

export interface PaginationList<T> {
    items: T[];
    total_count?: number;
    search_criteria?: SearchCriteria;
    aggregations?: any;
}

export interface ISearch {
    searchCriteria: {
        pageSize?: number;
        currentPage?: number;
        filter_groups?: FilterGroup[];
        sortOrders?: Order[];
    };
    fields?: string;
}
