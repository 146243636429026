import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../tailwind.config.js';
export const twFullConfig: any = resolveConfig(tailwindConfig);
import create from '@kodingdotninja/use-tailwind-breakpoint';

export const twColors = twFullConfig.theme?.colors as Record<string, string>;

const getBreakpointValue = (value: string): number => {
    if (!twFullConfig.theme?.screens) return 22;

    return twFullConfig.theme.screens[value].slice(0, twFullConfig.theme.screens[value].indexOf('px'));
};

export const getCurrentBreakpoint = (): string => {
    let currentBreakpoint: string = 'sm';
    if (typeof window == 'undefined') return currentBreakpoint;
    if (!twFullConfig?.theme?.screens) return '';

    let biggestBreakpointValue = 0;
    for (const breakpoint of Object.keys(twFullConfig.theme.screens)) {
        const breakpointValue = getBreakpointValue(breakpoint);
        if (breakpointValue > biggestBreakpointValue && window.innerWidth >= breakpointValue) {
            biggestBreakpointValue = breakpointValue;
            currentBreakpoint = breakpoint;
        }
    }

    return currentBreakpoint;
};

export const getIsBreakPoint = (breakpoint: string) => {
    if (!twFullConfig?.theme?.screens || typeof window == 'undefined') return false;
    for (const key of Object.keys(twFullConfig.theme.screens).reverse()) {
        const breakpointValue = twFullConfig.theme.screens[key];

        const query = window.matchMedia(`(min-width: ${breakpointValue})`);
        if (query.matches) {
            return true;
        }
        if (key === breakpoint) return false;
    }

    return false;
};

export const { useBreakpoint, useBreakpointValue, useBreakpointEffect } = create(twFullConfig.theme.screens);
