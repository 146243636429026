import { SVGProps } from 'react';

const IconPerson = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M5 21.3689C5 18.7373 7 16.7373 9.4 16.7373H14.5C17 16.7373 18.9 18.8426 18.9 21.3689"
                strokeWidth="1.4824"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.0005 5.78953C16.7005 7.579 16.7005 10.4211 15.0005 12.1053C13.3005 13.7895 10.6005 13.8948 9.00048 12.1053C7.40048 10.3158 7.30048 7.47374 9.00048 5.78953C10.7005 4.10531 13.3005 4.10531 15.0005 5.78953"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default IconPerson;
