import Image from 'next/image';
import { Column, Menu, PaymentList, Title } from '../styles';

interface IPaymentsProps {
    title?: boolean;
}
function Payments({ title = false }: IPaymentsProps) {
    return (
        <Column>
            {title && <Title>Formas de Pagamento</Title>}
            <PaymentList>
                <Image src="/images/brands-cards/visa.png" alt="Visa" width={43} height={27} />
                <Image src="/images/brands-cards/mastercard.png" alt="MasterCard" width={43} height={27} />

                <Image src="/images/brands-cards/pix.png" alt="PIX" width={43} height={27} />
                <Image src="/images/brands-cards/american.png" alt="AmericanExpress" width={43} height={27} />

                <Image src="/images/brands-cards/boleto.png" alt="Boleto" width={43} height={27} />
            </PaymentList>
        </Column>
    );
}

export default Payments;
