import axios from 'axios';
import qs from 'qs';

export interface TokenCardResponse {
    card: {
        brand: string;
        exp_month: string;
        exp_year: string;
        first_six_digits: string;
        holder_document: string;
        holder_name: string;
        last_four_digits: string;
    };
    created_at: string;
    expires_at: string;
    id: string;
    type: string;
}

export interface TokenCard {
    type: string;
    card: {
        holder_name: string;
        number: string | number;
        exp_month: string | number;
        exp_year: string | number;
        cvv: string | number;
        holder_document: string | number;
    };
}

const tokenCard = async (tokenCard: TokenCard): Promise<TokenCardResponse> => {
    const { data } = await axios.post(
        'https://api.mundipagg.com/core/v1/tokens?appId=pk_test_va517DyIGtmRkzEY',
        tokenCard,
        {
            paramsSerializer: (p) => {
                return qs.stringify(p, { arrayFormat: 'indices' });
            }
        }
    );
    return data;
};

export default {
    tokenCard
};
