import tw, { styled } from 'twin.macro';
import TextLatoRegular13 from '../text/Lator-regular-13';

export const Container = styled.div`
    ${tw`
        text-[#EF4123]
        mt-[3px]
        ml-[3px]
        text-14
        font-normal
        text-left
    `}
`;
