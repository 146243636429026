import { useQuery } from '@tanstack/react-query';
import { BannerService } from '../../../services';
import { NEXT_PUBLIC_HOME_BANNER_PARTNERS } from '../../../utils/envs';
import { BANNER } from '../../../utils/react-query-keys';
import Carousel from '../Carousel';
import { Container, Title } from './styles';
import useOnClickBanner from '../../../hooks/use-on-click-banner';

const PartnersCarouselSlide = () => {
    const { data: images } = useQuery(
        [BANNER, NEXT_PUBLIC_HOME_BANNER_PARTNERS],
        () => BannerService.getById(NEXT_PUBLIC_HOME_BANNER_PARTNERS),
        { refetchOnWindowFocus: false, refetchOnMount: false }
    );

    const onClickBanner = useOnClickBanner();

    return (
        <Container>
            <Title>Nossos Parceiros</Title>
            <Carousel marginItem={52} arrows={true}>
                {images?.map((banner, i) => {
                    // eslint-disable-next-line
                    return (
                        <img
                            src={banner.image}
                            onClick={() => onClickBanner(banner)}
                            className="cursor-pointer"
                            key={i}
                            alt={`partner-${i}`}
                        />
                    );
                })}
            </Carousel>
        </Container>
    );
};

export default PartnersCarouselSlide;
