import tw, { styled } from 'twin.macro';

import { IOrderDetailsSectionHeaderContainerProps } from './types';

export const Container = styled.header<IOrderDetailsSectionHeaderContainerProps>`
  ${tw`
		w-full
		flex
		justify-between
		mb-[15px]
		border-b-[1px]
		border-gray
		pb-[5px]
	`}  
`;
