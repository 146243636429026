import { AxiosRequestConfig } from 'axios';
import { CardResponse } from '../card/types';
import { Order } from '../order/types';

export enum PaymentTypes {
    MUNDIPAGG_CREDIT_CARD = 'mundipagg_creditcard',
    MUNDIPAGG_TWO_CREDIT_CARD = 'mundipagg_two_creditcard',
    MUNDIPAGG_BILLET = 'mundipagg_billet',
    PAGSEGURO_CREDIT_CARD = 'rm_pagseguro_cc',
    TTXPAY_PIX = 'ttxpay_pix',
    MONEY = 'checkmo',
    TTXPAY_CREDIT_CARD = 'ttxpay_cc',
    MUNDIPAGG_DEBIT = 'mundipagg_debit',
    CASHNODELIVERY = 'cashondelivery',
    PAGARME_BILLET = 'pagarme_billet',
    PAGARME_PIX = 'pagarme_pix',
    PAGARME_CREDIT_CARD = 'pagarme_creditcard',
    MERCPAGO_ADBPAYMENT = 'mercadopago_adbpayment_checkout_credits',
    PAGBANK_MAGENTO = 'pagbank_paymentmagento',
    MERCADOPAGO_PAY = 'mercadopago_adbpayment'
}
export const PAYMENTS_PIX = [PaymentTypes.TTXPAY_PIX];
export interface CreditCardValues {
    card_hash?: undefined | string;
    useSave: undefined | CardResponse;
    value?: undefined | string;
    number: undefined | string;
    valid: undefined | string;
    birthday?: undefined | string;
    cvv: undefined | string;
    name: undefined | string;
    cpf: undefined | string;
    type: undefined | string;
    installments?: InstallmentsResponse;
    save: undefined | boolean;
}

export interface PaymentSelected {
    paymentMethod: PaymentTypes;
    values?: CreditCardValues[];
}
export interface PaymentMethod {
    title: string;
    code: PaymentTypes;
}

export interface PaymentMethods {
    payment_methods: PaymentMethod[];
}

export interface GetInstallmentsParams {
    type: PaymentTypes;
    value?: string;
    creditCardType?: string;
    creditCardNumber?: string;
    creditCardCvv?: string;
    creditCardValid?: string;
}

export type InstallmentsResponse = {
    quantity: number;
    totalWithTax: number;
    installmentTotal: number;
    label: string;
    token?: string;
    tokenCardFront?: string;
};

export interface PaymentCheckout {
    flagName: string;
    installments: string;
    numberCard: string;
    method: string;
}

export class PaymentMethod {}

export interface IPaymentSelectedView {
    method: PaymentTypes;
    values: {
        installments?: number;
        value?: string;
        type?: string;
        number?: string;
        label: string;
    }[];
}

export interface PaymentInterface {
    getCheckout: (values: CreditCardValues[]) => Promise<{
        method: string;
        additional_data: any;
    }>;
    getFromOrder: (order: Order) => IPaymentSelectedView;
    getFromCheckout: (order: PaymentSelected) => IPaymentSelectedView;
    getInstallments: (params: GetInstallmentsParams, config?: AxiosRequestConfig) => Promise<InstallmentsResponse[]>;
}

export interface IPaymentInformation {
    address: {
        region_code: string;
        country_id: string;
        postcode: string;
        region_id: number;
    };
}

export interface IAllPaymentMethods {
    payment_methods: PaymentMethod[];
    totals: any;
}
