import { Address } from '../address/types';
import { CreditCardValues, PaymentMethod, PaymentTypes } from '../payment/types';
import { EProductTypeUnit, IProduct } from '../product/types';

export type CartId = string;

export interface Cart {
    items: CartItemWithElastic[];
    id: CartId;
    totalItems: number;
    info: Partial<InfoCart>;
}

export interface DeliveryDate {
    mp_delivery_date: string;
    mp_delivery_time: string;
    mp_delivery_comment: string;
}
export type IMeasureTypeTxt = 'unit' | 'weight';
export type IMeasureType = EProductTypeUnit;
export enum EMeasureType {
    UNIT = '1',
    WEIGHT = '2'
}
export interface CartItemProductOption {
    extension_attributes: {
        configurable_item_options: {
            option_id: number;
            option_value: number;
        }[];
    };
}
export interface IRefetchCartParams {
    updateId?: boolean;
    forceNewId?: boolean;
}
export interface CartItemProductExtensionAttributes {
    unit_weight: string;
    measure_type: IMeasureType;
    measure_type_txt: IMeasureTypeTxt;
}
export interface CartItem {
    item_id: number;
    sku: string;
    qty: number;
    name: string;
    price: number;
    quantity: number;
    available: boolean;
    product_type: string;
    quote_id: string;
    seller_id: number;
    seller_name: string;
    product_option?: CartItemProductOption;
    extension_attributes?: CartItemProductExtensionAttributes;
}
export interface CartItemWithElastic extends CartItem {
    elastic: IProduct;
}

export interface CartItemWithElasticItems {
    item_id: number;
    sku: string;
    qty: number;
    name: string;
    price: number;
    quantity: number;
    available: boolean;
    product_type: string;
    quote_id: string;
    seller_id: number;
    seller_name: string;
    product_option?: CartItemProductOption;
    extension_attributes?: CartItemProductExtensionAttributes;
    elastic: IProduct;
}

export interface CartShippingMethod {
    carrier_code: string;
    method_code: string;
    carrier_title: string;
    method_title: string;
    amount: number;
    base_amount: number;
    available: boolean;
    error_message: string;
    price_excl_tax: number;
    price_incl_tax: number;
}

export interface InfoCartPaymentMethods {
    item_id: number;
    price: number;
    qty: number;
    name: string;
}
export interface CreditCard {
    number: string;
    valid: string;
    cvv: string;
    name: string;
    cpf: string;
    type: string;
}
export interface CheckoutParams {
    address: Address;
    card: CreditCardValues[];
    paymentMethod: PaymentTypes;
}
export interface InfoCart {
    grand_total: number;
    subtotal: number;
    discount_amount: number;
    shipping_amount: number;
    items_qty: number;
    coupon_code: string;
    tax_amount?: number;
    shipping_incl_tax?: number;
    base_currency_code?: string;
    base_grand_total?: number;
    base_shipping_amount?: number;
    total_segments: {
        code: string;
        title: string;
        value: number;
    }[];
}

export interface ShippingInformationResponse {
    totals: InfoCart;
    payment_methods: PaymentMethod[];
}

export type SubscribeFn = (cart: Cart) => void;
type IFn = () => Promise<void>;

export type CreateTimeOut = (fn: IFn, ms: number, toastId?: number | string, isAdd?: boolean) => ResponseTimeOut;
export interface ResponseTimeOut {
    fn?: () => Promise<unknown>;
    timeout?: NodeJS.Timeout;
    toastId?: number | string;
    isAdd?: boolean;
}

export interface SetAddressParams {
    address: Address;
    shippingMethod: undefined | CartShippingMethod | null;
}

export interface BuyAgainParams {
    qty: number;
    sku: string;
    name: string;
    options?: CartItemProductOption;
}

export interface UpdateItemParams {
    itemId: number;
    qty: number;
    option?: CartItemProductOption;
    pageOrigin?: string;
    price?: number;
    isAdd?: boolean;
    name?: string;
    wait?: boolean;
    fromCart?: boolean;
    newQty?: number;
}

export interface IToastMessage {
    isAdd?: boolean;
    isAddTimeout?: boolean;
    product: CartItem | { name: string };
    toastId?: number | string;
}

export interface IBuyAgain {
    product: IProduct;
    qty: number;
}

interface CreditCardFormValues {
    number: string;
    valid: string;
    cvv: string;
    cpf: string;
    birthday: string;
    // installments?: any;
}

export interface ICreditCardCheckout {
    code: PaymentTypes;
    paymentValues: ICCardValues;
}

export interface IBlockPurchaseCEP {
    data: {
        cep: string;
    };
}

export interface IBlockPurchaseCEPRes {
    store_id: string;
    code: string;
    website_id: string;
    group_id: string;
    name: string;
    sort_order: string;
    is_active: string;
}

export interface ICCardValues {
    number: string;
    valid: string;
    cvv: string;
    name: string;
    brand: string;
    cpf: string;
    installments: any;
}

interface PaymentOption {
    id: number;
    interest: number;
    total_with_tax: number;
    label: string;
}

export interface PixPaymentRes {
    qr_code: string;
    qr_code_url: string;
    expires_at: string;
    additional_information: string | null;
    end_to_end_id: string | null;
    payer: string | null;
    gateway_id: string;
    amount: number;
    status: string;
    success: boolean;
    created_at: string;
    updated_at: string;
    attempt_count: number | null;
    max_attempts: number | null;
    splits: unknown | null;
    next_attempt: string | null;
    transaction_type: string;
    id: string;
    gateway_response: {
        code: string | null;
        errors: unknown | null;
    };
    antifraud_response: {
        status: string | null;
        return_code: string | null;
        return_message: string | null;
        provider_name: string | null;
        score: number | null;
    };
    metadata: unknown[];
    split: unknown | null;
    interest: number | null;
    fine: number | null;
    max_days_to_pay_past_due: number | null;
}

export interface PixPaymentInfo extends PixPaymentRes {
    qrCodeImageSrc: string;
}
