import { UseQueryOptions } from '@tanstack/react-query';
import { ElasticService, ProductService, WishlistService } from '../../services';
import { IProduct } from '../../services/product/types';
import { Wishlist } from '../../services/wishlist/types';
import { WISHLIST } from '../../utils/react-query-keys';
import { useQueryCancelToken } from '../use-query-cancel-token';

export interface IWishlistWithProducts extends Wishlist {
    products: {
        product: IProduct;
        qty: number;
    }[];
}

export const useWishlistWithProducts = (wishlistId: string, params?: UseQueryOptions<IWishlistWithProducts>) => {
    return useQueryCancelToken<IWishlistWithProducts>(
        [WISHLIST, wishlistId],
        async (paramsAxios) => {
            const lists = await WishlistService.getAll();
            const list = lists?.find((elem) => elem.wishlist_id === wishlistId) as Wishlist;
            const productsIds = list?.item.map((item) => item.sku);

            const products = await ElasticService.getProducts({
                filter: {
                    bool: {
                        must: [
                            {
                                query_string: {
                                    query: productsIds.join(' OR '),
                                    fields: ['sku']
                                }
                            }
                        ]
                    }
                },
                limit: productsIds.length
            });

            const productsWithQty = products.items.map((product) => {
                const item = list?.item.find((elem) => elem.product_id == product.id.toString());

                return {
                    product,
                    qty: item?.qty ? parseInt(item?.qty) : 1
                };
            });

            return {
                ...list,
                products: productsWithQty
            };
        },
        {
            refetchOnMount: true,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
            ...params
        }
    );
};
