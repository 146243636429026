import { useMutation } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { HTMLAttributes, useEffect, useState } from 'react';
import { useCart } from '../../../../../hooks';
import { CartService } from '../../../../../services';
import { IProduct } from '../../../../../services/product/types';
import { Button } from '../../../../molecules';
import { IButtonVariant } from '../../../../molecules/Button/types';
import { useQtyProduct } from '../../hooks/useQtyProduct';
import { Container } from './styles';
import { add } from 'date-fns';
import { useEstablishment } from '../../../../../hooks/use-establishment';
import Alert from '../../../../molecules/Alert';
import { AnimatePresence } from 'framer-motion';
import { useIsCnpjAvailable } from '../../../../../hooks/use-is-cnpj-available';

interface IButtonsProps extends HTMLAttributes<HTMLDivElement> {
    data: IProduct;
}
function Buttons({ data, ...props }: IButtonsProps) {
    const [showErrorAttrs, setShowErrorAttrs] = useState(false);
    const { qty, attrs } = useQtyProduct();
    const { data: cart } = useCart();
    const router = useRouter();

    const [isCnpjAvailable, setIsCnpjAvailable] = useState(true);

    useEffect(() => {
        const checkCnpj = async () => {
            const result = await useIsCnpjAvailable();

            setIsCnpjAvailable(result);
        };

        checkCnpj();
    }, []);

    const { data: establishment } = useEstablishment();

    const exists = cart && CartService.findProduct(cart?.items, undefined, data.sku);

    function checkAttributes(fn: any) {
        if (data.configurable_options && Object.values(data?.configurable_options).length !== attrs.length) {
            setShowErrorAttrs(true);
            return false;
        }
        setShowErrorAttrs(false);

        return fn();
    }

    function addToCart() {
        if (exists) {
            return CartService.updateItem({
                itemId: exists.item_id,
                qty: qty + exists.qty,
                name: data.name,
                isAdd: qty > 0,
                wait: true,
                price: data.price,
                newQty: qty
            });
        } else {
            let options = undefined;
            if (data.configurable_options) {
                options = {
                    extension_attributes: {
                        configurable_item_options: attrs
                    }
                };
            }
            return CartService.add(data.sku, qty, options);
        }
    }

    const { mutate: onClickAddCart, isLoading: isLoadingAddCart } = useMutation(async () => addToCart());
    const { mutate: onClickBuyNow, isLoading: isLoadingBuyNow } = useMutation(async () => addToCart(), {
        onSuccess: () => router.push('/checkout')
    });
    const isDisabled = establishment?.list.length == 0 || !isCnpjAvailable;

    return (
        <div>
            <AnimatePresence>
                {showErrorAttrs && (
                    <div className="mt-4">
                        <Alert>Selecione todas as opções primero</Alert>
                    </div>
                )}
                {!isCnpjAvailable && (
                    <div className="">
                        <Alert>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: 'Estamos em fase de construção, por enquanto não atendemos CNPJ.'
                                }}
                            ></div>
                        </Alert>
                    </div>
                )}
            </AnimatePresence>

            <Container {...props}>
                <Button
                    className="flex-1"
                    onClick={() => checkAttributes(onClickBuyNow)}
                    isLoading={isLoadingBuyNow}
                    disabled={isDisabled}
                    text="Comprar"
                    variant={IButtonVariant.PRIMARY_MEDIUM}
                />
                <Button
                    className="flex-1 max-h-[56px]"
                    text="Adicionar ao Carrinho"
                    disabled={isDisabled}
                    variant={IButtonVariant.BLACK_MEDIUM_OUTLINE}
                    onClick={() => checkAttributes(onClickAddCart)}
                    isLoading={isLoadingAddCart}
                />
            </Container>
        </div>
    );
}

export default Buttons;
