import { motion } from 'framer-motion';
import tw, { styled } from 'twin.macro';

export const Container = styled(motion.div)`
    ${tw`
		flex
		flex-col
		bg-primary
        md:hidden
	`}
`;

export const ImageContainer = styled.div`
    ${tw`
        flex
        justify-center
        items-center
        pt-8
        pb-6
    `}
`;
