import tw, { styled } from 'twin.macro';
import { motion } from 'framer-motion';
import { IconCartOutline } from '../../atoms';

export const Container = styled(motion.div)`
    ${tw`
        flex
        flex-col
        flex-1
        gap-[15px]
    `}
`;

export const IconEmpty = styled(IconCartOutline)`
    ${tw`
        w-[73px]
        h-[73px]
        stroke-gray

    `}
`;
