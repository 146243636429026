import { motion } from 'framer-motion';
import tw, { styled } from 'twin.macro';
import { IconClose, TextNunitoBold16, TextNunitoRegular14 } from '../../../atoms';

export const Container = styled(motion.div).attrs(() => ({
    variants: {
        hidden: { opacity: 0 },
        visible: { opacity: 1 }
    }
}))`
    ${tw`
        bg-white
        p-4
        flex
        flex-col
        absolute
        top-3
        left-0
        [box-shadow: 0px 7px 22.6px 0px rgba(0, 0, 0, 0.25)]
        rounded-8
        border-[0.5px]
        border-[#CBCBCB]
        w-full
        max-w-[424px]
        z-50
    `}
`;

export const Title = styled(TextNunitoBold16)`
    ${tw`
       text-[#9FBE43]
       h-8
    `}
`;

export const Description = styled(TextNunitoRegular14)`
    ${tw`
        mb-4
    `}
    strong {
        ${tw`
        text-primary
    `}
    }
`;

export const Close = styled(IconClose)`
    ${tw`
        w-6
        h-6
        stroke-[#323232]
        absolute
        top-4
        right-4
        cursor-pointer
    `}
`;
