import tw, { styled } from 'twin.macro';

import { IconEdit, IconShipping } from '../../atoms';
import { TextLatoRegular18 } from '../../atoms';
import { Button } from '../../molecules';

export const Container = styled.article`
    ${tw`
    w-full
		flex
		flex-col
  `}
`;

export const Title = styled(TextLatoRegular18)`
    ${tw`
		mb-[30px]
		uppercase
  `}
`;

export const HeaderButton = styled(Button)`
    ${tw`
		h-[24px]
		rounded-[8px]
	`}

    & span {
        font-size: 13px;
    }
`;

export const ShippingActionBtn = styled(IconShipping)`
    ${tw`
		w-5
		h-5
		text-primary
	`}
`;

export const EditDateBtn = styled(IconEdit)`
    ${tw`
		w-5
		h-5
		text-primary
    fill-primary
		cursor-pointer
	`}
`;

export const Columns = styled.div`
    ${tw`
    w-full
    grid
    grid-cols-[1fr]
    
    lg:gap-[150px]
    lg:grid-cols-[1fr 1fr]
  `}
`;

export const Column = styled.div`
    ${tw`
    w-full
  `}
`;
