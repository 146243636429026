import { FC, SVGProps } from 'react';

const IconEstablishment: FC<SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M16 14.175a.832.832 0 01-.237.583.803.803 0 01-.573.242H.81a.803.803 0 01-.573-.242.832.832 0 010-1.165.803.803 0 01.573-.242h.935v-5.31l.082.01a3.164 3.164 0 002.255-.627 3.154 3.154 0 003.187.378v5.55h5.366V7.872c.324.101.661.152 1 .151.087 0 .167 0 .26-.01.123-.01.245-.027.367-.05v5.387h.935c.213.002.418.09.568.244.15.154.235.363.235.58zm-5.132-3.664a.54.54 0 00-.275.075L8.76 11.665a.552.552 0 00-.272.34.57.57 0 00.198.603.544.544 0 00.624.025l1.833-1.078a.568.568 0 00.06-.93.544.544 0 00-.335-.116v.002zm.477-2.017a.557.557 0 00-.334-.261.54.54 0 00-.418.056L8.758 9.366a.552.552 0 00-.272.34.57.57 0 00.198.603.546.546 0 00.624.026l1.834-1.08a.569.569 0 00.205-.762h-.002zm.368-2.614a.135.135 0 01.119.068c.19.311.454.568.769.747.315.178.669.271 1.029.27h.167c1.371-.106 2.188-1.62 1.625-2.895L13.368.419A.807.807 0 0012.666 0h-9.53a.807.807 0 00-.71.419L.377 4.065c-.551 1.252.22 2.756 1.562 2.89a2.084 2.084 0 001.257-.26 2.13 2.13 0 00.77-.746.138.138 0 01.117-.068.135.135 0 01.118.068c.188.31.45.567.763.745a2.085 2.085 0 002.047.014c.314-.174.58-.427.772-.735a.138.138 0 01.116-.066.135.135 0 01.117.066c.192.308.457.56.772.735a2.084 2.084 0 002.047-.012 2.13 2.13 0 00.763-.745.137.137 0 01.116-.071z" />
        </svg>
    );
};

export default IconEstablishment;
