import { FC } from 'react';
import { Container } from './styles';

const item = {
    hidden: { opacity: 0 },
    show: { opacity: 1 }
};

const TableRow: FC<any> = ({ children }) => {
    return (
        <Container initial="hidden" animate="show" variants={item}>
            {children}
        </Container>
    );
};

export default TableRow;
