import { MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
    ApplyFiltersButton,
    Border,
    ContainerModal,
    Content,
    FiltersButton,
    FiltersButtonLabel,
    FiltersHeaderLabel,
    FiltersListHeader,
    variants
} from './styles';

import { AnimatePresence } from 'framer-motion';
import { useRouter } from 'next/router';
import { useBreakpointTailwind } from '../../../hooks';
import { IconClose, IconFilter } from '../../atoms';
import ModalPortal from '../../atoms/Modal-portal';
import { SearchFiltersCategory, SearchFiltersPrice } from '../../molecules';
import { IFilters, ISearchFiltersProps } from './types';
import SearchFiltersBrand from '../../molecules/Search-filters-brand';
import SearchFiltersFlavor from '../../molecules/Search-filters-flavor';

const SearchFilters = ({ aggregations }: ISearchFiltersProps) => {
    const [isOpen, setIsOpen] = useState(true);
    const [fixed, setFixed] = useState(false);
    const [positionTopFilters, setPositionTopFilters] = useState<number | null>(null);
    const [filters, setFilters] = useState<IFilters>({
        categories: [],
        price: [0, 0],
        brands: [],
        flavors: []
    });
    const rootRef = useRef<HTMLDivElement>(null);
    const router = useRouter();
    const isMobile = !useBreakpointTailwind(`lg`);

    useEffect(() => {
        if (positionTopFilters === null && rootRef.current) {
            const { y } = rootRef.current?.getBoundingClientRect();
            setPositionTopFilters(y + window.pageYOffset);
        }
    }, [positionTopFilters, rootRef.current]);

    useEffect(() => {
        const handleScroll = (event: any) => {
            var top = (window.pageYOffset || event.scrollTop) - (event.clientTop || 0) || 0;
            setFixed(positionTopFilters !== null && top > positionTopFilters && !isMobile);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isMobile, positionTopFilters]);

    const categoriesId = useMemo(() => {
        if (!router.query.categories) return [];

        return typeof router.query.categories === 'string'
            ? [parseInt(router.query.categories)]
            : (router.query.categories as string[]).map((category) => parseInt(category));
    }, [router.query.categories]);

    const brandsId = useMemo(() => {
        if (!router.query.brands) return [];

        return typeof router.query.brands === 'string'
            ? [parseInt(router.query.brands)]
            : (router.query.brands as string[]).map((brands) => parseInt(brands));
    }, [router.query.brands]);

    const flavorsId = useMemo(() => {
        if (!router.query.flavors) return [];

        return typeof router.query.flavors === 'string'
            ? [parseInt(router.query.flavors)]
            : (router.query.flavors as string[]).map((flavors) => parseInt(flavors));
    }, [router.query.flavors]);

    useEffect(() => {
        if (aggregations) {
            setFilters({
                categories: categoriesId || [],
                price: (router?.query?.price as unknown as number[]) || [],
                brands: brandsId || [],
                flavors: flavorsId || []
            });
        }
    }, [aggregations, router.query, categoriesId, brandsId]);

    const animate = isOpen ? 'open' : 'close';

    const handleChangePrice = useCallback((value: number[]) => {
        setFilters((old) => ({ ...old, price: value }));
    }, []);

    const handleChangeCategories = useCallback((value: number[]) => {
        setFilters((old) => ({ ...old, categories: value }));
    }, []);

    const handleChangeBrands = useCallback((value: number[]) => {
        setFilters((old) => ({ ...old, brands: value }));
    }, []);

    const handleChangeFlavors = useCallback((value: number[]) => {
        setFilters((old) => ({ ...old, flavors: value }));
    }, []);

    useEffect(() => {
        setIsOpen(!isMobile);
    }, [isMobile]);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleApplyFiltersButton = (e: MouseEvent<HTMLButtonElement>) => {
        router.push(
            {
                pathname: router.pathname,
                query: {
                    ...router.query,
                    ...filters
                }
            },
            undefined,
            { scroll: false }
        );
        e.stopPropagation();
        if (isMobile) {
            setIsOpen(false);
        }
    };

    const renderContent = useMemo(() => {
        return (
            isOpen && (
                <Content
                    animate={isMobile ? 'show' : animate}
                    initial={isMobile ? 'show' : 'close'}
                    exit={isMobile ? 'show' : 'close'}
                >
                    <div className="flex flex-col">
                        {isMobile ? (
                            <FiltersListHeader>
                                <IconFilter style={{ transform: 'scale(2)' }} />
                                <FiltersHeaderLabel>Filtros</FiltersHeaderLabel>
                                <IconClose
                                    fill="white"
                                    onClick={() => setIsOpen(false)}
                                    style={{ transform: 'scale(1)' }}
                                />
                            </FiltersListHeader>
                        ) : null}
                        <SearchFiltersPrice
                            min={aggregations?.original_price_min.value || 0}
                            max={aggregations?.original_price_max.value || 0}
                            value={filters.price}
                            onChange={handleChangePrice}
                        />
                        <SearchFiltersCategory
                            values={aggregations?.category.buckets}
                            value={filters.categories}
                            onChange={handleChangeCategories}
                        />
                        {/* 
                        <SearchFiltersBrand
                            values={aggregations?.marca_nome?.buckets}
                            value={filters.brands}
                            onChange={handleChangeBrands}
                        />

                        <SearchFiltersFlavor
                            values={aggregations?.sabor_nome.buckets}
                            value={filters.flavors}
                            onChange={handleChangeFlavors}
                        /> */}
                        <ApplyFiltersButton onClick={handleApplyFiltersButton}>Aplicar Filtros</ApplyFiltersButton>
                    </div>
                </Content>
            )
        );
    }, [isOpen, isMobile, animate, filters]);

    if (!aggregations) return <div></div>;

    return (
        <>
            <div ref={rootRef} className="h-[1px] w-full"></div>

            <Border $fixed={fixed}>
                <FiltersButton onClick={handleToggle}>
                    <FiltersButtonLabel>
                        {isMobile ? <IconFilter style={{ transform: 'scale(2)', marginTop: 5 }} /> : 'FILTROS'}
                    </FiltersButtonLabel>
                </FiltersButton>
                {!isMobile && <AnimatePresence>{renderContent}</AnimatePresence>}
                {isMobile && (
                    <ModalPortal>
                        {isOpen && (
                            <ContainerModal variants={variants} onClose={() => setIsOpen(false)}>
                                {renderContent}
                            </ContainerModal>
                        )}
                    </ModalPortal>
                )}
            </Border>
        </>
    );
};

export default SearchFilters;
