import { UseInfiniteQueryResult } from '@tanstack/react-query';
import { NextRouter, useRouter } from 'next/router';
import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useCategories, useInfiniteProducts } from '../../../../../hooks';
import { UseIfiniteOrderResponse } from '../../../../../hooks/use-infinite-products';
import { SortFieldKeys } from '../../../../../services/elastic';
import { IAggsResponse } from '../../../../../services/elastic/types';
import { CategoryService } from '../../../../../services';
import { Categories } from '../../../../../services/category/types';
import { GetServerSidePropsContext } from 'next';

export function getUrlRouter(router: NextRouter | GetServerSidePropsContext) {
    if (!router.query || !router.query?.params?.length || !Array.isArray(router.query.params)) return null;

    return router.query.params.join('/');
}

interface IFilters {
    categories: number[];
    subcategoria: number[];
    marca: string[];
    cor: string[];
    tamanho: string[];
    inflamavel: string[];
    preco: number[];
    voltagem: string[];
    potencia: string[];
    tipo: string[];
    q: string;
}

interface IProductSearchFiltersContext {
    filters: IFilters;
    setFiltersMobile: (value: Partial<IFilters>) => void;
    setFiltersDesktop: (value: Partial<IFilters>) => void;
    submitFilters: (filters: IFilters) => void;
    filterGroups: any;
    dataList: UseInfiniteQueryResult<UseIfiniteOrderResponse, unknown>;
    hasFilters?: boolean;
    aggregations?: IAggsResponse;
    setSort: (value: SortFieldKeys) => void;
    sort: SortFieldKeys;
    isCategory: Categories | null;
}
export interface IAggsState {
    aggregations?: IAggsResponse;
    searchedTerm: string;
    categories?: number[];
}

export const ProductSearchFiltersContext = createContext({} as IProductSearchFiltersContext);

export const useProductSearchFilters = () => useContext(ProductSearchFiltersContext);

export const ProductSearchFiltersProvider = ({ children }: PropsWithChildren) => {
    const [aggregations, setAggregations] = useState<IAggsState>({ searchedTerm: '', aggregations: undefined });
    const [sort, setSort] = useState<SortFieldKeys>('relevance');
    const router = useRouter();
    const lastUpdateAggregations = useRef<number>(0);
    const { data: categories } = useCategories({ refetchOnMount: true });

    const urlKey = getUrlRouter(router);
    const isCategory = useMemo(() => {
        if (!categories) return null;
        return (urlKey && CategoryService.getCategoryByUrlKey(categories, urlKey)) || null;
    }, [urlKey, categories]);

    const time = useMemo(() => parseInt((router.query.time as string) || Date.now().toString()), [router.query.time]);
    const searchedTerm = isCategory ? undefined : (router.query?.q as string);
    const price = router.query.preco as string[];
    const categoriesId = useMemo(() => (isCategory ? [Number(isCategory.id)] : []), [isCategory]);

    const useQueryArray = (key: string) => {
        return useMemo<string[]>(() => {
            if (!router.query[key]) return [];
            return (typeof router.query[key] === 'string' ? [router.query[key]] : router.query[key]) as string[];
        }, [router.query[key]]);
    };

    const marca = useQueryArray('marca');
    const cor = useQueryArray('cor');
    const tamanho = useQueryArray('tamanho');
    const inflamavel = useQueryArray('inflamavel');
    const voltagem = useQueryArray('voltagem');
    const potencia = useQueryArray('potencia');
    const tipo = useQueryArray('tipo');

    const categoriesSelectedId = useMemo(() => {
        if (!router.query.subcategoria) return [];

        return typeof router.query.subcategoria === 'string'
            ? [parseInt(router.query.subcategoria)]
            : (router.query.subcategoria as string[]).map((category) => parseInt(category));
    }, [router.query.subcategoria]);

    const [filters, setFilters] = useState<IFilters>({
        categories: categoriesId || [],
        marca,
        cor,
        tamanho,
        inflamavel,
        voltagem,
        potencia,
        tipo,
        subcategoria: categoriesSelectedId || [],
        preco: (router?.query?.preco as unknown as number[]) || [0, 0],
        q: searchedTerm || ''
    });
    const timer = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        setFilters((old) => {
            return {
                ...old,
                q: searchedTerm || '',
                categories: categoriesId || [],
                subcategoria: categoriesSelectedId || []
            };
        });
    }, [searchedTerm, categoriesId, categoriesSelectedId]);

    const setFiltersDesktop = (param: Partial<IFilters>) => {
        setFilters((old) => {
            if (timer.current) clearTimeout(timer.current);

            const newFilters = { ...old, ...param };

            timer.current = setTimeout(() => {
                submitFilters(newFilters);
            }, 400);

            return newFilters;
        });
    };
    const setFiltersMobile = (param: Partial<IFilters>) => {
        setFilters((old) => {
            return { ...old, ...param };
        });
    };

    const submitFilters = (filters: IFilters) => {
        const items = { ...filters };
        if (filters.q === '') {
            //@ts-ignoregetsort
            delete items.q;
        }

        //@ts-ignore
        delete items.categories;

        router.push(
            {
                pathname: router.pathname,
                query: {
                    ...router.query,
                    ...items
                }
            },
            undefined,
            { scroll: false, shallow: true }
        );
    };

    const filterGroups = useMemo(
        () =>
            generateFilterGroups({
                categoriesId,
                searchedTerm,
                price,
                categoriesSelectedId,
                marca,
                cor,
                tamanho,
                inflamavel,
                potencia,
                voltagem,
                tipo
            }),
        [
            categoriesId,
            searchedTerm,
            price,
            categoriesSelectedId,
            marca,
            cor,
            tamanho,
            inflamavel,
            potencia,
            voltagem,
            tipo
        ]
    );

    const dataList = useInfiniteProducts(
        createProductSearchConfig({
            filterGroups,
            sortOrderField: getSort(sort, isCategory)
        })
    );

    const hasFilters = useMemo(() => {
        return !!categoriesId.length || !!searchedTerm || !!filterGroups || !!price?.length;
    }, [categoriesId, searchedTerm, filterGroups]);

    useEffect(() => {
        setAggregations((old) => {
            const newAggregations = dataList.data?.pages[0].aggregations;
            if (
                (old.searchedTerm !== searchedTerm ||
                    time != lastUpdateAggregations.current ||
                    hasFilters == false ||
                    old.categories?.toString() != categoriesId.toString()) &&
                (newAggregations || dataList.data?.pages.length === 0)
            ) {
                lastUpdateAggregations.current = time;
                setFilters((oldFilter) => {
                    return {
                        ...oldFilter,
                        preco: [
                            newAggregations?.original_price_min.value || 0,
                            newAggregations?.original_price_max.value || 0
                        ],
                        categories: categoriesId || [],
                        marca: [],
                        subcategoria: [],
                        cor: [],
                        tamanho: [],
                        inflamavel: [],
                        voltagem: [],
                        potencia: [],
                        tipo: []
                    };
                });

                return {
                    searchedTerm: searchedTerm as string,
                    aggregations: { ...newAggregations },
                    categories: categoriesId
                };
                // } else if (dataList.data?.pages[0].aggregations || dataList.data?.pages.length === 0) {
                //     return { ...old, aggregations: dataList.data.pages[0].aggregations };
            }
            return old;
        });
    }, [searchedTerm, dataList.data, time, lastUpdateAggregations.current, hasFilters]);

    return (
        <ProductSearchFiltersContext.Provider
            value={{
                filters,
                setFiltersMobile,
                submitFilters,
                setFiltersDesktop,
                filterGroups,
                dataList,
                hasFilters,
                aggregations: aggregations.aggregations,
                setSort,
                sort,
                isCategory
            }}
        >
            {children}
        </ProductSearchFiltersContext.Provider>
    );
};

export const createProductSearchConfig = ({
    filterGroups,
    sortOrderField
}: {
    filterGroups: any;
    sortOrderField: any;
}) => {
    return {
        filterGroups,
        sortOrderField,
        aggs: {
            category: {
                terms: { field: 'category_ids' }
            },
            original_price_max: {
                max: { field: 'original_price' }
            },
            original_price_min: {
                min: { field: 'original_price' }
            },
            Marca: {
                terms: {
                    field: 'attr_marcadescricao.keyword'
                }
            },
            Tamanho: {
                terms: {
                    field: 'attr_EC_TAMANHO.keyword'
                }
            },
            Cor: {
                terms: {
                    field: 'attr_EC_COR.keyword'
                }
            },
            Inflamável: {
                terms: {
                    field: 'attr_B1_XINFLAM.keyword'
                }
            },
            ['Voltagem']: {
                terms: {
                    field: 'attr_EC_TENELET.keyword'
                }
            },
            Potencia: {
                terms: {
                    field: 'attr_EC_POT.keyword'
                }
            },
            Tipo: {
                terms: {
                    field: 'attr_EC_MOD.keyword'
                }
            }
        }
    };
};

export const generateFilterGroups = ({
    searchedTerm,
    price,
    categoriesSelectedId,
    categoriesId,
    marca,
    cor,
    tamanho,
    inflamavel,
    potencia,
    voltagem,
    tipo
}: {
    searchedTerm?: string;
    price?: string[];
    categoriesSelectedId?: number[];
    categoriesId?: number[];
    marca?: string[];
    cor?: string[];
    tamanho?: string[];
    inflamavel: string[];
    potencia: string[];
    voltagem?: string[];
    tipo?: string[];
}) => {
    let newFilterGroups: any = {
        bool: {
            must: [
                // {
                //     term: {
                //         status_stock: '1'
                //     }
                // }
            ],
            filter: [],
            should: []
        }
    };
    if (searchedTerm && searchedTerm.toString().trim().length) {
        // const isSku = /^[0-9]+$/.test(searchedTerm as string);
        // if (isSku) {
        //     newFilterGroups.bool.must.push({
        //         multi_match: {
        //             query: searchedTerm,
        //             fields: ['sku']
        //         }
        //     });
        // } else {
        newFilterGroups.bool.must.push({
            multi_match: {
                query: searchedTerm,
                fields: ['name', 'sku', 'ean'],
                analyzer: 'prefix_search',
                fuzziness: '1'
            }
        });
        // }
    }

    if (price && price.length) {
        newFilterGroups.bool.must.push({
            range: {
                original_price: {
                    gte: price[0],
                    lte: price[1]
                }
            }
        });
    }

    if (categoriesSelectedId?.length) {
        newFilterGroups.bool.filter.push({
            terms: {
                category_ids: categoriesSelectedId
            }
        });
    } else if (categoriesId?.length) {
        newFilterGroups.bool.filter.push({
            terms: {
                category_ids: categoriesId
            }
        });
    }
    if (marca?.length) {
        newFilterGroups.bool.filter.push({
            terms: {
                'attr_marcadescricao.keyword': marca
            }
        });
    }
    if (cor?.length) {
        newFilterGroups.bool.filter.push({
            terms: {
                'attr_EC_COR.keyword': cor
            }
        });
    }
    if (tamanho?.length) {
        newFilterGroups.bool.filter.push({
            terms: {
                'attr_EC_TAMANHO.keyword': tamanho
            }
        });
    }
    if (inflamavel?.length) {
        newFilterGroups.bool.filter.push({
            terms: {
                'attr_B1_XINFLAM.keyword': inflamavel
            }
        });
    }
    if (potencia?.length) {
        newFilterGroups.bool.filter.push({
            terms: {
                'attr_EC_POT.keyword': potencia
            }
        });
    }
    if (voltagem?.length) {
        newFilterGroups.bool.filter.push({
            terms: {
                'attr_EC_TENELET.keyword': voltagem
            }
        });
    }
    if (tipo?.length) {
        newFilterGroups.bool.filter.push({
            terms: {
                'attr_EC_MOD.keyword': tipo
            }
        });
    }

    return newFilterGroups;
};

export const getSort = (sort: string | number, isCategory: Categories | null) => {
    if (sort === 'relevance' && isCategory) {
        return {
            [`position_category_${isCategory.id}`]: {
                order: 'asc'
            }
        };
    } else if (sort === 'pct_discount') {
        return {
            pct_discount: {
                order: 'desc'
            }
        };
    }

    return sort;
};
