import axios from 'axios';
import Geocode from 'react-geocode';
import { NEXT_PUBLIC_API_KEY_GOOGLE_MAPS } from '../../utils/envs';
import { IGoogleMapsDistanceResponse, IResponseGoogleGeocode, IViacep } from './types';

Geocode.setApiKey(NEXT_PUBLIC_API_KEY_GOOGLE_MAPS);
Geocode.setLanguage('pt');
Geocode.setRegion('pt-BR');
Geocode.setLocationType('ROOFTOP');

const states: any = {
    AC: 'Acre',
    AL: 'Alagoas',
    AP: 'Amapá',
    AM: 'Amazonas',
    BA: 'Bahia',
    CE: 'Ceará',
    DF: 'Distrito Federal',
    ES: 'Espírito Santo',
    GO: 'Goiás',
    MA: 'Maranhão',
    MT: 'Mato Grosso',
    MS: 'Mato Grosso do Sul',
    MG: 'Minas Gerais',
    PA: 'Pará',
    PB: 'Paraíba',
    PR: 'Paraná',
    PE: 'Pernambuco',
    PI: 'Piauí',
    RJ: 'Rio de Janeiro',
    RN: 'Rio Grande do Norte',
    RS: 'Rio Grande do Sul',
    RO: 'Rondônia',
    RR: 'Roraima',
    SC: 'Santa Catarina',
    SP: 'São Paulo',
    SE: 'Sergipe',
    TO: 'Tocantins'
};

const getGeocode = () => {
    return Geocode;
};

const getLocationAddress = async (zipcode: string): Promise<any> => {
    return getGeocode().fromAddress(zipcode);
};
const getLocationLatLng = async (latitude: string, longitude: string): Promise<IResponseGoogleGeocode> => {
    return getGeocode().fromLatLng(latitude.toString(), longitude.toString()) as Promise<IResponseGoogleGeocode>;
};

const getDistanceBetweenLatLng = async (
    lat1: string,
    lng1: string,
    lat2: string,
    lng2: string
): Promise<number | undefined> => {
    try {
        const {
            data: {
                status,
                routes: [route]
            }
        } = await axios.get<IGoogleMapsDistanceResponse>('https://maps.googleapis.com/maps/api/directions/json', {
            params: {
                origin: `${lat1},${lng1}`,
                destination: `${lat2},${lng2}`,
                key: NEXT_PUBLIC_API_KEY_GOOGLE_MAPS
            }
        });
        if (status == 'OK') {
            return route.legs[0].distance.value / 1000;
        }
        return undefined;
    } catch (e) {
        console.info(e, 'getDistanceBetweenLatLng');
        return undefined;
    }
};

const getZipcodeFromLatLng = async (lat: string, lng: string): Promise<string | undefined> => {
    const resultMap = await getLocationLatLng(lat, lng);
    return resultMap.results[0].address_components.find(
        (ac) => ac.types.includes('postal_code') && ac.types.length == 1
    )?.long_name;
};

const getViaCep = async (zipcode: string, params?: any): Promise<IViacep | null> => {
    const { data } = await axios.get(`https://viacep.com.br/ws/${zipcode}/json/`, params);
    if (data.erro) {
        return null;
    }
    data['estado'] = states[data.uf];
    return data;
};

const getCurrentLocation = async () => {
    return new Promise<GeolocationPosition>((resolve, error) => {
        const options = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
        };

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(resolve, error, options);
        } else {
            error('not found navigator.geolocation');
        }
    });
};

export default {
    getZipcodeFromLatLng,
    getLocationAddress,
    getCurrentLocation,
    getViaCep,
    getDistanceBetweenLatLng
};
