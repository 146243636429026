import tw, { styled } from 'twin.macro';
import { TextLatoRegular13 } from '../../atoms';
import { Button } from '../../molecules';

export const Container = styled.div`
    ${tw`flex flex-col col-start-1 row-start-3 lg:col-start-1 lg:row-span-3`}
`;

export const FormMandatoryLabel = styled(TextLatoRegular13)`
    ${tw`
    mb-[15px]
    text-primary
    `}
`;

export const LoginFormInput = styled.input`
    ${tw`
    border-[1px]
    border-[#C4C4C4]
    rounded-[15px]
    p-[10px]
    mb-[20px]
    outline-none

    lg:w-[370px]
    `}
`;

export const SubmitForgetButton = styled.div`
    ${tw`flex flex-col items-center w-full mt-3 lg:flex-row lg:items-center lg:justify-start`}
`;

export const FormSubmitButton = styled(Button)`
    ${tw`
    w-full

    lg:w-auto
    lg:px-[40px]
    `}
`;
