import { useState } from 'react';
import { IconClose, Modal } from '../../../../atoms';
import ModalPortal from '../../../../atoms/Modal-portal';
import InstallmentButton from './Installment-button';
import { AnimatePresence, motion } from 'framer-motion';
import { useInstallments } from '../../../../../hooks/use-installments';
import { IProduct } from '../../../../../services/product/types';
import { ProductService } from '../../../../../services';
import { useQtyProduct } from '../../hooks/useQtyProduct';
import FadeIn from '../../../../atoms/Fade-in';
import { Fade } from '@mui/material';
import { currencyBrl } from '../../../../../utils/getFormattedValue';

interface InstallmentsModalProps {
    data: IProduct;
}

export default function InstallmentsModal({ data }: InstallmentsModalProps) {
    const [isOpen, setIsOpen] = useState(false);
    const { qty } = useQtyProduct();
    const [selected, setSelected] = useState('creditCard');
    const { data: installments, isLoading } = useInstallments(ProductService.getValue(data, 1) * qty, {
        enabled: selected === 'creditCard' && isOpen
    });

    const handleChangeInstallment = (installment: string) => {
        setSelected(installment);
    };

    const getContent = () => {
        if (!isOpen) return null;
        if (isLoading) {
            return (
                <FadeIn show={isLoading} animatePresence={false}>
                    <div className="grid grid-cols-3 h-[41px] items-center bg-white even:bg-[#F8F8F8] px-2.5">
                        <p className="w-full h-3 rounded-md bg-gray animate-pulse"></p>
                        <p className="w-full h-3 rounded-md bg-gray animate-pulse"></p>
                        <p className="w-full h-3 rounded-md bg-gray animate-pulse"></p>
                        <p className="w-full h-3 rounded-md bg-gray animate-pulse"></p>
                        <p className="w-full h-3 rounded-md bg-gray animate-pulse"></p>
                        <p className="w-full h-3 rounded-md bg-gray animate-pulse"></p>
                    </div>
                </FadeIn>
            );
        }

        if (selected === 'creditCard' && installments) {
            return (
                <FadeIn show={isLoading} animatePresence={false}>
                    {installments?.map((installment: number, index: number) => (
                        <div
                            key={installment.toString()}
                            className="grid grid-cols-3 h-[41px] items-center bg-white even:bg-[#F8F8F8] px-2.5"
                        >
                            <p className="text-left">
                                {index + 1}x de R$ {currencyBrl(installment)}
                            </p>
                            <p className="text-center">s/ juros</p>
                            <p className="text-right">total R$ {currencyBrl(ProductService.getValue(data, 1) * qty)}</p>
                        </div>
                    ))}
                </FadeIn>
            );
        }
        return (
            <FadeIn show={isLoading} animatePresence={false}>
                <div className="grid grid-cols-3 h-[41px] items-center bg-white even:bg-[#F8F8F8] px-2.5">
                    <p className="text-left">
                        1x de R$ {currencyBrl(ProductService.getCashPayment(ProductService.getValue(data, 1) * qty))}
                    </p>
                    <p className="text-center">s/ juros</p>
                    <p className="text-right">
                        total R$ {currencyBrl(ProductService.getCashPayment(ProductService.getValue(data, 1) * qty))}
                    </p>
                </div>
            </FadeIn>
        );
    };

    return (
        <>
            <button
                type="button"
                onClick={() => setIsOpen(true)}
                className="hidden md:flex text-xs pt-2 text-primary font-normal"
            >
                Formas de pagamento disponíveis
            </button>
            <ModalPortal>
                {isOpen && (
                    <Modal onClose={() => setIsOpen(false)} className="!w-auto !h-auto">
                        <IconClose
                            onClick={() => setIsOpen(false)}
                            className="absolute top-4 right-4 cursor-pointer "
                        />
                        <motion.div className="pt-[70px] px-[58px] pb-8 flex flex-col gap-6">
                            <div className="flex flex-row gap-3.5">
                                <InstallmentButton
                                    selected={selected === 'creditCard'}
                                    onClick={() => handleChangeInstallment('creditCard')}
                                >
                                    Cartão de crédito
                                </InstallmentButton>
                                <InstallmentButton
                                    selected={selected === 'pix'}
                                    onClick={() => handleChangeInstallment('pix')}
                                >
                                    PIX
                                </InstallmentButton>
                                <InstallmentButton
                                    selected={selected === 'boleto'}
                                    onClick={() => handleChangeInstallment('boleto')}
                                >
                                    Boleto
                                </InstallmentButton>
                            </div>
                            <AnimatePresence>{getContent()}</AnimatePresence>
                        </motion.div>
                    </Modal>
                )}
            </ModalPortal>
        </>
    );
}
