import { useMutation } from '@tanstack/react-query';
import axios, { Canceler } from 'axios';
import { FC, memo, useEffect, useRef, useState } from 'react';
import { useCart } from '../../../hooks';
import { CartService, ElasticService, EstablishmentService } from '../../../services';
import { Cart, CartItem } from '../../../services/cart/types';
import { IProduct } from '../../../services/product/types';
import { ListEmpty, ProductItem, ProductItemSkeleton } from '../../molecules';
import { Container, IconEmpty } from './styles';
import { IItems, ListCartProps } from './types';
import { useCartItems } from '../../../hooks/use-cart-items';
import { useCartDeleteItem } from '../../../hooks/use-cart-delete-item';

const ListCart: FC<ListCartProps> = ({ pageOrigin, className, isCheckout = false, ...rest }) => {
    const { data: items, isLoading } = useCartItems();

    // const [items, setItems] = useState<IItems[]>();
    // const refTimeout = useRef<NodeJS.Timeout>();
    // const { data: cartData } = useCart();

    // const { mutate: init, isLoading } = useMutation(async (stateCart: Cart) => {
    //     try {
    //         if (cancelToken.current) cancelToken.current();
    //         const establishment = EstablishmentService.getEstablishmentStorage();

    //         const skus = stateCart.items.map((item) => item.sku);
    //         const products = await ElasticService.getProducts({
    //             elasticId: establishment?.elastic_id_cart,
    //             filter: {
    //                 bool: {
    //                     must: [
    //                         {
    //                             multi_match: {
    //                                 query: skus.join(' and '),
    //                                 fields: ['sku']
    //                             }
    //                         }
    //                     ]
    //                 }
    //             },
    //             limit: skus.length,
    //             paramsAxios: {
    //                 cancelToken: new axios.CancelToken((c) => {
    //                     cancelToken.current = c;
    //                 })
    //             }
    //         });
    //         const newItems = stateCart.items.map((item, i) => {
    //             const product: IProduct = products.items.find((prod) => prod.sku == item.sku) as IProduct;
    //             return {
    //                 cart: item,
    //                 product
    //             };
    //         });
    //         console.log(products, stateCart.items, newItems, 'newItems');

    //         const result = [...newItems];

    //         setItems(result);
    //     } catch (e) {
    //         console.log(e);
    //     }
    // });

    // const checkProducts = (stateCart: Cart) => {
    //     //update items cart
    //     if (items && items.length == stateCart.items.length) {
    //         return;
    //     }

    //     if (items && items.length > stateCart.items.length) {
    //         const ids = stateCart.items.map((item) => item.item_id);
    //         const newItems = items?.filter((item) => ids?.includes(item.cart.item_id));
    //         setItems([...newItems]);

    //         return;
    //     }

    //     if (refTimeout.current) clearTimeout(refTimeout.current);

    //     if (!items || !items.length) {
    //         init(stateCart);
    //     } else {
    //         refTimeout.current = setTimeout(async () => {
    //             init(stateCart);
    //         }, 1000);
    //     }
    // };

    // useEffect(() => {
    //     const unsubscribe = CartService.createSubscribe((data) => checkProducts(data as Cart));

    //     return () => {
    //         unsubscribe();
    //     };
    // }, [items]);

    const { mutate: deleteItem, isLoading: isLoadingDelete } = useCartDeleteItem();

    const onHandleTrash = (itemId: number) => {
        deleteItem({ itemId, pageOrigin });
    };

    if (items && !items.length) {
        return <ListEmpty icon={<IconEmpty />} text="Carrinho" />;
    }
    return (
        <Container className={className} layoutScroll>
            {items?.map((item) => {
                // item.cart = cartData?.items.find((cart) => cart.item_id == item.cart.item_id) as CartItem;
                // if (!item.cart) return null;
                const product: IProduct = item.elastic;
                const optionProductSelected = item?.product_option;
                return <ProductItem key={item.item_id} data={product} />;
            })}
            {isLoading && <ProductItemSkeleton />}
        </Container>
    );
};

export default memo(ListCart);
