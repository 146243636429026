import { Container, ContentDesktop, ContentMobile, SelectedContainer } from './styles';

interface ITabColumnProps {
    title: string;
    icon: React.ReactNode;
    id: string;
    selected?: boolean;
    onClick?: () => void;
}
function TabColumn({ title, icon, selected, onClick, id }: ITabColumnProps) {
    const animate = selected ? 'selected' : 'unselected';
    return (
        <Container>
            <ContentMobile type="button" animate={animate} onClick={onClick}>
                <div className="z-[3]">{icon}</div>
                <div className="z-[3] flex shrink-0 flex-nowrap">{title}</div>
            </ContentMobile>
            <ContentDesktop type="button" animate={animate} onClick={onClick}>
                <div className="z-[3]">{icon}</div>
                <div className="z-[3] flex shrink-0 flex-nowrap ">{title}</div>
            </ContentDesktop>
            {selected && <SelectedContainer layoutId={`tab-${id}`} />}
        </Container>
    );
}

export default TabColumn;
