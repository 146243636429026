import Link from 'next/link';
import { Column, Menu, Title } from '../styles';

interface IContactUsProps {
    title?: boolean;
}
function ContactUs({ title = false }: IContactUsProps) {
    return (
        <Column>
            {title && <Title>Fale Conosco</Title>}
            <Menu>
                <Link href="/faq">Perguntas Frequentes</Link>
            </Menu>
            <Menu>
                <Link href="https://atendimento.matconcasa.com.br/hc/pt-br" target="_blank">
                    Central de Atendimento
                </Link>
            </Menu>
        </Column>
    );
}

export default ContactUs;
