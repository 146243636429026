import tw, { styled } from 'twin.macro';

export const Container = styled.button`
    ${tw`
        relative
        cursor-pointer
    `}
    &::after {
        content: '';
        position: absolute;
        top: -10px;
        bottom: -10px;
        left: -10px;
        right: -10px;
    }
`;
