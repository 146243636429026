import { FC, useEffect, useState } from 'react';

import dynamic from 'next/dynamic';
import Image from 'next/image';
import { AuthService } from '../../../services';
import IconBasketPack from '../../atoms/icons/Basket-pack';
import IconItemsList from '../../atoms/icons/Items-list';
import IconUser from '../../atoms/icons/User';
import CardAccount from '../../molecules/Card-account';
import Breadcrumb from '../../organisms/Breadcrumb';
import Layout from '../Layout';
import LayoutFitter from '../Layout-fitter';
import { Container, ExitButton, MainMenuMyAccount, WelcomeHeader } from './styles';
import block from '../../../services/block';
import { useBlockMyAccountBanner } from '../../../hooks/use-block-my-account-banner';

const ColumnUserInfo = dynamic(() => import('../../atoms/ColumnUserInfo'), { ssr: false });

interface IMyAccountProps {}

const MyAccount: FC<IMyAccountProps> = () => {
    const [myAccount, setMyAccount] = useState<string>();
    const [managerData, setManagerData] = useState<string>();
    const [myOrder, setMyOrder] = useState<string>();
    const [listOrder, setListOrder] = useState<string>();
    const { data: banner } = useBlockMyAccountBanner();

    const getInfoBlock = async (id: number, setState: React.Dispatch<React.SetStateAction<string | undefined>>) => {
        const blockText = await block.getBlockTextById(id);
        if (blockText) {
            setState(blockText.content);
        }
    };

    useEffect(() => {
        getInfoBlock(21, setMyAccount);
        getInfoBlock(22, setManagerData);
        getInfoBlock(23, setMyOrder);
        getInfoBlock(27, setListOrder);
    }, []);

    const loggout = async () => {
        await AuthService.logout();
    };

    return (
        <Layout>
            <LayoutFitter>
                <Container>
                    <WelcomeHeader>
                        <Breadcrumb
                            items={[
                                { label: 'Página Inicial', url: '/' },
                                {
                                    label: 'Minha Conta'
                                }
                            ]}
                        />

                        <div className="flex items-center w-full gap-4 mt-2 lg:flex-col lg:mt-2">
                            <h1 className="w-full text-[22px] font-bold  text-[#424242]">
                                Bem vindo{' '}
                                <span className="text-[#81AB05]">
                                    <ColumnUserInfo column="firstname" />
                                </span>
                            </h1>
                            <div className="flex justify-end w-full lg:justify-between">
                                <p className="hidden w-[745px] text-14 lg:block">{myAccount}</p>
                                <ExitButton onClick={loggout}>Sair</ExitButton>
                            </div>
                        </div>
                    </WelcomeHeader>

                    <MainMenuMyAccount>
                        <CardAccount
                            title="Gerenciar seus dados"
                            description={managerData}
                            icon={IconUser}
                            iconProps={{ className: 'stroke-primary lg:stroke-white' }}
                            className="lg:!bg-primary lg:!text-white"
                            href="gerenciar-dados"
                        />

                        <CardAccount title="Meus Pedidos" description={myOrder} icon={IconBasketPack} href="pedidos" />

                        <CardAccount
                            title="Listas de Compras"
                            description={listOrder}
                            icon={IconItemsList}
                            href="favoritos"
                        />

                        <Image
                            src={banner!}
                            alt="banner"
                            height={200}
                            width={488}
                            className="object-contain rounded-8"
                        />
                    </MainMenuMyAccount>
                </Container>
            </LayoutFitter>
        </Layout>
    );
};

export default MyAccount;
