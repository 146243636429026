import { SVGProps } from 'react';

const IconItemsList = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25.3333 28H6.66667C5.19333 28 4 26.8067 4 25.3333V6.66667C4 5.19333 5.19333 4 6.66667 4H25.3333C26.8067 4 28 5.19333 28 6.66667V25.3333C28 26.8067 26.8067 28 25.3333 28Z"
                stroke="#9FBE43"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M22.6665 10.6667H14.6665"
                stroke="#9FBE43"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.6665 16H22.6665"
                stroke="#9FBE43"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.6665 21.3334H22.6665"
                stroke="#9FBE43"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.33052 10.3333C9.14652 10.3333 8.99719 10.4827 8.99986 10.6667C8.99986 10.8507 9.14919 11 9.33319 11C9.51719 11 9.66652 10.8507 9.66652 10.6667C9.66652 10.4827 9.51719 10.3333 9.33052 10.3333"
                stroke="#9FBE43"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.33052 15.6666C9.14652 15.6666 8.99719 15.816 8.99986 16C8.99986 16.184 9.14919 16.3333 9.33319 16.3333C9.51719 16.3333 9.66652 16.184 9.66652 16C9.66652 15.816 9.51719 15.6666 9.33052 15.6666"
                stroke="#9FBE43"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.33052 21C9.14652 21 8.99719 21.1494 8.99986 21.3334C8.99986 21.5174 9.14919 21.6667 9.33319 21.6667C9.51719 21.6667 9.66652 21.5174 9.66652 21.3334C9.66652 21.1494 9.51719 21 9.33052 21"
                stroke="#9FBE43"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default IconItemsList;
