import tw, { styled } from 'twin.macro';

export const PriceRangeValuesContainer = styled.div`
    ${tw`
    flex
    w-full
    justify-center
    gap-2
    px-[15px]
  `}
`;

export const PriceRangeValue = styled.span`
    ${tw`
    text-darkGray
  `}
`;
