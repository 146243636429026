import React, { FC, SVGProps } from 'react';

const IconCircleCheck: FC<SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="11.5" stroke="#9FBE43" />
            <path d="M9 11L12 16L16 8" stroke="#9FBE43" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default IconCircleCheck;
