import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Input } from '../../molecules';

import { useMutation } from '@tanstack/react-query';
import InputMask from 'react-input-mask';
import { AddressService, MapService } from '../../../services';
import { REQUIRED } from '../../../utils/errors';

import { AddressInfoFormContainer, ErrorText, Scroll, SubmitActionsContainer } from './styles';

import { useRef, useState } from 'react';
import { useUserInfo } from '../../../hooks/use-user-info';
import addressService from '../../../services/address';
import { Address } from '../../../services/address/types';
import { IViacep } from '../../../services/map/types';
import { replaceParams } from '../../../utils/string';
import { IButtonVariant } from '../../molecules/Button/types';
import { IRegisterFormProps, IValuesForm } from './types';

const validationForm = Yup.object().shape({
    cep: Yup.string().required(REQUIRED),
    address: Yup.string().required(REQUIRED),
    addressNumber: Yup.string().required(REQUIRED),
    district: Yup.string().required(REQUIRED),
    complement: Yup.string().nullable(),
    telephone: Yup.string().required(REQUIRED),
    alias: Yup.string(),
    reference: Yup.string()
});

const RegisterAddressForm = ({ handleOnDismissForm, selectedAddress }: IRegisterFormProps) => {
    const [error, setError] = useState('');
    const [viaCep, setViaCep] = useState<IViacep | null | false>();
    const refTimeOutCep = useRef<NodeJS.Timeout>();
    const { data } = useUserInfo();

    const { mutate, isLoading, isError } = useMutation<unknown, unknown, IValuesForm>(
        async (form) => {
            let restBody = {};

            if (!selectedAddress) {
                if (!viaCep) {
                    throw new Error('Error interno');
                }

                const states = await AddressService.getStatesStorage();
                const state = states?.find((value) => value.code == viaCep.uf);

                if (!state) {
                    throw new Error('Estado não encontrado');
                }
                restBody = {
                    postcode: form.cep,
                    city: viaCep.localidade,
                    country_id: 'BR',
                    region: {
                        region: state.name,
                        region_id: parseInt(state.id),
                        region_code: state.code
                    },
                    region_id: parseInt(state.id)
                };
            }

            const requestBody: Address = {
                ...selectedAddress,
                street: [form.address, form.addressNumber, form.complement, form.district],
                telephone: form.telephone,
                firstname: data?.firstname,
                lastname: data?.lastname,
                vat_id: data?.taxvat,
                prefix: form.alias,
                suffix: form.reference,
                ...restBody
            } as Address;

            if (selectedAddress && selectedAddress.id) return addressService.edit(selectedAddress.id, requestBody);

            return addressService.addAddress(requestBody);
        },
        {
            onSuccess: async () => {
                handleOnDismissForm();
            },
            onError: (error: any) => {
                if (error.response.status == 400) {
                    return setError(replaceParams(error.response.data.message, error.response.data.parameters));
                }

                setError(error.message);
            }
        }
    );

    const formik = useFormik<IValuesForm>({
        initialValues: {
            cep: selectedAddress?.postcode || '',
            address: selectedAddress?.street[0] || '',
            addressNumber: selectedAddress?.street[1] || '',
            district: selectedAddress?.street[2] || '',
            city: selectedAddress?.city || '',
            state: selectedAddress?.region.region || '',
            complement: selectedAddress?.street[3] || '',
            alias: selectedAddress?.prefix || '',
            telephone: selectedAddress?.telephone || data?.telephone || '',
            reference: selectedAddress?.suffix || ''
        },
        validateOnMount: false,
        enableReinitialize: true,
        validationSchema: validationForm,
        onSubmit: (values) => {
            mutate(values);
        }
    });

    const handleChangeCep = (name: string, value: string) => {
        if (!value) {
            formik.setFieldValue('city', '');
            formik.setFieldValue('state', '');
            return;
        }
        setViaCep(null);
        if (refTimeOutCep.current) clearTimeout(refTimeOutCep.current);

        refTimeOutCep.current = setTimeout(async () => {
            const result = await MapService.getViaCep(value);

            if (result) {
                setViaCep(result);
                formik.setFieldValue('city', result?.localidade);
                formik.setFieldValue('state', result?.estado);
                formik.setFieldValue('address', result?.logradouro);
                formik.setFieldValue('district', result?.bairro);
            } else {
                setViaCep(false);
            }
        }, 1000);
    };

    return (
        <>
            <Scroll>
                <AddressInfoFormContainer>
                    <FormikProvider value={formik}>
                        <div className="flex gap-4 mt-8 mb-6">
                            <Input label="Apelido" name="alias" />
                            <div className="w-full">
                                <label className="text-12 text-[black]">Telefone</label>

                                <InputMask
                                    mask="(99) 99999-9999"
                                    value={formik.values.telephone}
                                    onChange={formik.handleChange}
                                >
                                    {
                                        // @ts-ignore
                                        (inputProps: any) => (
                                            <input
                                                {...inputProps}
                                                className="bg-[#F6F6F6] w-full h-[26] rounded-30 p-4 border-[0.5px] border-[#E6E6E6]"
                                                inputMode="numeric"
                                                name="telephone"
                                                placeholder="(99) 99999-9999"
                                                autocomplete="off"
                                            />
                                        )
                                    }
                                </InputMask>
                                {formik.errors.telephone && <ErrorText>{formik.errors.telephone}</ErrorText>}
                            </div>{' '}
                        </div>

                        <div className="flex gap-4 mb-6">
                            <div>
                                <Input
                                    inputMode="numeric"
                                    label="CEP*"
                                    mask="cep"
                                    onChange={handleChangeCep}
                                    name="cep"
                                />
                                {viaCep === false && <ErrorText>CEP inválido</ErrorText>}
                            </div>

                            <Input label="Endereço*" name="address" />
                        </div>
                        <div className="flex gap-4 mb-6">
                            <Input
                                inputMode="numeric"
                                label="Número*"
                                name="addressNumber"
                                maxLength={10}
                                mask="number"
                            />
                            <Input label="Complemento" name="complement" maxLength={30} />
                        </div>
                        <div className="flex gap-4">
                            <Input label="Bairro*" name="district" />
                            <Input label="Cidade*" disabled name="city" />
                            <Input label="Estado*" disabled name="state" />
                        </div>

                        {/* <Input label="Bairro*" name="district" maxLength={20} /> */}
                    </FormikProvider>
                    {error && <ErrorText> {error}</ErrorText>}
                </AddressInfoFormContainer>
            </Scroll>
            <SubmitActionsContainer>
                <Button
                    onClick={handleOnDismissForm}
                    text="Cancelar"
                    variant={IButtonVariant.OUTLINE}
                    style="
                            display: none;
                            @media (min-width: 768px) {
                                display: flex;
                            };
                            color: #FF7373; 
                            font-size: 16px;
                            font-weigth: bold;
                            border-color: #FF7373;
                            padding: 16px 36px;
                            max-height: 56px;
                            stroke: #81AB05;
                            text-align: center;
                            min-width: 256px;
                        "
                />
                <Button
                    isLoading={isLoading}
                    disabled={!formik.isValid}
                    onClick={() => formik.handleSubmit()}
                    text={selectedAddress ? 'Salvar' : 'Cadastrar'}
                    style="
                        min-width: 256px;
                    "
                />
            </SubmitActionsContainer>
        </>
    );
};

export default RegisterAddressForm;
