import tw, { styled } from 'twin.macro';
import { Image } from '../../../atoms';

export const Container = styled.div`
    ${tw`
        flex
        flex-col
        gap-4
    `}
`;
export const Content = styled.div`
    ${tw`
        flex
        flex-col
        gap-4
        lg:flex-row
    `}
`;

export const Products = styled.div`
    ${tw`
        grid
        grid-cols-2 
        md:grid-cols-3 
        2xl:grid-cols-4 
        gap-4
        w-full
        flex-wrap
    `}
`;

export const BannerContainer = styled.button`
    ${tw`
        w-full
        relative
        rounded-16
        overflow-hidden
        aspect-[16/7.5]
        md:min-h-[168px]
        md:aspect-auto
        lg:max-w-[308px]
        md:h-[352px]
        cursor-pointer
    `}
`;

export const Banner = styled(Image)`
    ${tw`
        object-cover
        rounded-16
    `}
`;

export const Title = styled.h2`
    ${tw`
        flex
        items-center
        text-black
        text-16
        font-semibold
        h-10
        md:h-12
        md:font-bold
        md:text-20
    `}
`;
