import { useRouter } from 'next/router';
import { forwardRef, Ref, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useCategories } from '../../../hooks';
import { CategoryService, EstablishmentService, ProductService } from '../../../services';
import { categories as categoriesListIcons } from '../../../utils/category';
import { NEXT_PUBLIC_ID_HIDDEN_CATEGORY } from '../../../utils/envs';
import { twColors } from '../../../utils/get-config-tailwind';
import SubcategoriesModal from '../Subcategories-modal';
import {
    Backdrop,
    CategoriesList,
    CategoriesListHeader,
    CategoriesListLabel,
    Category,
    CategoryContainer,
    CategoryIconContainer,
    CategoryLabel,
    CategoryLabelContainer,
    IconArrow,
    IconCross,
    IconDelivei,
    IconMenu,
    ListContainer,
    PromotionsCategory
} from './styles';
import { CategoryListItem, ICategoriesModal, ICategoryModalHandler } from './types';

const CategoriesModal = forwardRef((props: ICategoriesModal, ref: Ref<ICategoryModalHandler>) => {
    const [list, setList] = useState<CategoryListItem[]>([]);

    const { data: result } = useCategories({});

    useEffect(() => {
        if (result) {
            const iconDefault: any = categoriesListIcons.find((c) => c.name == 'Destaque');
            setList(
                result
                    .filter((elem) => elem.id != NEXT_PUBLIC_ID_HIDDEN_CATEGORY)
                    .map((category) => {
                        const icon = categoriesListIcons.find((c) => c.ids?.includes(parseInt(category.id)));
                        return {
                            id: category.id,
                            name: category.name,
                            childrenIds: category.childrenIds,
                            children: category.children,
                            url: category.url,
                            icon: icon ? icon.icon : iconDefault.icon
                        };
                    })
            );
        }
    }, []);

    useEffect(() => {
        const subscribe = CategoryService.createSubscribe((result) => {
            if (!result) return;

            const iconDefault: any = categoriesListIcons.find((c) => c.name == 'Destaque');
            setList(
                result
                    .filter((elem) => elem.id != NEXT_PUBLIC_ID_HIDDEN_CATEGORY)
                    .map((category) => {
                        const icon = categoriesListIcons.find((c) => c.ids?.includes(parseInt(category.id)));
                        return {
                            id: category.id,
                            name: category.name,
                            childrenIds: category.childrenIds,
                            children: category.children,
                            url: category.url,
                            icon: icon ? icon.icon : iconDefault.icon
                        };
                    })
            );
        }, true);

        return () => {
            subscribe();
        };
    }, []);

    const [isMobile, setIsMobile] = useState(false);

    const { data: rawCategories } = useCategories();
    const { top, left } = props;
    const router = useRouter();

    const refRootDiv = useRef<HTMLDivElement>(null);
    const categoryRef = useRef<HTMLDivElement>(null);
    const backdropRef = useRef<HTMLDivElement>(null);
    const [selectedCategory, setSelectedCategory] = useState<null | string>(null);

    useEffect(() => {
        const resize_ob = new ResizeObserver(function (entries) {
            if (window) setIsMobile(window.innerWidth < 1024);
        });
        if (refRootDiv.current) {
            resize_ob.observe(refRootDiv.current);
        }

        return () => {
            resize_ob.disconnect();
        };
    }, [rawCategories]);

    const toggleCategory = (id: string) => {
        if (selectedCategory === id) {
            setSelectedCategory(null);
            return;
        }

        setSelectedCategory(id);
    };

    const onMouseEnterCategory = (id: string) => {
        isMobile ? null : toggleCategory(id);
    };

    const showList = () => {
        refRootDiv.current?.classList.add('show');
        backdropRef.current?.classList.add('show');
    };

    const hideList = (event?: any) => {
        if (selectedCategory) {
            setSelectedCategory(null);
        }

        refRootDiv.current?.classList.remove('show');
        backdropRef.current?.classList.remove('show');

        if (event) event.stopPropagation();
    };

    useImperativeHandle(ref, () => ({
        show: showList,
        close: hideList
    }));

    return (
        <>
            <Backdrop onClick={hideList} ref={backdropRef} />
            <ListContainer ref={refRootDiv} style={isMobile ? { top: 0, left: 0 } : { top, left }}>
                <CategoriesListHeader style={isMobile ? { display: 'flex' } : { display: 'none' }}>
                    <IconMenu fill={twColors.primary} height={19} width={20} />
                    <CategoriesListLabel>Categorias</CategoriesListLabel>
                    <div onClick={hideList}>
                        <IconCross />
                    </div>
                </CategoriesListHeader>
                <CategoriesList>
                    <PromotionsCategory>
                        <IconDelivei />
                        Destaques
                    </PromotionsCategory>
                    {list?.map((category) => {
                        return (
                            <CategoryContainer
                                key={category.id}
                                onMouseEnter={() => onMouseEnterCategory(category.id)}
                                ref={categoryRef}
                            >
                                <Category onClick={() => toggleCategory(category.id)}>
                                    <CategoryLabelContainer
                                        onClick={(event) => {
                                            router.push(
                                                {
                                                    pathname: ProductService.getUrlSearchProduct(category.url)
                                                },
                                                undefined,
                                                { shallow: false }
                                            );
                                            hideList(event);
                                        }}
                                    >
                                        <CategoryIconContainer>
                                            {category.icon({ fill: twColors.primary })}
                                        </CategoryIconContainer>
                                        <CategoryLabel>{category.name}</CategoryLabel>
                                    </CategoryLabelContainer>
                                    <IconArrow
                                        color={twColors.primary}
                                        className={selectedCategory === category.id ? 'selected' : ''}
                                    />
                                </Category>
                                <SubcategoriesModal
                                    show={selectedCategory === category.id}
                                    category={category.children}
                                    categoryParent={category.id}
                                    closeModal={hideList}
                                    top={categoryRef.current?.offsetTop}
                                    closeCategoriesMenu={() => {}}
                                />
                            </CategoryContainer>
                        );
                    })}
                </CategoriesList>
            </ListContainer>
        </>
    );
});
CategoriesModal.displayName = 'CategoriesModal';
export default CategoriesModal;
