import * as Yup from 'yup';
import {
    NEXT_PUBLIC_MSG_FIELD_CARACTER_LOW,
    NEXT_PUBLIC_MSG_FIELD_CARACTER_UPP,
    NEXT_PUBLIC_MSG_FIELD_CELL,
    NEXT_PUBLIC_MSG_FIELD_CPF,
    NEXT_PUBLIC_MSG_FIELD_DATE,
    NEXT_PUBLIC_MSG_FIELD_EMAIL,
    NEXT_PUBLIC_MSG_FIELD_MIN,
    NEXT_PUBLIC_MSG_FIELD_MONEY_MAX,
    NEXT_PUBLIC_MSG_FIELD_MONEY_MIN,
    NEXT_PUBLIC_MSG_FIELD_NUM,
    NEXT_PUBLIC_MSG_FIELD_PASSWORD_MISMATCH,
    NEXT_PUBLIC_MSG_FIELD_REQUIRED
} from './envs';
import getFormattedValue, { currencyUnMask } from './getFormattedValue';

export const REQUIRED = NEXT_PUBLIC_MSG_FIELD_REQUIRED;
export const MIN = NEXT_PUBLIC_MSG_FIELD_MIN;
export const MONEY_MIN = NEXT_PUBLIC_MSG_FIELD_MONEY_MIN;
export const MONEY_MAX = NEXT_PUBLIC_MSG_FIELD_MONEY_MAX;
export const CPF = NEXT_PUBLIC_MSG_FIELD_CPF;
export const CELL = NEXT_PUBLIC_MSG_FIELD_CELL;
export const DATE = NEXT_PUBLIC_MSG_FIELD_DATE;
export const EMAIL = NEXT_PUBLIC_MSG_FIELD_EMAIL;
export const NUM = NEXT_PUBLIC_MSG_FIELD_NUM;
export const CARACTER_UPP = NEXT_PUBLIC_MSG_FIELD_CARACTER_UPP;
export const CARACTER_LOW = NEXT_PUBLIC_MSG_FIELD_CARACTER_LOW;
export const PASSWORD_MISMATCH = NEXT_PUBLIC_MSG_FIELD_PASSWORD_MISMATCH;

Yup.addMethod(Yup.string, 'moneyMin', function (errorMessage, min) {
    return this.test(`moneyMin`, errorMessage, function (value) {
        const { path, createError } = this;
        if (currencyUnMask(value) < min) {
            return createError({
                path,
                message: errorMessage,
                params: {
                    min: getFormattedValue({ value: min.toString(), mask: 'currency' })
                }
            });
        }
        return true;
    });
});

Yup.addMethod(Yup.string, 'moneyMax', function (errorMessage, max) {
    return this.test(`moneyMax`, errorMessage, function (value) {
        const { path, createError } = this;

        if (currencyUnMask(value) > max) {
            return createError({
                path,
                message: errorMessage,
                params: {
                    max: getFormattedValue({ value: max.toString(), mask: 'currency' })
                }
            });
        }
        return true;
    });
});
