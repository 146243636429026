import { AnimatePresence, HTMLMotionProps, motion } from 'framer-motion';

export default function FadeIn({
    children,
    show,
    className,
    animatePresence = true,
    ...props
}: {
    children: React.ReactNode;
    show: boolean;
    animatePresence?: boolean;
} & Partial<HTMLMotionProps<'div'>>) {
    if (!animatePresence) {
        return (
            <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
                {...props}
                className={`overflow-hidden ${className}`}
            >
                {children}
            </motion.div>
        );
    }

    return (
        <AnimatePresence>
            {show && (
                <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0 }}
                    {...props}
                    className={`overflow-hidden ${className}`}
                >
                    {children}
                </motion.div>
            )}
        </AnimatePresence>
    );
}
