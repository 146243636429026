import { useFormikContext } from 'formik';
import { IValuesForm } from '../../templates/Register/types';
import { Checkbox } from './styles';

interface CustomCheckboxSquareProps {
    onCheckboxSquareChange: (checked: boolean) => void;
    name?: string;
    isChecked?: boolean;
}

export const CustomCheckboxSquare: React.FC<CustomCheckboxSquareProps> = ({
    onCheckboxSquareChange,
    name,
    isChecked
}) => {
    let checked = isChecked || false;
    let setFieldValue: (field: string, value: any) => void;
    if (name) {
        const { values, setFieldValue: setFieldValueFormik } = useFormikContext<IValuesForm>();
        checked = values?.type === name ? true : false;
        setFieldValue = setFieldValueFormik;
    }

    return (
        <Checkbox
            type="checkbox"
            isChecked={checked}
            onChange={(e) => {
                onCheckboxSquareChange(e.target.checked);
                if (name) setFieldValue('type', name);
            }}
        />
    );
};
