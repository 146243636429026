import { FC, useEffect, useRef, useState } from 'react';
import { useBreakpointTailwind } from '../../../hooks';
import { CartService } from '../../../services';
import { IProduct } from '../../../services/product/types';
import { getIsBreakPoint, useBreakpoint } from '../../../utils/get-config-tailwind';
import ListCart from '../List-cart';
import {
    ButtonMobileShowList,
    ContainerIcon,
    Icon,
    ListItems,
    IconCart,
    TextTitle,
    variantsButtonMobile,
    variantsContainerIcon,
    variantsIcon,
    variantsList,
    ContainerText,
    ContainerIconCart
} from './styles';

interface IChechoutListItemsProps {}

const CheckoutListItems: FC<IChechoutListItemsProps> = ({}) => {
    const [isOpen, setIsOpen] = useState(false);
    const isLg = useBreakpointTailwind('lg');

    const animate = isOpen || isLg ? 'open' : 'close';

    return (
        <>
            <ButtonMobileShowList
                onClick={() => setIsOpen((old) => !old)}
                variants={variantsButtonMobile}
                animate={animate}
                whileTap={{ scale: 0.95 }}
            >
                <ContainerText>
                    <ContainerIconCart>
                        <IconCart />
                        <TextTitle>({CartService.getTotalItemsCart() || 0})</TextTitle>
                    </ContainerIconCart>
                    <TextTitle>Itens do seu pedido</TextTitle>
                </ContainerText>
                <ContainerIcon variants={variantsContainerIcon} initial="close" animate={animate}>
                    <Icon variants={variantsIcon} initial="close" animate={animate} />
                </ContainerIcon>
            </ButtonMobileShowList>
            <ListItems variants={variantsList} animate={animate}>
                <div className="p-[20px]">
                    <ListCart isCheckout />
                </div>
            </ListItems>
        </>
    );
};

export default CheckoutListItems;
