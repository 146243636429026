import { NEXT_PUBLIC_FLAVORS } from '../../utils/envs';

import { apiAdminPublic } from '../api';

import { Flavors } from './types';

const getAll = async (): Promise<Flavors[] | undefined> => {
    const { data } = await apiAdminPublic.get(`/V1/atributo/info/${NEXT_PUBLIC_FLAVORS}`, {});

    if (data) {
        return data;
    }
};

const initFlavor = async (): Promise<Flavors[] | undefined> => {
    const flavor = await getAll();

    if (!flavor) return;

    return flavor;
};

function getFlavorFromFlavors(id: number, flavors: Flavors[]): Flavors | null {
    for (let flavor of flavors) {
        if (flavor.value.toString() === id.toString()) {
            return flavor;
        }
    }

    return null;
}

export default {
    getAll,
    initFlavor,
    getFlavorFromFlavors
};
