import tw, { styled } from 'twin.macro';
import { IconPersonRounded, TextLatoRegular13 } from '../../atoms';
import { Button as ButtomMolecule } from '../../molecules';

export const Container = styled.div`
    ${tw`flex flex-col w-full `}
`;
export const Scroll = styled.div`
    ${tw`
        overflow-y-auto
        flex
        flex-col
        shrink-0
        px-[40px]
        mb-[64px]

    `}
`;

export const PersonalInfoFormContainer = styled.div`
    ${tw`flex flex-col `}
`;

export const AddressInfoFormContainer = styled.div`
    ${tw`flex flex-col `}
`;

export const LoginFormHeader = styled.span`
    ${tw`
    w-full
    flex
    justify-between
    items-center
    mb-[20px]

    lg:flex-col
    lg:items-start
    lg:justify-between
    lg:mb-[7px]
    `}
`;

export const ContainerTitle = styled.span`
    ${tw`
    font-nunito
    font-[400]
    text-[16px]  
    `}
`;

export const SubmitActionsContainer = styled.div`
    ${tw`
        flex
        flex-row
        justify-center
        md:justify-between
        items-center
        px-[40px]
        p-[20px]
    `}
`;
export const Button = styled(ButtomMolecule)`
    ${tw`flex-1 `}
`;
export const ErrorText = styled(TextLatoRegular13)`
    ${tw`
    mb-[15px]
    text-primary
    `}
`;
