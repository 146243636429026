import dynamic from 'next/dynamic';
import { EstablishmentService } from '../../../../services';
import { Logo } from '../../../atoms';
import InputSearch from '../Input-search';
import Menu from '../Menu';
import Order from '../Order';
import Region from '../Region';
import { Container, Content, Right, Row } from './styles';
import Link from 'next/link';
const Cart = dynamic(() => import('../Cart'), { ssr: false });

interface IContentMobileProps {
    onCloseChangeRegion: () => void;
    onClickChangeRegion: () => void;
}
function ContentMobile({ onClickChangeRegion, onCloseChangeRegion }: IContentMobileProps) {
    return (
        <Container>
            <Content>
                <Row>
                    <Menu />
                    <Link href={EstablishmentService.createUrl('')} className="px-10 w-full">
                        <Logo widthPortrait={200} heightPortrait={56} />
                    </Link>
                    <Right>
                        <Order />
                        <Cart />
                    </Right>
                </Row>
                <Row className="mt-3">
                    <InputSearch />
                </Row>
            </Content>
            <Region onClickChangeRegion={onClickChangeRegion} onCloseChangeRegion={onCloseChangeRegion} />
        </Container>
    );
}

export default ContentMobile;
