import tw, { styled } from 'twin.macro';

export const Menu = styled.li`
    ${tw`
        flex
        flex-col
        cursor-pointer
        items-center
        gap-2
        max-w-[72px]
        md:gap-4
        md:px-2
        md:max-w-[112px]
    `}
`;

export const MenuImgContainer = styled.div`
    ${tw`
        rounded-full
        flex
        w-[72px]
        h-[72px]
        justify-center
        items-center
        border-[4px]
        border-[#9FBE43]
        bg-[#F3F3F3]
        relative
        shrink-0
        md:h-[112px]
        md:w-[112px]
        md:max-w-[116px]
    `}
`;

export const MenuTitle = styled.div`
    ${tw`
        text-12
        font-normal
        text-center
        text-black
        leading-[16.37px]
        md:leading-[21.82px]
        md:text-16
    `};
`;
