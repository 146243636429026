import tw, { styled } from 'twin.macro';
import { TextLatoBold15, TextLatoExtraBold20, TextLatoRegular15 } from '../../atoms';
import { Button } from '../../molecules';

export const Container = styled.div`
    ${tw`
        bg-white
        flex
        flex-col      
        mt-[25px]
        p-[25px]
        flex-1
    `}
`;
export const Content = styled.div`
    ${tw`
        flex
        flex-col   
        flex-1
        mb-[50px]
    `}
`;

export const TextTitle = styled(TextLatoExtraBold20)`
    ${tw`
        text-primary
        text-center
    `}
`;
export const TextSubTitle = styled(TextLatoBold15)`
    ${tw`
        text-center
        mt-[18px]
    `}
`;
export const TextDescription = styled(TextLatoRegular15)`
    ${tw`
        text-center
        mt-[15px]
    `}
`;

export const ButtonNotFound = styled(Button)`
    ${tw`
        !bg-primary
    `}
`;
