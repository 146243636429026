import { motion } from 'framer-motion';
import tw, { styled } from 'twin.macro';
import TextLatoBlack20 from '../text/Lato-black-20';
import { IContainerProps } from './types';

export const Container = styled(motion.div)<IContainerProps>`
    ${tw`
        absolute
        inset-0
        bg-gray/80
        flex
        justify-center
        items-center
        transition-all
        cursor-pointer
        duration-1000
        p-[20px]
        z-[2]
        
    `}
`;

export const Title = styled.div.attrs(() => ({
    initial: {
        opacity: 0
    },
    animate: {
        opacity: 1
    },
    exit: {
        opacity: 0
    }
}))`
    ${tw`
        drop-shadow-md
        text-center
        transition-all
        text-18
        font-bold
    `}
`;
