import tw, { styled } from 'twin.macro';

export const Container = styled.div`
    ${tw`
        flex 
        flex-col 
        justify-between
        pb-4
        md:pb-0
        md:gap-4 
        md:flex-row 
    `}
`;

export const Buttons = styled.div`
    ${tw`
        hidden
        flex-row
        gap-4
        py-4
        md:flex
    `}
`;

export const Button = styled.button`
    ${tw`
        flex
        flex-row
        gap-2
        items-center
        h-8
        text-14
        font-semibold
        text-black
    `}
`;

export const Badges = styled.div`
    ${tw`
          flex
          flex-row
          gap-2
          md:hidden
    `}
`;
