import { QueryFunctionContext, QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';
import axios, { AxiosRequestConfig } from 'axios';

declare type QueryFunction<T = unknown, TQueryKey extends QueryKey = QueryKey> = (
    paramsAxios: AxiosRequestConfig,
    context: QueryFunctionContext<TQueryKey>
) => T | Promise<T>;

export function useQueryCancelToken<
    TQueryFnData = unknown,
    TError = unknown,
    TData = TQueryFnData,
    TQueryKey extends QueryKey = QueryKey
>(
    queryKey: TQueryKey,
    queryFn: QueryFunction<TQueryFnData, TQueryKey>,
    options?: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>
) {
    const callback = (context: any) => {
        const signal = context.signal;

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        const params: AxiosRequestConfig = {
            cancelToken: source.token
        };
        const promise = queryFn(params, context);

        signal?.addEventListener('abort', () => {
            source.cancel('Query was cancelled by TanStack Query');
        });

        return promise;
    };

    return useQuery(
        queryKey,
        (context) => {
            return callback(context) as any;
        },
        options
    );
}
