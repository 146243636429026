import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { QueryClient } from '@tanstack/react-query';
import { persistQueryClient } from '@tanstack/react-query-persist-client';
import { ADDRESS, CART, CATEGORIES, ESTABLISHMENT, SEARCH_MODE_LIST, USER_INFO } from '../../utils/react-query-keys';

const MS_IN_DAY = 1000 * 60 * 60 * 24;
const cacheTime = MS_IN_DAY * 5;
export const IS_BROWSER = typeof window !== 'undefined';

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            cacheTime,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false
            // staleTime: 1000 * 60
        }
    }
});

if (IS_BROWSER) {
    const localStoragePersistor = createSyncStoragePersister({
        storage: window.localStorage
    });

    const persistQueries: string[] = [USER_INFO, CATEGORIES, SEARCH_MODE_LIST, CART, ADDRESS];

    persistQueryClient({
        queryClient,
        persister: localStoragePersistor,
        maxAge: cacheTime,
        dehydrateOptions: {
            shouldDehydrateQuery: ({ queryKey }) => {
                return persistQueries.includes(queryKey[0] as any);
            }
        }
    });
}
