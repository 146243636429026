import tw, { styled } from 'twin.macro';
import MagnifyingGlass from '../../../atoms/icons/Magnifying-glass';
import { motion } from 'framer-motion';

export const Container = styled(motion.form)`
    ${tw`
        flex
        flex-col
        flex-1
        relative
    `}
`;

export const Content = styled(motion.div)`
    ${tw`
        flex
        flex-row
        items-center
        h-14
        px-4
        border
        border-[#95C230]
        bg-[#FCFFF3]
        gap-4
        rounded-28
        cursor-text
    `}
`;

export const Input = styled.input`
    ${tw`
        bg-[#FCFFF3]
        flex
        flex-1
        font-nunito
        text-[#698122]
        placeholder-[#698122]
        font-normal
        text-14
        leading-[19.1px]
        focus:outline-none
        border-none
    `}
`;

export const SearchIcon = styled(MagnifyingGlass)`
    ${tw`
  w-6
  h-6
  stroke-[#9FBE43]
`}
`;

export const RootModal = styled.div`
    ${tw`
        fixed
        w-screen
       h-screen
        z-20
        flex
        justify-center
        items-center
    top-0
    `}
`;
export const Modal = styled(motion.div).attrs(() => ({
    animate: {
        opacity: 1,
        height: 'auto'
    },
    initial: {
        opacity: 0,
        height: 0
    },
    exit: {
        opacity: 0,
        height: 0
    }
}))`
    ${tw`
        bg-white
        absolute
        rounded-10
        z-[20]
        flex
        flex-col
        shadow-md
        overflow-auto
        mt-[68px]
        w-full
        max-h-[440px]

    `}
`;
export const ContainerSearch = styled.div`
    ${tw`
        px-[15px]
        py-[15px]
       
    `}
`;

export const Items = styled.div`
    ${tw`
        flex
        flex-col
        px-4
        gap-4
        h-full
        overflow-y-auto
    `}
`;

export const TextTitle = styled.div`
    ${tw`
    `}
`;

export const ContainerSeeAll = styled.div`
    ${tw`
        flex
        justify-center
        items-center
        px-4
        pt-4
    `}
`;
