import tw, { styled } from 'twin.macro';
import { IItemProps } from './types';

export const Container = styled.div`
    ${tw`
        relative
        /* m-[-20px 0] */
        mb-[10px]
    `}
    .slick-list {
        ${tw`
      /* p-[10px]
      m-[-10px]   */
    //   p-[20px 0]
    `}
    }
    .slick-track {
        ${tw`
    
    `}
    }

    .swiper {
        overflow: visible;
        ${tw`!px-0`}
    }
    .swiper-slide {
        width: auto;
        overflow: visible;
    }
    .btn-disabled {
        ${tw`
            invisible
            opacity-0
            
      `}
    }
    .container-end {
        ${tw`
            !flex
            !flex-row
            !gap-2
        `}
    }
`;

export const Item = styled.div<IItemProps>`
    /* padding-right: ${({ marginItem }) => `${(marginItem / 2).toString()}px`};
    padding-left: ${({ marginItem }) => `${(marginItem / 2).toString()}px`}; */
    /* width: unset !important; */
`;

export const Arrow = styled.div`
    ${tw`
        absolute 
        top-[calc(50% - 22px)]
        z-[2]
        cursor-pointer
    `}
`;
