import tw, { styled } from 'twin.macro';

export const Container = styled.div`
    ${tw`
       flex
       flex-col 
       gap-2
       w-full
    `}
`;

export const List = styled.div`
    ${tw`
        flex
        flex-col
        gap-4
    `}
`;

export const Item = styled.div`
    ${tw`
        flex
        flex-row
        gap-4
        items-center
        text-16
        text-black
        font-normal
        cursor-pointer
    `}
`;
