import tw, { styled } from 'twin.macro';

export const ContainerIcon = styled.div`
    ${tw`
        z-[2]
        cursor-pointer
        shrink-0
        flex
    `}
`;
