import tw, { styled } from 'twin.macro';
import { TextNunitoRegular12, TextNunitoRegular14 } from '../../../atoms';
import { motion } from 'framer-motion';
import { variants } from '../../Search-filters/styles';

export const Container = styled(motion.div).attrs(() => ({
    variants: {
        hidden: { opacity: 0, height: 0 },
        visible: { opacity: 1, height: 'auto' }
    }
}))`
    ${tw`
        bg-[#F2684C]
        overflow-hidden
    `}
`;

export const Content = styled.div`
    ${tw`
        flex
        justify-center
        items-center
        py-3
        px-[14px]
        md:px-0
        md:py-4
    `}
`;

export const TextTitle = styled(TextNunitoRegular14)`
    ${tw`
        text-center
        text-white
        cursor-pointer
    `}
`;

export const TextMobile = styled(TextNunitoRegular12)`
    ${tw`
        text-center
        text-white
        cursor-pointer
        md:hidden
        
    `}
`;
