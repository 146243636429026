import React, { FC } from 'react';
import { PageBlock } from '../../organisms';
import Layout from '../Layout';
import LayoutFitter from '../Layout-fitter';
import { Container } from './styles';

const NEXT_PUBLIC_PAGE_QUEM_SOMOS_SITE = process.env.NEXT_PUBLIC_PAGE_QUEM_SOMOS_SITE || '59';

interface IQuemSomos {}

const QuemSomos: FC<IQuemSomos> = ({}) => {
    return (
        <Layout>
            <LayoutFitter>
                <Container>
                    <PageBlock id={NEXT_PUBLIC_PAGE_QUEM_SOMOS_SITE} />
                </Container>
            </LayoutFitter>
        </Layout>
    );
};

export default QuemSomos;
