import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { CartService } from '../../services';

interface IUseCartDeleteItem {
    itemId: number;
    pageOrigin?: string;
}

export const useCartDeleteItem = (options?: UseMutationOptions<void, unknown, IUseCartDeleteItem>) => {
    return useMutation<void, unknown, IUseCartDeleteItem>(
        ({ itemId, pageOrigin }) => CartService.remove(itemId, pageOrigin),
        options
    );
};
