import { isError, useMutation } from '@tanstack/react-query';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { ProductService } from '../../../../../services';
import { IProduct, IProductAlertStock } from '../../../../../services/product/types';
import { EMAIL, REQUIRED } from '../../../../../utils/errors';
import { Button, Input } from '../../../../molecules';
import { IButtonVariant } from '../../../../molecules/Button/types';
import { EInputType } from '../../../../molecules/Input/types';
import { ErrorMsgField } from '../../../../atoms';

const validationForm = Yup.object().shape({
    email: Yup.string().email(EMAIL).required(REQUIRED),
    name: Yup.string().required(REQUIRED)
});
interface IProductOutStockProps {
    data: IProduct;
}
function ProductOutStock({ data }: IProductOutStockProps) {
    const {
        mutate: alertStock,
        isLoading,
        data: resultAlert,
        isError
    } = useMutation<any, any, IProductAlertStock>((data) => ProductService.alertStock(data));

    const formik = useFormik({
        initialValues: {
            email: '',
            name: ''
        },
        onSubmit: (values) => {
            alertStock({
                productId: data.id,
                ...values
            });
        },
        validateOnMount: false,
        validationSchema: validationForm
    });

    const getContent = () => {
        if (resultAlert) {
            return (
                <div className="text-14 font-semibold">
                    Cadastro realizado com sucesso, você será avisado por e-mail quando o produto estiver disponível
                </div>
            );
        }

        return (
            <>
                <div className="text-18 font-bold">Produto sem estoque no momento</div>
                <div className="text-14 font-semibold pb-4">
                    Informe seu e-mail para ser avisado quando o produto estiver disponível
                </div>
                <div className="flex flex-col gap-4">
                    <Input name="name" placeholder="Nome Completo" type={EInputType.DEFAULT} />
                    <Input name="email" placeholder="E-mail" type={EInputType.DEFAULT} />
                    <Button
                        disabled={!formik.isValid}
                        onClick={() => formik.handleSubmit()}
                        text="Avise-me"
                        variant={IButtonVariant.PRIMARY_MEDIUM}
                        isLoading={isLoading}
                    />
                    {isError && (
                        <ErrorMsgField>
                            Ocorreu um erro ao tentar cadastrar seu e-mail, tente novamente mais tarde
                        </ErrorMsgField>
                    )}
                </div>
            </>
        );
    };

    return (
        <FormikProvider value={formik}>
            <div className="bg-gray/30 p-4 gap-4 w-full text-center rounded-md py-10 flex flex-col">{getContent()}</div>
        </FormikProvider>
    );
}

export default ProductOutStock;
