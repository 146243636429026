import { ProductService } from '../../../../../../../services';
import { IProduct } from '../../../../../../../services/product/types';
import { currencyBrl } from '../../../../../../../utils/getFormattedValue';
import { capitalizeEveryLetter } from '../../../../../../../utils/string';
import ProductImage from '../../../../../../atoms/Product-image';

interface MainProductProps {
    data: IProduct;
}

export default function MainProduct({ data }: MainProductProps) {
    return (
        <div className="flex flex-row bg-gray50 px-4 py-6 gap-2 relative">
            <ProductImage data={data} width={80} height={80} className="rounded-8 shrink-0" />
            <div className="flex flex-col gap-2 w-full">
                <h3 className="text-12 font-semibold text-black line-clamp-2">{capitalizeEveryLetter(data.name)}</h3>

                {data.discount_percentage && data.special_price && (
                    <p className="text-12 text-black line-through">de R$ {currencyBrl(data.special_price)}</p>
                )}
                <p className="text-14 text-green500 font-bold leading-6">
                    R$ {currencyBrl(ProductService.getValue(data, 1))}
                </p>
            </div>

            <div className="flex items-center justify-center absolute top-[-10px] right-4 bg-[#6054A4] rounded-8 px-3.5 h-6 text-white text-12 font-bold text-center">
                Este Item
            </div>
            <div className="absolute bottom-[-10px] left-0 right-0 flex items-center justify-center">
                <div className="flex items-center justify-center bg-[#6054A4] rounded-8  w-[56px] h-6 text-white text-12 font-bold text-center">
                    +
                </div>
            </div>
        </div>
    );
}
