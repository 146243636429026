import Link from 'next/link';
import React, { Dispatch, SetStateAction } from 'react';
import { Label } from './styles';

interface IProps {
    setChecked: Dispatch<SetStateAction<boolean>>;
}

const TermOfUseAndPrivacy: React.FC<IProps> = ({ setChecked }) => {
    const isChecked = (checked: boolean) => setChecked(checked);

    return (
        <Label>
            <input type="checkbox" onClick={(e: any) => isChecked(e.target.checked)} className="cursor-pointer" />
            <span className="ml-3 text-left">
                <span className="cursor-pointer">Aceito os </span>
                <Link target="_blank" href="/termos-e-condicoes">
                    &nbsp;<span className="underline">Termos e Condições</span>&nbsp;
                </Link>
                e
                <Link target="_blank" href="/politica-de-privacidade">
                    &nbsp;<span className="underline">Política de Privacidade</span>
                    <span style={{ color: 'red' }}>*</span>
                </Link>
            </span>
        </Label>
    );
};

export default TermOfUseAndPrivacy;
