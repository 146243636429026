import tw, { styled } from 'twin.macro';
import { TextNunitoRegular25 } from '../../atoms';
import { Button as Btn } from '../../molecules';

export const Container = styled.section`
  ${tw`
    w-full
    grid
    grid-cols-[100%]
    py-[35px]

    lg:grid-cols-[30% auto]
  `}
`;

export const MenuWrapper = styled.aside`
  ${tw`
    w-full
    lg:px-[20px]
  `}
`;

export const ContentWrapper = styled.section`
  ${tw`
    w-full
    lg:px-[20px]
  `}
`;

export const TitleBar = styled.section`
  ${tw`
    flex
    flex-col
    justify-between
    items-center

    md:flex-row
  `}
`;

export const ContentTitle = styled(TextNunitoRegular25)`
  ${tw`
    mt-[30px]
    mb-[20px]

    lg:mt-[0px]
  `}
`;
export const Button = styled(Btn)`
    ${tw`
      w-full
      md:w-[unset]
    `}
`;