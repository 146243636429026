import tw, { styled } from 'twin.macro';
import { TextLatoRegular25 } from '../../atoms';

export const ContentTitle = styled(TextLatoRegular25)`
    ${tw`
      font-nunito
    `}
`;
export const Header = styled(TextLatoRegular25)`
    ${tw`
    flex
    flex-row
    w-full
    justify-between
    items-center
    m-0
    mt-[30px]
    mb-[20px]
    lg:mt-[0px]
    font-nunito
  `}
`;
