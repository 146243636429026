import { createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useState } from 'react';
import { CartItemProductOption } from '../../../../../services/cart/types';

interface IQtyProductContext {
    qty: number;
    setQty: Dispatch<SetStateAction<number>>;
    attrs: CartItemProductOption['extension_attributes']['configurable_item_options'];
    setAttr: (option_id: number, option_value: number) => void;
}

export const QtyProductContext = createContext({} as IQtyProductContext);

export const useQtyProduct = () => useContext(QtyProductContext);

export const QtyProductProvider = ({ children }: PropsWithChildren) => {
    const [qty, setQty] = useState(1);
    const [attrs, setAttrs] = useState<CartItemProductOption['extension_attributes']['configurable_item_options']>([]);
    function setAttr(option_id: number, option_value: number) {
        setAttrs((prev) => {
            const index = prev.findIndex((item) => item.option_id === option_id);
            if (index === -1) {
                return [...prev, { option_id, option_value }];
            }
            return prev.map((item) => (item.option_id === option_id ? { ...item, option_value } : item));
        });
    }

    return (
        <QtyProductContext.Provider
            value={{
                qty,
                setQty,
                attrs,
                setAttr
            }}
        >
            {children}
        </QtyProductContext.Provider>
    );
};
