import tw, { styled } from 'twin.macro';
import { Modal } from '../../atoms';
import { ModalHeader } from '../../molecules';
import ButtomMolecule from '../../molecules/Button';

export const ContainerModal = styled(Modal)`
    ${tw`
        md:max-w-modalForceSm
        h-auto
        flex-col
    `}
`;

export const Header = styled(ModalHeader)`
    ${tw`
       justify-center
       items-center
       my-[10px]
    `}
`;

export const Container = styled.div`
    ${tw`
        bg-white
        px-[20px]
        py-[15px]
        flex
        flex-col
        text-center
    `}
`;

export const Footer = styled.div`
    ${tw`
      
    `}
`;

export const Buttons = styled.div`
    ${tw`
        flex
        flex-row
        gap-[15px]
        mt-[10px]
        pt-[5px]
        px-[20px]
        pb-[20px]
      
    `}
`;

export const Button = styled(ButtomMolecule)`
    ${tw`
        flex-1
    `}
`;
export const Separator = styled.div`
    ${tw`
        flex
        w-full
        flex-col
        h-[1px]
        bg-gray
        my-[20px]
    `}
`;
