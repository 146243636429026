import { forwardRef, Ref } from 'react';
import { Address } from '../../../services/address/types';
import { ModalHeader } from '../../molecules';
import { RegisterAddressForm } from '../../organisms';
import { Container, ContainerHeader, Content } from './styles';
import { INewAddressModalHandler } from './types';
interface INewAddressModalProps {
    onClose: () => void;
    addressEdit?: Address;
}

const NewAddressModal = forwardRef(
    ({ onClose, addressEdit }: INewAddressModalProps, ref: Ref<INewAddressModalHandler>) => {
        const title = addressEdit ? 'Alterar endereço' : 'Novo endereço';
        return (
            <Container onClose={onClose}>
                <ContainerHeader>
                    <ModalHeader title={title} onPressIconClose={onClose} showIconClose />
                </ContainerHeader>
                <RegisterAddressForm handleOnDismissForm={onClose} selectedAddress={addressEdit} />
            </Container>
        );
    }
);

export default NewAddressModal;
