import tw, { styled } from 'twin.macro';

export const Container = styled.div`
    ${tw`
        flex
    `}
`;

export const ExhibitionConfigsContainer = styled.span`
    ${tw`
    flex
    flex-row
    flex-1
    justify-between

    lg:flex-row-reverse
`}
`;

export const ItemsSelectorValue = styled.span`
    ${tw`
    lg:w-[50%]
`}
`;

export const ConfigsContainer = styled.span`
    ${tw`
    flex
    flex-col
    w-[45%]
    mr-[18px]
`}
`;

export const ConfigsLayoutContainer = styled.span`
    ${tw`
    flex
    flex-row
    w-[45%]
`}
`;

export const OrderByLabel = styled.span`
    ${tw`
    font-bold
    mt-[20px]
`}
`;

export const OrderByValue = styled.select`
    ${tw`
    text-[#999]
    border-b-[1px]
    border-[#444]
    w-[100%]
    bg-[#0000]
    outline-none
    cursor-pointer
`}
`;

export const ItemsSelectorLabel = styled.span`
    ${tw`
    font-bold
    mt-[20px]
`}
`;

export const PaginationsControls = styled.div`
    ${tw`
    m-auto
    flex
    flex-row
    items-center
    justify-center
    mt-[25px]
`}
`;

export const LayoutModeContainer = styled.div`
    ${tw`
    m-auto
    flex
    flex-row
    items-center
    justify-center
    pt-[15px]
    mr-[10px]
    h-full
`}
`;

export const ItemSelectorContainer = styled.div`
    ${tw`
    flex
    flex-col
    flex-1
`}
`;

export const IconContainer = styled.div`
    ${tw`
    cursor-pointer
    first:mr-[15px]
`}
`;
