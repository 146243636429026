import { useBlockConfig } from '../../../../hooks/use-block-config';

function Copyright() {
    const { data: config } = useBlockConfig();
    return (
        <div className="bg-[#424242] flex flex-col gap-4 items-center text-white p-4 md:text-black md:p-0 md:bg-transparent">
            <div
                className="text-12 text-center"
                dangerouslySetInnerHTML={{ __html: config?.footer_endereco as any }}
            ></div>
            <div
                className="text-center font-bold text-12 md:font-normal"
                dangerouslySetInnerHTML={{ __html: config?.footer_copyright as any }}
            ></div>
        </div>
    );
}

export default Copyright;
