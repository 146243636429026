import tw, { styled } from 'twin.macro';

export const ListItemContainer = styled.div`
    ${tw`
        shadow-md
        flex
        flex-col
        p-[10px]
        rounded-15
        cursor-pointer
        w-full
        animate-pulse 
        border-gray
        border
    `}
`;

export const ListItemHeader = styled.div`
    ${tw`
        flex
        justify-between
        items-center
        border-b-gray
        border-b-[1px]
        pb-[5px]
    `}
`;

export const ListItemTitle = styled.div`
    ${tw`
         animate-pulse 
         rounded-5
         bg-gray
         w-[80%]
         h-[15px]
         my-[1.5px]
    `}
`;

export const TrashIcon = styled.div`
    ${tw`
        animate-pulse 
        rounded-5
        bg-gray
        w-[15px]
        h-[15px]
    `}
`;

export const ListItemContent = styled.div`
    ${tw`
        flex-1
        flex
        justify-evenly
        py-[5px]
        gap-[10px]
    `}
`;

export const ListItemInfoContainer = styled.div`
    ${tw`
        flex
        flex-col
        items-center
        border-solid
        border-[1px]
        animate-pulse 
        border-gray
        rounded-[5px]
        p-[2px]
        my-[10px]
        w-full
    `}
`;

export const InfoHeader = styled.div`
    ${tw`
    animate-pulse 
    rounded-5
    bg-gray
    w-[80%]
    h-[15px]
    my-[1.5px]
    `}
`;

export const InfoData = styled.span`
    ${tw`
       animate-pulse 
       rounded-5
       bg-gray
       w-[15%]
       h-[15px]
       my-[1.5px]
    `}
`;
export const Button = styled.span`
    ${tw`
       animate-pulse 
       rounded-5
       bg-gray
      flex-1
      h-[38px]
    `}
`;
