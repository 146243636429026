import tw, { styled } from 'twin.macro';

export const Container = styled.div`
    ${tw`
        border-[#E6E6E6]
        border-[0.5px]
        rounded-8
        overflow-hidden
        gap-4
        flex
        flex-col
        shrink-0
        mx-4
        md:p-4
        md:mx-0
    `}
`;

export const Title = styled.div`
    ${tw`
        text-22
        font-semibold
        text-black
        hidden
       
        md:block
     
    `}
`;

export const Table = styled.div`
    ${tw`
        w-full
        border-[#E6E6E6]
        md:border-[0.5px]
        md:p-2
        md:rounded-8
        [&>*:nth-child(odd)]:bg-[#F2F2F2] [&>*:nth-child(even)]:bg-white
    `}
`;
export const Row = styled.div`
    ${tw`
        flex
        flex-row
        justify-between
        px-4
        p-2
        gap-4
        md:rounded-8
    `}
`;

export const Name = styled.h2`
    ${tw`
        text-12
        h-6
        flex
        items-center
        text-[#010101]
        md:flex-1
        md:font-bold
        md:text-14
    `}
`;

export const Value = styled.div`
    ${tw`
        text-12
        h-6
        flex
        items-center
        text-[#5E5E5E]
        md:flex-1
        md:text-14
    `}
`;
