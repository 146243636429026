import { HTMLAttributes } from 'react';
import { LayoutFitter } from '../../../templates';
import { Container, Content, Item, ItemDescription, ItemHeader, ItemTitle } from './styles';
import Image from 'next/image';
import { useBlockFooterDelivery } from '../../../../hooks/use-block-footer-delivery';

function FooterDeliveryBanner(props: HTMLAttributes<HTMLDivElement>) {
    const { data } = useBlockFooterDelivery();

    return (
        <Container {...props}>
            <LayoutFitter>
                <Content>
                    {data?.map((item, index) => (
                        <Item key={index}>
                            <Image src={item.image} width={62} height={62} alt={item.title} />
                            <ItemHeader>
                                <ItemTitle dangerouslySetInnerHTML={{ __html: item.title }}></ItemTitle>
                                <ItemDescription
                                    dangerouslySetInnerHTML={{ __html: item.description }}
                                ></ItemDescription>
                            </ItemHeader>
                        </Item>
                    ))}
                </Content>
            </LayoutFitter>
        </Container>
    );
}

export default FooterDeliveryBanner;
