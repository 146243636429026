import React, { FC, SVGProps } from 'react';

const FlagJcb: FC<SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg viewBox="0 0 42 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M39.225 0h-36.4C1.626 0 .654.986.654 2.202v21.525c0 1.216.973 2.202 2.173 2.202h36.399c1.2 0 2.173-.986 2.173-2.202V2.202C41.398.986 40.425 0 39.225 0z"
                fill="#0E4C96"
            />
            <path
                d="M34.185 19.09c0 2.291-1.832 4.149-4.094 4.149H7.865V6.84c0-2.292 1.832-4.15 4.094-4.15h22.226v16.4z"
                fill="#fff"
            />
            <path
                d="M26.94 13.325c.634.014 1.273-.029 1.905.022.64.12.795 1.103.226 1.425-.388.212-.85.079-1.27.116h-.861v-1.563zm2.272-1.77c.141.505-.339.957-.818.888H26.94c.01-.476-.02-.992.014-1.443.583.017 1.171-.034 1.75.027.25.063.457.27.508.528zm3.5-7.481c.027.963.004 1.977.012 2.96-.002 3.997.004 7.993-.003 11.99-.025 1.497-1.335 2.799-2.803 2.829-1.47.006-2.939 0-4.408.002v-6.042c1.6-.008 3.203.017 4.803-.012.742-.048 1.556-.544 1.59-1.372.088-.831-.686-1.406-1.42-1.497-.283-.008-.275-.084 0-.117.7-.153 1.25-.888 1.044-1.624-.176-.774-1.02-1.073-1.722-1.072-1.432-.01-2.864-.001-4.295-.004.009-1.128-.02-2.257.015-3.384.114-1.47 1.456-2.684 2.904-2.657h4.283z"
                fill="url(#prefix__paint0_linear)"
            />
            <path
                d="M9.331 6.884c.037-1.496 1.352-2.787 2.818-2.808 1.464-.005 2.928-.001 4.392-.002-.004 5.003.008 10.006-.006 15.01-.057 1.477-1.358 2.743-2.808 2.769-1.466.005-2.933 0-4.4.002V15.61c1.425.34 2.919.486 4.372.26.869-.142 1.82-.574 2.113-1.488.217-.781.095-1.603.127-2.405v-1.862h-2.515c-.011 1.232.023 2.465-.018 3.695-.068.756-.807 1.237-1.51 1.211-.873.01-2.603-.64-2.603-.64-.004-2.308.026-5.198.038-7.497z"
                fill="url(#prefix__paint1_linear)"
            />
            <path
                d="M17.479 10.867c-.132.028-.027-.457-.06-.641.008-1.165-.02-2.33.015-3.494.113-1.477 1.466-2.693 2.92-2.658h4.278c-.004 5.003.008 10.007-.006 15.01-.056 1.477-1.358 2.743-2.807 2.77-1.467.005-2.934 0-4.4.002v-6.843c1.001.833 2.363.963 3.61.965.941 0 1.877-.147 2.79-.367v-1.254c-1.03.52-2.24.85-3.381.551-.796-.2-1.374-.98-1.361-1.813-.093-.866.408-1.78 1.248-2.037 1.043-.331 2.179-.078 3.156.352.21.111.422.249.338-.106v-.985c-1.634-.394-3.373-.54-5.016-.11-.475.135-.938.341-1.324.658z"
                fill="url(#prefix__paint2_linear)"
            />
            <defs>
                <linearGradient
                    id="prefix__paint0_linear"
                    x1={25.51}
                    y1={12.964}
                    x2={32.724}
                    y2={12.964}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#007B40" />
                    <stop offset={1} stopColor="#55B330" />
                </linearGradient>
                <linearGradient
                    id="prefix__paint1_linear"
                    x1={9.327}
                    y1={12.964}
                    x2={16.541}
                    y2={12.964}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#1D2970" />
                    <stop offset={1} stopColor="#006DBA" />
                </linearGradient>
                <linearGradient
                    id="prefix__paint2_linear"
                    x1={17.418}
                    y1={12.965}
                    x2={24.632}
                    y2={12.965}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#6E2B2F" />
                    <stop offset={1} stopColor="#E30138" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default FlagJcb;
