import { motion, MotionProps } from 'framer-motion';
import ImageReact, { ImageLoader, ImageProps } from 'next/image';
import { FC, useEffect, useState } from 'react';

const ImageMotion = motion(ImageReact);

const myLoader: ImageLoader = ({ src, width, quality }) => {
    return `${src}`;
};
export type IImageProps = Omit<ImageProps, 'alt'> & {
    onError?: () => void;
    objectFit?: string;
    alt?: string;
    layoutId?: string;
    forceHeight?: boolean;
    disableImageOnError?: boolean;
    onLoad?: () => void;
} & MotionProps;

const Image: FC<IImageProps> = ({
    quality = 100,
    onError,
    src: srcProps,
    objectFit,
    forceHeight,
    disableImageOnError = false,
    onLoad,
    ...props
}) => {
    const [src, setSrc] = useState(srcProps);
    const [style, setStyle] = useState<any>({
        objectFit
    });

    useEffect(() => {
        setSrc(srcProps);
    }, [srcProps]);

    const handleError = () => {
        if (!disableImageOnError) setSrc('/images/favicon-primary.png');
        // setStyle({ width: `${props.width}px`, height: `${props.height}px`, objectFit });
        if (onError) onError();
    };
    let styleObj = {
        ...style,
        ...props.style
    };

    if (props.height) {
        styleObj = {
            ...style,
            maxHeight: `${props.height}px`,
            height: forceHeight ? `${props.height}px` : 'initial'
        };
    }
    return (
        <picture>
            <ImageMotion
                placeholder="blur"
                loading="lazy"
                loader={myLoader}
                quality={quality}
                variants={{
                    hover: {
                        scale: 1.2
                    }
                }}
                blurDataURL={src as string}
                src={src}
                onLoad={onLoad}
                onError={handleError}
                {...props}
                alt={props.alt || 'image'}
                style={styleObj}
            />
        </picture>
    );
};

export default Image;
