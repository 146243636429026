import tw, { styled } from 'twin.macro';

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
    isChecked: boolean;
}

export const Checkbox = styled.input.attrs<CheckboxProps>(({ isChecked }) => ({
    type: 'checkbox',
    checked: isChecked
}))<CheckboxProps>`
    ${tw`cursor-pointer outline-none border-solid rounded-md w-5 h-5`}
    border-width: 2px;
    border-color: ${({ isChecked }) => (isChecked ? '#9FBE43' : '#DBDBDB')};
    background-color: ${({ isChecked }) => (isChecked ? '#9FBE43' : 'white')};
    appearance: none;
    -webkit-appearance: none;
    position: relative;

    &:checked::after {
        content: '';
        ${tw`absolute`};
        top: 7px;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        border: solid white;
        justify-content: center;
        align-items: center;
        display: flex;
        border-width: 0 2px 2px 0;
        width: 6px; // Ajustado para se adaptar melhor ao tamanho do checkbox
        height: 10px; // Ajustado para manter proporção
    }

    // Garantir que o checkbox mantenha uma boa centralização
    display: flex;
    align-items: center;
    justify-content: center;
`;
