import { useEffect, useState } from 'react';
import { useInstallments } from '../../../../../../hooks/use-installments';
import { ProductService } from '../../../../../../services';
import { IProduct } from '../../../../../../services/product/types';
import { currencyBrl } from '../../../../../../utils/getFormattedValue';
import FadeIn from '../../../../../atoms/Fade-in';
import { Button } from '../../../../../molecules';
import { IButtonVariant } from '../../../../../molecules/Button/types';
import MainProduct from './MainProduct';
import SecondaryProduct from './SecondaryProduct';
import SecondaryProductSkeleton from './SecondaryProduct/Skeleton';

interface UpSellMobileProps {
    data: IProduct;
    products?: IProduct[];
    isLoadingProducts: boolean;
    isLoadingAddCart: boolean;
    onAdd: (products: IProduct[]) => void;
}

export default function UpSellMobile({
    data,
    products,
    isLoadingProducts,
    isLoadingAddCart,
    onAdd
}: UpSellMobileProps) {
    const [itemsChecked, setItemsChecked] = useState<IProduct[]>(products || []);

    useEffect(() => {
        setItemsChecked(products || []);
    }, [products]);

    const handleCheck = (checked: boolean, product: IProduct) => {
        setItemsChecked(
            checked ? [...itemsChecked, product] : itemsChecked.filter((idItem) => idItem.id !== product.id)
        );
    };

    const getSumValues = () => {
        return itemsChecked?.reduce(
            (acc, item) => {
                acc.special_price += item.special_price || 0;
                acc.price += ProductService.getValue(item, 1);
                return acc;
            },
            { special_price: 0, price: 0 }
        );
    };

    const { data: installments, isLoading: isLoadingInstallments } = useInstallments(getSumValues().price);

    const values = getSumValues();
    const lastInstallment = installments?.[installments.length - 1];

    const getInstallments = () => {
        if (isLoadingInstallments) return <div className="w-2/4 h-3 self-center bg-gray rounded-md animate-pulse" />;

        if (installments) {
            return (
                <p className="text-12 text-black font-normal text-center">
                    ou R$ {currencyBrl(Number(lastInstallment))}{' '}
                    <span className="font-light">em até {installments.length}x sem Juros</span>
                </p>
            );
        }
        return null;
    };

    return (
        <div className="flex flex-col p-4 mt-3 bg-white border-[0.5px] border-gray100 gap-4 rounded-8 md:hidden">
            <h3 className="text-14 font-medium text-gray900">Frequentemente comprados juntos</h3>
            <MainProduct data={data} />
            <div className="flex flex-col divide-y-[0.5px] divide-gray100">
                {isLoadingProducts
                    ? [...Array(3)].map((_, index) => <SecondaryProductSkeleton key={index} />)
                    : products?.map((product) => (
                          <SecondaryProduct
                              key={product.id}
                              data={product}
                              isChecked={itemsChecked.find((item) => item.id === product.id) ? true : false}
                              onCheck={(checked) => handleCheck(checked, product)}
                          />
                      ))}
                <div className="pt-4 flex flex-col gap-2">
                    <FadeIn className="flex flex-col gap-2" show={itemsChecked.length > 0}>
                        {itemsChecked.length > 0 && (
                            <>
                                {values.special_price > 0 && (
                                    <p className="text-12 text-black font-light line-through text-center">
                                        de R$ {currencyBrl(values.special_price)}
                                    </p>
                                )}
                                <p className="text-16 text-green500 font-semibold text-center">
                                    por R$ {currencyBrl(values.price)}
                                </p>
                                {getInstallments()}
                            </>
                        )}
                    </FadeIn>
                    <div className="pt-2 flex flex-col">
                        <Button
                            variant={IButtonVariant.PRIMARY_MEDIUM}
                            disabled={itemsChecked.length === 0}
                            text="Adicionar os selecionados"
                            isLoading={isLoadingAddCart}
                            onClick={() => onAdd(itemsChecked)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
