import { HTMLMotionProps, motion } from 'framer-motion';
import tw, { styled } from 'twin.macro';

export enum IconButtonVariant {
    Primary = 'primary',
    Secondary = 'secondary'
}

interface IconButtonProps extends HTMLMotionProps<'button'> {
    variant?: IconButtonVariant;
}

const Button = styled(motion.button)<{ $variant: IconButtonVariant }>`
    ${({ $variant }) => {
        if ($variant === IconButtonVariant.Primary) {
            return tw`
                p-1
                bg-white
                rounded-8
                hover:bg-[#F9FBF3] 
                focus:bg-[#F9FBF3]
            `;
        }
        if ($variant === IconButtonVariant.Secondary) {
            return tw`
                p-3
                bg-white
                rounded-full
                hover:bg-[#F9FBF3] 
                focus:bg-[#F9FBF3]
            `;
        }
    }}
`;

function IconButton({ children, variant = IconButtonVariant.Primary, ...props }: IconButtonProps) {
    return (
        <Button $variant={variant} whileHover="hover" animate="rest" {...props}>
            <motion.div
                variants={{
                    hover: {
                        scale: 1.1
                    }
                }}
            >
                {children}
            </motion.div>
        </Button>
    );
}

export default IconButton;
