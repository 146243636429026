import { Container, ImageContainer, Price, SubPrice, Title } from './styles';

interface ProductItemProps {
    forceWidth?: boolean;
}
function ProductItemSkeleton({ forceWidth = false }: ProductItemProps) {
    return (
        <Container $forceWidth={forceWidth}>
            <ImageContainer />
            <Title />
            <div className="flex flex-col gap-1">
                <Price />
                <SubPrice />
            </div>
        </Container>
    );
}

export default ProductItemSkeleton;
