import { IconButton } from '@mui/material';
import { IconClose, Modal } from '../../atoms';
import LoginForm from '../Login-form';
import ModalPortal from '../../atoms/Modal-portal';
import tw, { styled } from 'twin.macro';
import ForgotPassword from '../../templates/Forgot-password';
import { useEffect, useState } from 'react';

type LoginDrawerProps = {
    open: boolean;
    onClose: () => void;
    onLogin?: () => void;
};

const ModalLogin = styled(Modal)`
    ${tw`right-0 w-full h-full md:!max-w-[42%] lg:!max-w-[512px] md:!h-full md:rounded-[0] md:max-h-full md:!top-0 overflow-auto`}
`;

export const LoginDrawer = ({ onClose, open, onLogin }: LoginDrawerProps) => {
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const toggleForgottenPassword = () => {
        setShowForgotPassword((prev) => !prev);
    };

    useEffect(() => {
        setShowForgotPassword(false);
    }, [open]);

    return (
        <ModalPortal>
            {open && (
                <ModalLogin animateFromRight onClose={onClose}>
                    <div className="p-6 md:p-[56px] flex flex-col flex-1">
                        <div className="flex items-start justify-end">
                            <IconButton onClick={onClose}>
                                <IconClose stroke="#323232" />
                            </IconButton>
                        </div>
                        <div className="flex flex-col flex-1">
                            {showForgotPassword ? (
                                <ForgotPassword toggleForgottenPassword={toggleForgottenPassword} />
                            ) : (
                                <LoginForm
                                    onClose={onClose}
                                    onLogin={onLogin}
                                    toggleForgottenPassword={toggleForgottenPassword}
                                />
                            )}
                        </div>
                    </div>
                </ModalLogin>
            )}
        </ModalPortal>
    );
};
