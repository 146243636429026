import tw, { styled } from 'twin.macro';
import { TextLatoBold15, TextLatoRegular15 } from '../../atoms';
import { IContainerInputProps } from './types';

export const Container = styled.div`
    ${tw`
        pb-[20px]
        flex
        flex-col
    `}
`;
export const ContainerInput = styled.div<IContainerInputProps>`
    ${({ $error }) => ($error ? tw`border border-primary` : '')}

    ${tw`
        min-h-[48px]
        p-[15px]
        rounded-15
        flex
        flex-col
        justify-center
        bg-white
        shadow-md
        cursor-pointer
    `}
`;

export const Input = styled.div`
    ${tw`
        pb-[15px]
        flex
        flex-row
        justify-between
        items-center
    `}
`;

export const ContainerList = styled.div`
    ${tw`
        flex
        flex-col
        gap-[15px]
    `}
`;

export const Label = styled(TextLatoRegular15)``;
export const ItemText = styled(TextLatoRegular15)`
    ${tw`
        ml-[10px]
    `}
`;
export const Separator = styled.div`
    ${tw`
        h-1
        bg-gray
    `}
`;
export const ItemSelected = styled.div`
    ${tw`
        px-[20px]
        items-center
        flex
        flex-row
        flex-nowrap
        h-[40px]
    `}
`;
export const ItemLeft = styled.div`
    ${tw`
        flex
        flex-1
        flex-row
    `}
`;
export const TextAdd = styled(TextLatoRegular15)`
    ${tw`
        text-darkGray
    `}
`;
export const TextTitle = styled(TextLatoBold15)`
    ${tw`
        ml-[10px]
    `}
`;
export const Item = styled.div`
    ${tw`
        items-center
        flex    
        flex-row
        flex-nowrap
    `}
`;
