import tw, { styled } from 'twin.macro';
import { ICartItemVariant } from './types';
import { IconTrash } from '../../atoms';
import IconCart from '../../atoms/icons/Cart';

interface ICustomStyleProps {
    variant: ICartItemVariant;
}

export const Container = styled.div<ICustomStyleProps>`
    ${tw`relative flex gap-4 overflow-hidden  rounded-8 w-full`}

    ${({ variant }) => {
        switch (variant) {
            case ICartItemVariant.DEFAULT:
                return tw`flex-col `;
            case ICartItemVariant.PRIMARY_BIG:
                return tw`items-end `;
        }
    }}
`;

export const CartItemActions = styled.div<ICustomStyleProps>`
    ${tw`flex justify-end flex-1 `}

    ${({ variant }) => {
        switch (variant) {
            case ICartItemVariant.DEFAULT:
                return tw``;
            case ICartItemVariant.PRIMARY_BIG:
                return tw`
                    justify-end
                    gap-4
                `;
        }
    }}
`;

export const ProductTitle = styled.h1`
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.2;
    max-height: 2.4em;
    font-weight: 600;
`;

export const CartItemDetails = styled.div`
    ${tw`flex gap-4 w-full`}
`;

export const DescriptionProduct = styled.h1<ICustomStyleProps>`
    ${tw`flex flex-col `}

    ${({ variant }) => {
        switch (variant) {
            case ICartItemVariant.DEFAULT:
                return tw`w-full `;
            case ICartItemVariant.PRIMARY_BIG:
                return tw`
                    w-full
                `;
        }
    }}
`;

export const Trash = styled(IconTrash)`
    ${tw`
        w-6
        h-6
        shrink-0
        text-[#EB001B]
        cursor-pointer
    `}
`;
