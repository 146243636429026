import { CategoryService, EstablishmentService, ProductService, RouterService } from '../../services';
import { Banner, BannerType } from '../../services/banner';
import { useCategories } from '../use-categories';

function useOnClickBanner() {
    const { data: categories } = useCategories();

    const handleClick = (item: Banner) => {
        if (!item.type) return;

        if (item.type == BannerType.PRODUTO) {
            return RouterService.toRoute({
                options: {
                    url: EstablishmentService.createUrl(`/produto/${item.param}`)
                }
            });
        } else if (item.type == BannerType.LINK) {
            window.location.href = item.param;
            return;
        } else if (item.type == BannerType.CATEGORIA) {
            if (!categories) return;
            const category = CategoryService.getCategoryFromCategories(item.param, categories);
            if (!category) return;

            return RouterService.toRoute({
                options: {
                    url: {
                        pathname: ProductService.getUrlSearchProduct(category.url),
                        query: {
                            highlights: item.skuHighlight || null
                        }
                    }
                }
            });
        } else if (item.type == BannerType.PAGE) {
            return RouterService.toRoute({
                options: {
                    url: EstablishmentService.createUrl(`/pagina/${item.param.replace('site-page-', '')}`)
                }
            });
        }
    };

    return handleClick;
}

export default useOnClickBanner;
