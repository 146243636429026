import Link from 'next/link';
import { useCategories, useOutsideClick } from '../../../../hooks';
import { ProductService } from '../../../../services';
import { LayoutFitter } from '../../../templates';
import MenuFirst from '../Menu';
import { Container, Content, List, Menu, MenuText } from './styles';
import { useRef, useState } from 'react';
import { TextNunitoRegular16 } from '../../../atoms';
import IconMenu from '../../../atoms/icons/Menu';
import tw, { styled } from 'twin.macro';
import dynamic from 'next/dynamic';
const MenuDesktop = dynamic(() => import('../Menu/MenuDesktop'), { ssr: false });
const MenuMobile = dynamic(() => import('../Menu/MenuMobile'), { ssr: false });

export const Icon = styled(IconMenu)`
    ${tw`
        w-6
        h-4
        fill-[#9FBE43]
    `}
`;

function Categories() {
    const { data } = useCategories();
    const [isOpen, setIsOpen] = useState(false);
    const refRoot = useRef<HTMLDivElement>(null);
    const refMenuDesktop = useRef<HTMLDivElement>(null);

    useOutsideClick([refRoot, refMenuDesktop], () => {
        setIsOpen(false);
    });

    return (
        <>
            <Container layout="preserve-aspect" id="header-menu-categories">
                <LayoutFitter>
                    <Content layout="preserve-aspect">
                        <div
                            className={`cursor-pointer gap-2 flex flex-row items-center mr-2`}
                            id="header-menu"
                            onClick={() => setIsOpen((old) => !old)}
                            ref={refRoot}
                        >
                            <Icon />
                            <TextNunitoRegular16 className="hidden md:flex">Departamentos</TextNunitoRegular16>
                        </div>

                        <List>
                            {data
                                ?.filter((category) => category.isActive)
                                ?.slice(0, 8)
                                .map((category) => (
                                    <Link
                                        href={ProductService.getUrlSearchProduct(category.url)}
                                        className="url-produto"
                                        key={category.id}
                                    >
                                        <Menu>
                                            <MenuText>{category.name}</MenuText>
                                        </Menu>
                                    </Link>
                                ))}
                        </List>
                    </Content>
                </LayoutFitter>
            </Container>
            <MenuDesktop isOpen={isOpen} onClose={() => setIsOpen(false)} forwardedRef={refMenuDesktop} />
            <MenuMobile isOpen={isOpen} onClose={() => setIsOpen(false)} />
        </>
    );
}
export default Categories;
