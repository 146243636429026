import tw, { styled } from 'twin.macro';

export const CategoriesList = styled.div`
    ${tw`
    flex
    flex-col
    px-[10px]
  `}
`;

export const CategoryItem = styled.label`
    ${tw`
   py-[5px]
   flex
   flex-row
   gap-[5px]
   items-center
  `}
`;

export const Checkbox = styled.input`
    ${tw`cursor-pointer`}
`;
