import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Bar, Button, Container, TextDelivery, TextPickUp } from './styles';
import { IConfigBar, IEstablishmentSelectFilterProps } from './types';

const EstablishmentSelectFilter = ({ onChange, ...props }: IEstablishmentSelectFilterProps) => {
    const [isDelivery, setIsDelivery] = useState(true);
    const [configBar, setConfigBar] = useState<IConfigBar>({
        width: 0,
        x: 0
    });

    const refButtonDelivery = useRef<HTMLDivElement>(null);
    const refButtonPickUp = useRef<HTMLDivElement>(null);
    const setStyleBar = (isDelivery: boolean) => {
        let x;
        let width;
        if (isDelivery) {
            x = 0;
            width = refButtonDelivery.current?.offsetWidth;
        } else {
            width = refButtonPickUp.current?.offsetWidth;
            x = (refButtonDelivery.current?.offsetWidth || 0) + 18;
        }
        setConfigBar({
            width: width || 0,
            x
        });
    };

    const handlePress = (isDelivery: boolean) => {
        setIsDelivery(isDelivery);
        setStyleBar(isDelivery);
        if (onChange) onChange(isDelivery);
    };
    useLayoutEffect(() => {
        setTimeout(() => setStyleBar(true), 300);
    }, [refButtonDelivery.current]);

    return (
        <Container {...props}>
            <Bar width={configBar.width} x={configBar.x} />
            <Button onClick={() => handlePress(true)} ref={refButtonDelivery}>
                <TextDelivery isDelivery={isDelivery}>DELIVERY</TextDelivery>
            </Button>
            <Button onClick={() => handlePress(false)} ref={refButtonPickUp}>
                <TextPickUp isDelivery={isDelivery}>RETIRAR NA LOJA</TextPickUp>
            </Button>
        </Container>
    );
};

export default EstablishmentSelectFilter;
