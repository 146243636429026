import { FC } from 'react';
import tw, { styled } from 'twin.macro';

const Container = styled.span`
    line-height: 22px;
    ${tw`
        font-nunito
        text-18
        text-black
        font-normal
    `}
`;

const TextLatoRegular18: FC<any> = ({ children, ...props }) => {
    return <Container {...props}>{children}</Container>;
};

export default TextLatoRegular18;
