import React, { FC } from 'react';
import { Container } from './styles';
import { IInputShadowProps } from './types';

const InputShadow: FC<IInputShadowProps> = ({ children, error, ...rest }) => {
    return (
        <Container {...rest} $error={error}>
            {children}
        </Container>
    );
};

export default InputShadow;
