import { useMemo } from 'react';
import { ucfirst } from '../../../../../../utils/string';
import { Checkbox as CustomCheckbox } from '../../../../../atoms/Checkbox';
import { useProductSearchFilters } from '../../../hooks/useProductSearchFilters';
import MenuCollapse from '../Menu-collapse';
import { Container, Item, List } from './styles';

interface VoltagemProps {
    isMobile?: boolean;
}
function Voltagem({ isMobile = false }: VoltagemProps) {
    const { filters, setFiltersDesktop, setFiltersMobile, aggregations } = useProductSearchFilters();

    function handleChange(id: string) {
        const { voltagem } = filters;
        const index = voltagem.indexOf(id);
        let newFilters = [];

        if (index === -1) {
            newFilters = [...voltagem, id];
        } else {
            newFilters = voltagem.filter((item) => item !== id);
        }

        if (isMobile) {
            setFiltersMobile({ voltagem: newFilters });
            return;
        }

        setFiltersDesktop({ voltagem: newFilters });
    }

    const items = useMemo(() => {
        return (
            aggregations?.Voltagem.buckets
                .map((voltagem: any) => {
                    return {
                        key: voltagem.key,
                        name: ucfirst(voltagem.key?.toLowerCase())
                    };
                })
                .filter((v) => v != null) || []
        );
    }, [aggregations?.Voltagem.buckets]);

    if (!items.length) return null;

    return (
        <Container>
            <MenuCollapse title="Voltagem" qty={filters.voltagem.length || 0}>
                <List>
                    {items.map((voltagem: any) => (
                        <Item key={voltagem.key} onClick={() => handleChange(voltagem.key)}>
                            <CustomCheckbox
                                name={voltagem.key}
                                isChecked={filters.voltagem.includes(voltagem.key)}
                                withoutFormik
                                onCheckboxChange={() => handleChange(voltagem.key)}
                            />
                            <div>{voltagem.name}</div>
                        </Item>
                    ))}
                </List>
            </MenuCollapse>
        </Container>
    );
}

export default Voltagem;
