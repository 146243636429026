import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { CartService } from '../../services';
import { CartItemWithElastic } from '../../services/cart/types';
import { CART_ITEMS } from '../../utils/react-query-keys';

export const useCartItems = (params?: UseQueryOptions<CartItemWithElastic[]>) => {
    return useQuery<CartItemWithElastic[], unknown, CartItemWithElastic[]>([CART_ITEMS], () => CartService.list(), {
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        ...params
    });
};
