import tw, { styled } from 'twin.macro';

export const Container = styled.div`
    ${tw`
        flex 
        flex-col
        border-[0.5px] 
        border-[#E6E6E6] 
        rounded-8 
        p-4
        gap-4
    `}
`;
export const Content = styled.div`
    ${tw`
        flex 
        items-center 
        justify-between 
    `}
`;
export const Column = styled.div`
    ${tw`
       flex 
       gap-2 
       items-center
    `}
`;

export const ChangeZipCode = styled.button`
    ${tw`
        text-12 
        font-light 
        cursor-pointer 
        text-[#698122]
    `}
`;
