import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { CreditCardItem } from '../../molecules';
import CreditCardItemSkeleton from '../../molecules/Credit-card-item-skeleton';
import { Container } from './styles';
import { ICreditCardListProps } from './types';
import cardService from '../../../services/card';
import { CARDS } from '../../../utils/react-query-keys';
import { CardList, ListTitle } from '../../molecules/Credit-card-item/styles';
import { useUserInfo } from '../../../hooks/use-user-info';
import { TtxPayCardData } from '../../../services/card/types';

const CreditCardList = ({ title }: ICreditCardListProps) => {
    const user = useUserInfo().data;

    const dataCard: TtxPayCardData = {
        customer: user?.email ?? '',
        document_type: 'cpf',
        document_number: user?.taxvat ?? ''
    };

    const { data: card, isLoading } = useQuery([CARDS], () => cardService.getTtxPayCards(dataCard));

    return (
        <Container>
            {title ? <ListTitle>Meus cartões</ListTitle> : null}
            <CardList>
                {isLoading ? (
                    <>
                        <CreditCardItemSkeleton key={'creditcardskel1'} />
                        <CreditCardItemSkeleton key={'creditcardskel2'} />
                        <CreditCardItemSkeleton key={'creditcardskel3'} />
                    </>
                ) : (
                    card?.map((elem) => <CreditCardItem key={elem.id} data={elem} />)
                )}
            </CardList>
        </Container>
    );
};

export default CreditCardList;
