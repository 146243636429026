import tw, { styled } from 'twin.macro';
import { Image } from '../../atoms';

export const Container = styled.div`
    ${tw`
        flex
        flex-col
    `}

    .swiper-slide {
        ${tw`
            odd:rounded-l-10
            even:rounded-r-10
            overflow-hidden
        `}
    }
`;
export const Img = styled(Image)`
    ${tw`
    `};
`;

export const Skeleton = styled.div`
    aspect-ratio: 16/3;
    ${tw`
    animate-pulse 
    bg-gray 
    w-full
    
    h-full
    rounded-5
    `}
`;
