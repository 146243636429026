import { motion } from 'framer-motion';
import tw, { styled } from 'twin.macro';
import { IRegisteredAddress } from '../../organisms/My-addresses-registered-address/types';

export const RegisteredAddressContainer = styled(motion.div)<IRegisteredAddress>`
    > .MuiButtonBase-root {
        ${tw`
        absolute
        right-[5px]
        z-[0]
    `}
    }
    ${tw`
    flex
    items-center
    rounded-lg
    py-6
    px-4
    relative
    flex-[1 0]
    bg-white
    gap-3
    cursor-pointer
    border-[0.5px]
    border-[#E6E6E6]
    justify-between
  
`}

    ${({ $defaultAddress }) =>
        $defaultAddress
            ? tw`
      shadow-md
      `
            : tw`
     
    `}
`;

export const AddressDataContainer = styled(motion.div)`
    ${tw`
    mt-[10px]
    flex
    
    // md:hidden
    `}
`;

export const AddressDataValue = styled(motion.span)`
    ${tw`
    ml-[5px]
    md:line-clamp-1
    // md:max-w-[150px]
    // md:whitespace-nowrap
    // md:overflow-hidden
`}
`;

export const RegisteredAddressActions = styled.span`
    ${tw`
        flex
        flex-col
        md:flex-row
        gap-8
    `}
`;

export const AddressTitleWrapper = styled.span`
    ${tw`
        flex
        items-center
        gap-4
    `}
`;
