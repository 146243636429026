import tw, { styled } from 'twin.macro';

export const Container = styled.div`
  ${tw`
      flex
      flex-col
      justify-end
      w-full
      rounded-lg
      p-[5px]
      shadow-[0px 4px 10px #0003]
      transition-all
      animate-pulse
    `}
`;

export const Footer = styled.div`
  ${tw`
      flex
    `}
`;

export const UpperRow = styled.div`
  ${tw`
      flex
      justify-between
      rounded-5
      m-[5px]
      px-[5px]
    `}
`;

export const LowerRow = styled.div`
  ${tw`
    flex
      rounded-5
      m-[5px]
      px-[5px]
    `}
`;

export const Flag = styled.div`
  ${tw`
      h-[25px]
      w-[40px]
      bg-lightGray
    `}
`;

export const BrandContainer = styled.div`
  ${tw`
      flex
    `}
`;

export const BrandLabel = styled.div`
  ${tw`
      ml-[10px]
      font-[600]
      w-[86px]
      h-[26px]
      bg-lightGray
    `}
`;

export const TrashIcon = styled.div`
  ${tw`
      cursor-pointer
      w-[19px]
      h-[25px]
      bg-lightGray
    `}
`;

export const NumberSegment = styled.div`
  ${tw`
      // mr-[10px]
      w-[40px]
      h-[20px]
      bg-lightGray
    `}
`;
