import tw, { styled } from 'twin.macro';
import { Modal } from '../../atoms';

export const Container = styled(Modal)`
    ${tw`
        flex
        flex-col
        w-[420px]
        py-6
        px-8
    `}

    height: auto;
    max-height: 500px;
    min-height: 200px;
    overflow-y: auto;
`;

export const Header = styled.div`
    ${tw`
        gap-6
        mb-4
    `}
`;

export const Body = styled.div`
    ${tw`
        
    `}
`;

export const Footer = styled.div`
    ${tw`
        mt-8
        mb-4
        flex
        flex-col-reverse
        justify-center
        items-center
        gap-4
        md:flex-row
        md:gap-6
    `}
`;

export const Button = styled.button`
    ${tw`
    border-[1px]
    border-[#DBDBDB]
    p-[7px] 
    rounded-md 
    transition-colors 
    duration-200
`}

    &:hover {
        ${tw`bg-[#F5F5F5] border-[#9FBE43]`}
    }
`;

export const ButtonFavorite = styled.button`
    ${tw`
        border-[1px]
        border-[#DBDBDB]
        p-[8px] 
        rounded-md
    `}
`;

export const ButtonConfirm = styled.button`
    ${tw`
    border-[1px]
    bg-[#9FBE43]
    text-white
    font-medium
    border-[#9FBE43]
    p-[10px] 
    cursor-pointer 
    rounded-md
    w-[100px]
`}
`;
