import { FC, SVGProps } from 'react';

const IconWifi: FC<SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg viewBox="0 0 13 17" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M.608 8.673a1.179 1.179 0 012.356 0 1.179 1.179 0 01-2.356 0zm7.853-7.065a.783.783 0 011.41-.476c1.623 2.127 2.517 4.805 2.517 7.541 0 2.736-.893 5.413-2.514 7.538a.786.786 0 11-1.25-.952c1.415-1.853 2.194-4.192 2.194-6.586 0-2.395-.78-4.735-2.196-6.59a.776.776 0 01-.16-.475zM6.105 3.961a.782.782 0 011.41-.476C8.632 4.947 9.247 6.79 9.247 8.673c0 1.883-.614 3.724-1.73 5.184a.785.785 0 01-1.249-.952c.908-1.189 1.408-2.692 1.408-4.232s-.501-3.045-1.41-4.234a.777.777 0 01-.16-.476v-.001zM3.75 11.025a.78.78 0 01.155-.467 3.182 3.182 0 000-3.773.785.785 0 011.261-.936c1.23 1.656 1.23 3.989 0 5.644a.785.785 0 01-1.416-.467v-.001z" />
        </svg>
    );
};

export default IconWifi;
