import { Hydrate, QueryClientProvider } from '@tanstack/react-query';

import { MotionConfig } from 'framer-motion';
import '../../styles/globals.css';
import Cached from '../components/templates/cached';
import { queryClient } from '../services/query-client';

import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AppProps } from 'next/app';
import { motionConfig } from '../utils/motion';
import NextTopLoader from 'nextjs-toploader';

export interface IAppInitialProps {}

export interface IAppProps extends AppProps {
    props: IAppInitialProps;
}

function MyApp({ Component, pageProps = {} }: any) {
    return (
        <MotionConfig transition={motionConfig}>
            <QueryClientProvider client={queryClient}>
                <Hydrate state={pageProps?.dehydratedState}>
                    <NextTopLoader color="#424242" showSpinner={false} />
                    <Cached Component={Component} pageProps={pageProps} />
                    {process.env.NODE_ENV != 'production' && <ReactQueryDevtools initialIsOpen={false} />}
                </Hydrate>
            </QueryClientProvider>
        </MotionConfig>
    );
}

export default MyApp;
