import tw, { styled } from 'twin.macro';

export const Container = styled.div`
    ${tw`
      w-full
      animate-pulse
      flex
      flex-col
      overflow-hidden
  `}
`;

export const Row = styled.div`
    ${tw`
		grid
		grid-cols-[1fr .9fr .9fr .9fr .9fr 1fr .9fr]
		gap-[20px]
		mb-[10px]
  `}
`;

export const Cell = styled.div`
    ${tw`
		bg-gray
		rounded-md
		w-full
    mb-[10px]
		h-[35px]
  `}
`;

export const HeaderCell = styled.div`
    ${tw`
		bg-gray
		rounded-md
		w-full
		h-[18px]
		mb-[10px]
  `}
`;
