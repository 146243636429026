import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AddressService } from '../../services';
import { State } from '../../services/address/types';
import { STATES } from '../../utils/react-query-keys';

export const useStates = (params?: UseQueryOptions<State[] | null>) => {
    return useQuery<State[] | null>([STATES], () => AddressService.initStates(), {
        refetchOnMount: true,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        ...params
    });
};
