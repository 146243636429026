import { FormikProvider, useFormik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';
import { CARACTER_LOW, CARACTER_UPP, EMAIL, MIN, NUM, REQUIRED } from '../../../utils/errors';
import { Input } from '../../molecules';
import Layout from '../Layout';
import LayoutFitter from '../Layout-fitter';
import {
    ButtonEmptySpace,
    ChangePasswordContainer,
    ChangePasswordFields,
    ChangePasswordInput,
    ChangePasswordLabel,
    CheckBox,
    ConfirmChangesButton,
    ContentContainer,
    ContentForm,
    ContentFormTitle,
    ContentFormTitleContainer,
    ContentTitle,
    FormFieldLabel,
    NewsletterContainer
} from './styles';

import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useUserInfo } from '../../../hooks/use-user-info';
import { AddressService, UserService } from '../../../services';
import { CustomerInfo } from '../../../services/user/types';
import {
    getDatePt,
    getDateUS,
    REGEX_CARACTER_LOW,
    REGEX_CARACTER_NUM,
    REGEX_CARACTER_UPP
} from '../../../utils/string';
import { EInputType } from '../../molecules/Input/types';
import MyAccountLayout from '../../organisms/My-account-layout';
import { IValuesForm } from './types';

const MyInfos = () => {
    const userData = { ...useUserInfo().data, password: '' };

    const [showSaveChangesButton, setShowSaveChangesButton] = useState(false);
    const [showChangePasswordFields, setShowChangePasswordFields] = useState(false);
    const [isSubscribed, setIsSubscribed] = useState(userData.extension_attributes?.is_subscribed);

    const validationForm = Yup.object().shape({
        firstname: Yup.string().required(REQUIRED),
        email: Yup.string().email(EMAIL).required(REQUIRED),
        lastname: Yup.string().required(REQUIRED),
        taxvat: Yup.string().required(REQUIRED),
        password: Yup.string(),
        newPassword: Yup.string().when('password', {
            is: (pswrd: string) => pswrd !== undefined,
            then: Yup.string()
                .required(REQUIRED)
                .min(8, MIN)
                .matches(REGEX_CARACTER_LOW, CARACTER_LOW)
                .matches(REGEX_CARACTER_UPP, CARACTER_UPP)
                .matches(REGEX_CARACTER_NUM, NUM),
            otherwise: Yup.string()
        }),
        dob: Yup.string()
            .required(REQUIRED)
            .test('dob', 'Data inválida', (value) => {
                if (!value) return true;
                const date = new Date(getDateUS(value) as string);
                return date.toString() != 'Invalid Date' && date < new Date();
            })
    });

    const formik = useFormik<IValuesForm>({
        initialValues: {
            firstname: userData?.firstname || '',
            lastname: userData?.lastname || '',
            email: userData?.email || '',
            changePassword: false,
            taxvat: userData?.taxvat || '',
            telephone: userData?.telephone || '',
            password: '',
            passwordConfirm: '',
            dob: userData?.dob ? getDatePt(userData.dob) || '' : ''
        },
        validateOnMount: false,
        validationSchema: validationForm,
        onSubmit: (values) => {
            if (formik.values.password && showChangePasswordFields) {
                mutateSubmitPassword.mutate();
            }
            mutateSubmitContactInfos.mutate();
        }
    });

    const checkShouldSaveButtonAppear = (inputKey: string = '', value: string = '') => {
        if (!userData) return;
        const keysToCheck = ['email', 'lastname', 'firstname', 'taxvat', 'password'];
        let shouldShowButton = isSubscribed !== userData.extension_attributes?.is_subscribed ? false : true;
        keysToCheck.map((key) => {
            if (formik.values[key as keyof IValuesForm] != userData[key as keyof CustomerInfo] && key !== inputKey) {
                shouldShowButton = true;
            } else if (key == inputKey) {
                if (value != userData[key as keyof CustomerInfo]) shouldShowButton = true;
            }
        });

        if (showSaveChangesButton !== shouldShowButton) setShowSaveChangesButton(shouldShowButton);
    };

    const mutateSubmitContactInfos = useMutation(
        () =>
            UserService.editMe({
                ...formik.values,
                cpf: formik.values.taxvat,
                dob: formik.values.dob,
                isSubscribed
            }),
        {
            onError: (error: any) => {
                toast.error(error);
            },
            onSuccess: () => {
                AddressService.refetchAddress();
                toast.success('Informações de contato alteradas com sucesso!');
                setShowSaveChangesButton(false);
            }
        }
    );

    const mutateSubmitPassword = useMutation(
        () => {
            const { password: currentPassword, passwordConfirm } = formik.values;
            const newPassword = passwordConfirm;

            return UserService.changePassword({ currentPassword, newPassword });
        },
        {
            onError: (error: any) => {
                toast.error(error);
            },
            onSuccess: () => {
                toast.success('Senha alterada com sucesso!');
                setShowSaveChangesButton(false);
            }
        }
    );

    const handleChangePasswordInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        setShowChangePasswordFields(e?.target.checked);
    };

    const handleChangeNewsletterInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsSubscribed(e?.target.checked);
        checkShouldSaveButtonAppear();
    };

    return (
        <Layout>
            <LayoutFitter>
                <MyAccountLayout title="Meus dados">
                    <ContentContainer>
                        <ContentTitle>INFORMAÇÕES DA CONTA</ContentTitle>
                        <ContentForm>
                            <ContentFormTitleContainer>
                                <ContentFormTitle>Informações de contato</ContentFormTitle>
                            </ContentFormTitleContainer>
                            <FormikProvider value={formik}>
                                <Input onChange={checkShouldSaveButtonAppear} name="firstname" label="Nome" />
                                <Input onChange={checkShouldSaveButtonAppear} name="lastname" label="Sobrenome" />
                                <Input onChange={checkShouldSaveButtonAppear} name="email" label="Email" />
                                <Input onChange={checkShouldSaveButtonAppear} name="taxvat" label="CPF" mask="cpf" />
                                <Input
                                    onChange={checkShouldSaveButtonAppear}
                                    label="Data Nascimento*"
                                    inputMode="numeric"
                                    mask="date"
                                    name="dob"
                                />
                            </FormikProvider>
                            <ChangePasswordContainer>
                                <ChangePasswordInput>
                                    <CheckBox type="checkbox" onChange={handleChangePasswordInput} />
                                    <ChangePasswordLabel>Alterar minha senha</ChangePasswordLabel>
                                </ChangePasswordInput>
                                {showChangePasswordFields ? (
                                    <ChangePasswordFields>
                                        <FormikProvider value={formik}>
                                            <Input
                                                onChange={checkShouldSaveButtonAppear}
                                                type={EInputType.PASSWORD}
                                                name="password"
                                                label="Senha atual"
                                            />
                                            <Input
                                                onChange={checkShouldSaveButtonAppear}
                                                type={EInputType.PASSWORD}
                                                name="newPassword"
                                                label="Nova senha"
                                            />
                                        </FormikProvider>
                                    </ChangePasswordFields>
                                ) : null}
                            </ChangePasswordContainer>
                        </ContentForm>
                        <ContentForm>
                            <ContentFormTitleContainer>
                                <ContentFormTitle>Newsletter</ContentFormTitle>
                            </ContentFormTitleContainer>
                            <NewsletterContainer>
                                <CheckBox
                                    type="checkbox"
                                    checked={isSubscribed}
                                    onChange={handleChangeNewsletterInput}
                                />
                                <FormFieldLabel>
                                    Desejo participar da newsletter e receber novidades em meu e-mail.
                                </FormFieldLabel>
                            </NewsletterContainer>
                            {showSaveChangesButton ? (
                                <ConfirmChangesButton
                                    isLoading={mutateSubmitContactInfos.isLoading || mutateSubmitPassword.isLoading}
                                    onClick={formik.submitForm}
                                    text="Confirmar alterações"
                                />
                            ) : (
                                <ButtonEmptySpace />
                            )}
                        </ContentForm>
                    </ContentContainer>
                </MyAccountLayout>
            </LayoutFitter>
        </Layout>
    );
};

export default MyInfos;
