import { FC } from 'react';
import tw, { styled } from 'twin.macro';

const Container = styled.p`
    ${tw`
        font-nunito
        text-[25px]
        text-black
    `}
`;

const TextLatoRegular25: FC<any> = ({ children, ...props }) => {
    return <Container {...props}>{children}</Container>;
};

export default TextLatoRegular25;
