import React, { FC } from 'react';
import { useBlock } from '../../../hooks';
import HallFooterLinksSkeleton from '../Hall-footer-links-skeleton';
import { Container, Message } from './styles';
import { IPageBlockProps } from './types';

const PageBlock: FC<IPageBlockProps> = ({ id }) => {
    const { data, isLoading } = useBlock(id);

    if (isLoading) return <HallFooterLinksSkeleton />;

    if (!data)
        return (
            <Container>
                <Message>Não foi possível encontrar a página solicitada.</Message>
            </Container>
        );

    return <Container dangerouslySetInnerHTML={{ __html: data?.content || '' }}></Container>;
};

export default PageBlock;
