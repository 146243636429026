import tw, { styled } from 'twin.macro';
import { IconCheck, IconSpinner } from '../../atoms';

export const IconLoading = styled(IconSpinner)`
    ${tw`
        stroke-primary
        w-[20px]
        h-[20px]
    `}
`;
export const IconValid = styled(IconCheck)`
    ${tw`
        fill-gray
        w-[20px]
        h-[20px]
    `}
`;
