import tw, { styled } from 'twin.macro';

export const Container = styled.div`
    ${tw`
       flex
       flex-col 
       gap-2
       w-full
       pb-6
    `}

    .range-slider__range, .range-slider__thumb {
        ${tw`
            !bg-primary
        `}
    }
    .range-slider {
        ${tw`
            !bg-[#F0F0F0]
            !rounded-[4px]
        `}
    }
`;

export const Values = styled.div`
    ${tw`
        flex
        flex-row
        justify-between
        leading-[16px]
        text-12
        pb-1
        text-black
    `}
`;

export const Title = styled.div`
    ${tw`
        text-18
        text-black
        leading-[24px]
    `}
`;
