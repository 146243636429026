import tw, { styled } from 'twin.macro';

export const Container = styled.div`
  ${tw`
    flex
    flex-col
    w-full
    gap-[10px]
    p-[5px]
    max-h-[300px]
    overflow-auto
    mb-[50px]
    `}
`;