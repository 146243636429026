import tw, { styled } from 'twin.macro';
import { IconOptions } from '../../atoms';

export const Container = styled.div`
    ${tw`
        
    `}
`;
export const Icon = styled(IconOptions)`
    ${tw`
        fill-black
        w-[15px]
        h-[15px]
    `}
`;
