import Layout from '../Layout';
import LayoutFitter from '../Layout-fitter';

import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Input } from '../../molecules';

import { useMutation } from '@tanstack/react-query';
import { AuthService, RouterService, UserService } from '../../../services';
import { CARACTER_LOW, CARACTER_UPP, CPF, EMAIL, MIN, NUM, REQUIRED } from '../../../utils/errors';

import {
    AccessFormContainer,
    AddressInfoFormContainer,
    BoxContainer,
    CheckboxContainer,
    CheckboxWrapper,
    Container,
    ContainerTitle,
    ErrorText,
    FormsContainer,
    LoginFormHeader,
    PersonIcon,
    SubmitActionsContainer,
    TitleContainer,
    TitleLabel
} from './styles';

import { useRouter } from 'next/router';
import { useRef, useState } from 'react';
import InputMask from 'react-input-mask';
import {
    cpfValid,
    getDateUS,
    REGEX_CARACTER_LOW,
    REGEX_CARACTER_NUM,
    REGEX_CARACTER_UPP,
    replaceParams
} from '../../../utils/string';
import { IconClose, TextNunitoRegular12 } from '../../atoms';
import { Checkbox as CustomCheckbox } from '../../atoms/Checkbox';
import { IButtonVariant } from '../../molecules/Button/types';
import { InputPassword } from '../../molecules/Input-password';
import { EInputType } from '../../molecules/Input/types';
import { LoginDrawer } from '../../organisms/Login-drawer';
import TermOfUseAndPrivacy from '../../organisms/Term-of-use-and-privacy';
import { IRegisterFormProps, IValuesForm } from './types';
import { toast } from 'react-toastify';
import { LoginFormMandatoryBox, LoginFormMandatoryLabel } from '../../organisms/Login-form/styles';
import { useBlockConfig } from '../../../hooks/use-block-config';

const concatMessagePassword = [MIN, CARACTER_LOW, CARACTER_UPP, NUM].join('<br />');

const validationForm = Yup.object().shape({
    name: Yup.string().required(REQUIRED),
    cpf: Yup.string().when('type', {
        is: 'individual',
        then: Yup.string().test('cpf', CPF, cpfValid).required(REQUIRED),
        otherwise: Yup.string().notRequired()
    }),
    dob: Yup.string().when('type', {
        is: 'individual',
        then: Yup.string()
            .required(REQUIRED)
            .test('dob', 'Data inválida', (value) => {
                if (!value) return true;
                const date = new Date(getDateUS(value) as string);
                return date.toString() != 'Invalid Date' && date < new Date();
            }),
        otherwise: Yup.string().notRequired()
    }),
    cnpj: Yup.string().when('type', {
        is: 'entity',
        then: Yup.string().required(REQUIRED),
        otherwise: Yup.string().notRequired()
    }),

    // CONTATO
    email: Yup.string().email(EMAIL).required(REQUIRED),
    telephone: Yup.string().required(REQUIRED),

    passwordConfirm: Yup.string()
        .required(REQUIRED)
        .oneOf([Yup.ref('password'), null], 'As senhas não conferem')
});

let loginInfos = {
    email: '',
    password: ''
};

const Register = ({}: IRegisterFormProps) => {
    const router = useRouter();
    const [error, setError] = useState('');
    const [showLogin, setShowLogin] = useState(false);
    const timeout = useRef<any>(null);
    const [emailAvailable, setEmailAvailable] = useState(true);
    const [termsChecked, setTermsChecked] = useState(false);
    const { data: config } = useBlockConfig({ refetchOnMount: false });
    const { mutate, isLoading, reset, isError } = useMutation<unknown, unknown, IValuesForm>(
        async (form) => {
            loginInfos = { email: form.email, password: form.password };

            const formattedDate = formik.values.dob.split('/').join('-');

            const payload = {
                customer: {
                    email: form.email,
                    firstname: form.name,
                    lastname: form.type === 'entity' ? form.name : form.lastname,
                    dob: formattedDate,
                    taxvat: form.type === 'entity' ? form.cnpj : form.cpf,
                    // mobilenumber: form.telephone,
                    custom_attributes: [
                        {
                            attribute_code: 'person_type',
                            value: form.type === 'entity' ? 'cnpj' : 'cpf'
                        },
                        {
                            attribute_code: form.type === 'entity' ? 'cnpj' : 'cpf',
                            value: form.type === 'entity' ? form.cnpj : form.cpf
                        },
                        {
                            attribute_code: 'mobilenumber',
                            value: form.telephone
                        }
                    ]
                },
                password: form.password,
                passwordConfirmation: form.passwordConfirm
            };

            return UserService.register(payload);
        },
        {
            onSuccess: async () => {
                await AuthService.login(loginInfos.email, loginInfos.password);
                RouterService.toRoute({ options: { url: '/minha-conta' } });
            },
            onError: (error: any) => {
                if (error.response.status == 400) {
                    return setError(replaceParams(error.response.data.message, error.response.data.parameters));
                }

                setError(error.message);

                toast.error('Erro ao cadastrar usuário');
            }
        }
    );

    const formik = useFormik<IValuesForm>({
        initialValues: {
            name: '',
            lastname: '',
            cpf: '',
            cnpj: '',
            email: '',
            password: '',
            passwordConfirm: '',
            telephone: '',
            dob: '',
            type: ''
        },

        validateOnChange: false,
        validationSchema: validationForm,
        onSubmit: (values) => {
            mutate(values);
        }
    });

    const handleChangeEmail = (name: string, value: string) => {
        formik.setFieldValue(name, value);
        setEmailAvailable(true);
        if (timeout.current) {
            clearTimeout(timeout.current);
        }
        timeout.current = setTimeout(async () => {
            const valid: boolean = await UserService.emailAvailable(value);
            setEmailAvailable(valid);
        }, 400);
    };

    const allowButtonSubmit = (): boolean => {
        const { type, email, name, dob, cnpj, cpf, password } = formik.values;
        let isValid = !!email && !!name && !!password && termsChecked;

        if (type === 'individual') {
            isValid = isValid && !!dob && !!cpf;
        } else if (type === 'entity') {
            isValid = isValid && !!cnpj;
        }

        return !!isValid && termsChecked;
    };

    const passwordValidation = () => {
        const { password } = formik.values;

        if (!password) {
            return '';
        }

        const hasMin = password.length >= 8;
        const hasCaracterLow = REGEX_CARACTER_LOW.test(password);
        const hasCaracterUpp = REGEX_CARACTER_UPP.test(password);
        const hasCaracterNum = REGEX_CARACTER_NUM.test(password);

        if (!hasMin) {
            return 'A senha deve ter pelo menos 8 caracteres.';
        }
        if (!hasCaracterLow) {
            return 'A senha deve conter pelo menos uma letra minúscula.';
        }
        if (!hasCaracterUpp) {
            return 'A senha deve conter pelo menos uma letra maiúscula.';
        }
        if (!hasCaracterNum) {
            return 'A senha deve conter pelo menos um número.';
        }

        return '';
    };

    const isSamePassword = () => {
        const { password, passwordConfirm } = formik.values;

        if (!password || !passwordConfirm) {
            return false;
        }

        const isSame = password === passwordConfirm;

        if (!isSame) {
            return 'As senhas não conferem.';
        }
    };

    return (
        <Layout>
            <LayoutFitter>
                <Container>
                    <FormsContainer>
                        <TitleContainer>
                            <PersonIcon />
                            <TitleLabel>Cadastre-se</TitleLabel>
                        </TitleContainer>

                        <div className="flex flex-col mt-6 gap-9 md:flex-row">
                            <BoxContainer className="md:max-w-[768px] flex-1">
                                <AccessFormContainer>
                                    <LoginFormHeader>
                                        <ContainerTitle>Dados Gerais</ContainerTitle>
                                        <div className="mt-4">
                                            <TextNunitoRegular12>Qual o tipo de Cadastro?</TextNunitoRegular12>
                                        </div>
                                    </LoginFormHeader>
                                    <FormikProvider value={formik}>
                                        <CheckboxContainer>
                                            <CheckboxWrapper onClick={() => formik.setFieldValue('type', 'individual')}>
                                                <CustomCheckbox
                                                    withoutFormik
                                                    onCheckboxChange={() => formik.setFieldValue('type', 'individual')}
                                                    isChecked={formik.values.type === 'individual'}
                                                    name="individual"
                                                />
                                                <TextNunitoRegular12>Pessoa Física</TextNunitoRegular12>
                                            </CheckboxWrapper>
                                            {/* <CheckboxWrapper onClick={() => formik.setFieldValue('type', 'entity')}>
                                                <CustomCheckbox
                                                    withoutFormik
                                                    onCheckboxChange={() => formik.setFieldValue('type', 'entity')}
                                                    isChecked={formik.values.type === 'entity'}
                                                    name="entity"
                                                />
                                                <TextNunitoRegular12>Pessoa Jurídica</TextNunitoRegular12>
                                            </CheckboxWrapper> */}
                                        </CheckboxContainer>

                                        <Input
                                            label={formik.values.type === 'entity' ? 'Razão Social*' : 'Nome*'}
                                            name="name"
                                            autoComplete="off"
                                        />

                                        {formik.values.type === 'entity' ? null : (
                                            <span className="w-full mt-3">
                                                <Input label={'Sobrenome*'} name="lastname" />
                                            </span>
                                        )}

                                        <div className="flex flex-col gap-4 mt-4 md:mt-12 md:flex-row">
                                            <div className={`w-full ${formik.values.type !== 'entity' && 'hidden'}`}>
                                                <Input
                                                    label="CNPJ*"
                                                    inputMode="numeric"
                                                    name="cnpj"
                                                    mask="cnpj"
                                                    autoComplete="off"
                                                    placeholder="__.___.___/____-__"
                                                />
                                            </div>
                                            <div className={`w-full ${formik.values.type === 'entity' && 'hidden'}`}>
                                                <Input
                                                    label="CPF*"
                                                    inputMode="numeric"
                                                    name="cpf"
                                                    mask="cpf"
                                                    autoComplete="off"
                                                    placeholder="___.___.___-__"
                                                />
                                            </div>

                                            {formik.values.type === 'entity' ? null : (
                                                //@ts-ignore
                                                <div className="w-full">
                                                    <label className="text-12 text-[black]">Data de Nascimento</label>

                                                    <InputMask
                                                        mask="99/99/9999"
                                                        value={formik.values.dob}
                                                        onChange={formik.handleChange}
                                                    >
                                                        {
                                                            // @ts-ignore
                                                            (inputProps: any) => (
                                                                <input
                                                                    {...inputProps}
                                                                    className="bg-[#F6F6F6] w-full h-[26] rounded-30 p-4 border-[0.5px] border-[#E6E6E6]"
                                                                    label="Data Nascimento*"
                                                                    inputMode="numeric"
                                                                    placeholder="__/__/____"
                                                                    name="dob"
                                                                    autoComplete="off"
                                                                />
                                                            )
                                                        }
                                                    </InputMask>
                                                    {formik.errors.dob && <ErrorText>{formik.errors.dob}</ErrorText>}
                                                </div>
                                            )}
                                        </div>
                                    </FormikProvider>
                                </AccessFormContainer>

                                <div className="mt-8">
                                    <p
                                        dangerouslySetInnerHTML={{ __html: (config?.cadastro_mensagem as any) || '' }}
                                    ></p>
                                </div>
                            </BoxContainer>
                            <BoxContainer className="md:max-w-[544px] flex-1">
                                <LoginFormHeader>
                                    <ContainerTitle>Contato</ContainerTitle>
                                </LoginFormHeader>
                                <AddressInfoFormContainer>
                                    <FormikProvider value={formik}>
                                        <Input label="E-mail*" name="email" onChange={handleChangeEmail} />
                                        {!emailAvailable && <ErrorText> E-mail já cadastrado na base.</ErrorText>}

                                        <span className="w-full mt-3">
                                            <div className="w-full">
                                                <label className="text-12 text-[black]">Telefone*</label>

                                                <InputMask
                                                    mask="(99) 99999-9999"
                                                    value={formik.values.telephone}
                                                    onChange={formik.handleChange}
                                                >
                                                    {
                                                        // @ts-ignore
                                                        (inputProps: any) => (
                                                            <input
                                                                {...inputProps}
                                                                className="bg-[#F6F6F6] w-full h-[26] rounded-30 p-4 border-[0.5px] border-[#E6E6E6]"
                                                                inputMode="numeric"
                                                                name="telephone"
                                                                placeholder="(99) 99999-9999"
                                                                autoComplete="off"
                                                            />
                                                        )
                                                    }
                                                </InputMask>
                                                {formik.errors.telephone && (
                                                    <ErrorText>{formik.errors.telephone}</ErrorText>
                                                )}
                                            </div>
                                        </span>

                                        <LoginFormHeader>
                                            <ContainerTitle>Segurança</ContainerTitle>
                                        </LoginFormHeader>

                                        <span className="w-full ">
                                            <InputPassword
                                                label="Senha*"
                                                inputMode="password"
                                                name="password"
                                                type={EInputType.PASSWORD}
                                            />
                                            <ErrorText>{passwordValidation()}</ErrorText>
                                        </span>

                                        <span className="w-full mt-3">
                                            <InputPassword
                                                label="Confirmar Senha*"
                                                inputMode="password"
                                                name="passwordConfirm"
                                                type={EInputType.PASSWORD}
                                            />
                                            <ErrorText>{isSamePassword()}</ErrorText>
                                        </span>
                                        <span className="mt-2">
                                            <TermOfUseAndPrivacy setChecked={setTermsChecked} />
                                        </span>
                                    </FormikProvider>
                                </AddressInfoFormContainer>
                            </BoxContainer>
                        </div>
                    </FormsContainer>
                    {isError && (
                        <LoginFormMandatoryBox>
                            <LoginFormMandatoryLabel>
                                <div className="flex items-center justify-center text-center">
                                    {error || 'Erro ao cadastrar usuário. Tente novamente!'}
                                    <IconClose
                                        stroke="#323232"
                                        className="cursor-pointer"
                                        onClick={() => {
                                            reset();
                                        }}
                                    />
                                </div>
                            </LoginFormMandatoryLabel>
                        </LoginFormMandatoryBox>
                    )}{' '}
                    <SubmitActionsContainer>
                        <Button
                            isLoading={isLoading}
                            onClick={() => {
                                router.push('/login');
                            }}
                            text="Já tenho conta"
                            variant={IButtonVariant.BLACK_BIG_OUTLINE}
                            className="md:max-w-[400px] flex-1"
                        />

                        <Button
                            isLoading={isLoading}
                            onClick={() => formik.handleSubmit()}
                            text="Cadastrar"
                            disabled={!allowButtonSubmit()}
                            variant={IButtonVariant.PRIMARY_BIG}
                            className="md:max-w-[400px] flex-1"
                        />
                    </SubmitActionsContainer>
                </Container>
            </LayoutFitter>
            <LoginDrawer
                onClose={() => setShowLogin(false)}
                onLogin={() => {
                    router.push('/minha-conta');
                    setShowLogin(false);
                }}
                open={showLogin}
            />
        </Layout>
    );
};

export default Register;
