import { FormikProvider, setNestedObjectValues, useFormik } from 'formik';
import { Ref, forwardRef, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as Yup from 'yup';
import {
    Button,
    CheckoutSelectPayment,
    CheckoutSelectShippingMethod,
    InputCoupon,
    SelectAddress
} from '../../molecules';
import CheckoutSelectUnavability from '../../molecules/Checkout-select-unavaibility';
import { CheckoutListItems } from '../../organisms';

import {
    ColumnInfo,
    ColumnProducts,
    ContainerInput,
    ContainerLoading,
    ContainerPayment,
    ContainerSubmit,
    Content,
    Header,
    IconLoading,
    Modal,
    Scroll,
    Separator,
    SeparatorTotal,
    TextTitle,
    TotalAll,
    TotalItemText,
    TotalItemValue,
    TotalSubTotal,
    TotalTax,
    TotalText
} from './styles';

import { useMutation } from '@tanstack/react-query';
import { useMotionValue } from 'framer-motion';
import { useAddress, useCart, useHeight } from '../../../hooks';
import { CartService, EstablishmentService } from '../../../services';
import { Address } from '../../../services/address/types';
import { PaymentMethod } from '../../../services/payment/types';
import { REQUIRED } from '../../../utils/errors';
import { getIsBreakPoint } from '../../../utils/get-config-tailwind';
import { currencyBrl } from '../../../utils/getFormattedValue';
import { addHash, removeHash, replaceParams } from '../../../utils/string';
import ModalPortal from '../../atoms/Modal-portal';
import { ICheckoutSelectPaymentHandle } from '../../molecules/Checkout-select-payment/types';
import ModalConfirm from '../../organisms/Modal-confirm';
import ModalCheckoutConfirm from '../Modal-checkout-confirm';
import { IModalCart, IModalCheckoutHandler, IValuesForm } from './types';

const validationSchema = Yup.object().shape({
    address: Yup.object().typeError(REQUIRED).required(REQUIRED),
    unavailability: Yup.string().required(REQUIRED),
    creditCard: Yup.object().typeError(REQUIRED).required(REQUIRED),
    coupon: Yup.string()
        .nullable()
        .test('coupon', 'Cupom inválido', (value) => {
            return value !== null;
        })
});

const ModalCheckout = forwardRef(({ onClose }: IModalCart, ref: Ref<IModalCheckoutHandler>) => {
    const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false);
    const [errorModal, setErrorModal] = useState<{ show: boolean; message: string }>({ show: false, message: '' });

    const refShippingMethod = useRef<HTMLDivElement>(null);
    const refPaymentMethods = useRef<HTMLDivElement>(null);
    const refContainerSubmit = useRef<HTMLDivElement>(null);
    const refChechoutSelectPayment = useRef<ICheckoutSelectPaymentHandle>(null);
    const paymentMethods = useRef<PaymentMethod[]>([]);

    const isMd = getIsBreakPoint('md');
    const paddingBottom = useMotionValue('0');

    useHeight(refContainerSubmit, (value) => {
        if (isMd) {
            paddingBottom.set(value + 20 + 'px');
        }
    });

    const { data } = useCart();

    const { data: dataAddress } = useAddress();

    let initialDataValues = {
        grandTotal: data?.info.total_segments?.find((item) => item.code == 'grand_total')?.value,
        subTotal: data?.info.total_segments?.find((item) => item.code == 'subtotal')?.value,
        taxAmount: data?.info.total_segments?.find((item) => item.code == 'tax')?.value,
        shippingAmount: data?.info.total_segments?.find((item) => item.code == 'shipping')?.value,
        discount: data?.info.total_segments?.find((item) => item.code == 'discount')?.value
    };

    if (data && initialDataValues) {
        initialDataValues.subTotal = data.items
            .map((item) => item.price * item.qty)
            .reduce((acc, current) => acc + current, 0);

        const serviceTax = ((initialDataValues.taxAmount || 0) * 100) / initialDataValues.subTotal / 100; // Taxa de serviço, geralmente 3%

        initialDataValues.taxAmount = initialDataValues.subTotal * serviceTax;

        initialDataValues.grandTotal = data.info.grand_total =
            initialDataValues.subTotal +
            initialDataValues.taxAmount +
            (initialDataValues.shippingAmount || 0) +
            (initialDataValues.discount || 0);
    }

    useEffect(() => {
        onOpen();
        addHash('checkout');

        const refreshCartInterval = setInterval(() => {
            sync();
        }, 15000);

        return () => {
            clearInterval(refreshCartInterval);
            removeHash('checkout');
        };
    }, []);

    const handleHide = () => {
        if (onClose) onClose();
    };

    const { mutate: sync, isLoading } = useMutation(
        async () => {
            if (CartService.hasExecuteRequest()) {
                await CartService.executeAllRequest();
            }
        },
        {
            onSuccess: () => {
                CartService.refetchCart();
            }
        }
    );

    const hiddenShipping = useMemo(() => {
        // const cookie = EstablishmentService.getEstablishmentStorage();
        // return cookie && (!cookie.delivery || !cookie.pickUp);
    }, []);

    const { isLoading: isLoadingShipping, mutate: setShipping } = useMutation(async () => {
        if (!isLoading) {
            const result = await CartService.setAddress({
                address: dataAddress?.address as Address,
                shippingMethod: formik.values.shippingMethod
            });
            paymentMethods.current = result.payment_methods;
        }
    });

    const onOpen = async () => {
        formik.resetForm();
        sync();
        // const newCreditcard = (await PaymentService.getLastMethod()) as PaymentSelected;
        // formik.setFieldValue('creditCard', newCreditcard);
    };

    const formik = useFormik<IValuesForm>({
        initialValues: {
            coupon: data?.info?.coupon_code,
            unavailability: 'Entre em contato',
            shippingMethod: null,
            address: dataAddress?.address || null,
            creditCard: null,
            infoCart: null
        },
        validationSchema,
        onSubmit: () => {
            setShowModalConfirm(true);
        }
    });
    const mutationSubmit = useMutation(
        async () => {
            if (!formik.values.address || !formik.values?.creditCard || !formik.values?.creditCard.values) return;

            const result = await CartService.checkout({
                address: formik.values.address,
                card: formik.values.creditCard.values,
                paymentMethod: formik.values.creditCard.paymentMethod
            });

            return result;
        },
        {
            onSuccess: (data) => {
                removeHash('checkout');
                if (onClose) onClose();
                window.location.replace(EstablishmentService.createUrl(`minha-conta/pedidos/${data}`));
            },
            onError: (error: any) => {
                console.log(error);
                let mensageText;
                if (error?.response?.data.message) {
                    const { message, parameters } = error?.response?.data;

                    mensageText = replaceParams(message, parameters);
                } else {
                    mensageText = 'Erro interno :(';
                }
                setErrorModal({ show: true, message: mensageText });
            }
        }
    );

    useEffect(() => {
        formik.setFieldValue('address', dataAddress?.address || null);
        if (formik.values.shippingMethod) {
            setShipping();
        }
    }, [dataAddress, formik.values.unavailability, formik.values.shippingMethod, isLoading]);

    const handlePressPayment = useCallback(() => {
        if (!formik.values.shippingMethod) {
            refShippingMethod.current?.scrollIntoView();
            formik.setFieldTouched('shippingMethod', true);
        } else {
            refChechoutSelectPayment.current?.showModalCreditCardType(paymentMethods.current);
        }
    }, [formik.values, refShippingMethod.current, paymentMethods.current]);
    const handleConfirm = () => {
        setShowModalConfirm(false);
        mutationSubmit.mutate();
    };

    const handleErrorCheckout = () => {
        setErrorModal({ show: false, message: '' });
    };

    const handleMouseEnterConfirm = async () => {
        const validationErrors = await formik.validateForm();
        if (Object.keys(validationErrors).length > 0) {
            formik.setTouched(setNestedObjectValues(validationErrors, true));
            return;
        }
    };

    const handleSubmit = () => {
        formik.handleSubmit();
    };

    return (
        <>
            <Modal enableClose={false}>
                <Header title="Pagamento" showIconClose onPressIconClose={handleHide} />
                {isLoading ? (
                    <ContainerLoading>
                        <IconLoading />
                    </ContainerLoading>
                ) : (
                    <Content>
                        <ColumnProducts>
                            <CheckoutListItems />
                            {/* 
                            <CheckoutListGiftItems /> */}
                        </ColumnProducts>
                        <Separator />
                        <ColumnInfo>
                            <FormikProvider value={formik}>
                                <Scroll style={{ paddingBottom }}>
                                    <ContainerInput>
                                        <TextTitle>Onde será entregue:</TextTitle>
                                        <SelectAddress />
                                    </ContainerInput>
                                    <ContainerInput
                                        ref={refShippingMethod}
                                        style={{
                                            display: 'flex'
                                        }}
                                    >
                                        <TextTitle>Métodos de entrega</TextTitle>
                                        <CheckoutSelectShippingMethod name="shippingMethod" />
                                    </ContainerInput>

                                    <ContainerInput>
                                        <TextTitle>No caso de indisponibilidade</TextTitle>
                                        <CheckoutSelectUnavability name="unavailability" />
                                    </ContainerInput>
                                    <ContainerInput>
                                        <TextTitle>Cupom</TextTitle>
                                        <InputCoupon name="coupon" />
                                    </ContainerInput>
                                    <ContainerPayment ref={refPaymentMethods}>
                                        {isLoadingShipping ? (
                                            <ContainerLoading>
                                                <IconLoading />
                                            </ContainerLoading>
                                        ) : (
                                            <CheckoutSelectPayment
                                                ref={refChechoutSelectPayment}
                                                value={formik.values.creditCard}
                                                onClick={handlePressPayment}
                                            />
                                        )}
                                    </ContainerPayment>
                                </Scroll>

                                <ContainerSubmit ref={refContainerSubmit}>
                                    <TotalSubTotal>
                                        <TotalItemText>Subtotal</TotalItemText>
                                        <TotalItemValue>
                                            R$ {currencyBrl(initialDataValues.subTotal || 0)}
                                        </TotalItemValue>
                                    </TotalSubTotal>
                                    <TotalTax>
                                        <TotalItemText>Frete</TotalItemText>
                                        <TotalItemValue>
                                            R$ {currencyBrl(initialDataValues.shippingAmount || 0)}
                                        </TotalItemValue>
                                    </TotalTax>
                                    <TotalTax>
                                        <TotalItemText>Taxa de serviço</TotalItemText>
                                        <TotalItemValue>
                                            R$ {currencyBrl(initialDataValues.taxAmount || 0)}
                                        </TotalItemValue>
                                    </TotalTax>
                                    {data && Number(data?.info.discount_amount) != 0 && (
                                        <TotalTax>
                                            <TotalItemText>
                                                Desconto{' '}
                                                {data?.info?.coupon_code ? `(${data?.info?.coupon_code})` : null}
                                            </TotalItemText>
                                            <TotalItemValue>
                                                R$ {currencyBrl(data?.info?.discount_amount || 0)}
                                            </TotalItemValue>
                                        </TotalTax>
                                    )}
                                    <SeparatorTotal></SeparatorTotal>
                                    <TotalAll>
                                        <TotalText>Total</TotalText>
                                        <TotalText>R$ {currencyBrl(initialDataValues.grandTotal || 0)}</TotalText>
                                    </TotalAll>

                                    <div className="flex flex-col flex-1" onMouseEnter={handleMouseEnterConfirm}>
                                        <Button
                                            text="Finalizar"
                                            onClick={handleSubmit}
                                            disabled={!formik.isValid}
                                            isLoading={mutationSubmit.isLoading}
                                        />
                                    </div>
                                </ContainerSubmit>
                            </FormikProvider>
                        </ColumnInfo>
                    </Content>
                )}
            </Modal>
            <ModalPortal>
                {showModalConfirm && (
                    <ModalCheckoutConfirm
                        onPressConfirm={handleConfirm}
                        data={formik.values}
                        onPressClose={() => setShowModalConfirm(false)}
                    />
                )}
            </ModalPortal>

            <ModalPortal>
                {errorModal.show && (
                    <ModalConfirm
                        onPressConfirm={handleErrorCheckout}
                        onPressCancel={() => setErrorModal({ show: false, message: '' })}
                        onClose={() => setErrorModal({ show: false, message: '' })}
                        showCancelButton={false}
                        text={errorModal.message}
                    />
                )}
            </ModalPortal>
        </>
    );
});
ModalCheckout.displayName = 'ModalCheckout';
export default memo(ModalCheckout);
