import { useState, useEffect } from 'react';
import { getWidth } from '../../utils/get-window-width';

export const useWindowWidth = () => {
  const [width, setWidth] = useState(getWidth());

  useEffect(() => {
    const resizeHandler = () => {
      setWidth(getWidth());
    };

    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  return width;
};
