import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { useCheckCoverageEstablishment } from '../../../hooks/use-check-coverage-establishment';
import { REQUIRED } from '../../../utils/errors';
import { Button, Input } from '../../molecules';
import { CloseTitle, Container, Fields, Header, Title } from './styles';
import { useState } from 'react';

interface IModalAddressProps {
    onClose?: () => void;
}

interface IValuesForm {
    surname: string;
    zipCode: string;
    address: string;
    number: string;
    complement: string;
    state: string;
    city: string;
}

const required = (value: any, options: any) => {
    const useSave = Boolean(options.from[0].value.useSave);
    if (useSave) {
        return true;
    } else if (!useSave && !value) {
        return false;
    }

    return true;
};

const validationForm = Yup.object().shape({
    surname: Yup.string().required(REQUIRED),
    zipCode: Yup.string().test('required', REQUIRED, required),
    address: Yup.string().test('required', REQUIRED, required),
    number: Yup.string().test('required', REQUIRED, required),
    complement: Yup.string().nullable(),
    state: Yup.string().test('required', REQUIRED, required),
    city: Yup.string().test('required', REQUIRED, required)
});

function ModalCardPayment({ onClose }: IModalAddressProps) {
    const [selectIsOpen, setSelectIsOpen] = useState<boolean>(false);
    const {
        data,

        isLoading
    } = useCheckCoverageEstablishment({
        onSuccess: (list) => {}
    });
    const formik = useFormik<IValuesForm>({
        initialValues: {
            surname: '',
            zipCode: '',
            address: '',
            number: '',
            complement: '',
            state: '',
            city: ''
        },
        validateOnMount: false,
        validationSchema: validationForm,
        enableReinitialize: true,
        onSubmit: (values) => {}
    });

    return (
        <Container onClose={onClose}>
            <div className="w-full">
                <CloseTitle onClick={onClose}>Fechar</CloseTitle>
                <Header>
                    <Title>Novo Endereço</Title>
                </Header>
                <Fields onSubmit={formik.handleSubmit}>
                    <FormikProvider value={formik}>
                        <Input name="surname" pattern="\d*" label="Apelido" inputMode="string" />

                        <Input name="zipCode" label="CEP" />
                        <Input name="address" label="Endereço" />
                        <Input name="number" label="Número" />
                        <Input name="complement" label="Complemento" />
                        <Input name="state" label="Estado" />
                        <Input name="city" label="Cidade" />
                    </FormikProvider>
                </Fields>
            </div>

            <Button
                text="Confirmar Pagamento"
                isLoading={isLoading}
                onClick={() => formik.handleSubmit()}
                style="
                    width: 360px;
                    height: 64px;"
            ></Button>
        </Container>
    );
}

export default ModalCardPayment;
