import tw, { styled } from 'twin.macro';
import NextImage from 'next/image';

import { Button } from '../../molecules';

export const Container = styled.div`
    ${tw`
        flex
        flex-col
        items-center
        p-[30px]
        mb-[50px]
    `}

    > svg {
        width: 15%;
        height: 15%;
        margin-bottom: 10px;
    }

    b {
        text-align: center;
    }
`;

export const QRcodeContainer = styled.div`
    ${tw`
        w-[200px]
        h-[200px]
    `}
`;

export const CopyButton = styled(Button)`
    ${tw`
        h-[50px]
        mt-[15px]
    `}
`;

export const Image = styled(NextImage)`
    ${tw`
        w-[100%]
        
    `}

    aspect-ratio: 1;
`;

export const ItemTimer = styled.span`
    --tw-bg-opacity: 1;
    background-color: rgb(214 39 46 / var(--tw-bg-opacity));
    color: #fff;

    border-radius: 12px;

    padding: 1rem;
    margin-top: 1rem;
`;
