import { FC, useMemo } from 'react';
import { AddressService, EstablishmentService } from '../../../services';
import { Address } from '../../../services/address/types';
import { CartShippingMethod } from '../../../services/cart/types';
import { Image } from '../../atoms';
import { Container, Content, TextSubTitle, TextTitle } from './styles';

interface CheckoutDeliveryAddressProps {
    address: Address | null;
    shippingMethod?: CartShippingMethod | null;
}

const CheckoutConfirmDeliveryAddress: FC<CheckoutDeliveryAddressProps> = ({ shippingMethod, address }) => {
    const pickUp = 'Entregar em';

    const establishmentAddress = useMemo(() => {
        // const cookie = EstablishmentService.getEstablishmentStorage();
        // return cookie?.address;
    }, []);

    return (
        <Container>
            <Image src="/images/map-mini.png" height="47" width="47" />
            <Content>
                <TextTitle numberOfLines={2}>{pickUp}</TextTitle>
                {address && <TextSubTitle>{AddressService.formatAddress(address)}</TextSubTitle>}
            </Content>
        </Container>
    );
};

export default CheckoutConfirmDeliveryAddress;
