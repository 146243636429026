import { Logo } from '../../../atoms';
import { LogoColor } from '../../../atoms/Logo';
import AboutUs from '../About-us';
import Certificate from '../Certificate';
import ContactUs from '../Contact-us';
import Copyright from '../Copyright';
import MenuCollapse from '../Menu-collapse';
import Payments from '../Payments';
import SocialMedia from '../Social-media';
import { Container, ImageContainer } from './styles';

function FooterMobile() {
    return (
        <Container>
            <ImageContainer>
                <Logo widthPortrait={320} heightPortrait={56} color={LogoColor.SECONDARY} />
            </ImageContainer>
            <MenuCollapse borderTop title="Sobre">
                <AboutUs />
            </MenuCollapse>
            <MenuCollapse title="Fale Conosco">
                <ContactUs />
            </MenuCollapse>
            <MenuCollapse title="Formas de Pagamento">
                <Payments />
            </MenuCollapse>
            <MenuCollapse title="Redes Sociais">
                <SocialMedia />
            </MenuCollapse>
            <MenuCollapse title="Selos e Certificados">
                <Certificate />
            </MenuCollapse>
            <Copyright />
        </Container>
    );
}

export default FooterMobile;
