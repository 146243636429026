import { FormikProvider, useFormik } from 'formik';
import { AnimatePresence } from 'framer-motion';
import * as Yup from 'yup';
import { useCheckCoverageEstablishment } from '../../../hooks/use-check-coverage-establishment';
import { CookieService, EstablishmentService, UserService } from '../../../services';
import { REQUIRED } from '../../../utils/errors';
import { Button, Input } from '../../molecules';
import Alert from '../../molecules/Alert';
import { IButtonVariant } from '../../molecules/Button/types';
import { Close, Container, Description, Fields, Header, Title } from './styles';
import { useEffect } from 'react';
import { IconSpinner } from '../../atoms';
import { twColors } from '../../../utils/get-config-tailwind';
import { useQueryCancelToken } from '../../../hooks';
import { toast } from 'react-toastify';
import { useIsCnpjAvailable } from '../../../hooks/use-is-cnpj-available';

interface IModalChangeRegionProps {
    onClose?: () => void;
}

interface IValuesForm {
    city: string;
    zipCode: string;
}

const validationForm = Yup.object().shape({
    zipCode: Yup.string().required(REQUIRED)
});

function ModalChangeRegion({ onClose }: IModalChangeRegionProps) {
    const formik = useFormik<IValuesForm>({
        initialValues: {
            city: '',
            zipCode: ''
        },
        validateOnMount: false,
        validationSchema: validationForm,
        enableReinitialize: true,
        onSubmit: () => {}
    });

    const { data, isLoading } = useQueryCancelToken(
        ['check-coverage-establishment', formik.values.zipCode],
        async (params) => {
            if (formik.values.zipCode.length < 9) {
                return undefined;
            }

            const valid = await useIsCnpjAvailable();

            const data = await EstablishmentService.checkCoverageEstablishment(formik.values.zipCode, params);

            const user = UserService.getUserInfoStorage();

            const taxVat = user?.taxvat;

            // isCnpjAvailable é "1", "0" ou null
            if (taxVat && !valid) {
                const cleanedTaxVat = taxVat.replace(/\D/g, '');

                if (cleanedTaxVat.length > 11) {
                    toast.warning(`${data?.[0]?.message}`);
                }
            }

            return data;
        }
    );

    function handleSubmit() {
        EstablishmentService.setEstablishmentStorage({
            zipCode: formik.values.zipCode,
            list: data || []
        });
        onClose?.();
    }

    const disabledButton = !formik.isValid || (!formik.values.city && !formik.values.zipCode) || isLoading;
    const hasError = !isLoading && data?.length === 0;
    return (
        <Container onClose={onClose}>
            <Header>
                <Title>Minha Região</Title>
                <Description>Dessa forma você terá acesso aos produtos e ofertas da sua região.</Description>
            </Header>
            <Fields onSubmit={formik.handleSubmit} $hasError={hasError}>
                <FormikProvider value={formik}>
                    {/* <Input label="Buscar por cidade" placeholder="O que você precisa hoje?" name="city" /> */}
                    <Input
                        inputMode="numeric"
                        label="Buscar por CEP"
                        mask="cep"
                        placeholder="Digite o CEP"
                        name="zipCode"
                        iconRight={
                            isLoading ? (
                                <div className="text-primary">
                                    <IconSpinner width={20} />
                                </div>
                            ) : (
                                <></>
                            )
                        }
                    />
                </FormikProvider>
            </Fields>
            <AnimatePresence>
                {hasError && (
                    <Alert title="Ainda não entregamos para este endereço :(">
                        Mas você pode navegar sem problemas pela nossa loja
                    </Alert>
                )}
            </AnimatePresence>
            <Button
                variant={IButtonVariant.PRIMARY_MEDIUM}
                text={hasError ? 'Continuar mesmo assim' : 'Confirmar'}
                disabled={disabledButton}
                isLoading={isLoading}
                onClick={() => handleSubmit()}
            ></Button>
            <Close onClick={onClose} />
        </Container>
    );
}

export default ModalChangeRegion;
