import tw, { styled } from 'twin.macro';
import { IconEye, IconShipping } from '../../atoms';

export const ActionsContainer = styled.div`
    ${tw`flex justify-between w-full align-middle `}
`;

export const DetailsActionBtn = styled(IconEye)`
    ${tw`
		w-5
		h-5
		text-primary
		cursor-pointer
		ml-[10px]
	`}
`;

export const ShippingActionBtn = styled(IconShipping)`
    ${tw`w-5 h-5 cursor-pointer text-primary`}
`;

export const Badge = styled.span`
    ${tw`lg:min-w-[120px] inline-block text-center text-white align-baseline rounded whitespace-nowrap bg-[#E2F6E7]`}
    padding: 8px 16px;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;

    border-radius: 8px;
    border: 1.5px solid #81ab05;
    color: #81ab05;
`;
