import React, { FC, useEffect, useState } from 'react';

//@ts-ignore
import { createPortal } from 'react-dom';
import { IS_BROWSER } from '../../../services/query-client';

interface IPortalProps {
    children: any;
}

const Portal: FC<IPortalProps> = ({ children }) => {
    const [el] = useState(IS_BROWSER ? document.createElement('div') : null);

    useEffect(() => {
        if (el) {
            el.classList.add('portal');
            document.body.appendChild(el);
        }

        return () => {
            if (el) {
                el.remove();
            }
        };
    }, []);

    if (!el) return null;

    return createPortal(children, el);
};

export default Portal;
