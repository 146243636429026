import React, { FC } from 'react';
import { twColors } from '../../../utils/get-config-tailwind';
import { IconSpinner } from '../../atoms';
import { Container } from './styles';

interface LoadingFullProps {}

const LoadingFull: FC<LoadingFullProps> = ({}) => {
    return (
        <Container>
            <IconSpinner stroke={twColors.white} width={60} />
        </Container>
    );
};

export default LoadingFull;
