import React from 'react';
import { BrandContainer, BrandLabel, Container, Flag, LowerRow, NumberSegment, TrashIcon, UpperRow } from './styles';

const CreditCardItemSkeleton = () => {
    return (
        <Container>
            <UpperRow>
                <BrandContainer>
                    <Flag />
                    <BrandLabel />
                </BrandContainer>
                <TrashIcon/>
            </UpperRow>
            <LowerRow>
                <NumberSegment />
                <NumberSegment />
                <NumberSegment />
                <NumberSegment />
            </LowerRow>
        </Container>
    );
};

export default CreditCardItemSkeleton;
