import { AxiosRequestConfig } from 'axios';
import api from '../services/api';
import { InstallmentsMundipagg } from '../services/mundipagg/types';
import { GetInstallmentsParams, PaymentMethod } from '../services/payment/types';
import { ucfirst } from '../utils/string';
import { currencyBrl } from '../utils/getFormattedValue';

export class Mundipagg extends PaymentMethod {
    async getInstallments({ creditCardType, value }: GetInstallmentsParams, config?: AxiosRequestConfig) {
        const { data } = await api.get<InstallmentsMundipagg[]>(
            `V1/mundipagg/installments/brandbyamount/${ucfirst(creditCardType)}/${value}`,
            config
        );
        return data.map((value) => {
            const [, , installmentTotal] = value.label.split(' ');
            return {
                quantity: value.id,
                totalWithTax: value.total_with_tax,
                installmentTotal: parseFloat(installmentTotal.split('R$')[1]),
                label: `${value.label.split('(Total')[0]} (${currencyBrl(value.total_with_tax)})`
            };
        });
    }
}
