import tw, { styled } from 'twin.macro';
import { IconClose, Modal, TextNunitoRegular14 } from '../../atoms';

export const Container = styled(Modal)`
    ${tw`
        flex
        flex-col
        md:max-w-[608px]
        h-auto
        py-6
        px-4
        gap-6
        md:gap-4
        md:px-6
        md:py-6
    `}
`;

export const Header = styled.div`
    ${tw`
        flex
        flex-col
        gap-4
    `}
`;

export const Title = styled.div`
    ${tw`
        h-8
        flex
        flex-col
        justify-center
        text-primary
        text-16
        leading-4
        font-bold
        md:text-18
        md:leading-[18px]
        md:text-[#212226]
    `}
`;

export const Description = styled(TextNunitoRegular14)`
    ${tw`
        h-12
        flex
        flex-col
        justify-center
    `}
`;

export const Fields = styled.form<{ $hasError: boolean }>`
    ${tw`
        flex
        flex-col
        gap-6
    `}
    ${({ $hasError }) => !$hasError && tw`pb-2`}
`;

export const Close = styled(IconClose)`
    ${tw`
        stroke-[#323232]
        w-6
        h-6
        absolute
        top-6
        right-4
        cursor-pointer
        md:top-4
        md:right-6
    `}
`;
