import { CartItemProductOption } from '../cart/types';
import { PaymentTypes } from '../payment/types';
import { IProduct } from '../product/types';

export type IncrementId = number;
export type EntityId = number;

export interface OrderProductExtensionAttibutes {
    options: { label: string; value: string; option_id: number; option_value: number }[];
}

export interface AddressOrder {
    address_type: string;
    city: string;
    country_id: string;
    customer_address_id: number;
    email: string;
    entity_id: number;
    firstname: string;
    lastname: string;
    parent_id: number;
    postcode: string;
    prefix?: string;
    suffix?: string;
    region: string;
    region_code: string;
    region_id: number;
    street: string[];
    telephone: string;
    vat_id: string;
}

export interface OrderProductItem {
    name: string;
    qty_ordered: number;
    sku: string;
    item_id: number;
    price: number;
    base_discount_amount: number;
    product_id?: number;
    product_option?: CartItemProductOption;
    extension_attributes?: OrderProductExtensionAttibutes;
}

export interface IStatusDetail {
    index: number;
    canceled: boolean;
    status: string;
    statusDetail:
        | {
              created_at: string;
              status: string;
          }
        | undefined;
    delivered: boolean;
    originalStatus: {
        created_at: string;
        status: string;
    };
    time: string;
}

export interface TrackingStatus {
    title: string;
    key: StatusTimeline;
}

export interface OrderTracking extends TrackingStatus {
    active: boolean;
    canceled: boolean;
    date?: string;
    actualStatus: boolean;
}

export enum Status {
    PEDIDO_RECEBIDO = 'pedido_recebido',
    PEDIDO_RECENTE = 'pedido_recente',
    PAGAMENTO_RECUSADO = 'pagamento_recusado',
    PAGAMENTO_EXPIRADO = 'pagamento_expirado',
    PAGAMENTO_PENDENTE = 'pagamento_pendente',
    PAGAMENTO_ESTORNADO = 'pagamento_estornado',
    PEDIDO_PAGO = 'processing',
    PEDIDO_EM_PROCESSAMENTO = 'pedido_em_processamento',
    PEDIDO_CANCELADO = 'pedido_cancelado',
    PEDIDO_EM_SEPARACAO = 'pedido_em_separacao',
    PEDIDO_EM_ESPERA = 'pedido_em_espera',
    PEDIDO_SEPARADO = 'pedido_separado',
    PEDIDO_LIBERADO = 'pedido_liberado',
    PEDIDO_ENTREGUE = 'pedido_entregue',
    PEDIDO_ENVIADO = 'complete',
    PEDIDO_FATURADO = 'complete',
    CUPOM_FISCAL_EMITIDO = 'cupom_fiscal_emitido',
    NEW = 'new',
    PEDIDO_NOVO = 'pending',
    CANCELED = 'canceled',
    CANCELED_NOVOPEDIDO = 'canceled_novopedido',
    PENDING_PAYMENT = 'pending_payment',
    PEDIDO_EXPEDIDO = 'PedidoExpedido',
    HOLDED = 'holded',
    INVOICED = 'invoiced',
    COMPLETE = 'complete',
    CLOSED = 'closed',
    PEDIDO_TESTE = 'pedido_teste'
}

export const STATUS_CANCELED = [
    Status.CANCELED,
    Status.CANCELED_NOVOPEDIDO,
    Status.PEDIDO_CANCELADO,
    Status.PEDIDO_TESTE,
    Status.PAGAMENTO_EXPIRADO,
    Status.PAGAMENTO_ESTORNADO,
    Status.PAGAMENTO_RECUSADO
];

export const STATUS_ALLOWED_EDIT = [
    Status.PEDIDO_RECEBIDO,
    Status.PAGAMENTO_PENDENTE,
    Status.PEDIDO_PAGO,
    Status.PEDIDO_EM_PROCESSAMENTO,
    Status.PEDIDO_EM_ESPERA
];

export enum StatusTimeline {
    PENDING = 'pending',
    PICKING = 'picking',
    BILLED = 'billed',
    DISPATCHED = 'dispatched',
    DELIVERED = 'delivered'
}

export type IssueStatus = string;
export type IssueType = string;
export type IssueId = string;

export type DeliveryTime = {
    deliveryComment: string;
    deliveryDate: string;
    deliveryTime: string;
    houseSecurityCode: null;
};

export type OrdersStatusHistory = {
    created_at: string;
    status: Status;
};

export interface Order {
    base_grand_total: number;
    base_shipping_amount: number;
    base_subtotal: number;
    coupon_code: string;
    created_at: string;
    entity_id: EntityId;
    increment_id: IncrementId;
    discount_amount: number;
    total_qty_ordered: number;
    customer_id?: number;
    status: Status;
    shipping_description?: string;
    items: OrderProductItem[];
    billing_address: AddressOrder;
    extension_attributes: {
        tracking_information?: {
            title: string;
        }[];
        shipping_assignments: {
            shipping: {
                address: AddressOrder;
                method: string;
            };
        }[];
        payment_additional_info?: { key: string; value: string }[];
        delivery_comment?: string;
        delivery_date?: string;
        delivery_time?: string;
        issue_status?: IssueStatus;
        issue_type?: IssueType;
        issue_id?: IssueId;
    };
    payment: {
        additional_information: any[];
        cc_type?: string;
        cc_last4?: string;
        method: PaymentTypes;
    };
    status_histories: OrdersStatusHistory[];
    datetime?: number;
}

export interface IOrderWithProducts {
    order: Order;
    products: IProduct[];
}

export interface IChangeOrderDateParams {
    orderId: string | number;
    date: string;
    hour: string;
}
