import { FC } from 'react';
import { RouterService } from '../../../services';
import { ThirdPartyLoginButtons } from '../../atoms';
import LoginForm from '../../organisms/Login-form';
import Layout from '../Layout';
import LayoutFitter from '../Layout-fitter';
import {
    Container,
    LoginFormContainer,
    ContainerDescription,
    ContainerTitle,
    FormSubmitButton,
    PersonIcon,
    RegisterFormButtonContainer,
    RegisterFormContainer,
    ThirdPartyLoginContainer,
    TitleContainer,
    TitleLabel,
    DivisoryLine
} from './styles';

import { ILoginProps } from './types';

const Home: FC<ILoginProps> = () => {
    const handlePressCreateAccount = () => {
        RouterService.toRoute({ options: { url: '/cadastrar' } });
    };

    return (
        <Layout>
            <LayoutFitter>
                <Container>
                    <TitleContainer>
                        {/* <PersonIcon />
                        <TitleLabel>Área do cliente</TitleLabel> */}
                    </TitleContainer>
                    <DivisoryLine />
                    {/* <ThirdPartyLoginContainer>
                        <ThirdPartyLoginButtons />
                    </ThirdPartyLoginContainer> */}
                    <LoginFormContainer>
                        <LoginForm onClose={() => {}} toggleForgottenPassword={() => {}} />
                    </LoginFormContainer>
                    <RegisterFormContainer>
                        <ContainerTitle>NOVOS CLIENTES</ContainerTitle>
                        <ContainerDescription>
                            Criar uma nova conta tem muitos benefícios: fechar pedidos mais rapidamente, registrar mais
                            endereços, acompanhar pedidos e muito mais.
                        </ContainerDescription>
                    </RegisterFormContainer>
                    <RegisterFormButtonContainer>
                        <FormSubmitButton onClick={handlePressCreateAccount} text="Criar conta" />
                    </RegisterFormButtonContainer>
                </Container>
            </LayoutFitter>
        </Layout>
    );
};

export default Home;
