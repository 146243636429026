import { SVGProps } from 'react';

const IconUser = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            stroke="white"
            {...props}
        >
            <path
                d="M16.6255 4.0304C18.4438 5.84868 18.4438 8.79669 16.6255 10.615C14.8072 12.4333 11.8592 12.4333 10.041 10.615C8.22268 8.79669 8.22268 5.84868 10.041 4.0304C11.8592 2.21212 14.8072 2.21212 16.6255 4.0304"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19.1505 17.0614C17.4185 16.3694 15.3865 15.988 13.3332 15.988C7.93584 15.988 2.6665 18.6107 2.6665 22.644V23.9774C2.6665 24.7134 3.26384 25.3107 3.99984 25.3107H16.5505"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M22.6667 29.3333C18.9853 29.3333 16 26.3493 16 22.6667C16 19.0613 19.0667 15.996 22.672 16C26.352 16.0027 29.3333 18.9867 29.3333 22.6667C29.3333 26.348 26.3493 29.3333 22.6667 29.3333"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M22.6667 23V25.6667" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M22.6653 19.7041C22.5627 19.7041 22.48 19.7867 22.4813 19.8894C22.4813 19.9921 22.564 20.0747 22.6667 20.0747C22.7693 20.0747 22.852 19.9921 22.852 19.8894C22.852 19.7867 22.7693 19.7041 22.6653 19.7041"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default IconUser;
