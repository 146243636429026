import tw, { styled } from 'twin.macro';

export const Container = styled.ul`
    ${tw`
    w-full
    mb-[50px]
  `}
`;

export const Divider = styled.hr`
    ${tw`
    my-[10px]
    invisible
  `}
`;
