import { motion } from 'framer-motion';
import tw, { styled } from 'twin.macro';

export const Container = styled.div`
    ${tw`
      w-[136px]
      flex
      flex-row
      justify-between
      items-center  
      text-18
      font-bold
      text-black
    `}
`;

export const Button = styled(motion.button)`
    ${tw`
        w-10 
        h-10 
        shrink-0 
        rounded-full 
        border-[0.36px] 
        border-black 
        flex 
        justify-center 
        items-center
    `}
`;
