import { motion } from 'framer-motion';
import tw, { styled } from 'twin.macro';

export const Container = styled(motion.div)`
    ${tw`
    w-full
    gap-[20px]
    px-[5px]
    grid
    grid-cols-2
    sm:px-0
    sm:grid-cols-3
    lg:grid-cols-4
    `}
`;
