import tw, { styled } from 'twin.macro';
import { cssDefaultInput } from '../../styles';
import { InputProps } from './types';
import { motion } from 'framer-motion';
import IconArrowDown from '../../../../atoms/icons/Arrow-down';

export const Input = styled(motion.div)<InputProps>`
    ${cssDefaultInput}
    ${tw`
        flex
        flex-row
        w-full
        items-center
        justify-between
        pr-0
        cursor-pointer
    `}
`;
export const ContainerIcon = styled(motion.div)`
    ${tw`
        w-[40px]
        h-full
        flex
        justify-center
        items-center
    `}
`;

export const Icon = styled(IconArrowDown)`
    ${tw`
        fill-primary
        w-[15px]
        h-[15px]
    `}
`;
export const Container = styled.div`
    ${tw`
        relative
        flex
        w-full
    `}
`;
export const List = styled(motion.div).attrs(() => ({
    // initial: { height: 0 },
    // animate: {
    //     height: 'auto'
    // },
    exit: 'close'
}))`
    ${tw`
        bg-white
        max-h-[400px]
        h-auto
        divide-y-[1px]
        divide-gray
       
        rounded-br-15
        rounded-bl-15
        border-gray
       overflow-auto



  
    `};
`;
export const ContainerList = styled.div`
    ${tw`
        rounded-br-15
        rounded-bl-15
        w-full
        z-[50]
        absolute
        overflow-hidden
    `}
`;

export const variantsInput = {
    open: {
        borderBottomRightRadius: '0px',
        borderBottomLeftRadius: '0px'
    },
    close: {
        borderBottomRightRadius: '15px',
        borderBottomLeftRadius: '15px',
        transition: {
            delay: 0.185
        }
    }
};
export const variantsList = {
    open: {
        height: 'auto'
    },
    border: {
        borderBottomWidth: '1px',
        borderRightWidth: '1px',
        borderLeftWidth: '1px',
        transition: { delay: 0.1, duration: 0.001 }
    },
    close: {
        height: '0'
    }
};
export const variantsIcon = {
    open: {
        rotate: 180
    },
    close: {
        rotate: 0
    }
};
