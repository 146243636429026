import { useCallback, useEffect, useState } from 'react';
import { IModalsContext, IModalsProps } from './types';

import { createContext } from 'use-context-selector';
import { IS_BROWSER } from '../../services/query-client';

export const ModalsContext = createContext<IModalsContext>({} as IModalsContext);

export const ModalsProvider = ({ children }: IModalsProps) => {
    const [opens, setOpens] = useState(new Set());

    const checkModalsOpen = useCallback((isOpen: boolean, name: string) => {
        if (isOpen) {
            showChat(false);
        }
        if (!IS_BROWSER) return;

        const html = document.getElementsByTagName('html')[0];
        if (!html) return;
        if (isOpen) {
            setOpens((old) => {
                old.add(name);
                return new Set(old);
            });
            return;
        }

        setOpens((old) => {
            old.delete(name);
            return new Set(old);
        });
    }, []);

    const showChat = (show: boolean) => {
        const chat = document.getElementById('md-app-widget');
        if (!chat) return;
        chat.style.transitionProperty = 'all';
        chat.style.transitionTimingFunction = 'cubic-bezier(0.4, 0, 0.2, 1)';
        chat.style.transitionDuration = '150ms';

        if (show) {
            chat.style.transform = 'translateX(0%)';
        } else {
            chat.style.transform = 'translateX(100%)';
        }
    };

    useEffect(() => {
        const html = document.getElementsByTagName('body')[0];
        if (html) {
            if (opens.size === 0) {
                html.style.overflow = '';
                html.style.paddingRight = '';
                showChat(true);
            } else {
                html.style.overflow = 'hidden';
                if (window.innerWidth > 1024) {
                    html.style.paddingRight = '8px';
                }
                showChat(false);
            }
        }
    }, [opens]);

    return (
        <ModalsContext.Provider
            value={{
                opens: opens.size,
                checkModalsOpen
            }}
        >
            {children}
        </ModalsContext.Provider>
    );
};
