import React, { FC } from 'react';
import { PaymentMethod } from '../../../services/payment/types';
import { Radio } from '../../atoms';
import { Container, TextTitle } from './styles';

interface ICreditCardTypeItemProps {
    data: PaymentMethod;
    onPress: (data: PaymentMethod) => void;
    selected?: boolean;
}

const CreditCardTypeItem: FC<ICreditCardTypeItemProps> = ({ data, onPress, selected = false }) => {
    const handlePress = () => {
        onPress(data);
    };
    return (
        <Container onClick={handlePress}>
            <Radio checked={selected} onChange={handlePress} />
            <TextTitle>{data.title}</TextTitle>
        </Container>
    );
};

export default CreditCardTypeItem;
