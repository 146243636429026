import React, { FC, SVGProps } from 'react';

const IconCheck: FC<SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg viewBox="0 0 13 10" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M1.917 3.833l-1.25 1.25L4.833 9.25l7.5-7.5L11.083.5l-6.25 6.25-2.916-2.917z" />
        </svg>
    );
};

export default IconCheck;
