import tw, { styled } from 'twin.macro';
import { IContainerProps } from './types';

export const Container = styled.div<IContainerProps>`
    ${({ active }) => (active ? tw`bg-primary` : tw`bg-gray`)}
    ${({ active }) => (active ? tw`w-[22.4px]` : tw`w-[5.6px]`)}
    ${tw`
    transition-all
    duration-[300ms]
        h-[5.6px]
        rounded
        cursor-pointer
    `}
`;
