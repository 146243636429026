import tw, { styled } from 'twin.macro';
import { ArrowIcon, TextLatoRegular15 } from '../../atoms';
import { Button, ProductItemSkeleton as ProductSkeleton } from '../../molecules';

export const Container = styled.div`
    ${tw`
        py-[15px]
        px-[10px]
        grid
        grid-rows-[237px 855]

        lg:grid-cols-[2fr 1fr]
    `}
`;

export const ConfigsContainer = styled.span`
    ${tw`
    flex
    flex-col
    w-[45%]
`}
`;

export const PaginationsControls = styled.span`
    ${tw`
    m-auto
    flex
    flex-row
    items-center
    justify-center
    mt-[25px]
`}
`;

export const ArrowLeft = styled(ArrowIcon)`
    ${tw`
    fill-[#000]
    rotate-90
    mr-[10px]
    cursor-pointer
    mt-[3px]
`}
`;

export const ArrowRight = styled(ArrowIcon)`
    ${tw`
    fill-[#000]
    rotate-[270deg]
    ml-[10px]
    cursor-pointer
`}
`;

export const PageNumber = styled.span`
    ${tw`
    mr-[5px]
    text-[16px]
    cursor-pointer
    
    last:mr-[0px]
`}
`;

export const ActivePageNumber = styled.span`
    ${tw`
    mr-[5px]
    text-[17px]
    font-[900]
    cursor-pointer
    
    last:mr-[0px]
`}
`;
