import { useRef, useState } from 'react';
import { ProductService } from '../../../services';
import { PRODUCT_IMAGE_NOT_FOUND } from '../../../services/product';
import { IProduct } from '../../../services/product/types';
import Image, { IImageProps } from '../Image';

interface IProductImageProps extends Omit<IImageProps, 'src'> {
    data: IProduct;
}
export default function ProductImage({ data, ...props }: IProductImageProps) {
    const imageList = useRef(ProductService.getListImages(Number(data.ean).toString()));
    const [src, setSrc] = useState<string>(imageList.current[0].src);
    const tryEanWithZero = useRef<boolean>(false);

    const handleError = () => {
        const key = imageList.current.findIndex((image) => image.src === src);
        if (key > -1) {
            imageList.current[key] = {
                ...imageList.current[key],
                isLoaded: true
            };
        }

        const nextImage = imageList.current.find((image) => !image.isLoaded)?.src;
        if (nextImage) {
            setSrc(nextImage);
        } else if (!tryEanWithZero.current) {
            const newListImages = ProductService.getListImages(data.ean || '0');

            setSrc(newListImages[0].src);
            tryEanWithZero.current = true;

            imageList.current = newListImages;
        } else {
            setSrc(PRODUCT_IMAGE_NOT_FOUND);
        }
    };

    return <Image {...props} disableImageOnError onError={handleError} src={src} />;
}
