import { UseQueryOptions } from '@tanstack/react-query';
import { ProductService } from '../../services';
import { IProductEstimateShipping } from '../../services/product/types';

import { PRODUCT_ESTIMATE_SHIPPING } from '../../utils/react-query-keys';
import { useEstablishment } from '../use-establishment';
import { useQueryCancelToken } from '../use-query-cancel-token';

export const useEstimateShippingProduct = (
    sku: string,
    qty: number,
    params?: UseQueryOptions<IProductEstimateShipping[] | undefined>
) => {
    const { data: establishment } = useEstablishment();

    return useQueryCancelToken<IProductEstimateShipping[] | undefined>(
        [PRODUCT_ESTIMATE_SHIPPING, sku, qty, establishment?.zipCode],
        (paramsAxios) => ProductService.estimateShippingProduct(sku, qty, paramsAxios),
        {
            refetchOnMount: true,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
            ...params
        }
    );
};
