import React, { FC, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { CookieService } from '../../services';

const useReactGa4 = () => {
    useEffect(() => {
        // const establishment = CookieService.getEstablishmentStorage();
        // const GA4 = establishment?.googleGTMToken;
        // const GTAG = establishment?.googleGtagToken;

        // GTAG && ReactGA.initialize(GTAG);
        // GA4 && ReactGA.initialize(GA4);

        window.dataLayer = window.dataLayer || [];

        function gtag(gtag: string, params: any) {
            window.dataLayer.push(arguments);
        }

        // (GTAG || GA4) && gtag('js', new Date());
        // GA4 && gtag('config', GA4);
        // GTAG && gtag('config', GTAG);
    }, []);
};

export default useReactGa4;
