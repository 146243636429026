import { QueryObserver, QueryObserverOptions } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';
import { ICookieCtx } from '../../utils/cookie';
import { NEXT_PUBLIC_WEB_SITE_ID } from '../../utils/envs';
import { USER_INFO } from '../../utils/react-query-keys';
import api, { apiAdmin, apiNext } from '../api';
import authService from '../auth';
import axiosService from '../axios';
import { queryClient } from '../query-client';
import { ChangePassword, Customer, CustomerInfo, ResetPassword, SubscribeFn } from './types';

const getUserInfoStorage = (): Partial<CustomerInfo> => {
    return queryClient.getQueryData([USER_INFO]) as CustomerInfo;
};

const forgotPassword = async (email: string) => {
    const { data } = await api.put('V1/customers/password', {
        email,
        template: 'email_reset',
        website: NEXT_PUBLIC_WEB_SITE_ID
    });

    return data;
};

const getInfo = async (params?: AxiosRequestConfig, options?: ICookieCtx): Promise<Partial<CustomerInfo> | null> => {
    const token = authService.getUserTokenStorage(options);
    if (!token) {
        return null;
    }

    try {
        const api = axiosService.getClientApi(options);
        const { data } = await api.get('V1/customers/me', params);

        return data;
    } catch (e) {
        console.info('getInfo', e);

        return null;
    }
};

const emailAvailable = async (email: string): Promise<boolean> => {
    const { data } = await apiNext.post('user/is-email-available', {
        customerEmail: email
    });

    return data;
};

const register = async (customer: Customer) => {
    const { data } = await api.post('V1/customers', customer);
    return data;
};

interface CustomerEditMe {
    firstname: any;
    lastname: any;
    dob: any;
    cpf: any;
    email: any;
    isSubscribed?: boolean;
    telephone: any;
}
const editMe = async (customer: CustomerEditMe) => {
    // const addresses = addressService.getAddressListStorage();
    const user = getUserInfoStorage();

    let extension_attributes = {};
    if ('isSubscribed' in customer)
        extension_attributes = {
            ...user?.extension_attributes,
            is_subscribed: customer.isSubscribed
        };

    // const newAddresses = addresses
    //     ? addresses?.map((address) => ({
    //           ...address,
    //           telephone: customer.phone,
    //           firstname: customer.firstname,
    //           lastname: customer.lastname,
    //           vat_id: customer.cpf
    //       }))
    //     : [];
    const { data } = await apiAdmin.put(`V1/customers/${user.id}`, {
        customer: {
            email: customer.email,
            firstname: customer.firstname,
            lastname: customer.lastname,
            taxvat: customer.cpf,
            dob: customer.dob,
            store_id: user.store_id,
            website_id: user.website_id,
            extension_attributes,
            // addresses: newAddresses
            custom_attributes: [
                {
                    attribute_code: 'mobilenumber',
                    value: customer?.telephone.replace(/\D/g, '')
                }
            ]
        }
    });
    queryClient.invalidateQueries([USER_INFO]);
    return data;
};

const resetPassword = async (body: ResetPassword) => {
    const { data } = await api.post('V1/bee/resetpassword', {
        data: {
            id: body.resetToken.id,
            resetToken: body.resetToken.token,
            newPassword: body.newPassword
        }
    });
    return data;
};

const changePassword = async (body: ChangePassword) => {
    const { data } = await api.put('V1/customers/me/password', {
        ...body
    });
    return data;
};

const subscribeNewsletter = async (email: string) => {
    const { data } = await api.post('V1/newsletter/subscribe', {
        data: {
            subscriber_email: email
        }
    });

    return data;
};

const createSubscribe = (fn: SubscribeFn, executeFirst: boolean, options?: QueryObserverOptions<CustomerInfo>) => {
    const observer = new QueryObserver<CustomerInfo>(queryClient, {
        queryKey: [USER_INFO],
        refetchOnMount: false,
        ...options
    });
    if (executeFirst) {
        const user = queryClient.getQueryData([USER_INFO]) as CustomerInfo;
        fn(user);
    }
    return observer.subscribe(({ data }) => fn(data as CustomerInfo));
};

const isAffiliate = async (customerId: number): Promise<string> => {
    const { data } = await api.get(`V1/lof-affiliate/verifyCustomerIsAffiliate/${customerId}`);

    return data;
};

export default {
    editMe,
    getUserInfoStorage,
    createSubscribe,
    getInfo,
    emailAvailable,
    register,
    forgotPassword,
    resetPassword,
    changePassword,
    subscribeNewsletter,
    isAffiliate
};
