import { FC, useState } from 'react';
import { useCart } from '../../../hooks';
import { PaymentMethod } from '../../../services/payment/types';
import { currencyBrl } from '../../../utils/getFormattedValue';
import { Button, CartIconQty, CreditCardTypeItem, HeaderLogoImageBackground } from '../../molecules';
import { CartContainer, CartTitle, Container, Content, Footer, Header, Options, TextTitle } from './styles';
import { IModalCreditCardTypeProps } from './types';

const ModalCreditCardType: FC<IModalCreditCardTypeProps> = ({ onClose, paymentMethod, onSubmit }) => {
    const { data } = useCart();
    const [value, setValue] = useState<PaymentMethod>();

    const handleSubmit = () => {
        if (value) onSubmit(value);
    };
    return (
        <Container onClose={onClose}>
            {/* <HeaderLogoImageBackground onPressClose={onClose} /> */}
            <Header showIconClose onPressIconClose={onClose} title="Selecione forma de pagamento" />
            <Content>
                <CartContainer>
                    <CartTitle>Total R$ {currencyBrl(data?.info?.grand_total || '0')} </CartTitle>
                    <CartIconQty />
                </CartContainer>
                <TextTitle>FORMAS DE PAGAMENTO</TextTitle>
                <Options>
                    {paymentMethod.length > 0 &&
                        paymentMethod.map((method) => (
                            <CreditCardTypeItem
                                key={method.code}
                                onPress={setValue}
                                selected={Boolean(value && value.code == method.code)}
                                data={method}
                            />
                        ))}
                </Options>
            </Content>
            <Footer>
                <Button text="Avançar" onClick={handleSubmit} disabled={!value} />
            </Footer>
        </Container>
    );
};

export default ModalCreditCardType;
