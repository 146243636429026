import { FC } from 'react';
import { Container } from './styles';
import { IClickableAreaIconProps } from './types';

const ClickableAreaIcon: FC<IClickableAreaIconProps> = ({ children, ...props }) => {
    return (
        <Container type="button" {...props}>
            {children}
        </Container>
    );
};

export default ClickableAreaIcon;
