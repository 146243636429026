import tw, { styled } from 'twin.macro';
import { TextNunitoBold18 } from '../../atoms';

export const Container = styled.div`
    ${tw`flex flex-col bg-primary`}
`;

export const Content = styled.div`
    ${tw`flex flex-col flex-1 pt-6 pb-20 gap-14 md:flex-row`}
`;
export const ContentMenus = styled.div`
    ${tw`flex flex-col justify-between flex-1 gap-4 md:flex-row`}
`;

export const LeftColumn = styled.div`
    ${tw`flex flex-col gap-6 `}
`;
export const RightColumn = styled.div`
    ${tw`flex flex-col flex-1 `}
`;

export const Column = styled.ul`
    ${tw`flex flex-col gap-2 `}
`;

export const Title = styled(TextNunitoBold18)``;

export const Menu = styled.li`
    ${tw`text-black text-12 font-nunito`}
`;

export const PaymentList = styled.div`
    ${tw`flex flex-wrap grid-cols-4 gap-x-3 gap-y-4 md:grid`}
`;
export const SocialMediaList = styled.div`
    ${tw`flex flex-row gap-3 `}
`;
export const CertificateList = styled.div`
    ${tw`
		flex
		flex-col
		gap-1
	`}
`;
