import React, { FC } from 'react';
import { Container, IconDelivery, Item, TextItem } from './styles';

interface ICheckoutSelectShippingMethodsSkeletonProps {}

const CheckoutSelectShippingMethodsSkeleton: FC<ICheckoutSelectShippingMethodsSkeletonProps> = ({}) => {
    return (
        <Container>
            <Item>
                <IconDelivery />
                <TextItem></TextItem>
            </Item>

            <Item>
                <IconDelivery />
                <TextItem></TextItem>
            </Item>
        </Container>
    );
};

export default CheckoutSelectShippingMethodsSkeleton;
