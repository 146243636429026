import { FC, useMemo } from 'react';

import { MenuItemProps } from './types';
import { Container, ContentRegular, ContentBlack } from './styles';
import { useRouter } from 'next/router';
import Link from '../../atoms/Link';
import { EstablishmentService } from '../../../services';

export const MyAccMenuItem: FC<MenuItemProps> = ({ isDanger, label, link, onClick }) => {
    const router = useRouter();
    const isActive = useMemo(() => {
        return router.pathname.includes(link || '');
    }, [router]);

    return (
        <Link options={{ url: EstablishmentService.createUrl(`/minha-conta/${link}`) }} onClick={onClick}>
            <Container>
                {isActive ? (
                    <ContentBlack $isDanger={isDanger}>{label}</ContentBlack>
                ) : (
                    <ContentRegular $isDanger={isDanger}>{label}</ContentRegular>
                )}
            </Container>
        </Link>
    );
};
