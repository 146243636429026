import { ReactNode } from 'react';
import tw, { styled } from 'twin.macro';
import { IconPersonRounded, TextLatoRegular13 } from '../../atoms';

export const Container = styled.div`
    ${tw`flex flex-col w-full justify-evenly`}
`;

export const TitleContainer = styled.div`
    ${tw`
        row-start-1
        flex
        flex-col
        items-center
        pt-[41px]

        lg:items-start
        lg:col-start-2
    `}
`;

export const PersonIcon = styled(IconPersonRounded)`
    ${tw`
        fill-primary
        mb-[20px]

        lg:hidden
    `}
`;

export const TitleLabel = styled.div`
    ${tw`
        font-nunito
        text-[32px]
        font-bold
        leading-[43.65px]
        text-left
    `}
`;

export const ThirdPartyLoginContainer = styled.div`
    ${tw`flex flex-col items-center col-start-1 row-start-2 lg:col-start-2`}
`;

export const PersonalInfoFormContainer = styled.div`
    ${tw`flex flex-col col-start-1 row-start-3 lg:col-start-2`}
`;

export const AddressInfoFormContainer = styled.div`
    ${tw`flex flex-col items-center col-start-1 row-start-4 lg:col-start-2 lg:items-start`}
`;

export const AccessFormContainer = styled.div`
    ${tw`flex flex-col col-start-1 row-start-5 lg:col-start-2`}
`;

export const LoginFormHeader = styled.span`
    ${tw`
    mt-4
    w-full
    flex
    justify-between
    items-center
    mb-[20px]

    lg:flex-col
    lg:items-start
    lg:justify-between
    lg:mb-[7px]
    `}
`;

export const ContainerTitle = styled.span`
    ${tw`
        font-nunito
        text-[18px]
        font-bold
        leading-[24.55px]
        text-left
    `}
`;

export const ErrorText = styled.span`
    ${tw`
        mt-4
        mb-[15px]
        mx-5
        text-[#ef4123]
        text-14
    `}
`;
export const SubmitActionsContainer = styled.div`
    ${tw`

    flex
    flex-col
    gap-4
    md:flex-row
    lg:mb-8
   
    py-[20px]
    w-full
    
    justify-between

    `}
`;

export const FormBackButton = styled.span<React.HTMLAttributes<HTMLSpanElement>>`
    ${tw`
        w-full
        text-center
        font-nunito
        font-[400]
        text-[16px]
        text-[#F00]
        cursor-pointer

        lg:w-auto
        lg:m-[20px]
        lg:ml-[40px]
    `}
`;

export const FormsContainer = styled.div`
    ${tw``}
`;

interface BoxContainerProps {
    width?: string;
    height?: string;
    children?: ReactNode;
}

export const BoxContainer = styled.div<BoxContainerProps>`
    ${tw`
        flex
        flex-col
   
        gap-[20px]
        p-6
        bg-[#FDFDFD]
        rounded-8
        border-[0.5px]
        border-[#E6E6E6]
    `}
`;

export const CheckboxContainer = styled.div`
    ${tw`flex flex-col w-full gap-4 mt-8 mb-10 md:flex-row`}
`;

export const CheckboxWrapper = styled.div`
    ${tw`
        flex
        items-center
        h-[56px]
        shrink-0
        rounded-[28px]
        border-[0.5px]
        border-[#DBDBDB]
        bg-white
        gap-4
        px-4
        md:justify-center
        md:w-[208px]
        cursor-pointer
    `}
`;
