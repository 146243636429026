import tw from 'twin.macro';
import { styled } from 'twin.macro';

export const Container = styled.div`
    ${tw`
        w-full 
        
        px-4
        pt-4 
        pb-6 
        rounded-8 
        cursor-pointer
        min-h-[200px]
        bg-white 
        border-[0.5px] 
        border-[#E6E6E6] 
        text-[black]
        lg:min-w-[180px]
    `}
`;

export const Title = styled.h1`
    ${tw`
        text-18 font-semibold pt-2 max-w-[112px]
    `}
`;

export const Description = styled.p`
    ${tw`pt-4 text-12`}
`;
