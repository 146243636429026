import { FC, useState } from 'react';
import { useCartDeleteItem } from '../../../hooks/use-cart-delete-item';
import { Container, Title } from './styles';
import { IProductOutStockProps } from './types';

const ProductOutStock: FC<IProductOutStockProps> = ({
    horizontal,
    showTrash,
    itemId,
    disableStopPropagation = true
}) => {
    const [trash, setTrash] = useState(false);
    const { mutate: deleteItem, isLoading: isLoadingDelete } = useCartDeleteItem();

    const handleMouseEnter = () => {
        if (showTrash) {
            setTrash(true);
        }
    };

    function onClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        if (disableStopPropagation) {
            event.stopPropagation();
            event.preventDefault();
        }
        if (itemId) deleteItem({ itemId });
    }

    function getContent() {
        if (isLoadingDelete) {
            return <span>Removendo...</span>;
        }

        if (trash) {
            return <span>Remover produto</span>;
        }

        return <span>Produto indisponível</span>;
    }
    return (
        <Container
            onClick={(event) => onClick(event)}
            $horizontal={horizontal}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={() => setTrash(false)}
        >
            <Title>{getContent()}</Title>
        </Container>
    );
};

export default ProductOutStock;
