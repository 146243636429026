import React, { FC, SVGProps } from 'react';
import CreditCardType from 'credit-card-type';

import FlagMasterCard from '../Flags/Master-card';
import FlagVisa from '../Flags/Visa';
import FlagElo from '../Flags/Elo';
import FlagJcb from '../Flags/Jcb';
import FlagHipercard from '../Flags/Hipercard';
import FlagDiscover from '../Flags/Discover';
import FlagAmex from '../Flags/Amex';
import FlagDiners from '../Flags/Diners';
import IconQrcode from '../icons/Qrcode';
import IconTruck from '../icons/Truck';
import FlagMaestroCard from '../Flags/Maestro';

interface FlagTypeProps {
    width: number;
    height: number;
    type?: string;
}

const types: any = {
    [CreditCardType.types.MASTERCARD]: (props: SVGProps<SVGSVGElement>) => <FlagMasterCard {...props} />,
    [CreditCardType.types.VISA]: (props: SVGProps<SVGSVGElement>) => <FlagVisa {...props} />,
    [CreditCardType.types.ELO]: (props: SVGProps<SVGSVGElement>) => <FlagElo {...props} />,
    [CreditCardType.types.JCB]: (props: SVGProps<SVGSVGElement>) => <FlagJcb {...props} />,
    [CreditCardType.types.HIPERCARD]: (props: SVGProps<SVGSVGElement>) => <FlagHipercard {...props} />,
    [CreditCardType.types.DISCOVER]: (props: SVGProps<SVGSVGElement>) => <FlagDiscover {...props} />,
    [CreditCardType.types.AMERICAN_EXPRESS]: (props: SVGProps<SVGSVGElement>) => <FlagAmex {...props} />,
    [CreditCardType.types.DINERS_CLUB]: (props: SVGProps<SVGSVGElement>) => <FlagDiners {...props} />,
    [CreditCardType.types.MAESTRO]: (props: SVGProps<SVGSVGElement>) => <FlagMaestroCard {...props} />,
    pix: (props: SVGProps<SVGSVGElement>) => <IconQrcode color="#000" {...props} />,
    delivery: (props: SVGProps<SVGSVGElement>) => <IconTruck fill="#000" {...props} />
};

const FlagType: FC<FlagTypeProps> = ({ width, type, height }) => {
    if (!type) {
        return null;
    }
    type = type.replace(/ /, '');

    if (types.hasOwnProperty(type.toLowerCase())) {
        return types[type.toLowerCase()]({ width, height });
    }
};

export default FlagType;
