import { Container, Content, Title } from './styles';

interface IProductOfferProps {
    value: number;
}

function ProductOffer({ value }: IProductOfferProps) {
    return (
        <Container>
            <Content>
                <Title>-{value}%</Title>
            </Content>
        </Container>
    );
}

export default ProductOffer;
