import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { BlockService } from '../../services';
import { IBlockConfig } from '../../services/block/types';
import { IS_BROWSER } from '../../services/query-client';
import { BLOCK_CONFIG } from '../../utils/react-query-keys';

export const useBlockConfig = (params?: UseQueryOptions<IBlockConfig>) => {
    return useQuery<IBlockConfig>([BLOCK_CONFIG], () => BlockService.getGeneralInfos(), {
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        enabled: IS_BROWSER,
        ...params
    });
};
