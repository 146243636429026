import { motion } from 'framer-motion';
import tw, { styled } from 'twin.macro';
import TextLatoBold13 from '../text/Lato-bold-13';

export const Container = styled(motion.div)`
    ${tw`
        bg-primary
        rounded-5
        justify-center
        items-center
        flex
        p-[3px]
    `}
`;

export const Text = styled(TextLatoBold13)`
    ${tw`
        text-[10px]
        leading-[12px]
        text-white
        text-center
        whitespace-nowrap
    `}
`;
