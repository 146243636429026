import { forwardRef, Ref, useImperativeHandle, useRef } from 'react';
import { IModalHandle } from '../Modal/types';
import { IModalAboutProps } from './types';
import {
    Container,
    ContainerClose,
    ContainerImage,
    Content,
    DefaultModal,
    Scroll,
    Text,
    TextTitle,
    IconClose
} from './styles';
import { twColors } from '../../../utils/get-config-tailwind';
import { Image } from '../../atoms';

const ModalAbout = forwardRef(({}: IModalAboutProps, ref: Ref<IModalHandle>) => {
    const refModal = useRef<IModalHandle>(null);

    useImperativeHandle(ref, () => ({
        show: () => refModal.current?.show(),
        close: () => refModal.current?.close()
    }));

    return (
        <DefaultModal name="about" ref={refModal}>
            <Container>
                <ContainerClose onClick={() => refModal.current?.close()}>
                    <IconClose />
                </ContainerClose>
                <Scroll>
                    <ContainerImage>
                        <Image
                            width="268"
                            style={{ minWidth: '268px' }}
                            height="517"
                            objectFit="cover"
                            src="/images/components/organisms/modal-about/cell.png"
                        />
                    </ContainerImage>
                    <Content>
                        <TextTitle>Quem somos</TextTitle>
                        <Text>
                            O <strong>Delivei</strong> é seu novo aplicativo de compras. Você pode contar com ele quando
                            faltar um ingrediente da receita planejada para aquele jantar especial, ou quando ir com as
                            crianças ao mercado parecer uma aventura maior do que você está disposto a encarar. Perfeito
                            para quem quer praticidade, segurança e economia. Mas não é só isso. Sabe todas aquelas
                            vezes em que você desistiu de fazer as compras de supermercado pelo aplicativo pois já
                            imaginou que o abacaxi ia vir verde, o presunto cortado grosso e o desinfetante chegaria
                            embalado no mesmo saco que a uva? É pra resolver esse tipo de problema que chega o{' '}
                            <strong>Delivei</strong>. O que oferecemos não é apenas um aplicativo de entregas, mas um
                            time de pessoas como você, que têm como missão fazer suas compras com o mesmo cuidado e
                            carinho com que fariam para si próprios, para suas mães ou seus filhos. Para garantir que
                            tudo chegue do jeitinho que você gosta, o <strong>Delivei</strong> une o melhor da
                            tecnologia com o máximo de atenção por você. Pode confiar.
                        </Text>
                        <TextTitle>DNA</TextTitle>
                        <Text>
                            O <strong>Delivei</strong> faz parte do grupo TTX, holding responsável ainda pela conta
                            digital Dizconta, pelo marketplace Maxmania e pela Tatix Full Commerce, empresa com 7 anos
                            de experiência, que cuida de todas as etapas das vendas on-line de grandes marcas e conhece
                            profundamente os negócios digitais.
                        </Text>
                    </Content>
                </Scroll>
            </Container>
        </DefaultModal>
    );
});
ModalAbout.displayName = 'ModalAbout';
export default ModalAbout;
