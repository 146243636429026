import tw, { styled } from 'twin.macro';
import { GoogleLogo, FacebookLogo } from '..';

export const GoogleButton = styled.button`
    ${tw`
    flex
    items-center
    justify-center
    w-full
    h-[48px]
    bg-[#DD4C39]
    rounded-[15px]
    font-nunito
    font-[700]
    text-[18px]
    text-white
    outline-none
    mb-[20px]

    lg:w-[370px]
    `}
`;

export const ButtonLabel = styled.span`
    ${tw`
       flex-1
    `}
`;

export const GoogleIcon = styled(GoogleLogo)`
    ${tw`
        bg-white
        w-[35px]
        h-[30px]
        aspect-[1]
        ml-[10px]
        rounded-[10px]
        px-[5px]
    `}
`;

export const FacebookIconContainer = styled.div`
    ${tw`
        flex
        justify-center
        items-center
        bg-white
        w-[35px]
        h-[30px]
        aspect-[1]
        ml-[10px]
        rounded-[10px]
        px-[5px]
    `}
`;

export const FacebookButton = styled.button`
    ${tw`
    flex
    items-center
    justify-center
    w-full
    h-[48px]
    bg-[#475993]
    rounded-[15px]
    font-nunito
    font-[700]
    text-[18px]
    text-white
    outline-none
    mb-[20px]
    lg:w-[370px]
    `}
`;
