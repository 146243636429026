import { BannerService } from '../../../../services';

import { useQuery } from '@tanstack/react-query';
import { NEXT_PUBLIC_HOME_BANNER_4_IMAGES } from '../../../../utils/envs';
import { BANNER } from '../../../../utils/react-query-keys';
import useOnClickBanner from '../../../../hooks/use-on-click-banner';
import Image from 'next/image';
import LayoutFitter from '../../Layout-fitter';

export default function BannerFourImages() {
    const { data: images } = useQuery(
        [BANNER, NEXT_PUBLIC_HOME_BANNER_4_IMAGES],
        () => BannerService.getBannerResponsiveById(NEXT_PUBLIC_HOME_BANNER_4_IMAGES),
        {
            refetchOnWindowFocus: false,
            refetchOnMount: false
        }
    );

    const onClickBanner = useOnClickBanner();

    if (!images) return null;
    return (
        <div className="pt-8 md:pt-16" style={{ gridArea: 'BannerFourImages' }}>
            <LayoutFitter>
                <div className="grid grid-cols-2 gap-4 w-full">
                    {images?.map((image, index) => (
                        <div
                            className="rounded-8 overflow-hidden cursor-pointer"
                            key={index}
                            onClick={() => onClickBanner(image)}
                        >
                            <img
                                alt="banner"
                                className="hidden md:block object-contain"
                                sizes="auto"
                                width={660}
                                height={217}
                                src={image.image as string}
                            />
                            <img
                                alt="banner"
                                className="black md:hidden object-contain"
                                width={355}
                                height={200}
                                sizes="auto"
                                src={image.imageMobile as string}
                            />
                        </div>
                    ))}
                </div>
            </LayoutFitter>
        </div>
    );
}
