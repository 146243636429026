import tw, { styled } from 'twin.macro';

import { TextLatoRegular18, TextLatoRegular15 } from '../../atoms';
import { Button } from '../../molecules';

export const Container = styled.article`
  ${tw`
    w-full
		flex
		flex-col
  `}
`;

export const AboutTitle = styled(TextLatoRegular18)`
  ${tw`
    mt-[50px]
		mb-[15px]
		uppercase
  `}
`;

export const HeaderButton = styled(Button)`
  ${tw`
		h-[24px]
		rounded-[8px]
	`}

  & span {
    font-size: 13px;
  }
`;

export const StatusDescription = styled(TextLatoRegular15)`
  ${tw`
    mt-[5px]
    mb-[15px]
	`}
`;
