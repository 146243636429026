import tw, { styled } from 'twin.macro';
import { Modal, TextLatoBlack18, TextLatoRegular15 } from '../../atoms';
import { ModalHeader } from '../../molecules';

export const Container = styled(Modal)`
    ${tw`
    flex
    flex-col
    h-auto
    md:h-full
    md:max-w-modalForceSm
    `}
`;
export const Content = styled.div`
    ${tw`
        /* pt-[30px] */
        px-[20px]
        flex-1
        flex
        flex-col
        overflow-y-auto
        overflow-x-hidden
    `}
`;

export const CartContainer = styled.div`
    ${tw`
        border-b-[2px]
        border-gray
        flex
        flex-row
        justify-between
        items-center
        pb-[8px]     
    `}
`;

export const CartTitle = styled(TextLatoBlack18)`
    ${tw`
    `}
`;
export const Options = styled.div`
    ${tw`
        mt-[16px]
    `}
`;

export const TextTitle = styled(TextLatoRegular15)`
    ${tw`
        mt-[20px]        
    `}
`;
export const Footer = styled.div`
    ${tw`
        flex 
        flex-col
        p-[20px]
    `}
`;

export const Header = styled(ModalHeader)`
    ${tw`
        my-[10px]
    `}
`;
