import tw, { styled } from 'twin.macro';
import { IPulseDotProps } from '.';
import { DotContainerProps } from './types';

export const Container = styled.div<DotContainerProps>`
    ${tw`
        w-[22px]
        h-[22px]
        rounded-full 
        relative
    `}

    ${({ active }) => {
        return active
            ? tw`
                bg-primary 
            `
            : tw`
                bg-gray
            `;
    }}
`;

export const Pulse = styled.div<IPulseDotProps>`
    ${({ state }) => {
        switch (state) {
            case 'ACTIVE':
                return tw`
                    bg-primary
                `;
            case 'INACTIVE':
                return tw`
                    bg-gray
                `;
            case 'CURRENT':
                return tw`
                    bg-primary 
                    animate-ping
                `;
            default:
                return tw`
                    bg-gray
                `;
        }
    }}

    ${tw`
        absolute 
        inline-flex 
        h-full 
        w-full 
        rounded-full  
        opacity-75
    `}
`;
