import { motion } from 'framer-motion';
import tw, { styled } from 'twin.macro';

export const Container = styled(motion.div)`
    ${tw`
   flex
    flex-col
    overflow-hidden
  `}
`;

export const Header = styled.div`
    ${tw`
   flex
   flex-row
   justify-between
   items-center
   py-4
   font-semibold
   cursor-pointer
   text-18
   text-black
   leading-[24px]
  `}
`;

export const ContainerIcon = styled(motion.div).attrs({
    variants: {
        close: { rotate: 0 },
        open: { rotate: 180 }
    }
})``;
export const Content = styled(motion.div).attrs({
    initial: { opacity: 0, height: 0 },
    animate: { opacity: 1, height: 'auto' },
    exit: { opacity: 0, height: 0 }
})``;
