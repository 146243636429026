import tw, { styled } from 'twin.macro';

export const Container = styled.div`
    ${tw`
        flex
        flex-col
    `}
`;

export const AddressContainer = styled.div`
    ${tw`
        flex
        flex-col
        w-full
        pl-[10px]
        mb-[60px]
`}
`;

export const AddressDataLabel = styled.span`
    ${tw`
        font-[600]
`}
`;

export const AddressDataValue = styled.span`
    ${tw`
    ml-[5px]
`}
`;

export const AddressDataContainer = styled.div`
    ${tw`
    flex
    mt-[10px]
    first:mt-0
`}
`;

export const ContentFormTitleContainer = styled.span`
    ${tw`
        flex
        justify-between
        border-b-[gray]
        border-b-[1px]
        mb-[20px]
    `}
`;

export const ContentFormTitle = styled.span`
    ${tw`
        flex
        mt-[10px]
        font-[600]
    `}
`;

export const ContentFormAction = styled.span`
    ${tw`
        flex
        mt-[10px]
        font-[600]
        text-center
        text-primary
        cursor-pointer
    `}
`;
