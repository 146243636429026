import { height } from '@mui/system';
import { FC, HTMLAttributes, memo } from 'react';
import { Banner as BannerInterface } from '../../../services/banner';
import Banner from '../Banner';
import { Container, Img } from './styles';

interface IBannerCarouselSlideShowOneProps extends Partial<HTMLAttributes<HTMLDivElement>> {
    id: string;
    widthImage: number;
    heightImage: number;
}

const BannerCarouselSlideShowOne: FC<IBannerCarouselSlideShowOneProps> = ({
    className,
    id,
    widthImage,
    heightImage
}) => {
    const responsive = {
        616: {
            slidesPerView: 2,
            slidesPerGroup: 2
        }
    };

    const renderImage = (banner: BannerInterface) => <Img src={banner.image} width={widthImage} height={heightImage} />;

    return (
        <Container>
            <Banner
                slidesPerGroup={2}
                bannerId={id}
                responsive={responsive}
                marginItem={0}
                numberSkeleton={2}
                renderImage={renderImage}
                showArrows={true}
            />
        </Container>
    );
};

export default memo(BannerCarouselSlideShowOne);
