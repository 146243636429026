import { Container } from './styles';
import { BadgeProps } from './types';

function Badge({ children, color, size, customColor }: BadgeProps) {
    return (
        <Container $size={size} $color={color} $customColor={customColor}>
            {children}
        </Container>
    );
}

export default Badge;
