import { useEffect, useRef } from 'react';

export default function useAdoptCallbacks(id: string, onAccept: () => void, onReject: () => void) {
    const observerRef = useRef<MutationObserver | null>(null);
    const listenersAddedRef = useRef(false);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            let acceptButton: HTMLButtonElement | null = null;
            let rejectButton: HTMLButtonElement | null = null;

            const addEventListeners = () => {
                acceptButton = document.getElementById('adopt-accept-all-button') as HTMLButtonElement | null;
                rejectButton = document.getElementById('adopt-reject-all-button') as HTMLButtonElement | null;

                if (acceptButton && !acceptButton.hasAttribute(`data-listener-added-${id}`)) {
                    acceptButton.addEventListener('click', onAccept);
                    acceptButton.setAttribute(`data-listener-added-${id}`, 'true');
                }

                if (rejectButton && !rejectButton.hasAttribute(`data-listener-added-${id}`)) {
                    rejectButton.addEventListener('click', onReject);
                    rejectButton.setAttribute(`data-listener-added-${id}`, 'true');
                }

                if ((acceptButton || rejectButton) && !listenersAddedRef.current) {
                    listenersAddedRef.current = true;
                    // Desconecta o observador após adicionar os listeners
                    observerRef.current?.disconnect();
                }
            };

            // Se os listeners não foram adicionados, cria o MutationObserver
            if (!listenersAddedRef.current) {
                observerRef.current = new MutationObserver(() => {
                    addEventListeners();
                });

                observerRef.current.observe(document.body, {
                    childList: true,
                    subtree: true
                });

                // Tenta adicionar os event listeners imediatamente
                addEventListeners();
            }

            return () => {
                // Remove os event listeners e desconecta o observador na desmontagem
                if (acceptButton) {
                    acceptButton.removeEventListener('click', onAccept);
                    acceptButton.removeAttribute(`data-listener-added-${id}`);
                }
                if (rejectButton) {
                    rejectButton.removeEventListener('click', onReject);
                    rejectButton.removeAttribute(`data-listener-added-${id}`);
                }
                listenersAddedRef.current = false;
                observerRef.current?.disconnect();
                observerRef.current = null;
            };
        }
    }, [onAccept, onReject]);
}
