import tw, { styled } from 'twin.macro';

export const Container = styled.div`
    ${tw`
        animate-pulse
        h-[64px]
    `}
`;
export const TotalDetail = styled.div`
    ${tw`
        mt-[10px]
        flex    
        flex-row
        items-center
        justify-end
    `}
`;
export const ValueLabelDetail = styled.div`
    ${tw`
        w-[66px]
        h-[18px]
        mt-[2px]
        mb-[2px]
        rounded-5
        bg-gray
    `}
`;
export const ValueDetail = styled.div`
    ${tw`
        w-[84px]
        h-[18px]
        mt-[2px]
        mb-[2px]
        rounded-5
        bg-gray
        ml-[44px]
    `}
`;
