import { useField } from 'formik';
import { ChangeEvent, forwardRef, useCallback } from 'react';
import getFormattedValue from '../../../../../utils/getFormattedValue';
import { TypeProps } from '../types';
import { Container, ContainerIcon, CountryCode, Input } from './styles';

function InputText({ mask, ...props }: TypeProps<string>, ref: any) {
    const [field, meta, helpers] = useField<string>(props.name);

    const { setValue } = helpers;
    const { error, value } = meta;

    const handleChange = useCallback(
        (value: ChangeEvent<any>) => {
            let valueText = value.target.value;
            if (mask) {
                valueText = getFormattedValue({
                    value: valueText,
                    mask: mask
                });
            }
            if (props.onChange) props.onChange(props.name, valueText);
            setValue(valueText);
        },
        [props.onChange]
    );
    return (
        <Container $isError={Boolean(error)}>
            {/* {mask === 'mobileNumber' && <CountryCode>+55</CountryCode>} */}
            <Input {...props} value={value} onChange={handleChange} ref={ref} />
            {props.iconRight && <ContainerIcon>{props.iconRight}</ContainerIcon>}
        </Container>
    );
}

export default forwardRef(InputText);
