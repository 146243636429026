import { FC } from 'react';
import tw, { styled } from 'twin.macro';

const Container = styled.span`
    line-height: 16px;
    ${tw`
        font-nunito
        text-13
        text-black
        font-extrabold
    `}
`;

const TextLatoExtraBold13: FC<any> = ({ children, ...props }) => {
    return <Container {...props}>{children}</Container>;
};

export default TextLatoExtraBold13;
