import { motion } from 'framer-motion';
import tw, { styled } from 'twin.macro';
import { twColors } from '../../../utils/get-config-tailwind';
import { IconArrowDownFilled, TextLatoBold15 } from '../../atoms';
import IconCartAtom from '../../atoms/icons/Cart';

export const ButtonMobileShowList = styled(motion.div)`
    ${tw`
    h-[48px]
    shadow-md
    rounded-15
    flex
    flex-row
    justify-between
    items-center
    cursor-pointer
    mx-[20px]
    px-[15px]
    border-b
    border-gray

    lg:hidden
    `}
`;
export const ListItems = styled(motion.div)`
    ${tw`
        overflow-visible
        overflow-x-visible
        overflow-y-hidden
        mb-[20px]
        lg:mb-[0px]
        lg:overflow-auto
        lg:h-auto
    `}
`;

export const variantsList = {
    open: {
        height: 'auto'
    },
    close: {
        height: 0
    }
};
export const variantsContainerIcon = {
    open: {
        rotate: 180
    },
    close: {
        rotate: 0
    }
};
export const variantsButtonMobile = {
    open: {
        marginBottom: '0px'
    },
    close: {
        marginBottom: 0
    }
};
export const variantsIcon = {
    open: {
        fill: twColors.gray
    },
    close: {
        fill: twColors.primary
    }
};

export const TextTitle = styled(TextLatoBold15)`
    ${tw`
        
    `}
`;

export const ContainerIcon = styled(motion.div)`
    ${tw`
        w-[15px]
        h-[40px]
        flex
        items-center
        justify-center
    `}
`;

export const Icon = styled(motion(IconArrowDownFilled))`
    ${tw`
    w-[15px]
    h-[15px]
    fill-primary
    `}
`;

export const IconCart = styled(IconCartAtom)`
    ${tw`
        fill-black
        w-[20px]
        h-[20px]
    `}
`;
export const ContainerIconCart = styled.div`
    ${tw`
        justify-center
        items-center
        flex
        gap-[5px]
    `}
`;

export const ContainerText = styled.div`
    ${tw`
        flex
        flex-row
        items-center
        gap-[10px]
    `}
`;
