import React, { FC } from 'react';
import { CartService } from '../../../services';
import { Container, Icon, Text } from './styles';

interface ICartIconQtyProps {}

const CartIconQty: FC<ICartIconQtyProps> = ({}) => {
    return (
        <Container>
            <Icon />
            <Text>({CartService.getTotalItemsCart()})</Text>
        </Container>
    );
};

export default CartIconQty;
