import { memo } from 'react';
import { IBlockAisles } from '../../../services/block';
import Hall from '../Hall';
import { createFeaturedHallFilter } from './types';
// const currentDateFilter = format(new Date(), `Y-MM-dd`, { locale: ptBR });

// export const HALL_FEATURED_FILTER = {
//     bool: {
//         filter: [
//             {
//                 term: {
//                     status_stock: 1
//                 }
//             },
//             { condition_type: ConditionType.EQ, field: 'featured', value: 1 }
//         ]
//     }
// };

interface IHall {
    origin: string;
    id?: string;
    hallHome: IBlockAisles;
}

const HallProductsFeatured = ({ origin, id, hallHome }: IHall) => {
    const title = hallHome.title;
    const categories = hallHome.categories;

    return (
        <Hall
            title={title}
            id={id}
            filterGroups={createFeaturedHallFilter(categories)}
            sort={categories.map((item) => ({
                [`position_category_${item}`]: {
                    order: 'asc'
                }
            }))}
        />
    );
};

export default memo(HallProductsFeatured);
