import tw, { styled } from 'twin.macro';
import { IconPersonRounded } from '../../atoms';
import { Button } from '../../molecules';

export const Container = styled.div`
    ${tw`
        grid
        grid-cols-[auto]
        grid-rows-[191px 93px 389px 114px 55px]
        
        lg:grid-rows-[100px 104px 131px 96px]
        lg:grid-cols-[6fr 1fr 1fr 6fr]
        `}
`;

export const TitleContainer = styled.div`
    ${tw`
        row-start-1
        flex
        flex-col
        items-center
        pt-[41px]

        lg:items-start
        lg:pl-[40px]
    `}
`;

export const PersonIcon = styled(IconPersonRounded)`
    ${tw`
        fill-primary
        mb-[20px]

        lg:hidden
    `}
`;

export const TitleLabel = styled.div`
    ${tw`
        font-[900]
        text-[25px]
    `}
`;

export const FormContainer = styled.div`
    ${tw`
        row-start-3
        col-start-1
        flex
        flex-col
        
        lg:col-start-1
        lg:row-span-3
        lg:pl-[40px]
    `}
`;
