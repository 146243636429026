import React, { FC } from 'react';
import { Container, TitleDiv, TextBoxContainer, TextBoxItem } from './styles';

interface IHallFooterLinksSkeleton {}

const HallFooterLinksSkeleton: FC<IHallFooterLinksSkeleton> = ({}) => {
    const getTextBoxItemList = (TextBoxCount: number) => {
        const TextBoxList: JSX.Element[] = [];
        for (let i = 1; i < TextBoxCount; i++) {
            TextBoxList.push(<TextBoxItem key={i} style={{ width: ((TextBoxCount / i + 1) % 7) * 50 }} />);
        }
        return TextBoxList;
    };

    return (
        <Container>
            <TitleDiv />
            <TextBoxContainer>{getTextBoxItemList(15)}</TextBoxContainer>
            <TextBoxContainer>{getTextBoxItemList(25)}</TextBoxContainer>
            <TextBoxContainer>{getTextBoxItemList(25)}</TextBoxContainer>
        </Container>
    );
};

export default HallFooterLinksSkeleton;
