import tw, { styled } from 'twin.macro';

export const Container = styled.article`
    ${tw`
      w-full
      flex
      flex-col
      items-center
      justify-center
  `}
`;
