import { FormikProvider, useFormik } from 'formik';
import { Checkbox as CustomCheckbox } from '../../../../../atoms/Checkbox';
import MenuCollapse from '../Menu-collapse';
import { Container, Item, List } from './styles';
import { useProductSearchFilters } from '../../../hooks/useProductSearchFilters';
import { useMemo } from 'react';
import { CategoryService } from '../../../../../../services';
import { useCategories } from '../../../../../../hooks';

interface CategoryProps {
    isMobile?: boolean;
}
function Category({ isMobile = false }: CategoryProps) {
    const { data: categories } = useCategories({ refetchOnMount: false });
    const { filters, setFiltersDesktop, setFiltersMobile, aggregations } = useProductSearchFilters();

    function handleChange(id: number) {
        const { subcategoria } = filters;
        const index = subcategoria.indexOf(id);
        let newFilters = [];

        if (index === -1) {
            newFilters = [...subcategoria, id];
        } else {
            newFilters = subcategoria.filter((item) => item !== id);
        }

        if (isMobile) {
            setFiltersMobile({ subcategoria: newFilters });
            return;
        }

        setFiltersDesktop({ subcategoria: newFilters });
    }

    const items = useMemo(() => {
        if (!categories) return [];
        // const found = CategoryService.getCategoryFromCategories(category.key, categories);
        return (
            aggregations?.category.buckets
                .filter((category) => category.key != 2)
                .map((category: any) => {
                    const found = CategoryService.getCategoryFromCategories(category.key, categories);

                    if (found?.isActive === false) return null;

                    return {
                        key: category.key,
                        name: found?.name || category.key
                    };
                })
                .filter((v) => v != null) || []
        );
    }, [categories, aggregations?.category.buckets]);

    return (
        <Container>
            <MenuCollapse title="Categorias" qty={filters.subcategoria.length || 0}>
                <List>
                    {items.map((category: any) => (
                        <Item key={category.key} onClick={() => handleChange(category.key)}>
                            <CustomCheckbox
                                name={category.key}
                                isChecked={filters.subcategoria.includes(category.key)}
                                withoutFormik
                                onCheckboxChange={() => handleChange(category.key)}
                            />
                            <div>{category.name}</div>
                        </Item>
                    ))}
                </List>
            </MenuCollapse>
        </Container>
    );
}

export default Category;
