import tw, { styled } from 'twin.macro';
import { TextLatoExtraBold20, TextLatoRegular15 } from '../../atoms';

export const Container = styled.div`
    ${tw`
        justify-center
        items-center
        flex-col
        flex
        m-[20px]
    `}
`;
export const Border = styled.div`
    ${tw`
        justify-center
        items-center
        flex-col
        flex
        border
        border-gray
        rounded-10
        p-[20px]
        m-[-20px]
        shadow-md
        cursor-pointer

    `}
`;

export const TextTitle = styled(TextLatoExtraBold20)`
    ${tw`
        text-center
    `}
`;

export const TextDescription = styled(TextLatoRegular15)`
    ${tw`
        mt-[20px]
        text-center
    `}
`;
