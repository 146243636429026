import { useBlockConfig } from '../../../hooks/use-block-config';
import { IProduct } from '../../../services/product/types';
import { BadgeColor } from '../../atoms/Badge/types';
import { BadgeSize } from '../../atoms/Badge/types';
import Badge from '../../atoms/Badge';

interface IProductTags {
    data: IProduct;
    direction?: 'row' | 'column';
    absolute?: boolean;
    showAllOnMobile?: boolean;
    justFirst?: boolean;
}

export default function ProductTags({
    data,
    direction = 'column',
    absolute = true,
    showAllOnMobile = true,
    justFirst = false
}: IProductTags) {
    const { data: blockConfig } = useBlockConfig();

    const tags = blockConfig?.tags.filter((tag) =>
        data.extension_attributes?.category_links?.find((v) => v.category_id.toString() === tag.category)
    );

    if (!tags?.length) {
        return null;
    }

    const getStyle = (index: number) => {
        if (justFirst) {
            return index >= 1 ? 'hidden' : 'block';
        }

        if (showAllOnMobile) {
            return 'block';
        }
        return index >= 1 ? 'hidden md:block' : 'block';
    };

    return (
        <div
            className={`${absolute ? 'absolute left-4 top-4' : 'relative'} gap-2 ${direction === 'column' ? 'flex-col' : 'flex-row'} flex flex-wrap z-[1]`}
        >
            {tags.map((tag, index) => (
                <div key={tag.name} className={getStyle(index)}>
                    <Badge size={BadgeSize.MEDIUM} color={BadgeColor.CUSTOM} customColor={tag.color} key={tag.name}>
                        <div
                            className="font-normal mt-[2px] mb-[-2px]"
                            style={{
                                color: tag.colorText,
                                fontFamily: tag.font || 'nunito',
                                fontWeight: tag.weight || 400,
                                fontSize: tag.fontSize || '14px'
                            }}
                        >
                            {tag.name}
                        </div>
                    </Badge>
                </div>
            ))}
        </div>
    );
}
