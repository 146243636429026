import { TypeProps } from './Types-inputs/types';

export enum EInputType {
    DEFAULT = 'default',
    PASSWORD = 'password',
    SELECT = 'select',
    CHECKBOX = 'checkbox'
}

export interface IInputProps<T> extends TypeProps<T> {
    type?: EInputType;
    label?: string;
    inputMode?: string;
    maxLength?: number;
    className?: string;
}
export interface ContainerProps {
    emptyLabel: boolean;
}
