import { HTMLMotionProps } from 'framer-motion';
import { FC } from 'react';
import { motionConfig } from '../../../utils/motion';
import { Container } from './styles';

interface IBackdropProps extends HTMLMotionProps<'div'> {}
const variants = {
    closing: {
        opacity: 0
    },
    hide: {
        display: 'none',
        transition: {
            duration: 0.01,
            delay: motionConfig.duration
        }
    },
    show: {
        opacity: 1,
        display: 'flex'
    }
};

const Backdrop: FC<IBackdropProps> = ({ children, ...props }) => {
    return (
        <Container animate="show" variants={variants} exit={['closing', 'hide']} {...props}>
            {children}
        </Container>
    );
};

export default Backdrop;
