import tw, { styled } from 'twin.macro';
import { TextLatoRegular15 } from '../../atoms';
import IconCart from '../../atoms/icons/Cart';

export const Container = styled.div`
    ${tw`
        h-[37px]
        bg-primary
        rounded-10
        flex
        flex-row
        justify-center
        items-center
        px-[6px]
    `}
`;

export const Text = styled(TextLatoRegular15)`
    ${tw`
        text-white
        ml-[3.5px]
    `}
`;

export const Icon = styled(IconCart)`
    ${tw`
        fill-white
        h-[18.5px]
        w-[18.5px]
    `}
`;
