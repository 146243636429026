import { Children, FC, useRef } from 'react';
import { Arrow, Container } from './styles';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { CarouselArrow, CarouselArrowRound } from '../../atoms';
import { ICarouselProps } from './types';

import 'swiper/css';

import { Autoplay, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

const Carousel: FC<ICarouselProps> = ({
    children,
    marginItem,
    responsive,
    slidesToShow = 1,
    arrows = true,
    autoPlay = false
}) => {
    const refRoot = useRef<HTMLDivElement>(null);
    const refArrowLeft = useRef<any>(null);
    const refArrowRight = useRef<any>(null);

    return (
        <Container ref={refRoot}>
            {children && (
                <Swiper
                    className=""
                    spaceBetween={marginItem}
                    grabCursor={true}
                    slidesPerView={responsive ? slidesToShow : 'auto'}
                    breakpoints={responsive}
                    modules={[Pagination, Navigation, Autoplay]}
                    autoplay={
                        autoPlay
                            ? {
                                  delay: 2000,
                                  disableOnInteraction: true,
                                  pauseOnMouseEnter: true,
                                  stopOnLastSlide: false
                              }
                            : false
                    }
                    speed={500}
                    navigation={{
                        prevEl: refArrowLeft.current || undefined,
                        nextEl: refArrowRight.current || undefined,
                        disabledClass: 'btn-disabled'
                    }}
                    onInit={(swiper: any) => {
                        swiper.params.navigation.prevEl = refArrowLeft.current;

                        swiper.params.navigation.nextEl = refArrowRight.current;
                        swiper.navigation.init();
                        swiper.navigation.update();
                    }}
                >
                    {Children.toArray(children)?.map((item, i) => <SwiperSlide key={i.toString()}>{item}</SwiperSlide>)}
                    {arrows && (
                        <span slot="container-end" className="container-end">
                            <Arrow>
                                <CarouselArrowRound round left ref={refArrowLeft}></CarouselArrowRound>
                            </Arrow>
                            <Arrow className="right-0">
                                <CarouselArrowRound round ref={refArrowRight}></CarouselArrowRound>
                            </Arrow>
                        </span>
                    )}
                </Swiper>
            )}
        </Container>
    );
};

export default Carousel;
