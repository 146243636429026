import { RefObject, useEffect } from 'react';

export const useHeight = (ref: RefObject<any>, fn: (value: number) => void) => {
    useEffect(() => {
        const resize_ob = new ResizeObserver(function (entries) {
            let rect = entries[0].contentRect;
            fn(rect.height);
        });
        if (ref.current) {
            fn(ref.current.clientHeight);
            resize_ob.observe(ref.current);
        }

        return () => {
            resize_ob.disconnect();
        };
    }, [ref.current]);
};
