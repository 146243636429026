import tw, { styled } from 'twin.macro';
import { TextNunitoBold18 } from '../../../atoms';

export const Container = styled.div`
    ${tw`
       bg-[#424242] 
       py-8
       md:pb-24
       `}
`;

export const Content = styled.div`
    ${tw`
        flex
        flex-col
        gap-4
        md:gap-8
    `}
`;

export const Title = styled.div`
    ${tw`
        font-bold
        text-white
        text-14
        h-6
        md:h-auto
        md:text-18
    `}
`;

export const List = styled.ul`
    ${tw`
            grid
            grid-cols-3
            md:grid-cols-none
            md:grid-flow-col
            auto-rows-max
            gap-2
    `}
    grid-template-rows: repeat(5, minmax(0, 1fr));
`;

export const Menu = styled.li`
    ${tw`
        text-white
        cursor-pointer
        text-12
        font-nunito
        font-normal
        leading-[16.37px]
    `}
`;
