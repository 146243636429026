import { FC, SVGProps } from 'react';

const IconCartOutline: FC<SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={37} height={37} fill="none" {...props}>
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M11.422 21.963 9.056 10.792H28.52c1.003 0 1.739.942 1.495 1.916l-2.078 8.313a3.083 3.083 0 0 1-2.684 2.32l-10.508 1.051a3.084 3.084 0 0 1-3.324-2.43Z"
                clipRule="evenodd"
            />
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M9.056 10.792 8.054 6.167H5.396M26.376 29.703a.564.564 0 1 0 .007 1.128.564.564 0 0 0-.007-1.128M13.408 29.703a.56.56 0 0 0-.561.564.565.565 0 1 0 .561-.564"
            />
        </svg>
    );
};

export default IconCartOutline;
