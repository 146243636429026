import { Dialog, Transition } from '@headlessui/react';
import { motion } from 'framer-motion';
import { forwardRef, Fragment, Ref, useEffect, useImperativeHandle, useState } from 'react';
import { useContextSelector } from 'use-context-selector';
import { ModalsContext } from '../../../providers/modals';
import { IModalHandle, IModalProps } from './types';
const Panel = motion(Dialog.Panel);

let modalsOpen = 0;

const Modal = forwardRef(
    (
        { children, className, onClose, closeOnOutSide = true, onOpen, name, ...rest }: IModalProps,
        ref: Ref<IModalHandle>
    ) => {
        const [isOpen, setIsOpen] = useState<boolean>(false);
        const opens = useContextSelector(ModalsContext, (value) => value.opens);

        useImperativeHandle(ref, () => ({
            show: () => {
                if (onOpen) onOpen();
                setIsOpen(true);
            },
            close: () => setIsOpen(false),
            isOpen
        }));
        const handleClose = () => {
            if (closeOnOutSide) {
                setIsOpen(false);
                if (onClose) onClose();
            }
        };

        useEffect(() => {
            if (isOpen) {
                const html = document.getElementsByTagName('body')[0];
                if (html) {
                    html.style.overflow = 'hidden';
                    if (window.innerWidth > 1024) {
                        html.style.paddingRight = '8px';
                    }
                }
            } else {
                const html = document.getElementsByTagName('body')[0];
                if (!html) return;
                html.style.overflow = '';
                html.style.paddingRight = '';
            }
        }, [isOpen]);

        return (
            <Transition show={isOpen} as={Fragment}>
                <Dialog onClose={handleClose}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div
                            style={{ zIndex: opens + 1 * 3, backgroundColor: 'rgba(0,0,0, 0.3' }}
                            className="fixed inset-0"
                        />
                    </Transition.Child>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div
                            style={{ zIndex: opens + 1 * 3 }}
                            className="fixed inset-0 flex items-center justify-center p-4 h-screen"
                        >
                            <Panel className={className} {...rest}>
                                {children}
                            </Panel>
                        </div>
                    </Transition.Child>
                </Dialog>
            </Transition>
        );
    }
);

export default Modal;
