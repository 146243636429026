import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { BlockService } from '../../services';
import { IBlock } from '../../services/block/types';
import { IS_BROWSER } from '../../services/query-client';
import { BLOCK } from '../../utils/react-query-keys';

export const useBlock = (id: string, params?: UseQueryOptions<Partial<IBlock | null>>) => {
    return useQuery<Partial<IBlock | null>>([BLOCK, id], () => BlockService.getById(id), {
        refetchOnMount: true,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        enabled: IS_BROWSER,
        ...params
    });
};
