import tw, { styled } from 'twin.macro';

export const Container = styled.div`
    ${tw`
        border-[0.5px]
        border-[#E6E6E6]
        p-4
        flex
        flex-col
        rounded-8
        flex-1
        // md:min-h-[472px]
        md:min-w-[300px]
    `}
`;

export const Category = styled.div`
    ${tw`
        text-14
        h-4
        flex
        justify-center
        text-[#9F9F9F]
        font-medium
        md:text-[#636363]
    `}
`;

export const Sku = styled.div`
    ${tw`
        text-12
        text-[#636363]
        // md:h-4
        // md:flex
        // flex-col
        // items-end
    `}
`;

export const Header = styled.div`
    ${tw`
        flex
        flex-row
        justify-between
        items-start
        gap-4
    `}
`;

export const SoldBy = styled.div`
    ${tw`
        text-12
        text-[#636363]
        leading-[12px]
        hidden
        md:block
    `}

    strong {
        ${tw`
            text-12
            text-primary
        `}
    }
`;

export const TextTitle = styled.h1`
    ${tw`
        text-black
        font-semibold
        text-18
        pt-2
        pb-4
        md:pt-6
    `}
`;

export const PriceContainer = styled.div`
    ${tw`
        hidden
        border-t-[0.5px]
        border-b-[0.5px]
        border-[#F0F0F0]
        pt-2
        pb-3
        mb-6
        flex-col
        md:flex

    `}
`;
export const PriceText = styled.div`
    ${tw`
        text-32
        text-black
        font-bold
        min-h-[48px]
        flex
        flex-col
        justify-center
        items-start
    `}
`;
export const InstallmentsText = styled.div`
    ${tw`
        text-14
        text-black
        font-medium
        min-h-[24px]
        flex
        flex-col
        justify-center
        items-start
    `}
`;

export const PriceFrom = styled.div`
    ${tw`
        text-base
        min-h-[12px]
        font-light
        text-black
        line-through
    `}
`;

export const Attributes = styled.div`
    ${tw`
        flex
        flex-col
        gap-4
        shrink-0
        flex-1
        
    `}
`;
