import { useInfiniteQuery, UseInfiniteQueryOptions } from '@tanstack/react-query';

import { OrdersService, UserService } from '../../services';
import { PaginationList, ConditionType } from '../../services/magento/types';
import { Order } from '../../services/order/types';
import { ORDERS } from '../../utils/react-query-keys';

export interface UseOrdersResponse extends PaginationList<Order> {
    pagesTotal: number;
    page: number;
    nextPage: number | boolean;
}

export interface UseInfiniteOrderProps {
    fields: string;
    config?: UseInfiniteQueryOptions<UseOrdersResponse>;
}

export const useOrders = ({ fields, config }: UseInfiniteOrderProps) => {
    return useInfiniteQuery<UseOrdersResponse>(
        [ORDERS, fields],
        async ({ pageParam = 1 }) => {
            const pageSize = 5;

            const searchCriteria: any = {
                pageSize,
                currentPage: pageParam ? pageParam : 1,

                sortOrders: [
                    {
                        field: 'entity_id',
                        direction: 'DESC'
                    }
                ]
            };

            const response = await OrdersService.getAll({
                params: {
                    searchCriteria,
                    fields
                }
            });

            const pagesTotal = Math.ceil(response.total_count ? response.total_count / pageSize : 0);

            return {
                ...response,
                items: response.items ? response.items : [],
                pagesTotal,
                page: pageParam,
                nextPage: !response.items || pagesTotal <= pageParam ? false : pageParam + 1
            };
        },
        {
            getNextPageParam: (page) => page.nextPage,
            ...config,
            refetchOnMount: false
        }
    );
};
