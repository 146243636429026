import { FC } from 'react';
import { LoadingFull } from '../../molecules';
import { Container } from './styles';

const ModalEstablishmentStepLoading: FC = () => {
    return (
        <Container>
            <LoadingFull />
        </Container>
    );
};

export default ModalEstablishmentStepLoading;
