export const NEXT_PUBLIC_URL_API =
    process.env.NODE_ENV == 'production'
        ? process.env.NEXT_PUBLIC_URL_API
        : `http://${process.env.NEXT_PUBLIC_HOST || '127.0.0.1'}:8080/${process.env.NEXT_PUBLIC_URL_API}`;
export const NEXT_PUBLIC_URL_API_ELASTIC =
    process.env.NODE_ENV == 'production'
        ? process.env.NEXT_PUBLIC_URL_API_ELASTIC
        : `http://${process.env.NEXT_PUBLIC_HOST || '127.0.0.1'}:8080/${process.env.NEXT_PUBLIC_URL_API_ELASTIC}`;
export const NODE_ENV = process.env.NODE_ENV;
export const NEXT_PUBLIC_API_KEY_ADMIN = process.env.NEXT_PUBLIC_API_KEY_ADMIN!;
export const NEXT_PUBLIC_ELASTIC_AUTH = process.env.NEXT_PUBLIC_ELASTIC_AUTH!;
export const NEXT_PUBLIC_TITLE = process.env.NEXT_PUBLIC_TITLE || '';
export const NEXT_PUBLIC_NAME = process.env.NEXT_PUBLIC_NAME || '';
export const NEXT_PUBLIC_CLIENT = process.env.NEXT_PUBLIC_CLIENT || '';
export const NEXT_PUBLIC_URL_IMAGES = process.env.NEXT_PUBLIC_URL_IMAGES;
export const NEXT_PUBLIC_STORE_DEFAULT_ELASTIC = process.env.NEXT_PUBLIC_STORE_DEFAULT_ELASTIC!;
export const NEXT_PUBLIC_STORE_DEFAULT_CODE_ID = process.env.NEXT_PUBLIC_STORE_DEFAULT_CODE_ID!;
export const NEXT_PUBLIC_API_KEY_ADMIN_PUBLIC = process.env.NEXT_PUBLIC_API_KEY_ADMIN_PUBLIC;
export const NEXT_PUBLIC_HOME_BANNER_1 = process.env.NEXT_PUBLIC_HOME_BANNER_1!;
export const NEXT_PUBLIC_HOME_BANNER_SELLERS = process.env.NEXT_PUBLIC_HOME_BANNER_SELLERS!;
export const NEXT_PUBLIC_HOME_BANNER_PARTNERS = process.env.NEXT_PUBLIC_HOME_BANNER_PARTNERS!;
export const NEXT_PUBLIC_HOME_BANNER_MOBILE = process.env.NEXT_PUBLIC_HOME_BANNER_MOBILE!;
export const NEXT_PUBLIC_CONFIG_BLOCK = process.env.NEXT_PUBLIC_CONFIG_BLOCK!;
export const NEXT_PUBLIC_HOME_BANNER_PRODUCTS = process.env.NEXT_PUBLIC_HOME_BANNER_PRODUCTS!;
export const NEXT_PUBLIC_HOME_BANNER_COUPON = process.env.NEXT_PUBLIC_HOME_BANNER_COUPON!;
export const NEXT_PUBLIC_URL_API_NEXT = process.env.NEXT_PUBLIC_URL_API_NEXT!;
export const NEXT_PUBLIC_GOOGLE_GTM = process.env.NEXT_PUBLIC_GOOGLE_GTM!;
export const NEXT_PUBLIC_GOOGLE_SITE_VERIFICATION = process.env.NEXT_PUBLIC_GOOGLE_SITE_VERIFICATION!;
export const NEXT_PUBLIC_FOOTER_DELIVERY_BANNER = process.env.NEXT_PUBLIC_FOOTER_DELIVERY_BANNER!;
export const NEXT_PUBLIC_HALL_HOME = process.env.NEXT_PUBLIC_HALL_HOME!;
export const NEXT_PUBLIC_ROBOTS = process.env.NEXT_PUBLIC_ROBOTS!;
export const NEXT_PUBLIC_MY_ACCOUNT_BANNER = process.env.NEXT_PUBLIC_MY_ACCOUNT_BANNER!;
export const NEXT_PUBLIC_HOME_BANNER_4_IMAGES = process.env.NEXT_PUBLIC_HOME_BANNER_4_IMAGES!;
export const NEXT_PUBLIC_AISLE_PDP = process.env.NEXT_PUBLIC_AISLE_PDP!;

export const NEXT_PUBLIC_PRODUCT_DETAIL_HALL_RELATED =
    process.env.NEXT_PUBLIC_PRODUCT_DETAIL_HALL_RELATED || 'Produtos similares';
export const NEXT_PUBLIC_PRODUCT_DETAIL_HALL_UP_SELL =
    process.env.NEXT_PUBLIC_PRODUCT_DETAIL_HALL_UP_SELL || 'Turbine sua compra';
export const NEXT_PUBLIC_PRODUCT_DETAIL_HALL_CROSS_SELL =
    process.env.NEXT_PUBLIC_PRODUCT_DETAIL_HALL_CROSS_SELL || 'Compre com';

export const NEXT_PUBLIC_MULTIPLE_STORE = Boolean(parseInt(process.env.NEXT_PUBLIC_MULTIPLE_STORE || '1'));
export const NEXT_PUBLIC_PAGE_TERMOS_DE_USO_SITE = process.env.NEXT_PUBLIC_PAGE_TERMOS_DE_USO_SITE || '54';
export const NEXT_PUBLIC_PAGE_POLITICA_DE_ENTREGA_SITE = process.env.NEXT_PUBLIC_PAGE_POLITICA_DE_ENTREGA_SITE!;
export const NEXT_PUBLIC_PAGE_POLITICA_DE_PAGAMENTO_SITE = process.env.NEXT_PUBLIC_PAGE_POLITICA_DE_PAGAMENTO_SITE!;
export const NEXT_PUBLIC_PAGE_FAQ_SITE = process.env.NEXT_PUBLIC_PAGE_FAQ_SITE!;

export const NEXT_PUBLIC_SHOW_BAG_PLASTIC = Boolean(parseInt(process.env.NEXT_PUBLIC_SHOW_BAG_PLASTIC || '1'));
export const NEXT_PUBLIC_LOGO_IMAGE = Boolean(parseInt(process.env.NEXT_PUBLIC_LOGO_IMAGE || '1'));
export const NEXT_PUBLIC_LOGO_PORTRAIT = Boolean(parseInt(process.env.NEXT_PUBLIC_LOGO_PORTRAIT || '1'));
export const NEXT_PUBLIC_HOME_LOGIN_SHOWBACKGROUND = Boolean(
    parseInt(process.env.NEXT_PUBLIC_HOME_LOGIN_SHOWBACKGROUND || '0')
);
export const NEXT_PUBLIC_CHECKOUT_PLASTIC_BAG_SKU = process.env.NEXT_PUBLIC_CHECKOUT_PLASTIC_BAG_SKU || 'sacola';
export const NEXT_PUBLIC_API_KEY_GOOGLE_MAPS = process.env.NEXT_PUBLIC_API_KEY_GOOGLE_MAPS || '';
export const NEXT_PUBLIC_WEB_SITE_ID = process.env.NEXT_PUBLIC_WEB_SITE_ID;
export const NEXT_PUBLIC_CHAT_ID = process.env.NEXT_PUBLIC_CHAT_ID || '';

// msg erros
export const NEXT_PUBLIC_MSG_CART_ADD = 'Você ${type} ${product.name} para o seu carrinho de compras.';
export const NEXT_PUBLIC_MSG_CART_REMOVE = 'Você ${type} ${product.name} para o seu carrinho de compras.';
export const NEXT_PUBLIC_MSG_FIELD_REQUIRED = 'Campo obrigatório';
export const NEXT_PUBLIC_MSG_FIELD_MIN = 'Mínimo ${min} caracteres';
export const NEXT_PUBLIC_MSG_FIELD_MONEY_MIN = 'Mínimo ${min}';
export const NEXT_PUBLIC_MSG_FIELD_MONEY_MAX = 'Máximo ${max}';
export const NEXT_PUBLIC_MSG_FIELD_CPF = 'CPF inválido';
export const NEXT_PUBLIC_MSG_FIELD_CELL = 'Celular inválido';
export const NEXT_PUBLIC_MSG_FIELD_DATE = 'Data inválido';
export const NEXT_PUBLIC_MSG_FIELD_EMAIL = 'E-mail inválido';
export const NEXT_PUBLIC_MSG_FIELD_NUM = '1 ou mais caracteres numéricos';
export const NEXT_PUBLIC_MSG_FIELD_CARACTER_UPP = '1 ou mais caracteres maiúsculos';
export const NEXT_PUBLIC_MSG_FIELD_CARACTER_LOW = '1 ou mais caracteres minúsculos';
export const NEXT_PUBLIC_MSG_FIELD_PASSWORD_MISMATCH = 'Os campos senha e confirmação de senha não coincidem';
export const NEXT_PUBLIC_ID_HIDDEN_CATEGORY = process.env.NEXT_PUBLIC_ID_HIDDEN_CATEGORY;
export const NEXT_PUBLIC_BRANDS = process.env.NEXT_PUBLIC_BRANDS || '407';
export const NEXT_PUBLIC_FLAVORS = process.env.NEXT_PUBLIC_FLAVORS || '322 ';

export const NEXT_PUBLIC_MERCADOPAGO_PUBLICK_KEY = process.env.NEXT_PUBLIC_MERCADOPAGO_PUBLICK_KEY as string;
