import tw, { styled } from 'twin.macro';
import { Button, ModalHeader } from '../../molecules';
import { Modal, TextLatoBlack18 } from '../../atoms';

export const Container = styled(Modal)`
    ${tw`
        flex flex-col md:w-[27rem] h-[670px]
    `}
`;

export const CloseTitle = styled.div`
    ${tw`
        text-[#FF5F00]
        text-center
        mb-2
        cursor-pointer
    `}
`;

export const Header = styled.div`
    ${tw`
        text-center
        mb-6
    `}
`;

export const Title = styled.div`
    ${tw`
        h-8
        flex
        flex-col
        justify-center
        text-[22px]
        font-bold
        text-[#424242]
    `}
`;
export const Card = styled.div`
    ${tw`
    flex
    flex-col
    mt-[22px]
    items-center
    `}
`;

export const Content = styled.div`
    ${tw`
        flex-shrink-0
        flex-col
        flex-1
        flex
        overflow-y-auto
        overflow-x-hidden
    `}
`;
export const Footer = styled.div`
    ${tw`
        p-[20px]
        flex
        flex-row
    `}
`;
// export const ButtonAdd = styled(Button)`
//     ${tw`
//         flex-1
//     `}
// `;
export const Form = styled.div`
    ${tw`
        pt-[20px]
        px-[20px]
        flex-1
        flex
        flex-col
    `}
`;

export const TextTitle = styled(TextLatoBlack18)`
    ${tw`
        pb-[10px]
    `}
`;
export const InputCvv = styled.div`
    ${tw`
        flex
        flex-row
        items-center
        gap-[24px]
    `}
`;
