import { useField } from 'formik';
import { AnimatePresence, MotionConfig, useAnimationControls } from 'framer-motion';
import React, { FC, forwardRef, memo, ReactElement, Ref, useCallback, useEffect, useRef, useState } from 'react';
import { useCombinedRefs, useOutsideClick } from '../../../hooks';
import { TypeProps } from '../Input/Types-inputs/types';
import {
    Container,
    ContainerIcon,
    Icon,
    Input,
    Item,
    List,
    TextItem,
    TextItemSelected,
    variantsContainerIcon,
    variantsIcon,
    variantsList
} from './styles';
import { ISelectCollapseProps } from './types';

function SelectCollapse<T, V>(
    {
        values,
        name,
        label,
        renderLabel,
        keyExtractor,
        selectedExtractor,
        renderLabelSelected,
        onChange,
        setValue: setValueProps
    }: ISelectCollapseProps<T, V>,
    ref: Ref<HTMLDivElement>
) {
    const [isOpen, setIsOpen] = useState(false);
    const [field, meta, helpers] = useField<V>(name);

    const modalizeRef = useRef(null);
    const combinedRef = useCombinedRefs<HTMLDivElement>(ref, modalizeRef);

    const handleClose = () => {
        setIsOpen(false);
    };

    useOutsideClick([combinedRef], handleClose);

    const { setValue } = helpers;
    const { error, value, initialValue } = meta;
    const animate = isOpen ? 'open' : 'close';

    const handleToggle = () => {
        if (isOpen) {
            return handleClose();
        }
        setIsOpen(true);
    };

    const handleClick = (item: T) => {
        setValue(setValueProps(item));
        handleClose();
        if (onChange) onChange(item);
    };

    return (
        <MotionConfig transition={{ duration: 0.15 }}>
            <Container ref={combinedRef}>
                <Input $isError={Boolean(error)} onClick={handleToggle} whileTap={{ scale: 0.95 }}>
                    {!value ? label : renderLabelSelected(value)}
                    <ContainerIcon variants={variantsContainerIcon} initial="close" animate={animate}>
                        <Icon variants={variantsIcon} animate={animate} />
                    </ContainerIcon>
                </Input>
                <AnimatePresence>
                    {isOpen && (
                        <List initial="close" animate={animate} variants={variantsList}>
                            {values != null &&
                                values?.map((item) => {
                                    const key = keyExtractor(item);
                                    const selected = value && selectedExtractor(item, value);
                                    const labelStr = renderLabel(item);
                                    return (
                                        <Item key={key} onClick={() => handleClick(item)}>
                                            {selected ? (
                                                <TextItemSelected>{labelStr}</TextItemSelected>
                                            ) : (
                                                <TextItem>{labelStr}</TextItem>
                                            )}
                                        </Item>
                                    );
                                })}
                        </List>
                    )}
                </AnimatePresence>
            </Container>
        </MotionConfig>
    );
}

export default memo(forwardRef(SelectCollapse)) as typeof SelectCollapse;
