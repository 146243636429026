import { useMutation } from '@tanstack/react-query';
import creditCardType from 'credit-card-type';
import { FormikProvider, useFormik } from 'formik';
import Image from 'next/image';
import { FC, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useCart } from '../../../hooks';
import { CartService, RouterService } from '../../../services';
import { PaymentMethodOrder } from '../../../services/cart';
import { ICCardValues } from '../../../services/cart/types';
import { CPF, REQUIRED } from '../../../utils/errors';
import { cpfValid, REGEX_CREDIT_VALID } from '../../../utils/string';
import { Button, Input, ModalHeader } from '../../molecules';
import { EInputType } from '../../molecules/Input/types';
import { CloseTitle, Container, Content, Footer, Form, Header, InputCvv, Title } from './styles';
import { IModalTtxpayCreditCardProps, IPaymentOption } from './types';
import axios, { AxiosRequestConfig, Canceler } from 'axios';

const required = (value: any, options: any) => {
    const useSave = Boolean(options.from[0].value.useSave);
    if (useSave) {
        return true;
    } else if (!useSave && !value) {
        return false;
    }

    return true;
};
const validationSchema = Yup.object().shape({
    number: Yup.string().test('required', REQUIRED, required),
    valid: Yup.string().test('required', REQUIRED, required).matches(REGEX_CREDIT_VALID, 'Inválido'),
    cvv: Yup.string().test('required', REQUIRED, required),
    name: Yup.string().test('required', REQUIRED, required),
    cpf: Yup.string().test('cpf', CPF, cpfValid).required(REQUIRED),
    installments: Yup.object().required(REQUIRED)
});

const ModalTtxpayCreditCard: FC<IModalTtxpayCreditCardProps> = ({ onClose, selectedMethodsData, blockPurchase }) => {
    const [installmentsData, setInstallmentData] = useState<IPaymentOption[]>([]);
    const { data } = useCart();
    const installmentsCancelToken = useRef<Canceler>();

    const { isLoading, mutate } = useMutation<unknown, unknown, ICCardValues>(
        (form) =>
            CartService.creditCardCheckout({
                code: selectedMethodsData.code,
                paymentValues: { ...form, number: form.number.replace(/\s+/g, ''), cpf: form.cpf.replace(/[.\-]/g, '') }
            }),
        {
            onSuccess: () => {
                PaymentMethodOrder.setPaymentMethodId(null, selectedMethodsData.code);

                RouterService.toRoute({ options: { url: '/checkout/sucesso' } });
            },
            onError: (error: any) => {
                if (error.response.data) {
                    const msg = error.response.data.message.split(' : ')[1];
                    toast.error(msg);
                }
                onClose?.();
            }
        }
    );

    const formik = useFormik<ICCardValues>({
        initialValues: {
            number: '',
            valid: '',
            cvv: '',
            name: '',
            brand: '',
            cpf: '',
            installments: null
        },
        validationSchema,
        validateOnMount: false,
        onSubmit: (values) => {
            mutate(values);
        }
    });

    const handleChangeCC = async (name: string, value: string) => {
        if (value) {
            const [valueType] = creditCardType(value);
            const type = valueType && valueType.type && valueType.type;

            formik.setFieldValue('type', type);

            try {
                if (installmentsCancelToken.current) installmentsCancelToken.current();

                const params: AxiosRequestConfig = {
                    cancelToken: new axios.CancelToken((c) => {
                        installmentsCancelToken.current = c;
                    })
                };
                const response = await CartService.getInstallmentsPagarme(
                    data?.info.grand_total as number,
                    type,
                    params
                );
                setInstallmentData(response);
            } catch (e) {
                if (!axios.isCancel(e)) {
                    throw e;
                }
            }
        }
        formik.setFieldValue('type', '');
    };

    return (
        <Container onClose={onClose}>
            <div className="block mt-2 md:hidden">
                <CloseTitle onClick={onClose}>Fechar</CloseTitle>
                <Header>
                    <Title>Insira dados do Cartão</Title>
                </Header>
            </div>
            <div className="hidden md:block">
                <ModalHeader title="" showIconClose onPressIconClose={onClose} style={{ margin: '5 0 5 0' }} />
                <div className="flex flex-row items-center px-4 gap-[10px]">
                    <Image alt="Logo pay" src={'/images/brands-cards/credit-card.svg'} height={31} width={38} />
                    <span>Cartão de crédito</span>
                </div>
            </div>
            <Content>
                {/*<Card>
                    <CreditCardFlip
                        ref={refCard}
                        name={formik.values.name}
                        number={formik.values.number}
                        cvv={formik.values.cvv}
                        valid={formik.values.valid}
                    />
                </Card>*/}
                <Form className="gap-2">
                    <FormikProvider value={formik}>
                        <Input
                            mask="creditCard"
                            name="number"
                            pattern="\d*"
                            autoComplete="cc-number"
                            label="Número do cartão*"
                            inputMode="numeric"
                            onChange={handleChangeCC}
                        />
                        <InputCvv>
                            <Input
                                mask="dateMMYY"
                                pattern="\d*"
                                name="valid"
                                autoComplete="cc-exp"
                                label="Validade*"
                                inputMode="numeric"
                            />
                            <Input
                                mask="cvv"
                                name="cvv"
                                pattern="\d*"
                                autoComplete="cc-csc"
                                inputMode="numeric"
                                label="CVV*"
                                //onFocus={() => refCard.current?.flip()}
                                //onBlur={() => refCard.current?.flip()}
                            />
                        </InputCvv>
                        <Input
                            name="installments"
                            label="Parcelas*"
                            type={EInputType.SELECT}
                            labelExtractor={(value: any) => value.label || ''}
                            keyExtractor={(value: any) => value.value || ''}
                            values={installmentsData}
                        />
                        <Input name="name" label="Nome do Titular do Cartão*" />
                        <Input name="cpf" label="CPF do Titular do Cartão*" />
                    </FormikProvider>
                </Form>
            </Content>
            <Footer>
                <Button
                    isLoading={isLoading}
                    text="Finalizar pedido"
                    disabled={!formik.isValid || blockPurchase}
                    onClick={formik.submitForm}
                    style={{ display: 'flex', flex: 1 }}
                />
            </Footer>
        </Container>
    );
};

export default ModalTtxpayCreditCard;
