import Image from 'next/image';
import { CertificateList, Column, Menu, Title } from '../styles';

interface ICertificateProps {
    title?: boolean;
}

function Certificate({ title = false }: ICertificateProps) {
    return (
        <Column>
            {title && <Title>Selos e Certificados</Title>}
            <CertificateList>
                <div className="flex flex-row gap-2 items-center">
                    <div className="relative w-[120px] flex items-center h-[53px]">
                        <Image
                            src="/images/selos/compra-segura.png"
                            alt="Compra Segura"
                            className="object-contain object-left-top"
                            fill
                            quality={100}
                            sizes="120px"
                        />
                    </div>
                    <div className="relative w-[120px] h-[52px]">
                        <Image
                            src="/images/selos/google.png"
                            alt="Google"
                            className="object-contain object-left-top"
                            fill
                            quality={100}
                            sizes="120px"
                        />
                    </div>
                </div>
                <div className="flex flex-row gap-2 items-center">
                    <div className="relative w-[120px] flex h-[55px]">
                        <Image
                            src="/images/selos/reclame-aqui.png"
                            alt="Reclame Aqui"
                            className="object-contain object-left-top"
                            fill
                            quality={100}
                            sizes="auto"
                        />
                    </div>
                    <div className="relative w-[120px] h-[58px]">
                        <Image
                            src="/images/selos/clear-sale.png"
                            alt="Clear Sale"
                            className="object-contain object-left-top"
                            fill
                            quality={100}
                            sizes="120px"
                        />
                    </div>
                </div>
            </CertificateList>
        </Column>
    );
}

export default Certificate;
