import { useEffect } from 'react';
import tw, { styled } from 'twin.macro';
import { ProductItemSkeleton as ProductSkeleton } from '../../molecules';

export const Container = styled.div`
    ${tw`
    flex
    `}
`;

export const ProductItemContainer = styled.div`
    ${tw`
        mt-[10px]

        lg:max-w-[initial]
    `}
`;

export const ProductItemSkeleton = styled(ProductSkeleton)`
    ${tw`
        mt-[10px]
        gap-[10px]

        lg:m-[10px]
    `}
`;

export const ProductsListContainer = styled.div`
    ${tw`
        flex
        flex-row
        flex-wrap
        mt-[25px]
        min-w-full
        gap-[15px]
        justify-evenly

        lg:justify-start
        lg:col-start-1
    `}
`;

export const NoSearchResultFound = styled.div`
    ${tw`
        flex
        flex-col
        w-full
        justify-center
        items-center
        h-[400px]
        bg-[#EEE]
        rounded-xl
        text-center
    `}
`;

export const NoResultsTitle = styled.div`
    ${tw`
        text-[26px]
        font-[800]
        text-darkGray
        text-center
    `}
`;

export const NoResultsSubTitle = styled.div`
    ${tw`
        text-[18px]
        text-darkGray
    `}
`;