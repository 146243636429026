import { IconButton } from '../../../atoms';
import { Icon } from './styles';
import { useRouter } from 'next/router';

function Order() {
    const router = useRouter();
    return (
        <IconButton type="button">
            <Icon
                onClick={() =>
                    router.push({
                        pathname: `/minha-conta/listas`
                    })
                }
            />
        </IconButton>
    );
}

export default Order;
