import tw, { styled } from 'twin.macro';

export const StatusLineContainer = styled.div`
  ${tw`
      absolute
      w-[calc(100% - 115px)]
      h-[4px]
      top-[12.5px]
    `}
`;

export const StatusBarBg = styled.div`
    ${tw`
      w-full
      h-full
      bg-gray
      absolute
    `}
`;

export const StatusBar = styled.div`
    ${tw`
      h-full
      bg-primary
      absolute
    `}
`;