import { forwardRef, Ref, SVGProps } from 'react';

const IconArrowDownFilled = (props: SVGProps<SVGSVGElement>, ref: Ref<any>) => {
    return (
        <svg
            ref={ref}
            width="11"
            height="11"
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M7.23357 9.03952C6.46445 10.3764 4.53555 10.3764 3.76643 9.03951L0.729258 3.76043C-0.0378362 2.4271 0.924583 0.763075 2.46283 0.763075L8.53717 0.763075C10.0754 0.763076 11.0378 2.42711 10.2707 3.76044L7.23357 9.03952Z"
                fill={props.fill}
            />
        </svg>
    );
};

export default forwardRef(IconArrowDownFilled);
