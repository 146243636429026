import tw, { styled } from 'twin.macro';

export const Container = styled.div`
  ${tw`
      w-full
      animate-pulse
      flex
      flex-col
      overflow-hidden
			mb-[20px]
  `}
`;

export const Header = styled.div`
  ${tw`
		w-full
		flex
		justify-between
		mb-[15px]
  `}
`;

export const HeaderTitle = styled.div`
  ${tw`
		bg-gray
		rounded-md
		h-[20px]
		w-[25%]

  `}
`;

export const HeaderActions = styled.div`
  ${tw`
		bg-gray
		rounded-md
		h-[20px]
		w-[20%]
  `}
`;

export const LineFull = styled.div`
  ${tw`
		w-full
		bg-gray
		rounded-md
		h-[15px]
		mb-[40px]
  `}
`;

export const Line70 = styled.div`
  ${tw`
		w-[70%]
		bg-gray
		rounded-md
		h-[18px]
		mb-[20px]
  `}
`;

export const Title = styled.div`
  ${tw`
		w-[50%]
		bg-gray
		rounded-md
		h-[30px]
		mb-[15px]
  `}
`;

export const DoubleContainer = styled.div`
  ${tw`
		w-full
		grid
		grid-cols-[1fr 1fr]
		gap-[20px]
		mb-[15px]
  `}
`;

export const Middle = styled.div`
  ${tw`
		w-full
		bg-gray
		rounded-md
		h-[20px]
  `}
`;

// export const Header = styled.div`
//   ${tw`
// 		w-full
// 		bg-gray
// 		rounded-md
// 		h-[25px]
//   `}
// `;

// export const Body = styled.div`
//   ${tw`
// 		w-full
// 		flex
// 		mt-[15px]
// 		flex-col

// 	`}
// `;

// export const Row = styled.div`
//   ${tw`
// 		w-full
// 		grid
// 		grid-cols-[1fr 1fr 1fr 1fr]

// 	`}
// `;

// export const Item = styled.div`
//   ${tw`
// 		w-full
// 		bg-gray
// 		rounded-md

// 		h-[15px]
// 		col-span-2
// 	`}
// `;
