import Link from 'next/link';
import React, { FC, SVGProps } from 'react';
import { Container, Description, Title } from './styles';

interface ICardAccountProps extends React.HTMLAttributes<HTMLDivElement> {
    title: string;
    description: string | null | undefined;
    icon: React.FC<React.SVGProps<SVGSVGElement>>;
    iconProps?: SVGProps<SVGSVGElement>;
    href: string;
}

const CardAccount: FC<ICardAccountProps> = ({ title, description, icon: Icon, href, iconProps, ...props }) => {
    return (
        <Link href={`minha-conta/${href}`} className="w-full">
            <Container {...props}>
                <Icon {...iconProps} />
                <Title>{title}</Title>
                <Description>{description}</Description>
            </Container>
        </Link>
    );
};

export default CardAccount;
