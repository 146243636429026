import React, { FC } from 'react';
import { Container } from './styles';

interface ISelectItemProps {
    title: string;
    onClick: () => void;
}

const SelectItem: FC<ISelectItemProps> = ({ title, onClick }) => {
    return <Container onClick={onClick}>{title}</Container>;
};

export default SelectItem;
