import tw, { styled } from 'twin.macro';

export const SubCategoriesList = styled.div`
    &.show {
        ${tw`
        h-auto
        scale-100

        lg:flex
      `}
    }
    ${tw`
  flex
  h-0
  scale-0
  w-full
  flex-col
  flex-wrap
  bg-white
  pl-[10px]
  transition-['height 300ms']
  transition-['transform 300ms']
  
  lg:mt-[-20px]
  lg:pl-[20px]
  lg:bg-[#0000]
  lg:max-w-[calc(1280px - 328px)]
  lg:w-[calc(100vw - 328px)]
  lg:flex-row
  lg:absolute
  lg:py-[20px]
  lg:scale-100
  lg:h-auto
  lg:hidden
`}
`;
export const SubCategories = styled.div`
    ${tw`
  flex
  w-full
  flex-col
  flex-wrap
  bg-white
  overflow-hidden
  
  lg:bg-[#FFF]
  lg:w-full
  lg:flex-row
  lg:shadow-[0px_0px_10px_2px_rgba(132,132,132,0.5)]
`}
`;

export const SubCategory = styled.div`
    ${tw`
  flex
  items-center
  justify-start
  font-nunito
  text-[#888]
  py-2
  px-3
  font-normal
  text-left
  border-white
  border-b-2
  hover:border-b-primary
  duration-[300ms]

  lg:text-center
  lg:w-[25%]
  lg:pr-0
  lg:pl-3
  lg:text-[14px]

`}
`;

export const SubCategoryLabel = styled.span`
    ${tw`
  font-nunito
  text-left
`}
`;
