import tw, { styled } from 'twin.macro';
import { ArrowIcon } from '../../atoms';
import LinkNext from 'next/link';

export const NavTrace = styled.span`
    ${tw`
    flex
    flex-row
    items-center
    gap-2
    py-4

`}
`;

export const ArrowRight = styled(ArrowIcon)`
    ${tw`
    fill-[#000]
    rotate-[270deg]
    cursor-pointer
    h-1.5
`}
`;

export const Current = styled.span`
    ${tw`
    max-w-[126px]
    line-clamp-1
    font-bold
    text-12
    text-[#698122]
    break-all
`}
`;

export const Link = styled(LinkNext)`
    ${tw`
        cursor-pointer
        max-w-[126px]
        line-clamp-1
        text-12
        font-light
        break-all
    `}
`;
