import tw, { styled } from 'twin.macro';
import { TextLatoRegular15 } from '../../atoms';

export const Container = styled.div`
    ${tw`
        flex
        flex-row
        gap-[10px]
        items-center
        cursor-pointer
        py-[10px]
        px-[15px]
        transition-all
        bg-white
        hover:bg-lightGray
    `}
`;

export const TextTitle = styled(TextLatoRegular15)`
    ${tw`
        
    `}
`;
