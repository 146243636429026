import { FC, SVGProps } from 'react';

const IconSeveral: FC<SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M14.75 25.833h-3.5c-.965 0-1.75-.785-1.75-1.75v-5.02l-4.352 2.514a1.76 1.76 0 01-1.345.167 1.714 1.714 0 01-1.042-.818L1.016 17.9a1.753 1.753 0 01.63-2.394L5.988 13l-4.34-2.505a1.754 1.754 0 01-.63-2.397l1.748-3.031a1.703 1.703 0 011.037-.811 1.739 1.739 0 011.34.163L9.5 6.94V1.916c0-.965.785-1.75 1.75-1.75h3.5c.965 0 1.75.785 1.75 1.75v5.021l4.352-2.515c.415-.236.89-.295 1.345-.167.446.124.814.414 1.042.818L24.984 8.1a1.754 1.754 0 01-.63 2.394L20.012 13l4.34 2.505a1.755 1.755 0 01.632 2.397l-1.75 3.031a1.748 1.748 0 01-2.378.648L16.5 19.062v5.021c0 .965-.785 1.75-1.75 1.75zm-4.667-8.365a.584.584 0 01.583.583v6.032c0 .321.263.584.584.584h3.5c.32 0 .583-.263.583-.584v-6.031a.584.584 0 01.875-.505l5.227 3.021a.59.59 0 00.447.053.552.552 0 00.338-.265l1.755-3.04a.58.58 0 00-.209-.802l-5.214-3.009a.584.584 0 010-1.01l5.216-3.01a.581.581 0 00.208-.8L22.225 5.65a.578.578 0 00-.795-.215l-5.222 3.019a.584.584 0 01-.875-.505V1.916a.585.585 0 00-.583-.584h-3.5a.585.585 0 00-.583.584v6.031a.584.584 0 01-.875.506L4.565 5.432a.595.595 0 00-.447-.053.552.552 0 00-.338.265l-1.755 3.04a.58.58 0 00.208.802l5.214 3.009a.582.582 0 010 1.01l-5.216 3.01a.581.581 0 00-.208.8l1.752 3.035c.077.137.197.23.343.27.15.043.313.023.452-.055l5.223-3.018a.583.583 0 01.29-.079z" />
        </svg>
    );
};

export default IconSeveral;
