import { CheckoutConfirmDeliveryAddress, CheckoutConfirmPaymentSelected } from '../../molecules';
import { IValuesForm } from '../Modal-checkout/types';
import { Button, Buttons, Container, ContainerModal, Header, Separator } from './styles';
interface IModalCheckoutNotDeliverableCEP {
    data: IValuesForm;
    onPressConfirm: () => void;
    onPressClose: () => void;
}

const ModalCheckoutNotDeliverableCEP = ({ data, onPressConfirm, onPressClose }: IModalCheckoutNotDeliverableCEP) => {
    return (
        <ContainerModal>
            <Header title="Confirmar pedido" />

            <Container>
                <CheckoutConfirmDeliveryAddress shippingMethod={data.shippingMethod} address={data.address} />
                <Separator />
                <CheckoutConfirmPaymentSelected data={data.creditCard} />
            </Container>

            <Buttons>
                <Button text="Voltar" onClick={onPressClose} />
                <Button text="Confirmar" onClick={onPressConfirm} />
            </Buttons>
        </ContainerModal>
    );
};

export default ModalCheckoutNotDeliverableCEP;
