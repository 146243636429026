// ts ignora this file

import { ProductService } from '../../../../../services';
import { IProduct } from '../../../../../services/product/types';
import { IconNotebook, IconOrder } from '../../../../atoms';
import TabsComponent from '../../../../organisms/Tabs';
import TabDescription from './Tab-description';
import TabTechnicalSpecification from './Tab-technical-specification';

interface ITabsProps {
    data: IProduct;
}

function Tabs({ data }: ITabsProps) {
    const description = data ? (ProductService.getAttribute(data, 'description') as string) : '';

    const tabs = [
        description &&
            description.trim() !== '' && {
                content: () => <TabDescription data={data} />,
                title: 'Descrição',
                icon: <IconNotebook className="w-6 h-6" />
            },
        data.attributes &&
            Object.keys(data.attributes).length > 0 && {
                content: () => <TabTechnicalSpecification data={data} />,
                title: 'Ficha Técnica',
                icon: <IconOrder className="w-6 h-6" />
            }
    ].filter((v) => !!v) as any[]; // Remove os valores falsy (undefined) do array

    return (
        <div className="my-9">
            {tabs.length > 0 && <TabsComponent id="tests" classNameColumns="px-4 md:px-0" columns={tabs} />}
        </div>
    );
}

export default Tabs;
