import tw, { styled } from 'twin.macro';

export const Container = styled.div`
    ${tw`
        flex
        flex-col
        animate-pulse
    `}
`;

export const TitleDiv = styled.div`
    ${tw`
        w-[200px]
        h-[40px]
        bg-gray
        mt-[30px]
        mb-[20px]
    `}
`;

export const TextBoxContainer = styled.div`
    ${tw`
        w-[100%]
        flex
        flex-wrap
        justify-start
        mb-[20px]
    `}
`;

export const TextBoxItem = styled.div`
    ${tw`
        w-[100px]
        h-[25px]    
        bg-gray
        mr-[10px]
        mb-[10px]
    `}
`;
