import Layout from '../Layout';
import LayoutFitter from '../Layout-fitter';
import {
  ContentContainer,
} from './styles';

import MyAccountLayout from '../../organisms/My-account-layout';
import { CreditCardList } from '../../organisms';


const MyCards = () => {
  
  return (
    <Layout>
      <LayoutFitter>
        <MyAccountLayout title="Meus cartões">
          <CreditCardList />
        </MyAccountLayout>
      </LayoutFitter>
    </Layout>
  );
};

export default MyCards;
