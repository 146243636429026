import React, { FC, SVGProps } from 'react';

const FlagDiners: FC<SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg viewBox="0 0 42 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M38.827 0h-36.4C1.228 0 .256.986.256 2.202v21.525c0 1.216.973 2.202 2.173 2.202h36.399c1.2 0 2.173-.986 2.173-2.202V2.202C41 .986 40.027 0 38.827 0z"
                fill="#0079BE"
            />
            <path
                d="M32.032 13.099c0-5.473-4.508-9.256-9.447-9.254h-4.251c-4.998-.002-9.112 3.782-9.112 9.254 0 5.005 4.114 9.118 9.112 9.094h4.25c4.94.024 9.448-4.09 9.448-9.094z"
                fill="#fff"
            />
            <path
                d="M18.36 4.621c-4.567.002-8.268 3.76-8.269 8.4.001 4.638 3.702 8.396 8.27 8.398 4.568-.002 8.27-3.76 8.27-8.398 0-4.64-3.702-8.398-8.27-8.4z"
                fill="#0079BE"
            />
            <path
                d="M13.134 12.997A5.33 5.33 0 0116.5 8.028v9.936a5.328 5.328 0 01-3.365-4.967zm7.117 4.97V8.027a5.327 5.327 0 013.367 4.969 5.33 5.33 0 01-3.367 4.97z"
                fill="#fff"
            />
        </svg>
    );
};

export default FlagDiners;
