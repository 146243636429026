import tw, { styled } from 'twin.macro';
import Link from 'next/link';

import { TextNunitoBold14, TextNunitoRegular16 } from '../../atoms';
import { MenuItemContentProps } from './types';

export const Container = styled.li`
  ${tw`
    w-full
    border-b-[1px]
    border-b-gray
    pt-3
    pb-3
    pl-1
    flex
    justify-center
    cursor-pointer

    lg:justify-start
    lg:border-b-gray
    lg:pt-[20px]
    lg:pb-[9px]
  `}
`;

export const ContentRegular = styled(TextNunitoRegular16)<MenuItemContentProps>`
  ${({ $isDanger }) =>
    $isDanger
      ? tw`
          text-primary
          `
      : tw`
          text-lightGray
          lg:text-black
      	`}

  ${tw`
    uppercase
  `}
`;

export const ContentBlack = styled(TextNunitoBold14)<MenuItemContentProps>`
  ${({ $isDanger }) =>
    $isDanger
      ? tw`
          text-primary
          `
      : tw`
          text-lightGray
          lg:text-black
      	`}

  ${tw`
    uppercase
  `}
`;
