import tw, { styled } from 'twin.macro';

export const Container = styled.div`
    ${tw`
        bg-primary
        flex
        items-center
        justify-center
        w-full
        h-full
    `}
`;
