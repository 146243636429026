import tw, { styled } from 'twin.macro';
import { TextLatoRegular13 } from '../../atoms';

export const Container = styled.div`
    ${tw`
        min-h-[44px]
        flex
        flex-row
        border-b-[1px]
        border-gray
        cursor-pointer
        items-center
    `}
`;

export const TextTitle = styled(TextLatoRegular13)`
    ${tw`
        ml-[10]
    `}
`;
