import CreditCardType from 'credit-card-type';
import { FC } from 'react';
import FlagType from '../Flag-type';

interface CreditCardFlagProps {
    style?: any;
    number: any;
    color: string;
    width?: number;
    height?: number;
}

const CreditCardFlag: FC<CreditCardFlagProps> = ({ style, number, color, width = 46, height = 30 }) => {
    if (!number || number.length == 0) {
        return null;
    }
    const type = CreditCardType(number.replace(/ /, ''));
    if (type.length == 0) {
        return null;
    }

    const brand = type[0].type;

    return <FlagType type={brand} width={width} height={height} />;
};

export default CreditCardFlag;
