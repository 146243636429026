import { useCategories } from '../../../hooks';
import { useBlockConfig } from '../../../hooks/use-block-config';
import { CategoryService } from '../../../services';
import { Categories } from '../../../services/category/types';
import HallCategories from '../Hall-categories';
import { Container, List } from './styles';

function MostSearchedCategories() {
    const { data: config } = useBlockConfig({ retryOnMount: false });
    const { data: categories } = useCategories({ retryOnMount: false });

    if (!config?.categorias_destaques?.length || !categories) return null;

    const items = config.categorias_destaques
        ?.map((category) => {
            return CategoryService.getCategoryFromCategories(category, categories);
        })
        .filter((v) => v != null);

    return (
        <Container>
            <List>
                <HallCategories categories={items as Categories[]} />
            </List>
        </Container>
    );
}

export default MostSearchedCategories;
