import { Container, Title, Values } from './styles';
//@ts-ignore
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import { useProductSearchFilters } from '../../../hooks/useProductSearchFilters';
import { currencyBrl } from '../../../../../../utils/getFormattedValue';

interface PriceProps {
    isMobile?: boolean;
}

function Price({ isMobile = false }: PriceProps) {
    const { filters, setFiltersDesktop, setFiltersMobile, aggregations } = useProductSearchFilters();
    function handleChange(value: any) {
        if (isMobile) {
            setFiltersMobile({ preco: value });
            return;
        }

        setFiltersDesktop({ preco: value });
    }

    const isEmpty = !filters.preco || !filters.preco?.[0] || !filters.preco?.[1];

    return (
        <Container>
            <Title>Preço</Title>
            <Values>
                <div>R$ {currencyBrl(filters.preco?.[0] || aggregations?.original_price_min.value || 0)}</div>
                <div>R$ {currencyBrl(filters.preco?.[1] || aggregations?.original_price_max.value || 0)}</div>
            </Values>
            <RangeSlider
                min={aggregations?.original_price_min.value || 0}
                max={(aggregations?.original_price_max.value || 0) + 1}
                getAriaLabel={() => 'Price Range'}
                value={
                    isEmpty
                        ? [
                              aggregations?.original_price_min.value,
                              (aggregations?.original_price_max.value as number) + 1
                          ]
                        : filters.preco
                }
                onInput={handleChange}
            />
        </Container>
    );
}

export default Price;
