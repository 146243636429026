import {
    Button,
    InfoData,
    InfoHeader,
    ListItemContainer,
    ListItemContent,
    ListItemHeader,
    ListItemInfoContainer,
    ListItemTitle,
    TrashIcon
} from './styles';

const WishlistItemSkeleton = () => {
    return (
        <ListItemContainer>
            <ListItemHeader>
                <ListItemTitle />
                <TrashIcon />
            </ListItemHeader>
            <ListItemContent>
                <ListItemInfoContainer>
                    <InfoHeader />
                    <InfoData />
                </ListItemInfoContainer>
                <ListItemInfoContainer>
                    <InfoHeader />
                    <InfoData />
                </ListItemInfoContainer>
            </ListItemContent>
            <div style={{ display: 'flex' }}>
                <Button />
            </div>
        </ListItemContainer>
    );
};

export default WishlistItemSkeleton;
