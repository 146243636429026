import tw, { styled } from 'twin.macro';
import MapRoot from '../Map';
export const Container = styled.div`
    ${tw`
        gap-[15px]
        bg-white
        flex
        flex-col
        flex-1
        p-[15px]
    `}
`;

export const Map = styled(MapRoot)`
    ${tw`
        rounded-[15px]
        overflow-hidden
    `}
`;
