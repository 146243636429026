import tw, { styled } from 'twin.macro';
import { ArrowIcon, IconClose, IconFeatured } from '../../atoms';
import IconMenuAtom from '../../atoms/icons/Menu';

export const ListContainer = styled.div`
    &.show {
        ${tw`
        w-[80vw]
        visible

        lg:shadow-[0px 0px 0px 0px]
        lg:left-[0]
    `}
    }
    ${tw`
  bg-white
  flex
  invisible
  absolute
  flex-col
  w-0
  // shadow-[0px 0px 100vh 100vh #000C]
  justify-start
  items-center
  pb-[30px]
  rounded-br-[20px]
  z-[1000]
  transition-all
  duration-500
  left-[-100vw]
  overflow-hidden

  lg:bg-transparent
  lg:items-start
  lg:left-auto
  lg:transition-none
  lg:duration-[0ms]
  lg:overflow-visible

`}
`;

export const CategoriesListHeader = styled.div`
    ${tw`
  font-nunito
  font-bold
  text-[22px]
  items-center
  pt-[36px]
  pb-[10px]
  border-b-gray
  border-b-[3px]
  w-[80%]
`}
`;

export const CategoriesListLabel = styled.div`
    ${tw`
  flex-1
  mb-[2px]
`}
`;

export const IconMenu = styled(IconMenuAtom)`
    ${tw`
  mb-[5px]
  mx-[10px]
`}
`;

export const IconArrow = styled(ArrowIcon)`
    &.selected {
        ${tw`
  rotate-180
  lg:rotate-[270deg]
`}
    }
    ${tw`
  mb-[5px]
  mx-[10px]
  transition-transform
  duration-300
`}
`;

export const IconCross = styled(IconClose)`
    ${tw`
  mb-[5px]
  ml-[50px]
  mx-[10px]
  self-end
  fill-black
`}
`;

export const IconDelivei = styled(IconFeatured)`
    ${tw`
  w-[40px]
  h-[40px]
  p-[5px]
  fill-white
`}
`;

export const CategoriesList = styled.div`
    ${tw`
    z-50
  flex
  flex-col
  bg-white
  w-[80%]
  rounded-b-lg
  
  lg:w-[300px]
  lg:absolute
  lg:border-primary
  lg:border-[1px]
  lg:rounded-tr-[10px]
`}
`;

export const CategoryContainer = styled.div`
    ${tw`
  flex
  flex-1
  flex-col
  items-center
  justify-between
  border-b-[1px]
  border-[#0002]
  last:rounded-b-md
`}
`;

export const PromotionsCategory = styled.div`
    ${tw`
  flex
  items-center
  justify-start
  bg-primary
  font-nunito
  text-white
  py-2
  font-[700]
  rounded-xl
  mt-[20px]
  mb-[20px]

  lg:hidden
`}
`;

export const Category = styled.div`
    ${tw`
        flex
        w-full
        items-center
        justify-between
        font-nunito
        text-[#000]
        py-[16px]
        px-3
        rounded-xl
        font-normal
        last:rounded-b-md
        hover:bg-primary
        hover:text-white

        lg:rounded-[0px]
        lg:text-darkGray
        lg:py-2
        transition-colors
    `}
    & svg path {
        ${tw`
        transition-colors
        duration-300
      `}
    }
    &:hover svg path {
        ${tw`
        fill-white
      `}
    }
`;

export const CategoryIconContainer = styled.div`
    ${tw`
      w-[20px]
    `}
`;

export const CategoryLabel = styled.span`
    ${tw`
    ml-5
  `}
`;

export const CategoryLabelContainer = styled.span`
    ${tw`
      ml-5
      flex
      items-center
  `}
`;

export const Backdrop = styled.div`
    &.show {
        ${tw`
    left-[0px]
    opacity-50
  `}
    }
    ${tw`
     absolute
     top-[0px]
      w-[100vw]
      h-[100vh]
     bg-black
     left-[-100vw]
     z-[500]
     opacity-0
     transition-opacity
     duration-[500ms]

     lg:hidden
  `}
`;
