import tw, { styled } from 'twin.macro';

export const Container = styled.thead``;

export const TableHeaderRow = styled.tr`
  ${tw`
    border-b-[1px]
    border-b-gray  
  `}
`;
