import { FormikProvider, useFormik } from 'formik';
import { memo, MouseEvent } from 'react';
import * as Yup from 'yup';
import { useWishlistAdd } from '../../../hooks';
import { IProduct } from '../../../services/product/types';
import { REQUIRED } from '../../../utils/errors';
import { Button, Input } from '../../molecules';
import { Container, Header } from './styles';

const validationSchema = Yup.object().shape({
    name: Yup.string().required(REQUIRED)
});
interface IModalWishlistAdd {
    onClose: (event?: MouseEvent<HTMLDivElement>) => void;
    product: IProduct;
}

const ModalWishlistAdd = ({ onClose, product }: IModalWishlistAdd) => {
    const onClickRoot = (event: any) => {
        event?.stopPropagation();
        event?.preventDefault();
    };

    const { mutate: add, isLoading } = useWishlistAdd({ onSuccess: () => onClose() });

    const formik = useFormik({
        initialValues: {
            name: ''
        },
        validationSchema,
        onSubmit: (values) => {
            add({
                item: { quantity: 1, sku: product.sku, id: product.id.toString() },
                name: values.name,
                wishlistId: ''
            });
        }
    });

    return (
        <Container onClose={onClose} onClick={onClickRoot} layout exit={'exit'} animate={'animate'} initial={'initial'}>
            <FormikProvider value={formik}>
                <Header onSubmit={formik.handleSubmit}>
                    <Input name="name" label="Nome" />
                    <Button
                        onClick={formik.submitForm}
                        isLoading={isLoading}
                        disabled={!formik.isValid}
                        text="Adicionar"
                    ></Button>
                </Header>
            </FormikProvider>
        </Container>
    );
};
export default memo(ModalWishlistAdd);
