import tw, { styled } from 'twin.macro';
import { TextNunitoBlack18, TextNunitoRegular18 } from '../../atoms';

export const Container = styled.div`
    ${tw`
        bg-[#F8F8F8]
        py-6
        flex
        flex-col
        w-full
    `}
`;

export const Content = styled.div`
    ${tw`
        flex
        justify-center
        items-center
    `}
`;

export const List = styled.div`
    ${tw`
        flex
        flex-row
        w-full
        justify-between
        pl-7
        md:pl-0
    `}
`;
