import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import qs from 'qs';
import { SEARCH_MODE_LIST } from '../../utils/react-query-keys';
import { apiNext } from '../api';
import { IBoolQuery } from '../elastic/types';
import { FilterGroup } from '../magento/types';
import productService from '../product';
import { IProduct } from '../product/types';
import { queryClient } from '../query-client';

export interface GetAll {
    items: IProduct[];
    total_count?: number;
    aggregations?: any;
}

export enum ModeList {
    GRID = 'GRID',
    LIST = 'LIST'
}

const setModeListStorage = (mode: ModeList) => {
    queryClient.setQueryData([SEARCH_MODE_LIST], mode);
};
const getModeListStorage = (): string | undefined => {
    return queryClient.getQueryData([SEARCH_MODE_LIST]);
};

const getAll = async (params?: any, filterStock = true): Promise<GetAll> => {
    const { data } = await apiNext.get('product', {
        paramsSerializer: (p) => {
            return qs.stringify(p, { arrayFormat: 'indices' });
        },
        ...params,
        params: {
            ...params.params,
            searchCriteria: {
                ...params.params.searchCriteria,
                filter_groups: [
                    ...params.params.searchCriteria.filter_groups,
                    {
                        filters: [{ field: 'status', value: 1, condition_type: 'eq' }]
                    },
                    {
                        filters: [{ field: 'price', value: 0, condition_type: 'gt' }]
                    }
                ]
            },
            filterStock: filterStock ? 1 : 0
        }
    });

    //Estava retornando um [] em vez de null e bugando o hall
    if (Array.isArray(data.items)) {
        if (data.items.length < 1) {
            data.items = null;
            data.total_count = 0;
        }
    }

    return data;
};

const getFilterHallProductsOffer = (): IBoolQuery => {
    const currentDateFilter = format(new Date(), `Y-MM-dd`, { locale: ptBR });

    return {
        bool: {
            filter: [
                // {
                //     term: {
                //         status_stock: 1
                //     }
                // },
                {
                    range: {
                        special_from_date: {
                            lte: currentDateFilter,
                            format: 'yyyy-MM-dd'
                        }
                    }
                },
                {
                    range: {
                        special_to_date: {
                            gte: currentDateFilter,
                            format: 'yyyy-MM-dd'
                        }
                    }
                }
            ]
        }
    };
};

const getFilterHall = (filterGroups: FilterGroup[]) => ({
    searchCriteria: {
        pageSize: 30,
        filter_groups: filterGroups,
        sortOrders: [
            {
                direction: 'asc',
                field: 'position'
            }
        ]
    },
    fields: `items[${productService.PRODUCTS_FIELDS}],total_count`
});

export default {
    setModeListStorage,
    getModeListStorage,
    getAll,
    getFilterHallProductsOffer,
    getFilterHall
};
