import { HTMLAttributes } from 'react';
import tw, { styled } from 'twin.macro';
import { useEstablishment } from '../../../hooks/use-establishment';

export const SoldBy = styled.div`
    ${tw`
        // text-sm
        text-12
        // md:text-lg
        text-[#636363]
        `}

    strong {
        ${tw`
            text-primary
        `}
    }
`;

interface IProductSoldByProps extends HTMLAttributes<HTMLDivElement> {
    component?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'div';
}

export default function ProductSoldBy({ component = 'h1', ...props }: IProductSoldByProps) {
    const { data: establishment } = useEstablishment();

    const isCasaLavoura = [7, 9].includes(Number(establishment?.list?.[0]?.storeId));
    return (
        <SoldBy as={component} {...props}>
            {/* {isCasaLavoura ? (
                <>
                    Vendido e Entregue em Parceria <strong className="!text-black">CASA DA LAVOURA</strong> e{' '}
                    <strong>MATCON.CASA</strong>
                </>
            ) : ( */}
            <>
                Vendido por <strong>MATCON.CASA</strong> e entregue por <strong>MATCON.CASA</strong>
            </>
            {/* )} */}
        </SoldBy>
    );
}
