import tw, { styled } from 'twin.macro';

export const Container = styled.ul`
  ${tw`
    w-full
    mb-[50px]
  `}
`;

export const Item = styled.li`
  ${tw`
		w-full
    mb-[5px]
  `}
`;
