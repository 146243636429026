import tw, { styled } from 'twin.macro';
import { TextLatoRegular15, TextNunitoRegular14, TextNunitoRegular16 } from '../../atoms';
import { Button } from '../../molecules';
import { ButtonProps } from '../../molecules/Button/types';

export const LoginFormContainer = styled.div`
    ${tw`
    flex 
    flex-col 
    flex-1
    max-h-[800px]
    gap-4
    overflow-auto
    `}
`;

export const LoginFormHeader = styled.span`
    ${tw`flex items-center justify-center w-full `}
`;

export const ContainerTitle = styled.span`
    ${tw`
    font-[400]
    text-[16px]
    lg:text-[18px]
    `}
`;

export const LoginFormMandatoryLabel = styled(TextNunitoRegular14)`
    ${tw`
    mb-[15px]
    text-[#424242]
    `}
`;

export const LoginFormMandatoryBox = styled.div`
    border-radius: 8px;
    border: 1px solid #ef4123;
    background: rgba(239, 65, 35, 0.1);
    padding: 10px;
    margin-top: 2rem;
    margin-bottom: 0.6rem;
`;

export const ContainerDescription = styled.span`
    ${tw`
        text-[#424242]
        font-nunito
        text-[22px]
        font-bold
        text-center
        leading-normal
        mt-[10px]
        lg:min-h-[50px]
        lg:mb-[0px]
        lg:text-[22px]
    `}
`;

export const LoginFormInput = styled.input`
    ${tw`
    border-[1px]
    border-[#C4C4C4]
    rounded-[15px]
    p-[10px]
    mb-[20px]
    outline-none
    w-full
    lg:w-[370px]
    `}
`;

export const SubmitForgetButton = styled.div`
    ${tw`flex flex-col items-center w-full lg:items-center lg:justify-start`}
`;

export const FormSubmitButton = styled((props) => Button(props))`
    ${tw`
        rounded-[32px]
        w-full 
    `}
`;

export const LoginFormForgotPassword = styled.a`
    ${tw`
    font-[400]
    text-[13px]
    text-black
    flex
    h-[54px]
    items-center
    justify-center
    cursor-pointer
    `}
`;

export const ButtonContainer = styled.div`
    ${tw`flex flex-col items-center justify-center lg:gap-8 sh:gap-2`}
`;
