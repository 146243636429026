import { FormikProvider, useFormik } from 'formik';
import { Checkbox as CustomCheckbox } from '../../../../../atoms/Checkbox';
import MenuCollapse from '../Menu-collapse';
import { Container, Item, List } from './styles';
import { useProductSearchFilters } from '../../../hooks/useProductSearchFilters';
import { useMemo } from 'react';
import { useCategories } from '../../../../../../hooks';
import { ucfirst } from '../../../../../../utils/string';

interface InflamavelProps {
    isMobile?: boolean;
}
function Inflamavel({ isMobile = false }: InflamavelProps) {
    const { filters, setFiltersDesktop, setFiltersMobile, aggregations } = useProductSearchFilters();

    function handleChange(id: string) {
        const { inflamavel } = filters;
        const index = inflamavel.indexOf(id);
        let newFilters = [];

        if (index === -1) {
            newFilters = [...inflamavel, id];
        } else {
            newFilters = inflamavel.filter((item) => item !== id);
        }

        if (isMobile) {
            setFiltersMobile({ inflamavel: newFilters });
            return;
        }

        setFiltersDesktop({ inflamavel: newFilters });
    }

    const items = useMemo(() => {
        return (
            aggregations?.Inflamável.buckets
                .map((Inflamável: any) => {
                    return {
                        key: Inflamável.key,
                        name: ucfirst(Inflamável.key?.toLowerCase())
                    };
                })
                .filter((v) => v != null) || []
        );
    }, [aggregations?.Inflamável.buckets]);

    if (!items.length) return null;

    return (
        <Container>
            <MenuCollapse title="Inflamável" qty={filters.inflamavel.length || 0}>
                <List>
                    {items.map((Inflamável: any) => (
                        <Item key={Inflamável.key} onClick={() => handleChange(Inflamável.key)}>
                            <CustomCheckbox
                                name={Inflamável.key}
                                isChecked={filters.inflamavel.includes(Inflamável.key)}
                                withoutFormik
                                onCheckboxChange={() => handleChange(Inflamável.key)}
                            />
                            <div>{Inflamável.name}</div>
                        </Item>
                    ))}
                </List>
            </MenuCollapse>
        </Container>
    );
}

export default Inflamavel;
