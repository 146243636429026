import { useMutation } from '@tanstack/react-query';
import axios, { AxiosRequestConfig, Canceler } from 'axios';
import { useField } from 'formik';
import { FC, memo, useEffect, useRef, useState } from 'react';
import { CartService } from '../../../services';
import Button from '../Button';
import { IButtonVariant } from '../Button/types';
import Input from '../Input';
import { IconLoading, IconValid } from './styles';
import { IInputCouponProps } from './types';
import { toast } from 'react-toastify';
import { IconClose } from '../../atoms';

const InputCoupon: FC<IInputCouponProps> = ({ name, className, value }) => {
    const cancelToken = useRef<Canceler>();
    const [valid, setValid] = useState<boolean>();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const [, , { setValue, setError }] = useField<string | null | undefined>(name);

    const { isLoading, mutate } = useMutation(
        async (value: string) => {
            if (cancelToken.current) cancelToken.current();

            const params: AxiosRequestConfig = {
                cancelToken: new axios.CancelToken((c) => {
                    cancelToken.current = c;
                })
            };

            return { valid: await CartService.addCoupon(value, params), value };
        },
        {
            onSuccess: ({ valid, value }: any) => {
                setValid(valid);
                if (!valid) {
                    setValue(null);
                    CartService.removeCoupon();
                } else {
                    setValue(value);
                }
                CartService.updateInfoCart();
            },
            onError: (error: any) => {
                toast.error(error.response.data.message)
                setValid(false);
                setValue('');
                CartService.removeCoupon();
                CartService.updateInfoCart();
            }
        }
    );

    const handleChangeCoupon = async () => {
        if (value) {
            mutate(value);
        } else {
            await CartService.removeCoupon();
            setValue(undefined);
            setError(undefined);
            setValid(undefined);
            await CartService.updateInfoCart();
        }
    };

    const handleRemoveCoupon = async () => {
        await CartService.removeCoupon();
        setValue('');
        setValid(false);
        setError(undefined);
        await CartService.updateInfoCart();
    };

    const getIcon = () => {
        if (isLoading) return <IconLoading />;

        //if (valid) return <IconValid />;

        return undefined;
    };

    const getClearIcon = () => {
        if (valid) {
            return (
                <span onClick={handleRemoveCoupon} className="cursor-pointer">
                    <IconClose />
                </span>
            );
        }
        return null;
    };

    const previousData = async () => {
        const data = await CartService.getInfo()
        if (data?.coupon_code) {
            setValid(true)
        }
    };

    useEffect(() => {
        previousData()
    }, [])

    return (
        <div className={`flex gap-3 ${windowWidth < 1024 && 'flex-col w-full'}`}>
            <div className="relative w-full">
                <Input
                    name={name}
                    placeholder="Adicionar cupom"
                    iconRight={getIcon()}
                    className={className}
                />
                {getClearIcon() && (
                    <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
                        {getClearIcon()}
                    </div>
                )}
            </div>

            <span className="w-full">
                <Button
                    text="Aplicar"
                    style="padding: 16px 40px; min-height: 56px; width: 100%;"
                    variant={windowWidth < 1024 ? IButtonVariant.OUTLINE : IButtonVariant.PRIMARY_BIG}
                    onClick={handleChangeCoupon}
                />
            </span>
        </div>
    );
};

export default memo(InputCoupon);
