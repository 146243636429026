import { IProduct } from '../../../../../../services/product/types';
import Tabs from '../../../../../organisms/Tabs';
import { Container, Name, Row, Table, Title, Value } from './styles';

interface ITabDescriptionProps {
    data: any;
}

function TabTechnicalSpecification({ data }: ITabDescriptionProps) {
    return (
        <Tabs.Content>
            <Container>
                <Title>Ficha Técnica</Title>
                <Table>
                    {Object.keys(data.attributes).map((key) => {
                        return (
                            <div key={key}>
                                <Row>
                                    <Name>{key}</Name>
                                    <Value>{data.attributes[key]}</Value>
                                </Row>
                            </div>
                        );
                    })}
                </Table>
            </Container>
        </Tabs.Content>
    );
}

export default TabTechnicalSpecification;
