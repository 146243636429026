import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { EstablishmentService } from '../../services';
import { IEstablishmentItem } from '../../services/establishment/types';

export const useCheckCoverageEstablishment = (
    params?: UseMutationOptions<IEstablishmentItem[], AxiosError, string>
) => {
    return useMutation<IEstablishmentItem[], AxiosError, string>(
        (zipCode: string) => EstablishmentService.checkCoverageEstablishment(zipCode),
        params
    );
};
