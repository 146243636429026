import { FC } from 'react';
import { IconSpinner } from '../../atoms';
import { Container } from './styles';
import { ButtonProps, IButtonVariant } from './types';

const Button: FC<ButtonProps> = ({
    text,
    variant = IButtonVariant.PRIMARY_BIG,
    isLoading = false,
    onClick,
    disabled,
    style,
    icon: Icon,

    ...rest
}) => {
    return (
        <Container
            variant={variant}
            disabled={isLoading || disabled}
            type="button"
            onClick={onClick}
            css={style}
            {...rest}
        >
            {!isLoading && (
                <div className="flex items-center gap-2">
                    {Icon && <Icon width={24} height={24} />}
                    {text}
                </div>
            )}
            {isLoading && (
                <>
                    <IconSpinner className="w-5 h-5 " />
                    <div>Carregando...</div>
                </>
            )}
        </Container>
    );
};

export default Button;
