import Image from 'next/image';
import { LayoutFitter } from '../../../templates';
import { Container, Content, Item, ItemTitle } from './styles';
import { useEffect, useState } from 'react';
import block from '../../../../services/block';

function FooterMiddleBanner() {
    const [blockContent, setBlockContent] = useState<string>('');

    const getBlockTextById = async (id: number) => {
        const response = await block.getBlockTextById(id);
        setBlockContent(response.content);
    };

    useEffect(() => {
        getBlockTextById(28);
    }, []);

    return (
        <Container>
            <LayoutFitter>
                <Content dangerouslySetInnerHTML={{ __html: blockContent || '' }}></Content>
            </LayoutFitter>
        </Container>
    );
}

export default FooterMiddleBanner;
