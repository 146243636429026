import { DefaultSeo } from 'next-seo';
import { useCategoryDetails } from '../../../../../hooks/use-category-details';
import { NEXT_PUBLIC_TITLE } from '../../../../../utils/envs';
import { Button, ProductItem, ProductItemSkeleton } from '../../../../molecules';
import { IButtonVariant } from '../../../../molecules/Button/types';
import { useProductSearchFilters } from '../../hooks/useProductSearchFilters';
import CategoryCarousel from '../CategoryCarousel';
import FilterDesktop from '../FilterDesktop';
import SortDesktop from '../SortDesktop';
import { Container, Content, Products } from './styles';

function Items() {
    const {
        dataList: { hasNextPage, data, fetchNextPage, isLoading, isFetchingNextPage },
        isCategory
    } = useProductSearchFilters();

    const { data: category } = useCategoryDetails(isCategory?.id || '', { enabled: !!isCategory?.id });

    const getHead = () => {
        if (!category) return null;

        const metaTitle = category?.custom_attributes.find((item: any) => item.attribute_code === 'meta_title')?.value;
        const metaKeywords = category?.custom_attributes.find(
            (item: any) => item.attribute_code === 'meta_keywords'
        )?.value;
        const metaDescription = category?.custom_attributes.find(
            (item: any) => item.attribute_code === 'meta_description'
        )?.value;
        const additionalMetaTags = [];

        if (metaKeywords) {
            additionalMetaTags.push({ name: 'keywords', content: metaKeywords });
        }

        return (
            <DefaultSeo
                title={`${NEXT_PUBLIC_TITLE} | ${metaTitle || category?.name}`}
                description={metaDescription}
                additionalMetaTags={additionalMetaTags}
            />
        );
    };

    return (
        <>
            {getHead()}
            <Container>
                <FilterDesktop />
                <Content>
                    <CategoryCarousel className="hidden md:flex" />
                    <SortDesktop />
                    <Products>
                        {data?.pages.map((page) =>
                            page.items.map((product) => <ProductItem titleTag="h2" key={product.id} data={product} />)
                        )}
                        {!isLoading && !isFetchingNextPage && !data?.pages?.[0].total_count && (
                            <div className="flex items-center justify-center w-full h-32 col-span-4">
                                <span className="text-black text-18 font-semibold">Nenhum produto encontrado</span>
                            </div>
                        )}
                        {(isFetchingNextPage || isLoading) && (
                            <>
                                <ProductItemSkeleton />
                                <ProductItemSkeleton />
                                <ProductItemSkeleton />
                                <ProductItemSkeleton />
                            </>
                        )}
                    </Products>
                    {hasNextPage && (
                        <div className="flex items-center justify-center">
                            <Button
                                onClick={fetchNextPage}
                                className="min-w-[254px]"
                                text="Carregar mais produtos"
                                variant={IButtonVariant.OUTLINE}
                                isLoading={isFetchingNextPage}
                            />
                        </div>
                    )}
                </Content>
            </Container>
        </>
    );
}

export default Items;
