import { useField } from 'formik';
import { memo } from 'react';
import { ErrorMsgField } from '../../atoms';
import { Container, ContainerLabel, Label, Required } from './styles';
import { EInputType, IInputProps } from './types';
import Type from './Types-inputs';

function Input<T = any>({ type = EInputType.DEFAULT, ...props }: IInputProps<T>) {
    const [, { error }] = useField<T>(props.name);

    return (
        <Container emptyLabel={!Boolean(props.label)}>
            {props.label && (
                <ContainerLabel>
                    <Label title={props.label}>
                        {props.label}
                        {props.required && <Required>*</Required>}
                    </Label>
                </ContainerLabel>
            )}
            <Type type={type} {...(props as any)} />
            {error && (
                <ErrorMsgField>
                    {Array.isArray(error) ? error.map((err, i) => <div key={i}>{err}</div>) : error}
                </ErrorMsgField>
            )}
        </Container>
    );
}

const typedMemo: <T>(c: T) => T = memo;
export default typedMemo(Input);
