import tw, { styled } from 'twin.macro';
import { BadgeColor, BadgeSize } from './types';

export const Container = styled.div<{ $size: BadgeSize; $color: BadgeColor; $customColor?: string }>`
    ${({ $color, $customColor }) => {
        switch ($color) {
            case BadgeColor.PRIMARY:
                return tw`
                    bg-primary
                    text-white
                `;
            case BadgeColor.MOSS_GREEN:
                return tw`
                    bg-[#698122]
                    text-white
                `;
            case BadgeColor.GRAPE_GREEN:
                return tw`
                    bg-[#6054A4]
                    text-white
                `;
            case BadgeColor.GRAPE_GREEN_LIGHT:
                return tw`
                    bg-[#00A4E0]
                    text-white
                `;
            case BadgeColor.RED:
                return tw`
                    bg-[#F2684C]
                    text-white
                `;
            case BadgeColor.CUSTOM:
                return `
                background-color: ${$customColor || 'transparent'};
                color: white;
            `;
        }
    }}
    ${({ $size }) => {
        switch ($size) {
            case BadgeSize.LARGE:
                return tw`
                    py-2.5
                    px-7
                    rounded-20
                    text-14
                    font-extrabold
                    leading-[19px]
                `;
            case BadgeSize.SMALL:
                return tw`
                    rounded-16
                    py-1.5
                    px-4
                    text-12
                    font-semibold
                    leading-[11px]
                `;
            case BadgeSize.MEDIUM:
                return tw`
                    rounded-16
                    py-[5px]
                    px-4
                    text-14
                    font-extrabold
                    leading-[22px]
                `;
        }
    }}
    ${tw`
        flex
        items-center
        justify-center
        shrink-0
    `}
`;
