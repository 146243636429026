import { AnimatePresence } from 'framer-motion';
import { MouseEvent, PropsWithChildren, useState } from 'react';
import { Container, ContainerIcon, Content, Header } from './styles';
import IconArrowDown from '../../../atoms/icons/Arrow-down';

interface IMenuCollapseProps {
    title: string;
    borderTop?: boolean;
}

const MenuCollapse = ({ title, children, borderTop = false }: PropsWithChildren<IMenuCollapseProps>) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        setIsOpen(!isOpen);
    };

    return (
        <Container $borderTop={borderTop}>
            <Header onClick={handleToggle}>
                <span>{title}</span>
                <ContainerIcon animate={isOpen ? 'open' : 'close'}>
                    <IconArrowDown style={{ fill: '#212226' }} />
                </ContainerIcon>
            </Header>
            <AnimatePresence>
                {isOpen && (
                    <Content>
                        <div className="pb-4 px-6">{children}</div>
                    </Content>
                )}
            </AnimatePresence>
        </Container>
    );
};

export default MenuCollapse;
