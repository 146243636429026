import { AxiosRequestConfig } from 'axios';
import api from '../services/api';
import { Order } from '../services/order/types';
import { Installment, InstallmentPagseguroResponse } from '../services/pagseguro/types';
import {
    CreditCardValues,
    GetInstallmentsParams,
    InstallmentsResponse,
    PaymentInterface,
    PaymentMethod,
    PaymentSelected,
    PaymentTypes
} from '../services/payment/types';
import { default as UserService } from '../services/user';
import { currencyBrl, currencyUnMask } from '../utils/getFormattedValue';
import { ucfirst } from '../utils/string';
let PagSeguroDirectPayment: any;

export class PaymentPagseguroCreditCard extends PaymentMethod implements PaymentInterface {
    async getCheckout(valuesCheckout: CreditCardValues[]) {
        const user = await UserService.getInfo();
        const [card] = valuesCheckout;

        let cardInfo;

        if (card.useSave) {
            cardInfo = {
                cc_type: card.useSave.brand,
                cc_last_4: card.useSave.masked_number.substr(-4),
                cc_owner: `${user?.firstname} ${user?.lastname}`,
                cc_savecard: 0,
                cc_saved_card: card.useSave.id,
                cc_installments: card?.installments,
                cc_card_tax_amount: '0',
                cc_buyer_checkbox: false
            };
        } else {
            const [birthDay, bithMonth, bithYear] = card.birthday?.split('/') as string[];
            const number = card.number?.replace(/ /g, '') as string;
            cardInfo = {
                cc_installments: `${card.installments?.quantity}|${card.installments?.installmentTotal}`,
                cc_number: `${number.slice(0, 4)}****${number.slice(12)}`,
                cc_owner_birthday_day: birthDay,
                cc_owner_birthday_month: bithMonth,
                cc_owner_birthday_year: bithYear,
                cc_owner_cpf: card.cpf,
                cc_owner_name: card.name,
                cc_type: card.type,
                credit_card_token: card.installments?.token,
                sender_hash: card.installments?.tokenCardFront
            };
        }

        return {
            method: PaymentTypes.PAGSEGURO_CREDIT_CARD,
            additional_data: cardInfo
        };
    }

    getFromOrder(order: Order) {
        const paymentService = require('../services/payment').default;

        const type = order.extension_attributes.payment_additional_info?.find((v) => v.key == 'cc_type')?.value;
        const installments = parseInt(
            order.extension_attributes.payment_additional_info?.find((v) => v.key == 'cc_installments')?.value || '1'
        );
        const method = paymentService.getPaymentMethodTitle(PaymentTypes.PAGSEGURO_CREDIT_CARD, 'small');
        const number = order.extension_attributes.payment_additional_info?.find((v) => v.key == 'cc_last_4')?.value;
        return {
            method: PaymentTypes.PAGSEGURO_CREDIT_CARD,
            values: [
                {
                    installments,
                    value: currencyUnMask(order.base_grand_total).toString(),
                    type,
                    number,
                    label: `${ucfirst(type)} - ${installments}x ${method} - **** ${number}`
                }
            ]
        };
    }

    getFromCheckout(data: PaymentSelected) {
        const paymentService = require('../services/payment').default;

        const method = PaymentTypes.PAGSEGURO_CREDIT_CARD;
        const methodName = paymentService.getPaymentMethodTitle(method, 'small');
        return {
            method,
            values:
                data.values?.map((card) => {
                    const installments = card.installments?.quantity;
                    const type = card.type || card.useSave?.brand;
                    const number = card.number
                        ? card.number?.replace(/ /g, '').slice(12)
                        : card.useSave?.masked_number.slice(15);
                    return {
                        installments,
                        value: card.value,
                        type: type,
                        number: number,
                        label: `${ucfirst(type)} - ${installments}x ${methodName} - **** ${number}`
                    };
                }) || []
        };
    }

    async getInstallments(params: GetInstallmentsParams, config?: AxiosRequestConfig): Promise<InstallmentsResponse[]> {
        const [expMonth, expYear] = params.creditCardValid?.split('/') as string[];
        const { data } = await api
            .post<InstallmentPagseguroResponse>(
                'V1/custom/api/getcardinfos/pagseguro',
                {
                    data: {
                        type: '',
                        amount: parseFloat(params.value || '0'),
                        credit_card_type: params.creditCardType,
                        credit_card_number: params.creditCardNumber?.replace(/ /g, ''),
                        credit_card_cvv: params.creditCardCvv,
                        credit_card_expiration_month: expMonth,
                        credit_card_expiration_year: expYear
                    }
                },
                config
            )
            .catch();

        if (!data) return [];
        const [items, token, tokenLogin] = data;
        const newValue = currencyUnMask(params.value);

        window.PagSeguroDirectPayment.setSessionId(tokenLogin);
        const hash = window.PagSeguroDirectPayment.getSenderHash();

        return [
            {
                token,
                quantity: 1,
                installmentTotal: newValue,
                label: `1x de ${params.value}`,
                totalWithTax: newValue,
                tokenCardFront: hash
            }
        ];
        // return (
        //     items?.map((value: Installment) => {
        //         const juros = value.interestFree ? 'com' : 'sem';
        //         return {
        //             token,
        //             quantity: 1,
        //             installmentTotal: newValue,
        //             label: `1x de ${params.value}`,
        //             totalWithTax: newValue
        //             // quantity: value.quantity,
        //             // installmentTotal: value.installmentAmount,
        //             // label: `${value.quantity}x de ${currencyBrl(value.installmentAmount)} ${juros} juros (${currencyBrl(
        //             //     value.totalAmount
        //             // )})`,
        //             // totalWithTax: value.totalAmount
        //         };
        //     }) || []
        // );
    }
}
