import { Container, ImageContainer, Price, SubPrice, Title } from './styles';
function ProductItemHorizontalSkeleton() {
    return (
        <Container>
            <ImageContainer />
            <Title />
            <div className="flex flex-col gap-1">
                <Price />
                <SubPrice />
            </div>
        </Container>
    );
}

export default ProductItemHorizontalSkeleton;
