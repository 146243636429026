import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { FC, memo } from 'react';
import { IBoolQuery } from '../../../services/elastic/types';
import Hall from '../Hall';
import { IHallProductsOfferProps } from './types';

const HallProductsNew: FC<IHallProductsOfferProps> = (props) => {
    const currentDateFilter = format(new Date(), `Y-MM-dd`, { locale: ptBR });

    const filterGroups: IBoolQuery = {
        bool: {
            filter: [
                // {
                //     term: {
                //         status_stock: 1
                //     }
                // },
                {
                    range: {
                        news_from_date: {
                            lte: currentDateFilter,
                            format: 'yyyy-MM-dd'
                        }
                    }
                },
                {
                    range: {
                        news_to_date: {
                            gte: currentDateFilter,
                            format: 'yyyy-MM-dd'
                        }
                    }
                }
            ]
        }
    };

    // const filterGroups: FilterGroup[] = [
    //     { filters: [{ condition_type: ConditionType.LTEQ, field: 'news_from_date', value: currentDateFilter }] },
    //     { filters: [{ condition_type: ConditionType.GTEQ, field: 'news_to_date', value: endDateFilter }] }
    // ];

    return <Hall title="Produtos Novos" filterGroups={filterGroups} {...props} />;
};

export default memo(HallProductsNew);
