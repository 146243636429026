import tw, { styled } from 'twin.macro';
import { IconHeart, IconHeartOutline, IconOrder } from '../../../atoms';

export const Container = styled.button`
    ${tw`
        absolute
        top-2
        right-2
    `}
`;

export const Icon = styled(IconHeartOutline)`
    ${tw`
        h-6
        w-6
        text-[#9FBE43]
    `}
`;
export const IconFilled = styled(IconHeart)`
    ${tw`
        h-6
        w-6
        text-[#9FBE43]
    `}
`;
