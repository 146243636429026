import tw, { styled } from 'twin.macro';
import { cssDefaultInput } from '../../styles';
import { InputProps } from './types';

export const Input = styled.input<InputProps>`
    ${tw`
        w-full
        px-4
        outline-none	
        bg-[#F6F6F6]
        focus:outline-none
    `}
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px #f6f6f6 inset !important;
        border-color: inherit;
        -webkit-box-shadow: none;
        box-shadow: none;
        background-color: #f6f6f6 !important;
    }
    ${(p) => {
        return p.$isMobile ? tw`p-[0 5px]` : '';
    }}
`;
export const Container = styled.div<InputProps>`
    ${cssDefaultInput}

    ${tw`
        flex 
        flex-row 
        justify-between 
        w-full 
        h-14
        p-0
        overflow-hidden
        items-center
    `}
`;
export const ContainerIcon = styled.div`
    ${tw`
        flex
        justify-center
        items-center
        p-[0 10px]
    `}
`;

export const CountryCode = styled.span`
    ${tw`
        ml-[10px]
        text-[darkgrey]
    `}
`;
