import { format, parseISO } from 'date-fns';

export const formatDate = (date: string, formatStr: string = 'dd/MM/yyyy') => {
    try {
        return format(toDate(date), formatStr);
    } catch (e: any) {
        console.info(date, 'date error');
        return '00/00/0000';
    }
};

export const toDate = (date: string) => {
    return parseISO(`${date.split(' ').join('T')}Z`);
};
