import tw, { styled } from 'twin.macro';
import { TextLatoBold18, TextLatoBold20, TextLatoRegular15 } from '../../atoms';

export const Container = styled.div`
    ${tw`
        bg-[#37353B]
        rounded-[20px]
        w-[305.5px]
        h-[188px]
        pt-[15px]
        shadow-md
        flex
        flex-col
        relative
    `}
`;

export const Header = styled.div`
    ${tw`
        flex
        flex-col
        flex-1
    `}
`;
export const NumberFlip = styled(TextLatoBold18)`
    ${tw`
    text-[#ddd]
    ml-[36px]
    `}
`;
export const BarGray = styled.div`
    ${tw`
    h-[30px]
    bg-[#ddd]
    mt-[10px]
    justify-center
    items-end
    pr-[10px]
    flex
    flex-col
    `}
`;
export const CvvFlip = styled(TextLatoBold18)`
    ${tw`
        text-[#ddd]
        ml-[36px]
        
    `}
`;
export const Cvv = styled(TextLatoRegular15)`
    ${tw`
        
    `}
`;
export const FlagFlip = styled.div`
    ${tw`
    ml-[15px]
    mb-[5px]
    mt-[5px]
    `}
`;
