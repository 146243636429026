import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { FC, HTMLAttributes, ReactElement } from 'react';
import { SwiperOptions } from 'swiper';
import { BannerService, CategoryService, EstablishmentService, ProductService } from '../../../services';
import { Banner as BannerInterface, BannerType } from '../../../services/banner';
import routerService from '../../../services/router';
import { BANNER } from '../../../utils/react-query-keys';
import { Error } from '../../molecules';
import Carousel from '../Carousel';
import { Container, Skeleton } from './styles';
import { useCategories } from '../../../hooks';

interface IBannerProps extends Partial<HTMLAttributes<HTMLDivElement>> {
    marginItem: number;
    bannerId: string;
    showArrows?: boolean;
    autoPlay?: boolean;
    numberSkeleton: number;
    slidesToShow?: number;
    slidesPerGroup?: number;
    renderImage: (banner: BannerInterface) => ReactElement;
    responsive?: {
        [width: number]: SwiperOptions;
        [ratio: string]: SwiperOptions;
    };
}

const Banner: FC<IBannerProps> = ({
    bannerId,
    marginItem,
    numberSkeleton,
    renderImage,
    responsive,
    slidesToShow,
    slidesPerGroup,
    className,
    showArrows = false,
    autoPlay = false
}) => {
    const router = useRouter();
    const { data: categories } = useCategories();
    const {
        data: images,
        isError,
        isLoading,
        refetch
    } = useQuery([BANNER, bannerId], () => BannerService.getById(bannerId), { refetchOnWindowFocus: false });
    if (isError) {
        return <Error onPress={refetch} />;
    }

    const handleClick = (item: BannerInterface) => {
        if (!item.type) return;

        if (item.type == BannerType.PRODUTO) {
            return routerService.toRoute({
                options: {
                    url: EstablishmentService.createUrl(`/product/${item.param}`)
                }
            });
        } else if (item.type == BannerType.LINK) {
            window.location.href = item.param;
            return;
        } else if (item.type == BannerType.CATEGORIA) {
            if (!categories) return;
            const category = CategoryService.getCategoryFromCategories(item.param, categories);
            if (!category) return;

            return router.push({
                pathname: ProductService.getUrlSearchProduct(category.url),
                query: {
                    highlights: item.skuHighlight || null
                }
            });
        } else if (item.type == BannerType.PAGE) {
            return routerService.toRoute({
                options: {
                    url: EstablishmentService.createUrl(`/pagina/${item.param.replace('site-page-', '')}`)
                }
            });
        }
    };

    if (!isLoading && images?.length == 0) return null;

    images && slidesPerGroup && (showArrows = images?.length > slidesPerGroup ? true : false);

    return (
        <>
            <Container className={className}>
                <Carousel
                    marginItem={marginItem}
                    responsive={responsive}
                    slidesToShow={slidesToShow}
                    slidesPerGroup={slidesPerGroup || 2}
                    autoPlay={autoPlay}
                    arrows={showArrows}
                >
                    {isLoading && Array.from(Array(numberSkeleton).keys()).map((i) => <Skeleton key={i} />)}

                    {!isLoading &&
                        images?.map((banner, i) => (
                            <div
                                key={i.toString()}
                                className={` flex ${banner.type ? 'cursor-pointer' : ''}`}
                                onClick={() => handleClick(banner)}
                            >
                                {renderImage(banner)}
                            </div>
                        ))}
                </Carousel>
            </Container>
        </>
    );
};

export default Banner;
