import { useEffect } from 'react';
import authService from '../../services/auth';
import { queryClient } from '../../services/query-client';
import { USER_LOGGED } from '../../utils/react-query-keys';

export function useCachedResources() {
    const init = async () => {
        queryClient.prefetchQuery([USER_LOGGED], () => authService.checkLogged());
        // queryClient.prefetchQuery([WISHLIST], () => WishlistService.getAll());
    };

    useEffect(() => {
        init();
    }, []);

    return { isLoading: false };
}
