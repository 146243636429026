import React, { SVGProps } from 'react';
import {
    IconBakery,
    IconBeef,
    IconCandy,
    IconCereals,
    IconCleaning,
    IconCoffe,
    IconDairy,
    IconDiet,
    IconDrinks,
    IconFair,
    IconFeatured,
    IconFlyer,
    IconFrozen,
    IconHygiene,
    IconPets,
    IconSeasoning,
    IconSeveral,
    IconShop,
    IconSnacks
} from '../components/atoms';
import IconFlourBag from '../components/atoms/icons/Flour-bag';

interface Category {
    name: string;
    ids?: number[];
    icon: (props?: SVGProps<SVGSVGElement>) => any;
}

export const categories: Category[] = [
    {
        name: 'Carnes e Peixe',
        ids: [3],
        icon: (props) => <IconFlyer width={20.62} height={28} {...props} />
    },
    {
        name: 'Laticínios e Frios',
        ids: [5],
        icon: (props) => <IconDairy width={28} height={28} {...props} />
    },
    { name: 'Bebidas', ids: [37, 483], icon: (props) => <IconDrinks width={28} height={28} {...props} /> },
    { name: 'Destaque', icon: (props) => <IconFeatured width={28} height={28} {...props} /> },
    {
        name: 'Limpeza de Casa',
        icon: (props) => <IconCleaning width={26} height={28} {...props} />
    },
    { name: 'Biscoitos e Snacks', ids: [84], icon: (props) => <IconSnacks width={23.48} height={28} {...props} /> },
    {
        name: 'Alimentos Congelados e Resfriados',
        ids: [97],
        icon: (props) => <IconFrozen width={25.4} height={28} {...props} />
    },
    { name: 'Higiene e Beleza', ids: [166, 75], icon: (props) => <IconHygiene width={28} height={28} {...props} /> },
    { name: 'Hortifruti', ids: [8], icon: (props) => <IconFair width={30} height={30} {...props} /> },
    { name: 'Doces e Sobremesas', ids: [193], icon: (props) => <IconCandy width={30} height={30} {...props} /> },
    { name: 'Padaria', ids: [93], icon: (props) => <IconBakery width={29} height={24.32} {...props} /> },
    { name: 'Pet Shop', ids: [6], icon: (props) => <IconPets width={28} height={25.56} {...props} /> },
    { name: 'Cereais e Massas', icon: (props) => <IconCereals width={28} height={28} {...props} /> },
    { name: 'Matinais', icon: (props) => <IconCoffe width={28} height={28} {...props} /> },
    { name: 'Conservas e Temperos', icon: (props) => <IconSeasoning width={28} height={28} {...props} /> },
    { name: 'Bazar e Utilidades', ids: [155], icon: (props) => <IconShop width={28} height={28} {...props} /> },
    { name: 'Natural Light e Diet', ids: [178], icon: (props) => <IconDiet width={28} height={28} {...props} /> },
    { name: 'Diversos', ids: [43, 988], icon: (props) => <IconSeveral width={24.44} height={25.67} {...props} /> },
    { name: 'Casa Pedro', ids: [1350], icon: (props) => <IconFlourBag width={28} height={25} {...props} /> }
];
