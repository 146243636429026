import tw, { styled } from 'twin.macro';
import { IconListAdd, Modal } from '../../atoms';
import IconCopyUrl from '../../atoms/icons/Copy-url';

export const Container = styled(Modal)`
    ${tw`
        h-auto    
        md:max-w-[250px]
        md:rounded-15    
    `}
`;

export const Content = styled.div`
    ${tw`
        flex
        w-full
        flex-col
        py-[10px]
    `}
`;

export const IconList = styled(IconListAdd)`
    ${tw`
        fill-black
        w-[20px]
        h-[20px]
    `}
`;
export const IconUrl = styled(IconCopyUrl)`
    ${tw`
        fill-black
        w-[18px]
        h-[18px]
    `}
`;
