import { FC } from 'react';
import { Button, Buttons, Container, TextDescription, TextTitle } from './styles';

interface IModalConfirmProps {
    text: string;
    onPressConfirm: () => void;
    onPressCancel: () => void;
    isLoadingConfirm?: boolean;
    onClose: () => void;
    textCancel?: string;
    textConfirm?: string;
    showCancelButton?: boolean;
}

const ModalConfirm: FC<IModalConfirmProps> = ({
    text,
    onPressCancel,
    onPressConfirm,
    onClose,
    isLoadingConfirm = false,
    textCancel = 'Cancelar',
    textConfirm = 'Confirmar',
    showCancelButton = true
}) => {
    return (
        <Container onClose={onClose}>
            <TextTitle>Aviso!</TextTitle>
            <TextDescription>{text}</TextDescription>
            <Buttons>
                {showCancelButton && <Button onClick={onPressCancel} text={textCancel} />}
                <Button onClick={onPressConfirm} isLoading={isLoadingConfirm} text={textConfirm} />
            </Buttons>
        </Container>
    );
};

export default ModalConfirm;
