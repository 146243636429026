import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { CartService } from '../../services';
import { Cart } from '../../services/cart/types';
import { CART } from '../../utils/react-query-keys';

export const useCart = (params?: UseQueryOptions<Cart>) => {
    return useQuery<Cart, unknown, Cart>([CART], () => CartService.updateCart({ updateId: true }), {
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        ...params
    });
};
