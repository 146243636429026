import tw, { styled } from 'twin.macro';
import { IContainerProps } from './types';

export const Container = styled.div<IContainerProps>`
    ${({ $error }) => ($error ? tw`border border-primary` : '')}

    ${tw`
        h-[48px]
        shadow-md
        rounded-15
        flex
        items-center
        px-[18px]
        cursor-pointer
    `}
`;
