import tw, { styled } from 'twin.macro';
import { TextLatoExtraBold18, TextLatoRegular15 } from '../../atoms';

export const Container = styled.div`
    ${tw`
        relative
        flex
        flex-col
    `}
    .slick-list {
        ${tw`
            p-[20px 0]
        `}
    }
    .container-end {
        ${tw`
            !flex
            !flex-row
            !gap-2
        `}
    }

    .swiper {
        ${tw`
            grid
            overflow-hidden
            [grid-template-areas:
                'ContainerTitle'
                'SwiperWrapper'
                'SwiperPagination'
            ]
        `}
        grid-auto-rows: minmax(1px, auto);
        grid-auto-columns: minmax(1px, auto);
    }
    .swiper-slide {
        width: auto;
        overflow: visible;
    }
    .btn-disabled {
        ${tw`
            invisible
            opacity-0
            
      `}
    }
    .swiper-wrapper {
        ${tw`
            [grid-area: SwiperWrapper]
        `}
    }

    .swiper-pagination-bullet {
        ${tw`
            cursor-pointer
            !w-2.5
            !h-2.5
            bg-[#D9D9D9]
            rounded-full
        `}
    }
    .swiper-pagination-bullet-active {
        ${tw`
            bg-[#95C230]
        `}
    }
    .swiper-pagination {
        ${tw`
           justify-center
           gap-1
           mt-3
           hidden
           [grid-area: SwiperPagination]
        `}
    }
`;
export const ContainerSwiper = styled.div`
    ${tw`
    `}
    .slick-list {
        ${tw`
            p-[20px 0]
        `}
    }

    .swiper {
        overflow: visible;
    }
    .swiper-slide {
        width: auto;
        overflow: visible;
    }
    .btn-disabled {
        ${tw`
            invisible
            opacity-0
            
      `}
    }
`;

export const ContainerTitle = styled.div`
    ${tw`
        flex
        flex-row
        justify-between
        items-center
        mb-4
        [grid-area: ContainerTitle ]
    `}
`;

export const TextTitle = styled.h2`
    ${tw`
        h-10
        flex
        items-center
        text-20
        text-black
        font-bold
    `}
`;

export const ContainerIcon = styled.div<{ $left?: boolean }>`
    ${({ $left }) => {
        if ($left) {
            return tw`
            `;
        }
        return tw`
        `;
    }}
    ${tw`
        z-[2]
        cursor-pointer
        shrink-0
        flex
    `}
`;
