import tw, { styled } from 'twin.macro';

export const BackgroundImage = tw.div`
    h-[221px]
    bg-primary
    bg-center
    border-b-[5px]
    border-primary
    justify-center
    flex
    relative
`;
export const Overlay = styled.div`
    ${tw`
        h-full
        w-full
        backdrop-blur	
        absolute
    `}
    background-color: rgba(0, 0, 0, 0.5);
`;

export const ContainerLogo = tw.div`
    max-w-[242px]
    w-full
    h-[126px]
    bg-white
    rounded-b-[30px]
    flex
    justify-center
    items-center
    relative
`;

export const Logo = tw.img`
    // h-[100px]
    w-full
    h-auto
`;
