import tw, { styled } from 'twin.macro';
import { default as ModalRoot } from '../Modal';
export const Container = styled.div`
    ${tw`
        flex
        flex-col
        flex-1
        bg-primary
        p-[15px]
    `}
`;

export const Modal = styled(ModalRoot)`
    ${tw`
        w-full
        min-h-[400px]
        md:w-[70%] 
        md:h-[70%] 
        rounded-2xl 
        transition-all
        duration-300
        flex 
        bg-white  
        overflow-hidden
    `}
`;
