import tw, { styled } from 'twin.macro';
import { IconHeartOutline, IconOrder } from '../../../atoms';

export const Icon = styled(IconHeartOutline)`
    ${tw`
        text-[#9FBE43]
        w-[35px]
        h-[35px]
    `}
`;
