import { useState } from 'react';
import { LayoutFitter } from '../../../templates';
import { Container, Content, CrossIcon, TextMobile, TextTitle } from './styles';
import { useRouter } from 'next/router';
import { AnimatePresence } from 'framer-motion';

function AlertCnpjRegion() {
    const router = useRouter();

    const [isShowAlertRegion, setIsShowAlertRegion] = useState(true);

    if (router.pathname !== '/cadastrar') {
        return null;
    }

    return (
        <Container initial="hidden" animate="visible" exit="hidden">
            {isShowAlertRegion ? (
                <>
                    <AnimatePresence>
                        <LayoutFitter withoutPaddingMobile>
                            <Content>
                                <TextTitle onClick={() => setIsShowAlertRegion(false)}>
                                    <CrossIcon
                                        onClick={() => setIsShowAlertRegion(false)}
                                        style={{ float: 'right' }}
                                        className="cursor-pointer"
                                    />
                                    Estamos em fase de construção, por enquanto não atendemos CNPJ, mas estamos
                                    trabalhando para isso!<br></br>Conclua seu cadastro com o CPF e avisaremos por
                                    e-mail quando essa opção estiver disponível. Mas sua compra por CPF está
                                    liberada!&nbsp;
                                    <strong>MATCON.CASA</strong>&nbsp;
                                </TextTitle>
                                {/* <TextMobile onClick={onClick}>
                        Não identificamos a sua localização, isso pode influenciar&nbsp;
                        <strong>preços, estoques e frete</strong>
                    </TextMobile> */}
                            </Content>
                        </LayoutFitter>
                    </AnimatePresence>
                </>
            ) : null}
        </Container>
    );
}

export default AlertCnpjRegion;
