import tw, { styled } from 'twin.macro';
import { IconMapPoint, IconPerson, TextNunitoBold14, TextNunitoRegular12, TextNunitoRegular14 } from '../../../atoms';

export const Container = styled.div`
    ${tw`
        flex
        flex-row
        gap-2
        items-center
        p-4
        w-full
        bg-[#EFEFEF]
        md:cursor-pointer
        md:bg-white
        md:max-w-[180px]
        md:rounded-8
        md:p-2
        md:hover:bg-[#F9FBF3]
        md:focus:bg-[#F9FBF3]
    `}
`;

export const Icon = styled(IconMapPoint)`
    ${tw`
        stroke-[#424242]
        md:stroke-[#9FBE43]
        h-6
        w-6
    `}
`;

export const Title = styled(TextNunitoRegular14)`
    ${tw`
        md:text-12
        md:leading-[16.37px]
    `}
`;
export const Subtitle = styled.span`
    ${tw`
        font-semibold
        text-[#9FBE43]
        flex-nowrap
        flex 
        flex-col
        underline
        md:no-underline
        md:text-14
        md:font-bold
        md:text-black
        md:leading-[19.1px]
    `}
`;

export const TitleContainer = styled.div`
    ${tw`
        flex
        flex-row
        justify-between
        items-center
        w-full
        md:w-auto
        md:flex-col
        md:items-start
        md:justify-start
    `}
`;
