import { FC, useState } from 'react';
import { useAddress } from '../../../hooks';
import { AddressService } from '../../../services';
import { InputShadow } from '../../atoms';
import ModalPortal from '../../atoms/Modal-portal';
import { ModalAddress } from '../../templates';
import { TextTitle } from './styles';
import { ISelectAddressProps } from './types';

const SelectAddress: FC<ISelectAddressProps> = ({}) => {
    const [showModal, setShowModal] = useState(false);
    const { data } = useAddress();

    const handleCloseModal = () => setShowModal(false);

    const getTitle = () => {
        if (!data || !data.address) return null;

        if (data.address.prefix) return data.address.prefix;

        return AddressService.formatAddress(data.address);
    };

    return (
        <>
            <InputShadow onClick={() => setShowModal(true)}>
                <TextTitle>{getTitle()}</TextTitle>
            </InputShadow>
            <ModalPortal>{showModal && <ModalAddress onClose={handleCloseModal} />}</ModalPortal>
        </>
    );
};

export default SelectAddress;
