import { motion } from 'framer-motion';
import tw, { styled } from 'twin.macro';
import { Modal } from '../../atoms';

export const Border = styled(motion.div)<{ $fixed: boolean }>`
    ${tw`
    flex
    flex-col
    relative
    border
    border-primary
    rounded-[10px]
    overflow-hidden
    lg:w-[300px]
    `}
`;

export const FiltersButtonLabel = styled.span`
    ${tw`
    h-[40px]
    font-[800]
`}
`;

export const FiltersButton = styled(motion.div)`
    ${tw`
        text-white
        bg-primary
        py-[10px]
        px-[20px]
        cursor-pointer
        h-[45px]
    `}
`;

export const Content = styled(motion.div).attrs(() => ({
    variants: {
        open: {
            height: 'auto',
            opacity: 1
        },
        close: {
            height: 0,
            opacity: 0
        }
    }
}))`
    ${tw`

    absolute
    bg-white
    flex-col
    shadow-md
    inset-0
    overflow-hidden
    w-full
    lg:relative
    lg:rounded-b-[15px]
    lg:w-full
    `}
`;

export const FilterType = styled.div`
    ${tw`
    text-darkGray
    font-normal
    border-b-gray
    border-b-[1px]
    overflow-hidden
    last:border-b-[0px]
  `}
`;
export const FilterTypeLabel = styled.div`
    ${tw`
   flex
   justify-between
   items-center
   py-[10px]
   px-[8px]
  `}
`;

export const FilterConfigs = styled.div`
    &.show {
        ${tw`
  pt-[10px]
  pb-[5px]
  h-[auto]

  before:content-[""]
  before:w-[70%]
  before:h-[1px]
  before:bg-primary
  before:block
  before:m-auto
  before:mb-[10px]
`}
    }
    ${tw`
  text-darkGray
  font-normal
  border-b-gray
  h-[0]

  `}
`;

export const FilterContent = styled.div`
    ${tw`

  `}
`;

export const ApplyFiltersButton = styled.button`
    ${tw`
    bg-primary
    mx-auto
    my-[10px]
    py-[5px]
    px-[10px]
    rounded-10
    shadow-[0px 3px 4px 1px #0009]
    text-white
  `}
`;

export const FiltersListHeader = styled.div`
    ${tw`
   flex
   justify-between
   items-center
   px-[20px]
   bg-primary
   h-[40px]
   text-white
  `}
`;

export const FiltersHeaderLabel = styled.div`
    ${tw`
   flex
   justify-between
   items-center
  `}
`;

export const Backdrop = styled.div`
    ${tw`
    bg-lightGray
    bg-opacity-40
    h-[100vh]
    w-[100vw]
    absolute
    top-[0px]
    left-[0px]
  `}
`;

export const variants = {
    animate: {
        x: '0'
    },
    initial: {
        x: '-100%'
    },
    exit: {
        x: '-100%'
    }
};
export const ContainerModal = styled(Modal)`
    ${tw`
    flex
    flex-col
    rounded-none
    h-full
    max-h-full
    left-0
    max-w-modalForceSm
    `}
`;
