import { FC } from 'react';

import { OrderDetailsSectionHeader } from '../../molecules';
import OrderPaymentMethod, { IOrderPaymentMethodProps } from '../../molecules/Order-payment-method';
import { Container, Divider } from './styles';

type IOrderDetailsPaymentMethodsGroupProps = {
    paymentMethods: IOrderPaymentMethodProps[];
};

const OrderDetailsPaymentMethodsGroup: FC<IOrderDetailsPaymentMethodsGroupProps> = ({ paymentMethods }) => {
    return (
        <Container>
            <OrderDetailsSectionHeader title="Métodos de pagamento" />

            {paymentMethods.map((method, index) => (
                <>
                    <OrderPaymentMethod key={`${method.type}-${index.toString()}`} {...method} />
                    <Divider />
                </>
            ))}
        </Container>
    );
};

export default OrderDetailsPaymentMethodsGroup;
