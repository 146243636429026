import Link from 'next/link';
import { useCategories } from '../../../../hooks';
import { CategoryService, ProductService } from '../../../../services';
import { LayoutFitter } from '../../../templates';
import { Container, Content, List, Menu, Title } from './styles';

function FooterCategorySection() {
    const { data } = useCategories({ refetchOnMount: false });

    const categoriesActive = CategoryService.filterIsActiveCategories(data || []);

    const categoriesByLevel = data && CategoryService.getCategoriesByLevel(categoriesActive, 40);

    return (
        <Container>
            <LayoutFitter className="!px-7">
                <Content>
                    <Title>Todas as Categorias</Title>
                    <List>
                        {categoriesByLevel?.map((category) => (
                            <Link href={ProductService.getUrlSearchProduct(category.url)} key={category.id}>
                                <Menu>{category.name}</Menu>
                            </Link>
                        ))}
                    </List>
                </Content>
            </LayoutFitter>
        </Container>
    );
}

export default FooterCategorySection;
