import React, { FC } from 'react';
import { Container, Row as StyledRow, Cell, HeaderCell } from './styles';

interface IOrdersListSkeletonProps {}

const cellsMap = new Array(7).fill(null);

const Header = () => (
    <StyledRow>
        {cellsMap.map((_, i) => (
            <HeaderCell key={i} />
        ))}
    </StyledRow>
);

const Row = () => <Cell />;

const OrdersListSkeleton: FC<IOrdersListSkeletonProps> = ({}) => (
    <Container>
        <Header />
        <Row />
        <Row />
        <Row />
        <Row />
    </Container>
);

export default OrdersListSkeleton;
