import tw, { styled } from 'twin.macro';
import { TextNunitoRegular12, TextNunitoRegular14 } from '../../atoms';

export const Container = styled.div`
    ${tw`
        flex
        flex-col
        w-full
        justify-center
        p-4
        bg-[#9FBE43]
    `}
`;

export const Content = styled.div`
    ${tw`relative flex flex-row items-center justify-center gap-4 overflow-visible 
        
    
    
    `}
`;

export const Text = styled(TextNunitoRegular12)`
    ${tw`
        text-[#212226]
        text-center
        font-redHatDisplay
        font-bold

        md:text-14
        md:leading-[16px]
    `}
`;
