import { motion } from 'framer-motion';
import { ProductService } from '../../../services';
import { IProduct } from '../../../services/product/types';
import { currencyBrl } from '../../../utils/getFormattedValue';
import ProductOffer from './Product-offer';
import ProductShoppingList from './Shopping-list';
import {
    Container,
    Content,
    ContentContainer,
    ImageContainer,
    Img,
    Installments,
    Price,
    PriceFrom,
    Title
} from './styles';
import { capitalizeEveryLetter } from '../../../utils/string';
import { NEXT_PUBLIC_URL_IMAGES } from '../../../utils/envs';
import Link from 'next/link';
import ProductTags from '../Product-tags';

interface ProductItemHorizontalProps {
    data: IProduct;
    forceWidth?: boolean;
}

function ProductItemHorizontal({ data, forceWidth = false }: ProductItemHorizontalProps) {
    const percentDiscount = data.discount_percentage;

    return (
        <Link href={ProductService.getRouterProduct(data)} passHref>
            <Container initial="rest" whileHover="hover" animate="rest">
                <ProductTags data={data} direction="row" absolute={false} justFirst={true} />
                <ContentContainer>
                    <div className="relative">
                        <motion.div className="overflow-hidden rounded-8">
                            <ImageContainer
                                variants={{
                                    hover: {
                                        scale: 1.1
                                    }
                                }}
                            >
                                <Img
                                    data={data}
                                    fill
                                    sizes="auto"
                                    className="object-cover"
                                    forceHeight={true}
                                    quality={50}
                                    alt="product image"
                                />
                            </ImageContainer>
                        </motion.div>
                        {percentDiscount && percentDiscount > 0 && <ProductOffer value={percentDiscount} />}
                    </div>
                    <Content>
                        <Title title={capitalizeEveryLetter(data.name)}>{capitalizeEveryLetter(data.name)}</Title>
                        <div>
                            {data.special_price && data.special_price > 0 && (
                                <PriceFrom>de R$ {currencyBrl(data.special_price)}</PriceFrom>
                            )}

                            <Price>R$ {currencyBrl(ProductService.getValue(data, 1))}</Price>

                            {/* {data?.installments?.parcelas && (
                                <Installments>
                                    até {data.installments.parcelas}x R$ {currencyBrl(data.installments.valor)}
                                </Installments>
                            )} */}
                        </div>
                    </Content>
                </ContentContainer>
            </Container>
        </Link>
    );
}

export default ProductItemHorizontal;
