import tw, { styled } from 'twin.macro';
import { IconWifi, LogoFavIcon, TextLatoBold13, TextLatoRegular13 } from '../../atoms';

export const Container = styled.div`
    background: linear-gradient(90deg, #37353b 0%, #37353b 100%);
    ${tw`
    rounded-[20px]
    w-[305.5px]
    h-[188px]
    flex
    flex-row
    p-[15px]
    relative
    shadow-md
    `}
`;

export const Left = styled.div`
    ${tw`
        flex
        flex-col
    `}
`;

export const Header = styled.div`
    ${tw`
        flex
        flex-1
        flex-col
    `}
`;
export const Content = styled.div`
    ${tw`
        flex
        flex-[3]
        flex-col
    `}
`;

export const Chip = styled.div`
    ${tw`
        flex
        flex-row
        items-center
    `}
`;

export const Wifi = styled(IconWifi)`
    ${tw`
        w-[15.75px]
        h-[11.78px]
        fill-white
    `}
`;
export const CntrNumber = styled.div`
    ${tw`
        flex
        flex-col
        flex-1
        justify-end
    `}
`;
export const Number = styled(TextLatoBold13)`
    ${tw`
        text-white
    `}
`;
export const Name = styled(TextLatoRegular13)`
    ${tw`
        text-gray
    `}
`;
export const BgChip = styled.div`
    ${tw`
        bg-[url(/images/chip.png)]
        w-[45.68px]
        h-[35.34px]
        bg-center
        bg-cover
    `}
`;

export const Right = styled.div`
    ${tw`
        flex
        flex-col
        flex-1
        items-end
    `}
`;

export const Bar = styled.div`
    ${tw`
        absolute
        right-0
        top-[calc(50% - 16.5px)]
        bg-primary
        w-[7px]
        h-[33px]
    
    `}
`;

export const Icon = styled(LogoFavIcon)`
    ${tw`
        w-[66.75px]
        h-[89.53px]
    `}
`;

export const Flag = styled.div`
    ${tw`
        fill-white
    `}
`;
