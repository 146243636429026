import { ProductService } from '../../../../../../services';
import { IProduct } from '../../../../../../services/product/types';
import Tabs from '../../../../../organisms/Tabs';
import { Container, Description } from './styles';

interface ITabDescriptionProps {
    data: IProduct;
}

function TabDescription({ data }: ITabDescriptionProps) {
    const description = data ? (ProductService.getAttribute(data, 'description') as string) : '';

    return (
        <Tabs.Content>
            <Container>
                <Description
                    dangerouslySetInnerHTML={{
                        __html: description?.replace(/(\n|\r)/g, '<br />')
                    }}
                ></Description>
            </Container>
        </Tabs.Content>
    );
}

export default TabDescription;
