import tw, { styled } from 'twin.macro';
import { Button } from '../../molecules';

export const ContentContainer = styled.div`
    ${tw`
        flex
        flex-col
        mb-[20px]
        lg:min-h-[80vh]
`}
`;

export const ContainerTitle = styled.div`
    ${tw`
        text-[30px]
        font-[600]
        mt-[20px]
    `}
`;

export const ContentTitle = styled.div`
    ${tw`
        text-[20px]
        font-[400]
        mt-[10px]
`}
`;

export const ContentForm = styled.div`
    ${tw`
    w-full
    flex
    flex-col
`}
`;

export const ContentFormTitleContainer = styled.span`
    ${tw`
        flex
        justify-between
        border-b-[gray]
        border-b-[1px]
        mb-[20px]
    `}
`;

export const ContentFormTitle = styled.span`
    ${tw`
        flex
        mt-[10px]
        font-[600]
    `}
`;

export const ContentFormAction = styled.span`
    ${tw`
        flex
        mt-[10px]
        font-[600]
        text-center
        text-primary
        cursor-pointer
    `}
`;

export const FormFieldContainer = styled.div`
    ${tw`
    mt-[10px]
    pr-[50px]
    `}
`;

export const FormFieldLabel = styled.span`
    ${tw`
    text-[16px]
    font-[500]
    ml-[10px]
    `}
`;

export const FormFieldInput = styled.input`
    ${tw`
    text-[16px]
    font-[400]
    ml-[10px]
    outline-none
    min-w-[300px]
    `}
`;

export const NewsletterContainer = styled.div`
    ${tw`
        flex
        items-center
    `}
`;

export const CheckBox = styled.input`
    ${tw`
        cursor-pointer
        text-primary
        outline-none
        h-[17px]
        w-[17px]
    `}
`;

export const ConfirmChangesButton = styled(Button)`
    ${tw`
        mt-[50px]
    `}
`;

export const ButtonEmptySpace = styled.div`
    ${tw`
        h-[54px]
        mt-[50px]
    `}
`;

export const SuccessMessageContainer = styled.span`
    ${tw`
        h-[54px]
    `}
`;

export const ChangePasswordContainer = styled.div`
    ${tw`
        flex
        flex-col
        justify-center
        mt-[20px]
    `}
`;

export const ChangePasswordLabel = styled.div`
    ${tw`
        flex
        ml-[10px]

    `}
`;

export const ChangePasswordFields = styled.div`
    ${tw`
        flex
        flex-col
    `}
`;

export const ChangePasswordInput = styled.div`
    ${tw`
        flex
        mb-[20px]
    `}
`;

