import tw, { styled } from 'twin.macro';
import { TextLatoBold20, TextLatoRegular15, IconClose as IconCloseAtom } from '../../atoms';
import Modal from '../Modal';

export const DefaultModal = styled(Modal)`
    ${tw`
    w-10/12 
    rounded-2xl 
    bg-white 
    overflow-hidden 
    max-w-[95%] 
    max-h-[95%] 
    
    md:h-full
    
    xl:max-w-[1030px] 
    xl:h-auto
    `}
`;

export const IconClose = styled(IconCloseAtom)`
    ${tw`
        fill-primary
    `}
`;

export const Container = styled.div`
    ${tw`
        relative
        flex
        h-full
    `}
`;
export const Scroll = styled.div`
    ${tw`
    flex
    flex-row
    h-full
    p-[30px]
    overflow-auto
    `}
`;

export const ContainerImage = styled.div`
    ${tw`
        hidden
        md:flex
        w-[268px]
        md:h-full
        md:items-center
    `}
`;

export const Content = styled.div`
    ${tw`
        flex
        flex-col

        md:h-full
        md:overflow-y-auto
    `}
`;

export const TextTitle = styled(TextLatoBold20)`
    ${tw`
        text-primary
        mb-[20px]
    `}
`;
export const Text = styled(TextLatoRegular15)`
    ${tw`
        mb-[20px]
    `}
`;

export const ContainerClose = styled.div`
    ${tw`
        absolute
        top-[10px]
        right-[10px]
        cursor-pointer
    `}
`;
