import { FC } from 'react';
import { Border, Container, TextDescription, TextTitle } from './styles';
import { IErrorProps } from './types';

const Error: FC<IErrorProps> = ({ onPress, className }) => {
    return (
        <Container className={className}>
            <Border onClick={onPress}>
                <TextTitle>Ocorreu um error</TextTitle>
                <TextDescription>Não foi possivel carregar, Tente novamente</TextDescription>
            </Border>
        </Container>
    );
};

export default Error;
