import { LayoutFitter } from '../../../templates';
import { Container, Content, TextMobile, TextTitle } from './styles';

interface IAlertRegionProps {
    onClick: () => void;
}

function AlertRegion({ onClick }: IAlertRegionProps) {
    return (
        <Container initial="hidden" animate="visible" exit="hidden">
            <LayoutFitter withoutPaddingMobile>
                <Content>
                    <TextTitle onClick={onClick}>
                        A sua região ainda não é atendida, mas pode navegar à vontade pela loja&nbsp;
                        <strong>MATCON.CASA</strong>&nbsp;
                        <strong>- Para trocar sua localização, clique aqui</strong>
                    </TextTitle>
                    {/* <TextMobile onClick={onClick}>
                        Não identificamos a sua localização, isso pode influenciar&nbsp;
                        <strong>preços, estoques e frete</strong>
                    </TextMobile> */}
                </Content>
            </LayoutFitter>
        </Container>
    );
}

export default AlertRegion;
