import { motion } from 'framer-motion';
import tw, { styled } from 'twin.macro';

export const Container = styled(motion.div)`
    ${tw`
        flex
        flex-col
        gap-[10px]
    `}
`;
