import { FC } from 'react';
import { Container, TableHeaderRow } from './styles';

const TableHeader: FC<any> = ({ children }) => {
  return (
    <Container>
      <TableHeaderRow>{children}</TableHeaderRow>
    </Container>
  );
};

export default TableHeader;
