import { css } from 'styled-components';
import tw, { styled } from 'twin.macro';
import { TextLatoRegular13, TextNunitoRegular12 } from '../../atoms';
import { InputProps } from './Types-inputs/Text/types';
import { ContainerProps } from './types';

export const Container = styled.div<ContainerProps>`
    ${tw`relative flex flex-col w-full `}
`;

export const ContainerMsg = styled.div`
    ${tw`
    flex
    
        h-[16px]
    `}
`;

export const ContainerLabel = styled.div`
    ${tw`flex pb-2 `}
`;

export const Label = styled(TextNunitoRegular12)`
    ${tw`text-black line-clamp-1 font-nunito`}
`;
export const Required = styled(TextLatoRegular13)`
    ${tw` text-[#EF4123]`}
`;

export const cssDefaultInput = css<InputProps>`
    ${({ $isError }) => ($isError ? tw`border-[#EF4123]` : tw`border-[#DBDBDB]`)}
    ${tw`
        border-[0.5px]
        rounded-30
        h-14
        px-4
        bg-[#F6F6F6]
    `}
`;
