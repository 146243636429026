export default function SecondaryProductSkeleton() {
    return (
        <div className="flex flex-row px-4 gap-2 py-6 relative">
            <div className="w-[80px] h-[80px] shrink-0 bg-gray animate-pulse rounded-md" />
            <div className="flex flex-col gap-2 w-full">
                <div className="w-full h-3 bg-gray rounded-md animate-pulse"></div>
                <div className="w-3/4 h-3 bg-gray rounded-md animate-pulse"></div>
                <div className="h-[24px]  bg-gray rounded-md animate-pulse w-[100px]"></div>
            </div>
            <div className="flex items-center">
                <div className="w-[24px] h-[24px] bg-gray rounded-md animate-pulse"></div>
            </div>
        </div>
    );
}
