import { forwardRef, HTMLAttributes, Ref } from 'react';
import { Container } from './styles';

interface ICarouselArrowProps extends Partial<HTMLAttributes<HTMLDivElement>> {
    left?: boolean;
}

const CarouselArrow = forwardRef(({ onClick, left = false, ...rest }: ICarouselArrowProps, ref: Ref<any>) => {
    return (
        <Container left={left} ref={ref} {...rest}>
            {left ? (
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="36" viewBox="0 0 20 36" fill="none">
                    <path
                        d="M18 2L2 18.2462L18 34"
                        stroke="#9FBE43"
                        strokeWidth="4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            ) : (
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="36" viewBox="0 0 20 36" fill="none">
                    <path
                        d="M2 2L18 18.2462L2 34"
                        stroke="#9FBE43"
                        strokeWidth="4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            )}
        </Container>
    );
});

export default CarouselArrow;
