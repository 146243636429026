import { FC, SVGProps } from 'react';

const IconShop: FC<SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M1.989 11.5v8.434a.41.41 0 10.82 0V11.5a2.348 2.348 0 001.523-.985 2.343 2.343 0 003.867 0 2.343 2.343 0 003.867 0 2.343 2.343 0 003.867 0 2.343 2.343 0 003.867 0 2.343 2.343 0 003.867 0c.35.509.893.873 1.523.985v1.542a.41.41 0 00.82 0v-1.542a2.347 2.347 0 001.935-2.307v-1.05h-.001a.409.409 0 00-.044-.185L24.285.829A1.508 1.508 0 0022.934 0H9.919 5.066c-.574 0-1.092.318-1.351.83L.1 7.959a.41.41 0 00-.044.185v1.05c0 1.152.836 2.112 1.933 2.307zm.41-.783c-.84 0-1.523-.683-1.523-1.523v-.64h3.046v.64c0 .84-.683 1.523-1.523 1.523zM9.4.82l-1.53 6.914H4.917L7.437.82H9.4zm2.83 0l-.544 6.914H8.71L10.241.82h1.99zm2.716 0l.543 6.914h-2.98L13.053.82h1.894zm2.812 0l1.53 6.914h-2.976L15.77.82h1.989zm2.804 0l2.519 6.914H20.13L18.599.82h1.964zM6.266 10.717c-.84 0-1.523-.683-1.523-1.523v-.64h3.046v.64c0 .84-.683 1.523-1.523 1.523zm3.867 0c-.84 0-1.523-.683-1.523-1.523v-.64h3.046v.64c0 .84-.683 1.523-1.523 1.523zm3.867 0c-.84 0-1.523-.683-1.523-1.523v-.64h3.046v.64c0 .84-.683 1.523-1.523 1.523zm3.867 0c-.84 0-1.523-.683-1.523-1.523v-.64h3.046v.64c0 .84-.683 1.523-1.523 1.523zm3.867 0c-.84 0-1.523-.683-1.523-1.523v-.64h3.046v.64c0 .84-.683 1.523-1.523 1.523zm3.867 0c-.84 0-1.523-.683-1.523-1.523v-.64h3.046v.64c0 .84-.683 1.523-1.523 1.523zm-2.048-9.516l3.314 6.533h-2.912L21.436.82h1.498c.263 0 .5.146.62.38zm-19.106 0a.691.691 0 01.62-.38h1.497L4.045 7.733H1.133l3.314-6.533z" />
            <path d="M12.564 13.81a.41.41 0 00-.41.41v9.503c0 .226.183.41.41.41h11.27a.41.41 0 00.41-.41V14.22a.41.41 0 00-.41-.41h-11.27zm.41.821h10.45v6.914h-10.45V14.63zm10.45 8.682h-10.45v-.948h10.45v.948zM9.028 18.286a.41.41 0 00-.41.41v2.21a.41.41 0 10.82 0v-2.21a.41.41 0 00-.41-.41z" />
            <path d="M27.038 25.412H26.01V14.957a.41.41 0 10-.82 0v10.455H11.206V14.22a.41.41 0 00-.41-.41h-6.63a.41.41 0 00-.41.41v11.19H2.81v-3.563a.41.41 0 10-.82 0v3.564H.963a.964.964 0 00-.963.963v.663c0 .53.432.962.963.962h21.25a.41.41 0 100-.82H.963a.142.142 0 01-.143-.142v-.663c0-.08.064-.143.143-.143h26.075c.078 0 .142.064.142.143v.663a.142.142 0 01-.142.142h-2.91a.41.41 0 100 .82h2.91c.53 0 .962-.432.962-.962v-.663a.964.964 0 00-.962-.963zM4.577 14.632h5.809v10.78h-5.81v-10.78z" />
            <path d="M20.979 16.77l-1.879-.774a.41.41 0 00-.467.113l-.54.63h-.861a2.07 2.07 0 00-2.068 2.067v.995c0 .226.184.41.41.41h5.249a.41.41 0 00.41-.41v-2.652a.41.41 0 00-.254-.38zm-.567 2.62h-4.427v-.584c0-.688.56-1.247 1.247-1.247h1.05a.41.41 0 00.31-.143l.47-.548 1.35.556v1.966z" />
        </svg>
    );
};

export default IconShop;
