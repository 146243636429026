export const BANNER_SEARCH_ZIPCODE = 'banner-search-zipcode';
export const ESTABLISHMENT_SELECT_DELIVERY_LIST = 'establishment-select-delivery-list';
export const USER_INFO = 'user-info';
export const USER_TOKEN = 'user-token';
export const USER_LOGGED = 'user-logged';
export const CATEGORIES = 'categories';
export const BRANDS = 'brands';
export const MAX_HOUR_TO_DELIVERY = 'max-hour-to-delivery';
export const FLAVORS = 'flavors';
export const HALL = 'hall';
export const BANNER = 'banner';
export const CART = 'cart';
export const CART_INSTALLMENTS = 'cart-installments';
export const CART_ITEMS = 'cart-items';
export const CARDS = 'cards';
export const CATEGORY = 'category';
export const SEARCH_MODE_LIST = 'serach-mode-list';
export const BLOCK = 'block';
export const BLOCK_FOOTER_DELIVERY = 'block-footer-delivery';
export const BLOCK_HALL_CATEGORY = 'block-hall-category';
export const BLOCK_MY_ACCOUNT_BANNER = 'block-my-account-banner';
export const BLOCK_HALL_CATEGORY_VERTICAL = 'block-hall-category-vertical';
export const ADDRESS = 'address';
export const STATES = 'states';
export const ORDER = 'order'; // pedidos
export const ORDERS = 'orders'; // pedidos
export const ESTABLISHMENT = 'establishment';
export const HALL_LAST_ORDER = 'hall-last-order';
export const WISHLIST_FAVORITE = 'wishlist-favorite';
export const WISHLIST = 'wishlist';
export const PRODUCT = 'product';
export const PRODUCT_URL_KEY = 'product-url-key';
export const PRODUCT_ESTIMATE_SHIPPING = 'product-estimate-shipping';
export const BLOCK_CONFIG = 'block-config';
export const BLOCK_AISLES = 'block-aisles';
export const BLOCK_FEATURED_CATEGORY = 'block-featured-category';
export const DELIVERY_DATE = 'delivery-date'; //datas disponíveis para entrega;
export const SEARCH_BAR = 'search-bar';
export const PAYMENTS = 'payments';
export const UP_SELL_PRODUCTS = 'up-sell-products';
