import tw, { styled } from 'twin.macro';

export const Container = styled.div`
    ${tw`
        flex
        flex-row
        gap-[15px]
        animate-pulse

    `}
`;

export const Item = styled.div`
    ${tw`
        border-gray
        border
        rounded-15
        flex
        flex-col
        p-[15px]
        gap-[10px]
        justify-center
        items-center
        w-full
        cursor-pointer
    `}
`;
export const TextItem = styled.div`
    ${tw`
  w-[80%]
  max-w-[85px]
  h-[15px]
  bg-gray
  rounded-5
`}
`;
export const IconDelivery = styled.div`
    ${tw`
  w-[24px]
  h-[24px]
  bg-gray
  rounded-5
`}
`;
