import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import axios, { AxiosRequestConfig, Canceler } from 'axios';
import { useRef } from 'react';

declare type QueryFunction<TData, TVariables> = (
    paramsAxios: AxiosRequestConfig,
    context: TVariables
) => TData | Promise<TData>;

export function useMutationCancelToken<TData = unknown, TError = unknown, TVariables = void, TContext = unknown>(
    mutationFn: QueryFunction<TData, TVariables>,
    options?: UseMutationOptions<TData, TError, TVariables, TContext>
) {
    const cancelToken = useRef<Canceler>();

    const callback = async (context: any): Promise<unknown> => {
        try {
            if (cancelToken.current) cancelToken.current();

            const params: AxiosRequestConfig = {
                cancelToken: new axios.CancelToken((c) => {
                    cancelToken.current = c;
                })
            };
            return await mutationFn(params, context);
        } catch (e) {
            if (!axios.isCancel(e)) {
                throw e;
            }
        }
    };

    return useMutation((context) => {
        return callback(context) as any;
    }, options);
}
