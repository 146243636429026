import React, { FC, SVGProps } from 'react';

const IconPets: FC<SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg viewBox="0 0 26 28" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M16.188 14.388a.547.547 0 100 1.094.547.547 0 000-1.094z" />
            <path d="M21.067 17.332c-.513-.512-1.756-1.899-2.737-4.148a4.669 4.669 0 00-4.322-2.88 4.669 4.669 0 00-4.321 2.88c-1.23 2.823-2.911 4.317-2.928 4.33a4.69 4.69 0 00-1.566 3.494c0 1.626.774 3.04 2.117 3.935a5.188 5.188 0 004.254.687c.728-.2 1.57-.305 2.436-.305.88 0 1.736.11 2.472.315a5.155 5.155 0 004.234-.665c1.358-.898 2.146-2.325 2.146-3.966 0-1.444-.65-2.782-1.785-3.677zm-.964 6.73a4.063 4.063 0 01-3.336.525A10.438 10.438 0 0014 24.232c-.962 0-1.905.118-2.725.343a4.095 4.095 0 01-3.358-.541c-1.03-.688-1.63-1.763-1.63-3.025 0-1.018.435-1.992 1.194-2.672.117-.103 1.891-1.692 3.21-4.72l.004-.008a3.578 3.578 0 013.313-2.211c1.455 0 2.756.867 3.314 2.21l.004.009c1.07 2.458 2.45 3.976 2.993 4.514a.55.55 0 00.05.044 3.567 3.567 0 011.389 2.834c0 1.272-.609 2.362-1.655 3.053z" />
            <path d="M17.677 16.565a.547.547 0 10-.916.597c.832 1.277 1.62 2.129 2.017 2.522a.547.547 0 00.77-.776c-.365-.363-1.094-1.15-1.87-2.343zM12.228 7.638c.525-.919.744-2.139.6-3.347-.277-2.352-1.9-4.323-3.712-4.105-.818.097-1.546.634-2.05 1.513-.524.917-.743 2.137-.6 3.346.453 3.842 4.03 5.618 5.762 2.593zM8.016 2.242c.3-.523.767-.978 1.368-.978 1.077 0 2.157 1.445 2.359 3.156.116.98-.053 1.955-.465 2.674-1.26 2.202-3.428.346-3.726-2.177-.116-.981.053-1.956.464-2.675zM6.989 13.52c.158-.975-.035-2.053-.542-3.036-.508-.983-1.276-1.763-2.163-2.198-.941-.462-1.906-.486-2.715-.067-.81.418-1.348 1.218-1.516 2.253-.158.975.034 2.053.542 3.036.508.982 1.276 1.763 2.162 2.198.88.431 1.855.512 2.716.067.81-.418 1.347-1.218 1.516-2.253zM4.97 14.802c-.494.255-1.109.227-1.732-.078-.677-.333-1.271-.943-1.672-1.719-.401-.775-.555-1.613-.434-2.358.111-.684.444-1.202.938-1.457.546-.282 1.179-.193 1.732.078.677.332 1.271.942 1.672 1.718.4.776.555 1.614.434 2.358-.111.685-.444 1.202-.938 1.458zM18.101 9.177c1.658 0 3.172-1.81 3.446-4.121.29-2.462-.95-4.875-2.92-4.875-1.658 0-3.17 1.81-3.444 4.12-.292 2.467.952 4.876 2.918 4.876zM16.27 4.43c.202-1.71 1.283-3.155 2.359-3.155.05 0 .094.002.133.007.583.065 1.035.575 1.3 1.095.362.708.508 1.637.4 2.55-.21 1.772-1.385 3.292-2.493 3.149l-.006-.001c-.497-.059-.969-.457-1.294-1.094-.362-.708-.507-1.637-.4-2.55zM27.946 10.474c-.346-2.13-2.271-3.148-4.231-2.186-1.834.9-3.032 3.217-2.704 5.234.35 2.155 2.294 3.136 4.23 2.186 1.835-.9 3.033-3.217 2.705-5.234zm-3.186 4.252c-.623.305-1.238.333-1.731.078-.494-.255-.828-.773-.939-1.457-.25-1.54.702-3.388 2.107-4.077 1.286-.631 2.44-.037 2.67 1.38.249 1.537-.7 3.386-2.107 4.076z" />
        </svg>
    );
};

export default IconPets;
