import { useState } from 'react';
import { useEstablishment } from '../../../../../hooks/use-establishment';
import { IProduct } from '../../../../../services/product/types';
import { IconTruck } from '../../../../atoms';
import IconCircleCheck from '../../../../atoms/icons/Circle-check';
import ModalPortal from '../../../../atoms/Modal-portal';
import ModalChangeRegion from '../../../Modal-change-region';
import Shipping from './Shipping';
import { ChangeZipCode, Column, Container, Content } from './styles';

interface IZipCodeProps {
    showShippingMethod?: boolean;
    data?: IProduct;
}

function ZipCode({ showShippingMethod = false, data }: IZipCodeProps) {
    const { data: establishment } = useEstablishment();
    const [isOpenChangeRegionModal, setIsOpenChangeRegionModal] = useState(false);

    return (
        <>
            <Container>
                <Content>
                    <div className="flex flex-row gap-2">
                        <Column>
                            <IconTruck stroke="#9FBE43" />
                            <p className="text-12 font-medium">Opções de entrega para:</p>
                        </Column>
                        <Column>
                            <IconCircleCheck />
                            <p className="text-12 font-light">CEP {establishment?.zipCode}</p>
                        </Column>
                    </div>
                    <ChangeZipCode type="button" onClick={() => setIsOpenChangeRegionModal(true)}>
                        Alterar CEP
                    </ChangeZipCode>
                </Content>
                {showShippingMethod && data && <Shipping data={data} />}
            </Container>
            <ModalPortal>
                {isOpenChangeRegionModal && <ModalChangeRegion onClose={() => setIsOpenChangeRegionModal(false)} />}
            </ModalPortal>
        </>
    );
}

export default ZipCode;
