import { FC, useRef, useState } from 'react';
import { Button } from '../../molecules';
import { ICenter, IMapHandle } from '../Map/types';
import { Container, Map } from './styles';

interface ModalEstablishmentStepMapProps {
    center: ICenter;
    onCheckCoverage: (center: ICenter) => void;
}

const ModalEstablishmentStepMap: FC<ModalEstablishmentStepMapProps> = ({ center, onCheckCoverage }) => {
    const [isLoading, setisLoading] = useState(false);
    const refMap = useRef<IMapHandle>(null);

    const handlePress = () => {
        setisLoading(true);
        const center = refMap.current?.getCenter();

        if (center) onCheckCoverage(center);
    };
    return (
        <Container>
            <Map center={center} ref={refMap} />
            <Button text="Continuar" isLoading={isLoading} onClick={handlePress} />
        </Container>
    );
};

export default ModalEstablishmentStepMap;
