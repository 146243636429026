import qs from 'qs';
import { ICookieCtx } from '../../utils/cookie';
import { mergeDeep } from '../../utils/merge-deep';

import { apiAdmin, apiNext } from '../api';
import { ConditionType, PaginationList } from '../magento/types';
import { Order, OrderTracking, STATUS_CANCELED, Status, StatusTimeline, TrackingStatus } from './types';

export const statusRelated: Partial<Record<Status, StatusTimeline>> = {
    [Status.PEDIDO_RECEBIDO]: StatusTimeline.PENDING,
    [Status.PAGAMENTO_PENDENTE]: StatusTimeline.PENDING,
    [Status.PEDIDO_PAGO]: StatusTimeline.PICKING,
    [Status.PEDIDO_EM_PROCESSAMENTO]: StatusTimeline.PICKING,
    [Status.PEDIDO_EM_SEPARACAO]: StatusTimeline.PICKING,
    [Status.PEDIDO_EM_ESPERA]: StatusTimeline.PICKING,
    [Status.PEDIDO_SEPARADO]: StatusTimeline.PICKING,
    [Status.CUPOM_FISCAL_EMITIDO]: StatusTimeline.BILLED,
    [Status.PEDIDO_LIBERADO]: StatusTimeline.DISPATCHED,
    [Status.PEDIDO_ENTREGUE]: StatusTimeline.DELIVERED,
    [Status.NEW]: StatusTimeline.PENDING,
    [Status.PEDIDO_NOVO]: StatusTimeline.PENDING,
    [Status.PENDING_PAYMENT]: StatusTimeline.PENDING,
    [Status.HOLDED]: StatusTimeline.PICKING,
    [Status.PEDIDO_EXPEDIDO]: StatusTimeline.DISPATCHED,
    [Status.INVOICED]: StatusTimeline.BILLED,
    [Status.COMPLETE]: StatusTimeline.DELIVERED,
    [Status.CLOSED]: StatusTimeline.DELIVERED
};

export const allStatus: TrackingStatus[] = [
    {
        title: 'Pedido recebido',
        key: StatusTimeline.PENDING
    },
    {
        title: 'Pedido pago',
        key: StatusTimeline.PICKING
    },
    {
        title: 'Pedido faturado',
        key: StatusTimeline.BILLED
    },
    {
        title: 'Pedido despachado',
        key: StatusTimeline.DISPATCHED
    },
    {
        title: 'Pedido entregue',
        key: StatusTimeline.DELIVERED
    }
];

const getAllAdmin = async (
    params: { [key: string]: any } = {},
    customerId?: string,
    options?: ICookieCtx
): Promise<PaginationList<Order>> => {
    const paramsGet = mergeDeep(
        {
            searchCriteria: {
                filter_groups: [
                    { filters: [{ field: 'customer_id', value: customerId, conditionType: ConditionType.EQ }] }
                ]
            }
        },
        params
    );

    const { data } = await apiAdmin.get('V1/orders', {
        paramsSerializer: (p) => {
            return qs.stringify(p, { arrayFormat: 'indices' });
        },
        params: paramsGet
    });

    return data;
};
const getAll = async (params?: { [key: string]: unknown }): Promise<PaginationList<Order>> => {
    const { data } = await apiNext.get('order?a=' + Date.now(), {
        paramsSerializer: (p) => {
            return qs.stringify(p, { arrayFormat: 'indices' });
        },
        ...params
    });

    return data;
};

const getById = async (id: number, params?: { [key: string]: unknown }): Promise<Order> => {
    const { data, headers } = await apiNext.get<Order>(`order/${id}`, {
        paramsSerializer: (p) => {
            return qs.stringify(p, { arrayFormat: 'indices' });
        },
        ...params
    });

    const date = new Date(headers['date']);
    data.datetime = date.getTime();
    return data;
};

const getByIdAdmin = async (id: number, params?: { [key: string]: unknown }): Promise<Order> => {
    const { data } = await apiAdmin.get<Order>(`V1/orders/${id}`, {
        paramsSerializer: (p) => {
            return qs.stringify(p, { arrayFormat: 'indices' });
        },
        ...params
    });

    return data;
};

const getOrderByAdmin = async (id: number): Promise<Order> => {
    const { data } = await apiAdmin.get<Order>(`V1/orders/${id}`);

    return data;
};

const getStatusTitleCurrent = (order: Order) => {
    const status = getStatus(order).find((v) => v.actualStatus);

    if (status?.canceled) return 'Cancelado';

    return status?.title;
};

const getStatus = ({ status_histories, status }: Order): OrderTracking[] => {
    const [lastStatus] = status_histories.filter((s) => s.status && !STATUS_CANCELED.includes(s.status));
    const canceled = STATUS_CANCELED.includes(status);

    const statusHistoriesRelated = status_histories.map((sh) => {
        return {
            ...sh,
            key: statusRelated[sh.status] || StatusTimeline.PENDING
        };
    });

    const related = statusRelated[lastStatus?.status] || StatusTimeline.PENDING;
    const allStatusIndexActive = allStatus.findIndex((a) => a.key == related);
    return allStatus.map((s, i) => {
        const history = statusHistoriesRelated.find((sh) => sh.key == s.key);
        return {
            ...s,
            active: i <= allStatusIndexActive,
            canceled: i == allStatusIndexActive && canceled,
            date: history?.created_at,
            actualStatus: allStatusIndexActive === i
        };
    });
};

export const getUniqueStatus = (status: string): string => {
    const related = statusRelated[status as keyof typeof statusRelated] || StatusTimeline.PENDING;
    const allStatusIndexActive = allStatus.find((a) => a.key == related);

    return allStatusIndexActive?.title || status;
};

const cancelOrderAdmin = async (id: number) => {
    const { data } = await apiAdmin.post(
        `V1/orders/${id}/cancel`,
        {},
        {
            paramsSerializer: (p) => {
                return qs.stringify(p, { arrayFormat: 'indices' });
            }
        }
    );

    return data;
};

const cancelOrder = async (id: number) => {
    const { data } = await apiNext.post(
        `order/${id}/cancel`,
        {},
        {
            paramsSerializer: (p) => {
                return qs.stringify(p, { arrayFormat: 'indices' });
            }
        }
    );

    return data;
};

export default {
    getAll,
    getAllAdmin,
    getStatus,
    getById,
    getByIdAdmin,
    getStatusTitleCurrent,
    cancelOrderAdmin,
    cancelOrder,
    getOrderByAdmin,
    getUniqueStatus
};
