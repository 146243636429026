import { FC, ReactElement } from 'react';
import MyAccountMenu from '../My-account-menu';
import { Container, MenuWrapper, ContentWrapper, ContentTitle, TitleBar, Button } from './styles';

export interface IMyAccountLayoutProps {
    title: string | ReactElement<unknown>;
    children: React.ReactNode;
    actionButton?: { label: string; callback: () => void, isLoading: boolean };
}

const MyAccountLayout: FC<IMyAccountLayoutProps> = ({ title, children, actionButton }) => {
    return (
        <Container>
            <MenuWrapper>
                <MyAccountMenu />
            </MenuWrapper>
            <ContentWrapper>
                <TitleBar>
                    {typeof title === 'string' ? <ContentTitle>{title}</ContentTitle> : title}
                    {actionButton && <Button isLoading={actionButton.isLoading} text={actionButton.label} onClick={actionButton.callback} />}
                </TitleBar>
                {children}
            </ContentWrapper>
        </Container>
    );
};

export default MyAccountLayout;
