import tw, { styled } from 'twin.macro';
import { TextLatoBold15 } from '../../atoms';

export const Container = styled.div`
    ${tw`
        flex
        flex-col
        gap-[15px]
    `}
`;
export const Item = styled.div`
    ${tw`
        flex
        flex-row
        flex-nowrap
        items-center
    `}
`;
export const ContentFlag = styled.div`
    ${tw`
        flex
        flex-col
        items-center
        justify-center
        w-[47px]
    `}
`;
export const Content = styled.div`
    ${tw`
        flex
        flex-col
        justify-center
        ml-[10px]
    `}
`;

export const TextTitle = styled(TextLatoBold15)`
    ${tw`
      
        ml-[10px]
    `}
`;
