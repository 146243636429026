import { FC, memo, MouseEvent, useEffect, useState } from 'react';
import { IS_BROWSER } from '../../../services/query-client';
import { getIsBreakPoint } from '../../../utils/get-config-tailwind';
import Backdrop from '../Backdrop';
import { Container, Root, variantsMd, variantsRight, variantsSm } from './styles';
import { IModalProps } from './types';

const Modal: FC<IModalProps> = ({
    onClose,
    children,
    enableClose = true,
    animateFromRight = false,
    enableScroll = false,
    ...rest
}) => {
    const [name] = useState(new Date().getTime().toString());
    const isMd = getIsBreakPoint('md');

    const handleClickBackdrop = (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.preventDefault();
        if (enableClose && onClose) {
            onClose(e);
        }
    };
    useEffect(() => {
        if (IS_BROWSER && !enableScroll) {
            const html = document.getElementsByTagName('body')[0];
            if (html) {
                html.style.overflow = 'hidden';
                if (window.innerWidth > 1024) {
                    html.style.paddingRight = '8px';
                }
            }
        }
    }, []);

    function getVariant() {
        if (animateFromRight && isMd) {
            return variantsRight;
        }
        if (isMd) {
            return variantsMd;
        }
        return variantsSm;
    }

    return (
        <Root className="modal" layout>
            <Backdrop onClick={handleClickBackdrop} className="z-20" />
            <Container initial="initial" animate="animate" exit="exit" variants={getVariant()} {...rest}>
                {children}
            </Container>
        </Root>
    );
};

export default memo(Modal);
