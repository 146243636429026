import React, { FC } from 'react';
import { PageBlock } from '../../organisms';
import Layout from '../Layout';
import LayoutFitter from '../Layout-fitter';
import { Container } from './styles';

const NEXT_PUBLIC_PAGE_TROCAS_E_DEVOLUCOES_SITE = process.env.NEXT_PUBLIC_PAGE_TROCAS_E_DEVOLUCOES_SITE || 'site-page-trocas-e-devolucoes';

interface ITrocasEDevolucoes {}

const TrocasEDevolucoes: FC<ITrocasEDevolucoes> = ({}) => {
    return (
        <Layout>
            <LayoutFitter>
                <Container>
                    <PageBlock id={NEXT_PUBLIC_PAGE_TROCAS_E_DEVOLUCOES_SITE} />
                </Container>
            </LayoutFitter>
        </Layout>
    );
};

export default TrocasEDevolucoes;
