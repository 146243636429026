import { SVGProps } from 'react';

const IconBasketPack = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M15.9996 26.6711H6.66241C5.18846 26.6711 3.99463 25.4773 3.99463 24.0034V9.99754C3.99463 8.89308 4.891 7.9967 5.99546 7.9967H23.336C24.4405 7.9967 25.3368 8.89308 25.3368 9.99754V14.6661"
                stroke="#9FBE43"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.33008 7.99669V7.66322V7.66322C9.33008 4.90073 11.5697 2.66113 14.3322 2.66113H14.9991C17.7616 2.66113 20.0012 4.90073 20.0012 7.66322V7.66322V7.99669"
                stroke="#9FBE43"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19.4144 19.3468H28.0046L27.0655 24.0447H20.3548L19.0115 17.3339H17.3335"
                stroke="#9FBE43"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M26.4413 27.222C26.468 27.2487 26.468 27.29 26.4413 27.3167C26.4147 27.3434 26.3733 27.3434 26.3466 27.3167C26.32 27.29 26.32 27.2487 26.3466 27.222C26.372 27.1953 26.416 27.1953 26.4413 27.222"
                stroke="#9FBE43"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M21.0722 27.222C21.0989 27.2487 21.0989 27.29 21.0722 27.3167C21.0455 27.3434 21.0042 27.3434 20.9775 27.3167C20.9508 27.29 20.9508 27.2487 20.9775 27.222C21.0028 27.1953 21.0455 27.1953 21.0722 27.222"
                stroke="#9FBE43"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default IconBasketPack;
