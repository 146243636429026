import tw, { styled } from 'twin.macro';
import { Button, ModalHeader } from '../../molecules';
import { Modal as ModalAtom } from '../../atoms';

export const Modal = styled(ModalAtom)`
    ${tw`
    md:max-w-modalForceSm
    flex
    flex-col
    h-[unset]
  `}
`;
export const Container = styled.div`
    ${tw`
      px-[20px]
      flex-1
      flex
      flex-col
      justify-center
      items-center
      overflow-auto
    `}
`;
export const Header = styled(ModalHeader)`
    ${tw`
      my-[10px]  
    `}
`;


export const ConfirmButton = styled(Button)`
    ${tw`
      m-[20px]
      mx-[20px]
    `}
`;