import tw, { styled } from 'twin.macro';

export const Container = styled.div`
    ${tw`
       flex
       flex-col
       items-center
       justify-center
       
       lg:max-w-[calc(1280px - 424px)]
       lg:flex-row
       lg:items-start
       lg:pl-[20px]
       lg:mb-[50px]
       lg:relative
  `}
`;