import { useQuery } from '@tanstack/react-query';
import Image from 'next/image';
import useOnClickBanner from '../../../../hooks/use-on-click-banner';
import { BannerService } from '../../../../services';
import { NEXT_PUBLIC_HOME_BANNER_PRODUCTS } from '../../../../utils/envs';
import { BANNER } from '../../../../utils/react-query-keys';
import { ProductItem } from '../../../molecules';
import LayoutFitter from '../../Layout-fitter';
import { BannerContainer, Container, Content, Products, Title } from './styles';

function HomeBannerProducts() {
    const banner = useOnClickBanner();

    const { data } = useQuery(
        [BANNER, NEXT_PUBLIC_HOME_BANNER_PRODUCTS],
        () => BannerService.getBannerWithProducts(NEXT_PUBLIC_HOME_BANNER_PRODUCTS),
        {
            refetchOnWindowFocus: false,
            refetchOnMount: false
        }
    );
    if (!data?.products?.length) return null;

    function handleClickBanner() {
        if (data) {
            banner({
                type: data.type,
                param: data.paramDestiny,
                image: '',
                skuHighlight: []
            });
        }
    }

    return (
        <LayoutFitter>
            <Container>
                <Title>{data.title}</Title>
                <Content>
                    <Products>
                        {data?.products?.map((product, i) => (
                            <div
                                className={`w-full  [a:w-full]${
                                    data.products && i == data?.products?.length - 1 ? 'flex md:hidden 2xl:flex' : ''
                                }`}
                                key={product.id}
                            >
                                <ProductItem data={product} />
                            </div>
                        ))}
                    </Products>
                    <BannerContainer
                        type="button"
                        onClick={() => {
                            handleClickBanner();
                        }}
                    >
                        <Image
                            alt="banner"
                            className=" object-contain md:hidden"
                            fill
                            sizes="auto"
                            src={data?.imageMobile as string}
                        />
                        <Image
                            alt="banner"
                            className="object-contain hidden md:block"
                            fill
                            sizes="auto"
                            src={data?.image as string}
                        />
                    </BannerContainer>
                </Content>
            </Container>
        </LayoutFitter>
    );
}

export default HomeBannerProducts;
