import { useCategories } from '../../../../../hooks';
import { CategoryService } from '../../../../../services';
import HallCategories from '../../../../organisms/Hall-categories';
import { useProductSearchFilters } from '../../hooks/useProductSearchFilters';
import { Container } from './styles';

interface ICategoryCarouselProps {
    className?: string;
}
function CategoryCarousel({ className }: ICategoryCarouselProps) {
    const { filters } = useProductSearchFilters();
    const { data: categories } = useCategories({ refetchOnMount: false });

    if (
        !categories?.length ||
        filters.categories.length === 0 ||
        filters.categories.length > 1 ||
        !CategoryService.isRootCategory(filters.categories?.[0], categories)
    ) {
        return null;
    }

    const category = CategoryService.getCategoryFromCategories(filters.categories[0], categories)?.children;

    if (!category) return null;

    return (
        <Container className={className}>
            <HallCategories noMargin categories={category} />
        </Container>
    );
}

export default CategoryCarousel;
