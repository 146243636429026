import { useEffect, useState } from 'react';
import { useInstallments } from '../../../../../../hooks/use-installments';
import { ProductService } from '../../../../../../services';
import { IProduct } from '../../../../../../services/product/types';
import { currencyBrl } from '../../../../../../utils/getFormattedValue';
import MainProduct from './MainProduct';
import FadeIn from '../../../../../atoms/Fade-in';
import { IButtonVariant } from '../../../../../molecules/Button/types';
import { Button } from '../../../../../molecules';
import { IconPlus } from '../../../../../atoms';
import MainProductSkeleton from './MainProduct/Skeleton';

interface UpSellDesktopProps {
    data: IProduct;
    products?: IProduct[];
    isLoadingProducts: boolean;
    isLoadingAddCart: boolean;
    onAdd: (products: IProduct[]) => void;
}

export default function UpSellDesktop({
    data,
    products,
    isLoadingProducts,
    isLoadingAddCart,
    onAdd
}: UpSellDesktopProps) {
    const [itemsChecked, setItemsChecked] = useState<IProduct[]>(products || []);

    useEffect(() => {
        setItemsChecked(products || []);
    }, [products]);

    const handleCheck = (checked: boolean, product: IProduct) => {
        setItemsChecked(
            checked ? [...itemsChecked, product] : itemsChecked.filter((idItem) => idItem.id !== product.id)
        );
    };

    const getSumValues = () => {
        return itemsChecked?.reduce(
            (acc, item) => {
                acc.special_price += item.special_price || 0;
                acc.price += ProductService.getValue(item, 1);
                return acc;
            },
            { special_price: 0, price: 0 }
        );
    };

    const { data: installments, isLoading: isLoadingInstallments } = useInstallments(getSumValues().price);

    const values = getSumValues();
    const lastInstallment = installments?.[installments.length - 1];

    const getInstallments = () => {
        if (isLoadingInstallments) return <div className="w-2/4 h-3.5 bg-gray rounded-md animate-pulse" />;

        if (installments) {
            return (
                <p className="text-14 text-medium font-normal ">
                    R$ {currencyBrl(Number(lastInstallment))}{' '}
                    <span className="font-light">em até {installments.length}x sem Juros</span>
                </p>
            );
        }
        return null;
    };

    return (
        <div className="hidden flex-row p-4 mt-6 bg-white border-[0.5px] border-gray100 gap-4 rounded-8 md:flex items-center shrink-0">
            <h3 className="text-32 font-bold text-black w-[120px]">Compre Junto</h3>
            <div className="flex flex-row items-center overflow-x-auto scrollbar-thin w-full">
                <div className="flex flex-row ">
                    <MainProduct data={data} isPrimary />
                    <div className="flex items-center justify-center relative w-[40px]">
                        <div className="w-[1px] bg-[#F0F0F0] h-full" />
                        <div className="absolute left-0">
                            <IconPlus className="w-[40px] h-[40px] fill-primary" />
                        </div>
                    </div>
                </div>
                <div className="flex flex-row gap-4">
                    {isLoadingProducts ? (
                        <>
                            <MainProductSkeleton />
                            <MainProductSkeleton />
                        </>
                    ) : (
                        products?.map((product) => (
                            <MainProduct
                                key={product.id}
                                isChecked={itemsChecked.find((item) => item.id === product.id) ? true : false}
                                data={product}
                                onCheck={handleCheck}
                            />
                        ))
                    )}
                </div>
                <FadeIn
                    className="ml-8 flex flex-col gap-4 w-full max-w-[288px] shrink-0"
                    show={itemsChecked.length > 0}
                >
                    {itemsChecked.length > 0 && (
                        <div>
                            {values.special_price > 0 && (
                                <p className="text-12 text-black font-light line-through ">
                                    de R$ {currencyBrl(values.special_price)}
                                </p>
                            )}
                            <p className="text-32 text-black font-bold leading-[48px]">
                                R$ {currencyBrl(values.price)}
                            </p>
                            {getInstallments()}
                        </div>
                    )}
                    <Button
                        variant={IButtonVariant.PRIMARY_MEDIUM}
                        disabled={itemsChecked.length === 0}
                        text="Comprar"
                        isLoading={isLoadingAddCart}
                        onClick={() => onAdd(itemsChecked)}
                    />
                </FadeIn>
            </div>
        </div>
    );
}
