import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AuthService } from '../../services';
import { USER_LOGGED } from '../../utils/react-query-keys';

export const useUserLogged = (params?: UseQueryOptions<boolean>) => {
    return useQuery<boolean>([USER_LOGGED], () => AuthService.checkLogged(), {
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        ...params
    });
};
