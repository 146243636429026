import { useQuery } from '@tanstack/react-query';
import { useField } from 'formik';
import { FC, memo, useEffect, useRef } from 'react';
import { useAddress, useCart } from '../../../hooks';
import { CartService } from '../../../services';
import { CartShippingMethod } from '../../../services/cart/types';
import { currencyBrl } from '../../../utils/getFormattedValue';
import CheckoutSelectShippingMethodsSkeleton from '../Checkout-select-shipping-methods-skeleton';
import { Container, IconDelivery, IconDeliverySelected, Item, TextItem, TextItemSelected } from './styles';

interface ISelectCollapseShippingMethodProps {
    name: string;
}

const CheckoutSelectShippingMethod: FC<ISelectCollapseShippingMethodProps> = ({ name }) => {
    const refCupoum = useRef<string | undefined>(undefined);
    const [, { value }, { setValue }] = useField<CartShippingMethod | null>(name);

    const { data: address } = useAddress();
    const { data: cart } = useCart();

    const { data, isLoading } = useQuery(['shipping', address, refCupoum.current, cart?.info.items_qty], async () => {
        if (address?.address) {
            return CartService.estimateShippingMethods(address?.address);
        }
        return null;
    });

    useEffect(() => {
        const subscribe = CartService.createSubscribe((data) => {
            if (data.info.coupon_code != refCupoum.current) {
                refCupoum.current = data.info.coupon_code;
            }
        });

        return () => {
            subscribe();
        };
    }, []);

    if (isLoading) return <CheckoutSelectShippingMethodsSkeleton />;

    const list = data?.sort((a, b) => a.amount - b.amount).slice(0, 2);
    return (
        <Container>
            {list?.map((item) => (
                <Item
                    key={item.carrier_code}
                    selected={value?.carrier_code == item.carrier_code}
                    onClick={() => setValue(item)}
                    whileTap={{ scale: 0.95 }}
                >
                    {value?.carrier_code == item.carrier_code ? (
                        <>
                            <IconDeliverySelected />
                            <TextItemSelected>
                                {item.carrier_title.replace('Entrega', '')}
                                <br />
                                R$ {currencyBrl(item?.amount)}
                                <br />
                                {item.method_title}
                            </TextItemSelected>
                        </>
                    ) : (
                        <>
                            <IconDelivery />
                            <TextItem>
                                {item.carrier_title.replace('Entrega', '')}
                                <br />
                                R$ {currencyBrl(item?.amount)}
                                <br />
                                {item.method_title}
                            </TextItem>
                        </>
                    )}
                </Item>
            ))}
        </Container>
    );
};

export default memo(CheckoutSelectShippingMethod);
