import tw, { styled } from 'twin.macro';
/* transition-all
        duration-300
        hover:scale-[105%]
        hover:z-10
        hover:shadow-lg */
const ZoomItem = styled.div`
    ${tw`
    transition-all
        duration-300
        hover:scale-[102%]
        hover:z-10
        cursor-pointer
    `}
`;

export default ZoomItem;
