import { FC } from 'react';
import tw, { styled } from 'twin.macro';
import { ProductService } from '../../../services';
import getFormattedValue from '../../../utils/getFormattedValue';
import TextLatoBold13 from '../text/Lato-bold-13';
import { IProductValueDiscountProps } from './types';

const Container = styled(TextLatoBold13)`
    ${tw`
        text-gray
         line-through
    `}
`;

const ProductValueDiscount: FC<IProductValueDiscountProps> = ({ data, uniqueId, ...props }) => {
    const value = ProductService.getValueBefore(data);
    const percent = data.discount_percentage;

    if (!value || !percent) return null;

    return (
        <Container {...props} layoutId={`${uniqueId}-discount`}>
            R$ {getFormattedValue({ value: value?.toString(), mask: 'currency' })}
        </Container>
    );
};

export default ProductValueDiscount;
