import { motion } from 'framer-motion';
import tw, { styled } from 'twin.macro';

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
    isChecked: boolean;
}

// export const Checkbox = styled.input.attrs<CheckboxProps>(({ isChecked }) => ({
//     type: 'checkbox',
//     checked: isChecked
// }))<CheckboxProps>`
//     ${tw`w-5 h-5 border-solid rounded-full outline-none cursor-pointer`}
//     border-width: 4px;
//     border-color: ${({ isChecked }) => (isChecked ? '#E2E2E2' : '#DBDBDB')};
//     background-color: ${({ isChecked }) => (isChecked ? '#9FBE43' : 'white')};
//     appearance: none;
//     -webkit-appearance: none;
// `;

export const Container = styled(motion.div).attrs(() => ({
    variants: {
        selected: {
            background: '#9FBE43',
            height: '100%',
            width: '100%'
        },
        unselected: {
            background: '#F4F4F4',
            width: '0%',
            height: '0%'
        }
    }
}))`
    ${tw`
        rounded-full
        shrink-0
        max-w-[16px]
        max-h-[16px]
    `}
`;

export const Radio = styled.div`
    ${tw`
        w-6
        h-6
        rounded-full
        border-[0.5px]
        border-[#E2E2E2]
        bg-[#F4F4F4]
        flex
        shrink-0
        items-center
        justify-center
        cursor-pointer
    `}
`;
