import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { BlockService } from '../../services';
import { BLOCK_AISLES } from '../../utils/react-query-keys';
import { IBlockAisles } from '../../services/block';

export const useBlockAisle = (id: string, params?: UseQueryOptions<IBlockAisles[]>) => {
    return useQuery<IBlockAisles[]>([BLOCK_AISLES, id], () => BlockService.getBlockAisle(id), {
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        ...params
    });
};
