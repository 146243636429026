import tw, { styled } from 'twin.macro';

//button quando não tem produto no carrinho
export const ButtonRoot = styled.div`
    ${tw`
        w-[116px]
        h-[30px]
        justify-center
        flex
        items-center
        cursor-pointer
        bg-gray
        rounded-5
        animate-pulse 
    `}
`;
export const TextAddCart = styled.div`
    ${tw`
    w-[80%]
        h-[13px]
        mt-[1.5px]
        mb-[1.5px]
        bg-gray
        rounded-5
        animate-pulse 
    `}
`;
