import { FC } from 'react';
import { useCart } from '../../../hooks';
import { ProductService } from '../../../services';
import TextLatoExtraBold18 from '../text/Lato-extra-bold-18';
import { IProductValueProps } from './types';

const ProductValue: FC<IProductValueProps> = ({ data, uniqueId, ...props }) => {
    const { data: cartData } = useCart();
    const item = cartData?.items.find((cartItem) => cartItem.sku == data.sku);
    return (
        <TextLatoExtraBold18 {...props} layoutId={`${uniqueId}-value`}>
            {ProductService.getValueDisplay(data, item?.qty || 0)}
        </TextLatoExtraBold18>
    );
};

export default ProductValue;
