import React, { FC } from 'react';
import { ProductItemSkeleton } from '../../molecules';
import { Container, ContainerTitle, List, TextSeeAll, TextTitle } from './styles';

interface IHallSkeletonProps {
    horizontal: boolean;
    qty?: number;
}

const HallSkeleton: FC<IHallSkeletonProps> = ({ horizontal, qty = 8 }) => {
    return (
        <Container>
            <ContainerTitle>
                <TextTitle />
                <TextSeeAll />
            </ContainerTitle>
            <List>
                {Array.from(Array(qty).keys()).map((item, index) => (
                    <ProductItemSkeleton forceWidth key={index.toString()} />
                ))}
            </List>
        </Container>
    );
};

export default HallSkeleton;
