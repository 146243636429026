import { useEffect, useState } from 'react';
import { Container, Columns } from './styles';
import TabColumn from './Tab-column';
import { AnimatePresence } from 'framer-motion';
import TabContent from './Tab-content';

interface ITabsProps {
    id: string;
    activeTab?: number;
    onChangeTab?: (index: number) => void;
    columns: {
        title: string;
        icon: React.ReactNode;
        content: () => React.ReactNode;
    }[];
    classNameColumns?: string;
}
function Tabs({ columns, id, activeTab = 0, onChangeTab, classNameColumns }: ITabsProps) {
    const [selected, setSelected] = useState(activeTab);

    useEffect(() => {
        setSelected(activeTab);
    }, [activeTab]);

    function handleClick(index: number) {
        setSelected(index);
        if (onChangeTab) {
            onChangeTab(index);
        }
    }

    return (
        <Container>
            <Columns className={`no-scrollbar ${classNameColumns}`}>
                {columns.map((column, index) => (
                    <TabColumn
                        onClick={() => handleClick(index)}
                        selected={index === selected}
                        id={id}
                        key={index}
                        title={column.title}
                        icon={column.icon}
                    />
                ))}
            </Columns>
            <AnimatePresence mode="wait">{columns[selected].content()}</AnimatePresence>
        </Container>
    );
}

Tabs.Content = TabContent;

export default Tabs;
