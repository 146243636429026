import Image from 'next/image';
import { FC } from 'react';
import { NEXT_PUBLIC_LOGO_PORTRAIT } from '../../../utils/envs';

export enum LogoColor {
    PRIMARY = 'primary',
    SECONDARY = 'secondary'
}

export interface LogoProps {
    widthPortrait?: number;
    heightPortrait?: number;
    widthSquare?: number;
    heightSquare?: number;
    color?: LogoColor;
}

const Logo: FC<LogoProps> = ({
    widthPortrait,
    widthSquare,
    heightPortrait,
    heightSquare,
    color = LogoColor.PRIMARY
}) => {
    const width = NEXT_PUBLIC_LOGO_PORTRAIT ? widthPortrait : widthSquare;
    const height = NEXT_PUBLIC_LOGO_PORTRAIT ? heightPortrait : heightSquare;

    const name = color === LogoColor.PRIMARY ? 'logo' : 'logo-branca';

    return <img alt="LOGO" src={`/images/${name}.png`} width={width} height={height} />;
};

export default Logo;
