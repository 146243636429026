import { AddressService, CartService, MapService } from '../../services';
import { Address } from '../../services/address/types';
import { Cart } from '../../services/cart/types';
import { queryClient } from '../../services/query-client';
import { CART } from '../../utils/react-query-keys';
import { useAddress } from '../use-address';
import { useEstablishment } from '../use-establishment';
import { useQueryCancelToken } from '../use-query-cancel-token';

function useEstimateShipping() {
    const { data: userAddress } = useAddress();
    const { data: establishment } = useEstablishment();

    const zipCode = establishment?.region || establishment?.zipCode;

    return useQueryCancelToken(['estimateShippingMethods', userAddress?.address, zipCode], async (params) => {
        const cartData = queryClient.getQueryData([CART]) as Cart;

        if (cartData?.items.length == 0) {
            return 0;
        }

        if (userAddress && userAddress?.address) {
            const shipping = await CartService.estimateShippingMethods(userAddress?.address, params);
            const lessAmount = shipping.reduce((min, current) => {
                return current.amount < min ? current.amount : min;
            }, shipping[0].amount);

            return lessAmount;
        } else if (zipCode) {
            const result = await MapService.getViaCep(zipCode, params);
            const states = await AddressService.getStatesStorage();
            const cartId = await CartService.getCartIdStorage();

            if (!cartId) {
                return 0;
            }

            const state = states?.find((value) => value.code == result?.uf);

            if (!state) {
                return 0;
            }

            const addressNotLogged = {
                country_id: 'BR',
                postcode: zipCode,
                region: {
                    region_code: state.code,
                    region_id: parseInt(state.id)
                }
            } as unknown as Address;

            const shipping = await CartService.guestEstimateShippingMethods(addressNotLogged, cartId, params);

            return shipping.reduce((min, current) => {
                return current.amount < min ? current.amount : min;
            }, shipping[0].amount);
        }
    });
}

export default useEstimateShipping;
