import { PropsWithChildren } from 'react';
import { Container, Description, Title, VARIANTS } from './styles';

interface IAlertProps {
    title?: string;
}

function Alert({ title, children }: PropsWithChildren<IAlertProps>) {
    return (
        <Container layout variants={VARIANTS} initial="hidden" exit="hidden" animate="visible">
            {title && <Title>{title}</Title>}
            <Description>{children}</Description>
        </Container>
    );
}

export default Alert;
