import tw, { styled } from 'twin.macro';

export const Container = styled.div`
    ${tw`
        py-[16px]
        divide-gray
        items-center
        flex
        px-[15px]
        transition-all
        cursor-pointer
        hover:bg-lightGray
    `}
`;
