import React, { FC, useEffect, useState } from 'react';

import LoadingCustom from '../../templates/LoadingCustom';
import { Body, Button, ButtonConfirm, ButtonFavorite, Container, Footer, Header } from './styles';
import Image from 'next/image';
import { Checkbox } from '../../atoms/CheckboxSquare/styles';
import { toast } from 'react-toastify';
import { WishlistService } from '../../../services';
import { IWishlist } from '../../../services/wishlist/types';
import { useRouter } from 'next/router';
import { IconClose } from '../../atoms';

interface IProductMenuProps {
    onClose: () => void;
    onConfirm: () => void;
    product: string;
    sku: string;
}

const IncludeItemWishlist: FC<IProductMenuProps> = ({ onClose, onConfirm, product, sku }) => {
    const [isLoadingCustom, setIsLoadingCustom] = useState(false);
    const [isCreatingList, setIsCreatingList] = useState(false);
    const [newListName, setNewListName] = useState('');
    const [selectedList, setSelectedList] = useState<IWishlist>();
    const [defaultList, setDefaultList] = useState<string>('0');
    const [data, setData] = useState<IWishlist[] | null>(null);

    const router = useRouter();

    const handleAddNewListClick = () => {
        setIsCreatingList(true);
    };

    const handleConfirm = async () => {
        try {
            if (!selectedList) {
                toast.error('Selecione uma lista');
                return;
            }
            const response = await WishlistService.add({
                wishlistId: selectedList?.wishlist_id,
                name: selectedList?.wishlist_name,
                item: {
                    quantity: 1,
                    id: product,
                    sku
                }
            });
            if (response) {
                toast.success(
                    <div className="flex flex-row justify-between">
                        <span>Produto adicionado à lista</span>
                    </div>,
                    {
                        position: 'bottom-center',
                        autoClose: 5000,
                        hideProgressBar: true,
                        className: 'text-sm'
                    }
                );
            }
        } catch (error: any) {
            toast.error(error.response.data.message);
        } finally {
            onClose?.();
            onConfirm?.();
        }
    };

    const getData = async () => {
        try {
            setIsLoadingCustom(true);
            const response = await WishlistService.all();
            const wishlist = response.find((item: IWishlist) => {
                return item.wishlist_name === 'My Wishlist' || 'Principal';
            });
            if (wishlist) {
                setDefaultList(wishlist?.wishlist_id);
                setSelectedList(wishlist);

                setData(response);
            }
        } catch (error) {
            console.error('Error fetching data: ', error);
        } finally {
            setIsLoadingCustom(false);
        }
    };

    const handleCreateList = async () => {
        setIsLoadingCustom(true);
        setNewListName('');
        setIsCreatingList(false);
        try {
            const response = await WishlistService.create(newListName);
            if (response) {
                getData();
            }
        } catch (error) {
            console.error('Error fetching data: ', error);
        } finally {
            setIsLoadingCustom(false);
        }
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <Container
            onClose={onClose}
            onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
            }}
        >
            {isLoadingCustom ? (
                <LoadingCustom isLoading={isLoadingCustom} />
            ) : (
                <>
                    <Header>
                        <div className="flex justify-end ">
                            <IconClose className="w-6 h-6 cursor-pointer" onClick={onClose} />
                        </div>

                        <h3 className="text-center font-bold">
                            {isCreatingList ? 'Criar lista de produtos' : 'Adicionar à lista'}
                        </h3>
                    </Header>
                    <Body>
                        {isCreatingList ? (
                            <div className="flex flex-col items-center justify-center">
                                <input
                                    type="text"
                                    placeholder="Nome da lista"
                                    value={newListName}
                                    onChange={(e) => setNewListName(e.target.value)}
                                    maxLength={25}
                                    className="border-[1px] border-[#DBDBDB] rounded-md p-2 w-full"
                                />
                                <div className="self-end mb-4">
                                    <p>{newListName.length} / 25</p>
                                </div>

                                <ButtonConfirm onClick={handleCreateList}>Criar lista</ButtonConfirm>
                            </div>
                        ) : (
                            // Exibe as listas de favoritos e o botão para criar nova lista
                            <>
                                <div
                                    onClick={handleAddNewListClick}
                                    className="flex flex-row items-center gap-4 mb-2 cursor-pointer"
                                >
                                    <Button>
                                        <Image src="/images/plus.svg" height={20} width={20} alt="Plus" />
                                    </Button>
                                    <span>Criar nova lista</span>
                                </div>

                                {data && data.length > 0
                                    ? data.map((item, index) => (
                                          <div
                                              className="flex flex-row items-center justify-between gap-4 mb-2"
                                              key={index}
                                          >
                                              <div>
                                                  <ButtonFavorite>
                                                      <Image
                                                          src="/images/heart.svg"
                                                          height={18}
                                                          width={18}
                                                          alt="Heart"
                                                      />
                                                  </ButtonFavorite>
                                                  <span className="ml-4">{item?.wishlist_name}</span>
                                              </div>
                                              <Checkbox
                                                  type="checkbox"
                                                  isChecked={selectedList?.wishlist_id === item.wishlist_id}
                                                  onChange={() => setSelectedList(item)}
                                              />
                                          </div>
                                      ))
                                    : ''}
                            </>
                        )}
                    </Body>
                    {!isCreatingList ? (
                        <Footer>
                            <ButtonConfirm onClick={handleConfirm}>Confirmar</ButtonConfirm>

                            <span
                                className="font-semibold cursor-pointer"
                                onClick={() =>
                                    router.push({
                                        pathname: `/minha-conta/listas`
                                    })
                                }
                            >
                                Ir para listas
                            </span>
                        </Footer>
                    ) : (
                        ''
                    )}
                </>
            )}
        </Container>
    );
};

export default IncludeItemWishlist;
