import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { EstablishmentService } from '../../services';
import { IEstablishment } from '../../services/establishment/types';
import { ESTABLISHMENT } from '../../utils/react-query-keys';

export const useEstablishment = (params?: UseQueryOptions<IEstablishment | null>) => {
    return useQuery<IEstablishment | null>([ESTABLISHMENT], () => EstablishmentService.getEstablishmentsStorage(), {
        refetchOnMount: true,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        ...params
    });
};
