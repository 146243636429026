import tw, { styled } from 'twin.macro';
import { cssDefaultInput } from '../Input/styles';
import { InputProps } from './types';
import { motion } from 'framer-motion';
import IconArrowDownFilled from '../../atoms/icons/Arrow-down-filled';
import { TextLatoBold15, TextLatoRegular15 } from '../../atoms';
import { twColors } from '../../../utils/get-config-tailwind';

export const Input = styled(motion.div)<InputProps>`
    ${cssDefaultInput}
    ${tw`
        border-0
        shadow-md
        flex
        flex-row
        w-full
        items-center
        justify-between
        pr-0
        cursor-pointer
    `}
`;
export const ContainerIcon = styled(motion.div)`
    ${tw`
        w-[40px]
        h-full
        flex
        justify-center
        items-center
    `}
`;

export const Icon = styled(motion(IconArrowDownFilled))`
    ${tw`
        w-[15px]
        h-[15px]
    `}
`;
export const Container = styled.div`
    ${tw`
        flex
        w-full
        flex-col
        h-auto
    `}
`;
export const List = styled(motion.div).attrs(() => ({
    // initial: { height: 0 },
    // animate: {
    //     height: 'auto'
    // },
    exit: 'close'
}))`
    ${tw`
        overflow-hidden
        h-auto
        divide-gray
        divide-y
        rounded-br-15
        rounded-bl-15
        border-gray
       px-[15px]
    `};
`;
export const Item = styled.div`
    ${tw`
        py-[20px]
        px-[10px]
        cursor-pointer
    `}
`;
export const TextItem = styled(TextLatoRegular15)``;
export const TextItemSelected = styled(TextLatoBold15)``;

export const variantsInput = {
    open: {
        borderBottomRightRadius: '0px',
        borderBottomLeftRadius: '0px'
    },
    close: {
        borderBottomRightRadius: '15px',
        borderBottomLeftRadius: '15px'
    }
};
export const variantsList = {
    open: {
        height: 'auto'
    },
    close: {
        height: '0'
    }
};
export const variantsContainerIcon = {
    open: {
        rotate: 180
    },
    close: {
        rotate: 0
    }
};
export const variantsIcon = {
    open: {
        fill: twColors.gray
    },
    close: {
        fill: twColors.primary
    }
};
