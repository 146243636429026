import { FC, HTMLAttributes } from 'react';
import tw, { styled } from 'twin.macro';

const Container = styled.span`
    ${tw`
        font-nunito
        text-16
        font-normal
        text-black
        leading-[21.82px]
    `}
`;

const TextNunitoRegular16 = ({ children, ...props }: HTMLAttributes<HTMLDivElement>) => {
    return <Container {...props}>{children}</Container>;
};

export default TextNunitoRegular16;
