import { motion } from 'framer-motion';
import { PropsWithChildren } from 'react';

function TabContent({ children }: PropsWithChildren) {
    return (
        <motion.div
            variants={{
                visible: {
                    opacity: 1
                },
                hidden: {
                    opacity: 0
                }
            }}
            initial="hidden"
            animate="visible"
            exit="hidden"
        >
            {children}
        </motion.div>
    );
}

export default TabContent;
