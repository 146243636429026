import tw, { styled } from 'twin.macro';
import { IconClose } from '../../atoms';

export const Container = styled.div`
    ${tw`
        flex
        flex-row
        justify-between
        items-center
        h-[50px]
        pl-[20px]
        pr-[20px]
    `}
`;
export const TextTitle = styled.h1`
    ${tw`
        font-bold
        text-[18px]
        text-[#212226]
    `}
`;

export const Icon = styled(IconClose)`
    ${tw`
        fill-black
        w-[20px]
        h-[20px]
    `}
`;
