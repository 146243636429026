import { ArrowRight, Current, Link, NavTrace } from './styles';

export interface IBreadcrumbProps {
    items: {
        url?: string;
        label: string;
    }[];
}
function Breadcrumb({ items }: IBreadcrumbProps) {
    return (
        <NavTrace>
            {items?.length > 0 &&
                items
                    ?.map((item, index) => {
                        return index === items.length - 1 ? (
                            <Current key={index}>{item.label}</Current>
                        ) : (
                            <Link key={index} href={item.url || ''}>
                                {item.label}
                            </Link>
                        );
                    })
                    ?.reduce(
                        (prev, curr) => [prev, (<ArrowRight key={prev.key + '-separator'} />) as any, curr] as any
                    )}
        </NavTrace>
    );
}

export default Breadcrumb;
