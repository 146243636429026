import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { IS_BROWSER } from '../../services/query-client';
import userService from '../../services/user';
import { CustomerInfo } from '../../services/user/types';
import { USER_INFO } from '../../utils/react-query-keys';

export const useUserInfo = (params?: UseQueryOptions<Partial<CustomerInfo> | null>) => {
    return useQuery<Partial<CustomerInfo> | null>([USER_INFO], () => userService.getInfo(), {
        refetchOnMount: true,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        enabled: IS_BROWSER,
        ...params
    });
};
