import mundipaggService from '../services/mundipagg';
import { Order } from '../services/order/types';
import { CreditCardValues, PaymentInterface, PaymentSelected, PaymentTypes } from '../services/payment/types';
import { currencyUnMask } from '../utils/getFormattedValue';
import { ucfirst, unMask } from '../utils/string';
import { Mundipagg } from './Mundipagg';

export class PaymentMundipaggDebit extends Mundipagg implements PaymentInterface {
    async getCheckout(valuesCheckout: CreditCardValues[]) {
        const [card] = valuesCheckout;

        let cardInfo;

        if (card.useSave) {
            cardInfo = {
                cc_type: card.useSave.brand,
                cc_last_4: card.useSave.masked_number,
                cc_owner: card.useSave.owner_name,
                cc_savecard: 0,
                cc_saved_card: card.useSave.id,
                cc_installments: card?.installments,
                cc_card_tax_amount: '0',
                cc_buyer_checkbox: false
            };
        } else {
            const tokenCard = {
                type: 'card',
                card: {
                    holder_name: card.name as string,
                    number: card.number?.replace(/ /g, '') as string,
                    exp_month: card.valid?.split('/')[0] as string,
                    exp_year: card.valid?.split('/')[1] as string,
                    cvv: card?.cvv as string,
                    holder_document: unMask(card?.cpf as string) as string
                }
            };
            const { id: tokenId } = await mundipaggService.tokenCard(tokenCard);

            cardInfo = {
                cc_type: card.type,
                cc_last_4: card.number?.slice(12),
                cc_exp_year: card.valid?.split('/')[1],
                cc_exp_month: card.valid?.split('/')[0],
                cc_owner: card?.name,
                cc_savecard: card?.save,
                cc_saved_card: null,
                cc_installments: card?.installments,
                cc_token_credit_card: tokenId,
                cc_card_tax_amount: '0',
                cc_buyer_checkbox: false
            };
        }

        return {
            method: PaymentTypes.MUNDIPAGG_DEBIT,
            additional_data: cardInfo
        };
    }
    getFromCheckout(data: PaymentSelected) {
        const paymentService = require('../services/payment').default;
        const methodName = paymentService.getPaymentMethodTitle(PaymentTypes.MUNDIPAGG_DEBIT, 'small');

        return {
            method: PaymentTypes.MUNDIPAGG_DEBIT,
            values:
                data.values?.map((card) => {
                    const number = card.number?.replace(/ /g, '').slice(12) as string;
                    const installments = card.installments?.quantity;
                    return {
                        installments,
                        value: card.value,
                        type: card.type,
                        number: number,
                        label: `${ucfirst(card.type as string)} - ${methodName} - **** ${number}`
                    };
                }) || []
        };
    }

    getFromOrder(order: Order) {
        const paymentService = require('../services/payment').default;
        const method = PaymentTypes.MUNDIPAGG_DEBIT;
        const type = order.extension_attributes.payment_additional_info?.find((v) => v.key == 'cc_type')?.value;
        const methodName = paymentService.getPaymentMethodTitle(method, 'small');
        const number = order.extension_attributes.payment_additional_info?.find((v) => v.key == 'cc_last_4')?.value;

        return {
            method: method,
            values: [
                {
                    installments: parseInt(
                        order.extension_attributes.payment_additional_info?.find((v) => v.key == 'cc_installments')
                            ?.value || '1'
                    ),
                    value: currencyUnMask(order.base_grand_total).toString(),
                    type: order.extension_attributes.payment_additional_info?.find((v) => v.key == 'cc_type')?.value,
                    number: order.extension_attributes.payment_additional_info?.find((v) => v.key == 'cc_last_4')
                        ?.value,
                    label: `${ucfirst(type)} - ${methodName} - **** ${number}`
                }
            ]
        };
    }
}
