import React, { FC, SVGProps } from 'react';

const FlagMaestroCard: FC<SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg viewBox="0 0 200 155.6" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g>
                <g>
                    <path
                        fill="#00A2E5"
                        d="M198.1,100.1v-2.4h-0.6l-0.7,1.6l-0.7-1.6h-0.6v2.4h0.4v-1.8l0.7,1.6h0.5l0.7-1.6v1.8H198.1z M194.1,100.1
			v-2h0.8v-0.4h-2v0.4h0.8v2H194.1z"
                    />
                    <path fill="#7375CF" d="M127,110.4H72.9V13.2H127V110.4z" />
                    <path
                        fill="#EB001B"
                        d="M76.4,61.8c0-19.7,9.2-37.3,23.6-48.6C89.5,4.9,76.2,0,61.8,0C27.7,0,0,27.7,0,61.8s27.7,61.8,61.8,61.8
			c14.4,0,27.7-4.9,38.2-13.2C85.6,99.1,76.4,81.5,76.4,61.8"
                    />
                    <path
                        fill="#00A2E5"
                        d="M200,61.8c0,34.1-27.7,61.8-61.8,61.8c-14.4,0-27.7-4.9-38.2-13.2c14.4-11.3,23.6-28.9,23.6-48.6
			s-9.2-37.3-23.6-48.6C110.5,4.9,123.8,0,138.2,0C172.3,0,200,27.7,200,61.8"
                    />
                </g>
                <g>
                    <path
                        d="M145.2,137.9c0.7,0,1.8,0.1,2.5,0.4l-1.1,3.4c-0.8-0.3-1.5-0.4-2.2-0.4c-2.3,0-3.5,1.5-3.5,4.2v9.2h-3.6v-16.4h3.5v2
			C141.8,138.8,143.2,137.9,145.2,137.9 M132,141.5h-5.8v7.4c0,1.6,0.6,2.7,2.4,2.7c0.9,0,2.1-0.3,3.2-0.9l1,3.1
			c-1.1,0.8-2.9,1.3-4.5,1.3c-4.2,0-5.7-2.3-5.7-6.1v-7.5h-3.3v-3.3h3.3v-5h3.6v5h5.8V141.5z M86.3,145c0.4-2.4,1.8-4,4.4-4
			c2.3,0,3.8,1.4,4.2,4H86.3z M98.6,146.5c0-5.1-3.2-8.6-7.8-8.6c-4.8,0-8.2,3.5-8.2,8.6c0,5.2,3.5,8.6,8.4,8.6
			c2.5,0,4.7-0.6,6.7-2.3l-1.8-2.6c-1.4,1.1-3.1,1.7-4.8,1.7c-2.3,0-4.4-1.1-4.9-4h12.2C98.5,147.4,98.6,147,98.6,146.5
			 M114.3,142.5c-1-0.6-3-1.4-5.1-1.4c-2,0-3.1,0.7-3.1,1.9c0,1.1,1.2,1.4,2.8,1.6l1.7,0.2c3.6,0.5,5.7,2,5.7,4.9
			c0,3.1-2.7,5.4-7.5,5.4c-2.7,0-5.2-0.7-7.1-2.1l1.7-2.8c1.2,0.9,3,1.7,5.5,1.7c2.4,0,3.7-0.7,3.7-2c0-0.9-0.9-1.4-2.9-1.7
			l-1.7-0.2c-3.7-0.5-5.7-2.2-5.7-4.8c0-3.3,2.7-5.3,6.8-5.3c2.6,0,5,0.6,6.7,1.7L114.3,142.5z M158.3,141.2c-0.7,0-1.4,0.1-2,0.4
			c-0.6,0.3-1.2,0.6-1.6,1.1s-0.8,1-1.1,1.7c-0.3,0.6-0.4,1.4-0.4,2.1c0,0.8,0.1,1.5,0.4,2.1c0.3,0.6,0.6,1.2,1.1,1.7s1,0.8,1.6,1.1
			c0.6,0.3,1.3,0.4,2,0.4c0.7,0,1.4-0.1,2-0.4c0.6-0.3,1.2-0.6,1.6-1.1c0.5-0.5,0.8-1,1.1-1.7c0.3-0.6,0.4-1.4,0.4-2.1
			c0-0.8-0.1-1.5-0.4-2.1c-0.3-0.6-0.6-1.2-1.1-1.7c-0.5-0.5-1-0.8-1.6-1.1C159.7,141.4,159,141.2,158.3,141.2 M158.3,137.9
			c1.3,0,2.5,0.2,3.5,0.7c1.1,0.4,2,1,2.8,1.8c0.8,0.8,1.4,1.7,1.9,2.7c0.4,1.1,0.7,2.2,0.7,3.4c0,1.2-0.2,2.4-0.7,3.4
			c-0.4,1.1-1.1,2-1.9,2.7c-0.8,0.8-1.7,1.4-2.8,1.8c-1.1,0.4-2.3,0.7-3.5,0.7c-1.3,0-2.5-0.2-3.5-0.7c-1.1-0.4-2-1-2.8-1.8
			c-0.8-0.8-1.4-1.7-1.9-2.7c-0.4-1.1-0.7-2.2-0.7-3.4c0-1.2,0.2-2.4,0.7-3.4c0.4-1.1,1.1-2,1.9-2.7c0.8-0.8,1.7-1.4,2.8-1.8
			C155.8,138.1,157,137.9,158.3,137.9 M65.5,146.5c0-2.9,1.9-5.3,5-5.3c3,0,4.9,2.3,4.9,5.3c0,3-2,5.2-4.9,5.2
			C67.4,151.7,65.5,149.4,65.5,146.5 M78.8,146.5v-8.2h-3.6v2c-1.1-1.5-2.8-2.4-5.2-2.4c-4.6,0-8.2,3.6-8.2,8.6c0,5,3.6,8.6,8.2,8.6
			c2.3,0,4-0.9,5.2-2.4v2h3.6V146.5z M58.7,154.7v-10.3c0-3.9-2.5-6.5-6.5-6.5c-2.1,0-4.3,0.6-5.8,2.9c-1.1-1.8-2.9-2.9-5.4-2.9
			c-1.7,0-3.5,0.5-4.8,2.4v-2h-3.6v16.4h3.6v-9.1c0-2.8,1.6-4.4,4-4.4c2.4,0,3.6,1.5,3.6,4.3v9.1h3.6v-9.1c0-2.8,1.7-4.4,4-4.4
			c2.4,0,3.6,1.5,3.6,4.3v9.1H58.7z"
                    />
                    <path
                        d="M171,153.4v0.4h0.4c0.1,0,0.2,0,0.2-0.1c0.1,0,0.1-0.1,0.1-0.2s0-0.1-0.1-0.1c0,0-0.1-0.1-0.2-0.1H171z M171.4,153.2
			c0.2,0,0.3,0,0.4,0.1c0.1,0.1,0.2,0.2,0.2,0.4c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.4,0.1l0.5,0.6h-0.4l-0.5-0.6H171v0.6h-0.3
			v-1.5H171.4z M171.3,155.2c0.2,0,0.3,0,0.5-0.1c0.2-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.2-0.2,0.3-0.4c0.1-0.2,0.1-0.3,0.1-0.5
			s0-0.3-0.1-0.5c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1-0.1-0.2-0.2-0.4-0.3c-0.1-0.1-0.3-0.1-0.5-0.1s-0.3,0-0.5,0.1
			c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.1,0.3-0.1,0.5s0,0.3,0.1,0.5c0.1,0.2,0.2,0.3,0.3,0.4
			c0.1,0.1,0.3,0.2,0.4,0.3C171,155.2,171.1,155.2,171.3,155.2 M171.3,152.3c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.4,0.2,0.5,0.3
			c0.1,0.1,0.3,0.3,0.4,0.5c0.1,0.2,0.1,0.4,0.1,0.6c0,0.2,0,0.4-0.1,0.6s-0.2,0.4-0.4,0.5c-0.2,0.1-0.3,0.3-0.5,0.4
			c-0.2,0.1-0.4,0.1-0.6,0.1c-0.2,0-0.5,0-0.7-0.1c-0.2-0.1-0.4-0.2-0.5-0.4c-0.1-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.4-0.1-0.6
			c0-0.2,0-0.4,0.1-0.6c0.1-0.2,0.2-0.4,0.4-0.5c0.1-0.1,0.3-0.3,0.5-0.3C170.8,152.3,171.1,152.3,171.3,152.3"
                    />
                </g>
            </g>
        </svg>
    );
};

export default FlagMaestroCard;
