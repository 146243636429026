import { motion } from 'framer-motion';
import tw, { styled } from 'twin.macro';

export const variantsSm = {
    animate: {
        y: '0'
    },
    initial: {
        y: '100%'
    },
    exit: {
        y: '100%'
    }
};
export const variantsMd = {
    animate: {
        opacity: 1,
        scale: 1
    },
    initial: {
        opacity: 0,
        scale: 0
    },
    exit: {
        opacity: 0,
        scale: 0
    }
};

export const variantsRight = {
    animate: {
        x: '0'
    },
    initial: {
        x: '100%'
    },
    exit: {
        x: '100%'
    }
};

export const Container = styled(motion.div)`
    ${tw`
        bg-white
        fixed
        rounded-tl-8
        rounded-tr-8
        z-[20]
        max-h-modalSm
        max-w-modalSm
        flex
        w-modalSm
        h-full
        shadow-md
        bottom-0
        md:max-w-modal
        md:h-modal
        md:w-modal
        md:max-h-modal
        md:bottom-auto
        md:rounded-8
        overflow-hidden
    `}
`;
export const Root = styled(motion.div)`
    ${tw`
        md:fixed
        md:w-screen
        md:h-screen
        md:z-20
        md:flex
        md:justify-center
        md:items-center
        md:top-0
    `}
`;
