import Category from './Category';
import Cor from './Cor';
import Inflamavel from './Inflamavel';
import Marca from './Marca';
import Modelo from './Modelo';
import Potencia from './Potencia';
import Price from './Price';
import { Container } from './styles';
import Tamanho from './Tamanho';
import Voltagem from './Voltagem';
interface FilterContentProps {
    isMobile?: boolean;
}

function FilterContent({ isMobile = false }: FilterContentProps) {
    return (
        <Container>
            <Price isMobile={isMobile} />
            <Category />
            <Cor />
            <Tamanho />
            <Inflamavel />
            <Marca />
            <Voltagem />
            <Potencia />
            <Modelo />
        </Container>
    );
}

export default FilterContent;
