import React, { FC, SVGProps } from 'react';

const IconQrcode: FC<SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                style={{
                    fill: 'none',
                    stroke: '#000',
                    strokeWidth: 2,
                    strokeMiterlimit: 10
                }}
                d="M20 6h6v6h-6zM6 6h6v6H6zM6 20h6v6H6z"
            />
            <path d="M8 8h2v2H8zM22 8h2v2h-2zM8 22h2v2H8z" />
            <path
                style={{
                    fill: 'none',
                    stroke: '#000',
                    strokeWidth: 2,
                    strokeMiterlimit: 10
                }}
                d="M16 5v7M17 21v6M21 25v2M23 23v2M26 25v2M16 8h2M13 14h8M5 16h2M7 14h2M9 16h2M21 16h2M23 14h2M25 16h2M21 20h2M23 18h2M25 20h2M11 18h8M17 22h8M15 16h2M14 10h2"
            />
        </svg>
    );
};

export default IconQrcode;
