import { getSwitchUtilityClass } from '@mui/material';
import tw, { styled } from 'twin.macro';

export const Container = styled.div`
    ${tw`
        md:px-[1%]
        flex
        flex-col
        md:hidden
    `}
`;
export const Content = styled.div`
    ${tw`
        flex
        flex-col
        px-4
        pt-5
        pb-4
    `}
`;
export const Row = styled.div`
    ${tw`
        flex
        flex-row
        items-center
    `}
`;

export const Right = styled.div`
    ${tw`
        flex
        flex-row
        items-center
        gap-6
    `}
`;
