import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { WishlistService } from '../../services';
import { AddRemoveResponse, IWishlistAdd } from '../../services/wishlist/types';
import { useMutationCancelToken } from '../use-mutation-cancel-token';

export const useWishlistAdd = (options?: UseMutationOptions<AddRemoveResponse, unknown, IWishlistAdd>) => {
    return useMutationCancelToken<AddRemoveResponse, unknown, IWishlistAdd>(
        (axiosParams, params) => WishlistService.add(params, axiosParams),
        options
    );
};
