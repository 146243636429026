import { capitalize } from '@mui/material';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useCategories, useUserLogged } from '../../../../../hooks';
import { useBlockConfig } from '../../../../../hooks/use-block-config';
import { useUserInfo } from '../../../../../hooks/use-user-info';
import { CategoryService, ProductService, WishlistService } from '../../../../../services';
import { Categories } from '../../../../../services/category/types';
import { IProduct, IProductExtensionAttributesLinks } from '../../../../../services/product/types';
import { IWishlist } from '../../../../../services/wishlist/types';
import BadgeProductDiscount from '../../../../atoms/Badge-product-discount';
import { BadgeSize } from '../../../../atoms/Badge/types';
import ModalPortal from '../../../../atoms/Modal-portal';
import IncludeItemWishlist from '../../../../molecules/Include-item-wishlist';
import BreadcrumbComponent, { IBreadcrumbProps } from '../../../../organisms/Breadcrumb';
import LayoutFitter from '../../../Layout-fitter';
import { Badges, Button, Buttons, Container } from './styles';
const ProductShoppingList = dynamic(() => import('../../../../molecules/Product-item/Shopping-list'), {
    ssr: false
});

// Função para buscar o nível mais profundo da categoria do produto
function getDeepestCategoryLevel(
    categoryLinks: IProductExtensionAttributesLinks[],
    categories: Categories[]
): string | undefined {
    let deepestLevel: Categories | undefined;
    for (const categoryLink of categoryLinks) {
        const category = CategoryService.getCategoryFromCategories(categoryLink.category_id, categories);
        if (category && (!deepestLevel || category.level > deepestLevel.level)) {
            deepestLevel = category;
        }
    }
    return deepestLevel?.id;
}

interface BreadCrumbProps {
    data: IProduct;
}

function Breadcrumb({ data }: BreadCrumbProps) {
    const { data: categories } = useCategories({ refetchOnMount: false });

    useUserInfo();
    useBlockConfig();

    if (!data || !data.extension_attributes?.category_links || !categories) return null;

    const firstCategory = getDeepestCategoryLevel(data?.extension_attributes?.category_links, categories);

    if (!firstCategory) return null;

    const breadcrumb = CategoryService.getBreadcrumb(categories, firstCategory.toString());

    if (!breadcrumb || !breadcrumb.length) return null;

    const categoriesProduct = [
        { label: 'Página Inicial', url: '/' },
        ...breadcrumb.map((categoryProduct) => {
            return {
                label: categoryProduct.name,
                url: ProductService.getUrlSearchProduct(categoryProduct.url)
            };
        }),

        { label: capitalize(data?.name || '') }
    ];

    return (
        <LayoutFitter>
            <Container>
                <BreadcrumbComponent items={categoriesProduct as IBreadcrumbProps['items']} />
                <Buttons>
                    {/* <ProductShoppingList sku={data.sku} productId={data.id} /> */}
                    <Button type="button">
                        <ProductShoppingList onlyIcon sku={data.sku} productId={data.id} classNameIcon="!text-black" />{' '}
                        <div>Adicionar a Lista de Compras</div>
                    </Button>
                </Buttons>
                {data.discount_percentage && data.discount_percentage > 0 && (
                    <Badges>
                        {/* <BadgeProductFeature size={BadgeSize.SMALL} /> */}
                        {/* <BadgeProductFreeShipping size={BadgeSize.SMALL} /> */}
                        <BadgeProductDiscount size={BadgeSize.SMALL} value={`-${data.discount_percentage}`} />
                    </Badges>
                )}
            </Container>
        </LayoutFitter>
    );
}

export default Breadcrumb;
