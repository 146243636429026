import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { Input } from '../../molecules';

import { useMutation } from '@tanstack/react-query';
import router from 'next/router';
import { AuthService, EstablishmentService, ReactGa4Service } from '../../../services';
import { EMAIL, REQUIRED } from '../../../utils/errors';
import { IconClose, Image } from '../../atoms';
import { IButtonVariant } from '../../molecules/Button/types';
import { InputPassword } from '../../molecules/Input-password';
import {
    ButtonContainer,
    ContainerDescription,
    FormSubmitButton,
    LoginFormContainer,
    LoginFormForgotPassword,
    LoginFormHeader,
    LoginFormMandatoryBox,
    LoginFormMandatoryLabel,
    SubmitForgetButton
} from './styles';
import { ILoginFormProps, IValuesForm } from './types';
import { useBlockConfig } from '../../../hooks/use-block-config';

const validationForm = Yup.object().shape({
    email: Yup.string().email(EMAIL).required(REQUIRED),
    password: Yup.string().required(REQUIRED)
});

const LoginForm = ({ onClose, toggleForgottenPassword, onLogin }: ILoginFormProps) => {
    const { mutate, isLoading, isError, reset } = useMutation<unknown, unknown, IValuesForm>(
        (form) => AuthService.login(form.email, form.password),
        {
            onSuccess: () => {
                ReactGa4Service.onLoginInfo();
                onClose();
                onLogin?.();
            }
        }
    );
    const { data: config } = useBlockConfig({ retryOnMount: false });

    const formik = useFormik<IValuesForm>({
        initialValues: {
            email: '',
            password: ''
        },
        validateOnMount: false,
        validationSchema: validationForm,
        onSubmit: (values) => {
            mutate(values);
        }
    });

    return (
        <form className="flex flex-col flex-1 overflow-auto" onSubmit={formik.handleSubmit}>
            <LoginFormContainer>
                <div className="flex flex-col flex-1">
                    <LoginFormHeader>
                        <Image src="/images/logo.png" alt="logo" width={248} height={69} />
                    </LoginFormHeader>

                    <ContainerDescription
                        dangerouslySetInnerHTML={{
                            __html: (config?.login_mensagem as any) || ''
                        }}
                    ></ContainerDescription>
                </div>

                <div>
                    {isError && (
                        <LoginFormMandatoryBox>
                            <LoginFormMandatoryLabel>
                                <div className="flex items-center justify-center text-center">
                                    Senha ou usuário incorreto. Tente novamente!
                                    <IconClose
                                        stroke="#323232"
                                        className="cursor-pointer"
                                        onClick={() => {
                                            reset();
                                        }}
                                    />
                                </div>
                            </LoginFormMandatoryLabel>
                        </LoginFormMandatoryBox>
                    )}
                    <FormikProvider value={formik}>
                        <div className="flex flex-col gap-4 mt-2 sh:mt-[-30px]">
                            <Input label="Usuário" name="email" required />
                            <InputPassword label="Senha" name="password" />
                        </div>
                    </FormikProvider>
                    {/* <LoginFormInput placeholder="Senha*" /> */}

                    <SubmitForgetButton>
                        <div onClick={toggleForgottenPassword}>
                            <LoginFormForgotPassword>Esqueci a Senha</LoginFormForgotPassword>
                        </div>
                    </SubmitForgetButton>
                    <ButtonContainer>
                        <FormSubmitButton
                            text="Entrar"
                            isLoading={isLoading}
                            onClick={(e: any) => {
                                e.preventDefault();
                                e.stopPropagation();
                                formik.handleSubmit();
                            }}
                        />
                        <span className="lg:hidden">Ainda não tem uma conta com a gente?</span>
                        <span className="hidden lg:block">Ou</span>
                        <FormSubmitButton
                            text="Cadastre-se"
                            variant={IButtonVariant.OUTLINE}
                            onClick={() => {
                                onClose();
                                setTimeout(() => {
                                    router.push(EstablishmentService.createUrl(`cadastrar`));
                                }, 200);
                            }}
                        />
                    </ButtonContainer>
                </div>
            </LoginFormContainer>
        </form>
    );
};

export default LoginForm;
