import { useEffect, useRef } from 'react';
import { useEstablishment } from '../../../../hooks/use-establishment';
import ChangeMyRegionModal, { IChangeMyRegionModalHandle } from '../Change-my-region-modal';
import { Container, Icon, Subtitle, Title, TitleContainer } from './styles';
import AlertRegionModal, { IAlertRegionModalHandle } from '../Alert-region-modal';
import useAdoptCallbacks from '../../../../hooks/use-adopt-callbacks';

interface IRegionProps {
    onCloseChangeRegion: () => void;
    onClickChangeRegion: () => void;
}

function Region({ onCloseChangeRegion, onClickChangeRegion }: IRegionProps) {
    const refChangeMyRegionModal = useRef<IChangeMyRegionModalHandle>(null);
    const refAlertRegionModal = useRef<IAlertRegionModalHandle>(null);
    const refContent = useRef<HTMLDivElement>(null);
    const { data: establishment } = useEstablishment();

    const handleClickChangeRegion = () => {
        if (establishment?.zipCode && !establishment.region) {
            onClickChangeRegion();
            return;
        }
        const documentElement = refContent.current?.getBoundingClientRect() as any;
        const x = documentElement.left + window.scrollX; // Adiciona o deslocamento horizontal do scroll
        const y = documentElement.top + window.scrollY; // Adiciona o deslocamento vertical do scroll
        const height = documentElement.height;

        refChangeMyRegionModal.current?.toggle(x, y + height + 8);
    };

    const zipCode = establishment?.region || establishment?.zipCode;

    useAdoptCallbacks(
        'region',
        () => {},
        () => {
            const documentElement = refContent.current?.getBoundingClientRect() as any;

            const x = documentElement.left + window.scrollX; // Adiciona o deslocamento horizontal do scroll
            const y = documentElement.top + window.scrollY; // Adiciona o deslocamento vertical do scroll
            const height = documentElement.height;

            refChangeMyRegionModal.current?.toggle(x, y + height + 8);
        }
    );

    useEffect(() => {
        if (establishment?.showAlertModal) {
            setTimeout(() => {
                const documentElement = refContent.current?.getBoundingClientRect() as any;
                if (!documentElement) {
                    return;
                }

                const x = documentElement.left + window.scrollX; // Adiciona o deslocamento horizontal do scroll
                const y = documentElement.top + window.scrollY; // Adiciona o deslocamento vertical do scroll
                const height = documentElement.height;

                if (y && height) {
                    refAlertRegionModal.current?.toggle(x, y + height + 8);
                }
            }, 1000);
        }
    }, [establishment?.showAlertModal]);

    useEffect(() => {
        const handleScroll = () => {
            refAlertRegionModal.current?.close();
            refChangeMyRegionModal.current?.close();
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [refChangeMyRegionModal.current, refAlertRegionModal.current]);

    return (
        <>
            <div className="flex w-full md:hidden">
                <Container>
                    <Icon />
                    <TitleContainer>
                        <Title>Você esta em: {zipCode && <strong>{zipCode}</strong>}</Title>
                        <Subtitle onClick={onClickChangeRegion}>{zipCode ? 'Alterar' : 'Informar'} CEP</Subtitle>
                    </TitleContainer>
                </Container>
            </div>
            <div className="hidden md:flex shrink-0">
                <Container onClick={handleClickChangeRegion} ref={refContent}>
                    <Icon />
                    <TitleContainer>
                        <Title>{zipCode ? 'Preços válidos para' : 'Onde'}</Title>
                        <div className="flex flex-row items-center gap-2 flex-nowrap">
                            <Subtitle className="shrink-0">{zipCode ? zipCode : 'você esta?'}</Subtitle>
                            {zipCode && <span className="text-[#F2684C] text-12 h-3 flex items-center]">Editar</span>}
                        </div>
                    </TitleContainer>
                </Container>
            </div>
            <ChangeMyRegionModal
                onClose={onCloseChangeRegion}
                refOutSideClick={refContent}
                onClickChangeZipCode={onClickChangeRegion}
                ref={refChangeMyRegionModal}
            />
            <AlertRegionModal onClickChangeZipCode={onClickChangeRegion} ref={refAlertRegionModal} />
        </>
    );
}

export default Region;
