import { motion } from 'framer-motion';
import tw, { styled } from 'twin.macro';
import { variants } from '../../../../organisms/Search-filters/styles';

export const Container = styled.div`
    ${tw`
        flex
        flex-row
        gap-4
    `}
`;

export const TextTitle = styled.div`
    ${tw`
        h-7
        text-14
        font-medium
        text-black
        flex
        flex-col
        justify-center
    `}
`;

export const Items = styled.div`
    ${tw`
        flex
        flex-row
        gap-2
        flex-wrap
    `}
`;

export const Item = styled(motion.button).attrs(() => ({
    variants: {
        selected: {
            borderColor: '#9FBE43',
            color: '#9FBE43',
            background: '#E2F6E7'
        },
        unselected: {
            borderColor: '#E6E6E6',
            color: '#000000',
            background: '#ffffff'
        }
    }
}))`
    ${tw`
        rounded-16
        flex
        flex-col
        items-center
        justify-center
        border-[0.5px]
        py-[5.33px]
        px-3
    `}

    ${tw`
         disabled:bg-white
         disabled:!text-[rgba(0,0,0,0.25)]
         disabled:!border-[rgba(230,230,230,0.50)]
         disabled:cursor-not-allowed
    
    `}
`;

export const ItemText = styled.div`
    ${tw`
        min-w-[56px]
        min-h-[21.33px]
        font-medium
        text-12
        flex
        justify-center
        items-center
        md:min-h-[16px]
    `}
`;
