import React, { FC } from 'react';
import { CreditCardFlag, Image, LogoFavIcon } from '../../atoms';
import {
    Bar,
    BgChip,
    Chip,
    CntrNumber,
    Container,
    Content,
    Header,
    Icon,
    Left,
    Name,
    Number,
    Right,
    Wifi
} from './styles';
import { ICreditCardFlipFrontProps } from './types';

const CreditCardFlipFront: FC<ICreditCardFlipFrontProps> = ({
    colorsBg = ['#37353B', '#37353B'],
    number,
    name,
    valid
}) => {
    return (
        <Container>
            <Left>
                <Header>
                    <CreditCardFlag number={number} color="#fff" />
                </Header>
                <Content>
                    <div className="flex flex-1" />
                    <Chip>
                        <BgChip />
                        <Wifi />
                    </Chip>

                    <CntrNumber>
                        <Number>{number ? number : 'Número do cartão'}</Number>
                    </CntrNumber>
                </Content>
                <CntrNumber>
                    <Name>{name ? name : 'Nome do titular'}</Name>
                </CntrNumber>
            </Left>
            <Right>
                <Header />
                <Content>
                    <Icon color="white" />
                </Content>
                <CntrNumber>
                    <Name>{valid ? `Val. ${valid}` : 'Validade'}</Name>
                </CntrNumber>
            </Right>
            <Bar />
        </Container>
    );
};

export default CreditCardFlipFront;
