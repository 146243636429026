import tw, { styled } from 'twin.macro';
import { IconPerson, IconTruck } from '../../atoms';
import Link from '../../atoms/Link';

export const Address = styled.div`
    ${tw`
      flex
      items-center
      justify-start
      flex-row-reverse
      max-w-[50%]

      lg:max-w-[100%]
      lg:flex-row
      lg:justify-end
`}
`;

export const AddressIcon = styled(IconTruck)`
    ${tw`
    w-6
    min-w-[24px]
    h-6
    lg:order-1
    xl:order-none
    fill-black
`}
`;

export const AddressText = styled.span`
    ${tw`
  font-nunito
  text-15
  text-gray
  pl-[5px]
  lg:text-black
  focus:border-none
  text-right
  cursor-pointer
  max-w-[calc(100% - 10px)]
  mr-[10px]
  truncate

  lg:pl-[0px]
  lg:max-w-[220px]
  
`}
    border:none;
    outline: none;
    /* -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -ms-appearance: none; */
`;

export const Account = styled(Link)`
    ${tw`
  flex
  items-center
  justify-center
  cursor-pointer
  max-w-[50%]
  lg:max-w-[100%]
`}
`;

export const AccountText = styled.span`
    ${tw`
  text-[15px]
  font-nunito
  mr-[10px]
  text-gray
  text-right
  whitespace-nowrap
  overflow-hidden
  overflow-ellipsis
  w-[90%]
  
  lg:w-auto
  lg:max-w-[220px]
  lg:text-black
  lg:text-center

`}
`;

export const AccountIcon = styled(IconPerson)`
    ${tw`
  w-6
  min-w-[24px]
  h-6
  fill-primary
`}
`;
