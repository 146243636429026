import { QueryObserver, QueryObserverOptions } from '@tanstack/react-query';
import { parse as parseHimalaya, stringify } from 'himalaya';
import { NEXT_PUBLIC_URL_API, NEXT_PUBLIC_URL_API_NEXT } from '../../utils/envs';
import { CATEGORIES } from '../../utils/react-query-keys';
import { generateUrlKey } from '../../utils/string';
import { apiAdminPublic } from '../api';
import { queryClient } from '../query-client';
import { Categories, CategoriesById, CategoriesResponse, CategoryImages, SubscribeFn } from './types';
import { existsSync, mkdirSync, readFileSync, unlinkSync, writeFileSync } from 'fs';
import path, { join } from 'path';
import { writeFile } from 'fs/promises';

const getCategoriesWithProductsStorage = async (): Promise<Categories[]> => {
    const result = queryClient.getQueryData<Categories[]>([CATEGORIES]);
    if (result) {
        return result.filter((c: any) => c.product_count > 0);
    }

    return [];
};

const OUTPUT_FILE = path.join(process.cwd(), 'public', 'categories.json');

const removeCategoriesFile = async () => {
    await writeFile(OUTPUT_FILE, '[]');
    await getAll();
};

const getAll = async (): Promise<CategoriesResponse[] | undefined> => {
    try {
        const initialCategories = JSON.parse(readFileSync(OUTPUT_FILE, 'utf8'));

        if (initialCategories.length > 0) {
            return initialCategories as unknown as CategoriesResponse[];
        }

        const response = await apiAdminPublic.get('V1/categories');
        if (response) {
            await writeFile(OUTPUT_FILE, JSON.stringify(response.data.children_data, null, 2));

            return response.data.children_data;
        }
    } catch (e) {
        console.error('getAll', e);
    }
};

const initCategoriesFromLocalFile = (): Categories[] => {
    try {
        const categories = require('../../../public/categories.json');

        return generateCategories(categories);
        return [];
    } catch (e) {
        console.error('initCategoriesFromLocalFile', e);
        return [];
    }
};

const generateCategories = (categories: CategoriesResponse[]): Categories[] => {
    const processCategory = (category: CategoriesResponse, parentUrl: string = ''): Categories => {
        const urlKey = generateUrlKey(category.name);
        const url = parentUrl ? `${parentUrl}/${urlKey}` : urlKey;

        const processedCategory: Categories = {
            id: category.id.toString(),
            name: category.name,
            level: category.level,
            product_count: category.product_count,
            childrenIds: category.children_data ? category.children_data.map((sub) => sub.id) : [],
            children: category.children_data ? category.children_data.map((child) => processCategory(child, url)) : [],
            images: category.description ? parseImages(category.description) : null,
            isActive: category.is_active,
            url: url
        };

        return processedCategory;
    };
    return categories.sort((a, b) => a.position - b.position).map((category) => processCategory(category));
};

const initCategory = async (): Promise<Categories[] | undefined> => {
    const categories = await getAll();

    if (!categories) return;

    return generateCategories(categories);
};

const filterIsActiveCategories = (categories: Categories[]): Categories[] => {
    //filter recursively
    return categories
        .map((category) => {
            const children = filterIsActiveCategories(category.children);
            return {
                ...category,
                children
            };
        })
        .filter((category) => category.isActive);
};

const parseImages = (content: string): CategoryImages | null => {
    try {
        const parse = parseHimalaya(content);
        const table = parse.find((v: any) => v.tagName == 'table');
        if (!table) {
            return null;
        }
        const tbody = table.children.find((v: any) => v.tagName == 'tbody');
        if (!tbody) {
            return null;
        }
        const trs = tbody.children.filter((v: any) => v.tagName == 'tr');

        const image = trs[0].children.filter((v: any) => v.tagName == 'td').map((td: any) => td.children[0])[1];
        const banner = trs[1].children.filter((v: any) => v.tagName == 'td').map((td: any) => td.children[0])[1];
        const menuBanner = trs[2].children.filter((v: any) => v.tagName == 'td').map((td: any) => td.children[0])[1];
        const description = trs?.[3]?.children?.filter((v: any) => v.tagName == 'td')?.[1]?.children;

        const imageSrc = image?.attributes
            ?.find((a: any) => a.key == 'src')
            ?.value.replace(/&quot;/g, '"')
            .match(/\"(.*?)\"/)[1];
        const bannerSrc = banner?.attributes
            ?.find((a: any) => a.key == 'src')
            ?.value.replace(/&quot;/g, '"')
            .match(/\"(.*?)\"/)[1];
        const menuBannerSrc = menuBanner?.attributes
            ?.find((a: any) => a.key == 'src')
            ?.value.replace(/&quot;/g, '"')
            .match(/\"(.*?)\"/)[1];

        return {
            image: imageSrc ? `${NEXT_PUBLIC_URL_API}/media/${imageSrc}` : null,
            banner: bannerSrc ? `${NEXT_PUBLIC_URL_API}/media/${bannerSrc}` : null,
            menuBanner: menuBannerSrc ? `${NEXT_PUBLIC_URL_API}/media/${menuBannerSrc}` : null,
            description: description ? stringify(description) : null
        };
    } catch (e) {
        console.error('parse images category', e);
        return null;
    }
};

const getCategoriesByLevel = (categories: Categories[], quantity: number): Categories[] => {
    const result: Categories[] = [];
    let queue: Categories[] = [...categories];

    while (queue.length > 0 && result.length < quantity) {
        const currentCategory = queue.shift();

        if (currentCategory && currentCategory.name) {
            result.push(currentCategory);

            queue = queue.concat(currentCategory.children);
        }
    }

    return result.slice(0, quantity);
};

const createSubscribe = (fn: SubscribeFn, executeFirst: boolean, options?: QueryObserverOptions<Categories[]>) => {
    const observer = new QueryObserver<Categories[]>(queryClient, {
        queryKey: [CATEGORIES],
        refetchOnMount: true,
        staleTime: 0,
        ...options
    });
    if (executeFirst) {
        const categories = queryClient.getQueryData([CATEGORIES]) as Categories[];
        fn(categories);
    }
    return observer.subscribe(({ data }) => fn(data as Categories[]));
};

function getCategoryFromCategories(categoryId: number | string, categories: Categories[]): Categories | null {
    for (let category of categories) {
        if (category.id.toString() === categoryId.toString()) {
            return category;
        }

        if (category.children) {
            const categoryName = getCategoryFromCategories(categoryId, category.children as Categories[]);
            if (categoryName) {
                return categoryName;
            }
        }
    }

    return null;
}

function isRootCategory(categoryId: number, categories: Categories[]): boolean {
    for (let category of categories) {
        if (category.id.toString() === categoryId.toString()) {
            return true;
        }
    }
    return false;
}

//breadcrumb

function findCategoryPath(categories: Categories[], targetId: string, path: Categories[] = []): Categories[] {
    for (const category of categories) {
        // Add the current category to the path
        path.push(category);
        if (category.id === targetId) {
            // Found the target category
            return path.slice(); // Return a copy of the path
        }
        // If the category has children, search them
        if (category.children && category.children.length > 0) {
            const result = findCategoryPath(category.children, targetId, path);
            if (result.length > 0) {
                // Found in children
                return result;
            }
        }
        // Remove the current category from the path
        path.pop();
    }
    // Not found in this branch
    return [];
}

async function getCategoryById(id: string): Promise<CategoriesById> {
    const { data } = await apiAdminPublic.get(`V1/categories/${id}`);
    return data;
}
// function buildBreadcrumb(path: Categories[]): BreadcrumbItem[] {
//     const breadcrumb: BreadcrumbItem[] = [];
//     const urlParts: string[] = [];

//     for (const category of path) {
//         if (category.level && category.level === 1) {
//             // Skip level 1 category
//             continue;
//         }
//         const urlKey = generateUrlKey(category.name);
//         urlParts.push(urlKey);
//         const url = urlParts.join('/');

//         breadcrumb.push({
//             id: category.id,
//             url: url,
//             name: category.name
//         });
//     }
//     return breadcrumb;
// }

function getBreadcrumb(categories: Categories[], targetId: string): Categories[] {
    const path = findCategoryPath(categories, targetId);

    return path;
}

function findCategoryByUrlKey(categories: Categories[], urlKey: string): Categories | null {
    for (const category of categories) {
        if (category.url === urlKey) {
            return category;
        }
        if (category.children && category.children.length > 0) {
            const found = findCategoryByUrlKey(category.children, urlKey);
            if (found) {
                return found;
            }
        }
    }
    return null;
}

function getCategoryByUrlKey(categories: Categories[], urlKey: string): Categories | null {
    return findCategoryByUrlKey(categories, urlKey);
}

export default {
    getCategoriesWithProductsStorage,
    getAll,
    initCategory,
    createSubscribe,
    isRootCategory,
    getCategoryFromCategories,
    getCategoriesByLevel,
    filterIsActiveCategories,
    getCategoryByUrlKey,
    getBreadcrumb,
    removeCategoriesFile,
    initCategoriesFromLocalFile,
    getCategoryById
};
