import tw, { styled } from 'twin.macro';

export const Container = styled.div`
    ${tw`
        animate-pulse
        bg-white
        flex
        flex-1
        flex-col
        shrink-0
        rounded-[15px]
        overflow-hidden
        max-w-[331px]
        border
        border-gray
    `}
`;
export const Image = styled.div`
    ${tw`
        h-[156px]
        w-full
        bg-gray
        object-cover
    `}
`;
export const Content = styled.div`
    ${tw`
        flex
        flex-col
        items-center
        mx-[10px]
    `}
`;

export const TextDistance = styled.div`
    ${tw`
        h-[15px]
        my-[2px]
        w-[67%]
        bg-gray
        rounded-md
    `}
`;
export const TextTitle = styled.div`
    word-break: break-word;
    ${tw`
    h-[25px]
    w-[72%]
        mt-[9.5px]
        mb-[14.5px]
        bg-gray
        rounded-md
    `}
`;
export const TextDescription = styled.div`
    ${tw`
        h-[15px]
        my-[2px]
        w-[70%]
        bg-gray
        rounded-md
        mt-[9px]
        mb-[15px]
    
    `};
`;
