import { IHallProps } from '../Hall/types';

export const HALL_FEATURED_TITLE = 'Ofertas';
export const HALL_FEATURED_FILTER = {
    bool: {
        filter: [
            {
                terms: {
                    category_ids: [3405]
                }
            }
        ],
        should: []
    }
};

export const HALL_FEATURED_FILTER_2 = {
    bool: {
        filter: [
            {
                terms: {
                    category_ids: [3406]
                }
            }
        ],
        should: []
    }
};
export const HALL_FEATURED_FILTER_3 = {
    bool: {
        filter: [
            {
                terms: {
                    category_ids: [3407]
                }
            }
        ],
        should: []
    }
};

export function createFeaturedHallFilter(categories: string[]) {
    return {
        bool: {
            filter: [
                {
                    terms: {
                        category_ids: categories
                    }
                }
            ],
            should: []
        }
    } as IHallProps['filterGroups'];
}
