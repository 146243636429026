import tw, { styled } from 'twin.macro';
import { IconClose } from '../../atoms';
import Cart from '../../atoms/icons/Cart';
import ModalOrgn from '../../atoms/Modal';

import { ModalHeader, ProductItem } from '../../molecules';
import ListCart from '../List-cart';
import { motion } from 'framer-motion';

// export const Modal = styled(ModalOrgn)<IModalProps>`
//     ${tw`
//     flex
//     bg-white
//     flex-col
//     justify-start

//     absolute
//     w-screen
//     left-0
//     md:w-[448px]
//     md:right-0
//     md:rounded-tl-2xl
//     md:rounded-bl-2xl
//     md:left-auto
//     overflow-hidden
//     h-screen
//     py-[20px]
//     pb-[20px]

//   `}
// `;

export const variants = {
    animate: {
        x: '0'
    },
    initial: {
        x: '100%'
    },
    exit: {
        x: '100%'
    }
};

export const Modal = styled(ModalOrgn)`
    ${tw`
        flex-col
        right-0
        max-h-modalSm
        max-w-modalSm
        h-full
        p-4
        md:h-full
        md:max-h-full
        md:rounded-r-[0]
        md:max-w-modalForceSm
    `}
`;

export const Header = styled(ModalHeader)`
    ${tw`
`}
`;

export const HeaderUpper = styled.div`
    ${tw`
  flex
  flex-row
  w-full
  justify-between
  items-center
`}
`;

export const HeaderTitle = styled.span`
    ${tw`
  flex
  flex-row
  flex-1
  text-primary
  text-[18px]
  font-[700]
  items-center
`}
`;

export const IconCloseModalContainer = styled.div`
    ${tw`
  cursor-pointer
`}
`;

export const IconCloseModal = styled(IconClose)`
    ${tw`
  w-[16px]
  h-[16px]
  fill-primary
`}
`;

export const SubTitle = styled.span`
    ${tw`
  flex
  text-darkGray
  text-[15px]
  font-[400]
  font-nunito
  justify-start
`}
`;

export const ProductsListContainer = styled(motion.div)`
    ${tw`
  w-full
  flex
  flex-col
  pb-[30px]
  flex-1
  overflow-auto
`}
`;

export const CategoryTitle = styled.span`
    ${tw`
  text-primary
  font-nunito
  text-[18px]
  font-[900]
`}
`;

export const CartItemsList = styled(ListCart)`
    ${tw`
  flex
  flex-col
`}
`;

export const CartItem = styled(ProductItem)`
    ${tw`
  mt-4
  hidden
`}
`;

export const Footer = styled.div`
    ${tw`
      mb-4
    `}
`;
