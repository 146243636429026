import tw, { styled } from 'twin.macro';

export const Container = styled.div`
    ${tw`
        absolute
        bottom-[-8px]
        flex
        justify-center
        w-full
    `}
`;

export const Content = styled.div`
    ${tw`
        rounded-16
        shrink-0
        bg-[#F2684C]
        w-[62px]
        h-[25px]
        flex
        items-center
        justify-center
        md:w-[80px]
        md:h-[32px]
        `}
`;

export const Title = styled.div`
    ${tw`
        text-center
        font-extrabold
        text-white
        flex-nowrap
        flex
        items-center
        text-10
        leading-[16.76px]
        h-[16.76px]
        md:text-14
        md:h-[21.33px]
        md:leading-[21.33px]
    `}
`;
