import tw, { styled } from 'twin.macro';

export const Container = styled.div`
    ${tw`
        flex
        flex-col
        gap-6
    `}
`;

export const List = styled.ul`
    ${tw`
        2xl:flex
        2xl:flex-row
        2xl:justify-center

    `}
`;
