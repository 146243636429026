import { useMutation } from '@tanstack/react-query';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useMemo, useState } from 'react';
import { useWishlistWithProducts } from '../../../hooks';
import { CartService } from '../../../services';
import ModalPortal from '../../atoms/Modal-portal';
import { Button } from '../../molecules';
import MyAccountLayout from '../../organisms/My-account-layout';
import Layout from '../Layout';
import LayoutFitter from '../Layout-fitter';
import ModalWishlistItemsToCart from '../Modal-wishlist-items-to-cart';
import { ContentTitle, Header } from './styles';
const WishlistDetailList = dynamic(() => import('../../organisms/Wishlist-detail-list'), { ssr: false });

const Wishlist = () => {
    const [showOutOfStockModal, setShowOutOfStockModal] = useState(false);
    const router = useRouter();

    const wishlistId = router.query.id as string;
    const { data: list, isLoading } = useWishlistWithProducts(wishlistId);

    const handlePressAddToList = () => {
        list && list.products.filter(({ product }) => !product.inStock).length > 0
            ? setShowOutOfStockModal(true)
            : mutateAsync();
    };

    const wishlistName = useMemo(() => (!isLoading && list?.name) || '', [list, wishlistId, isLoading]);

    const { mutateAsync } = useMutation(() => CartService.buyAgain(list?.products), {
        onSuccess: (data) => {
            setShowOutOfStockModal(false);
        },
        onError: () => {}
    });

    const title = useMemo(
        () => (
            <Header>
                <ContentTitle>Lista {wishlistName}</ContentTitle>
                <Button text="Adicionar todos ao carrinho" onClick={handlePressAddToList} />
            </Header>
        ),
        [wishlistName]
    );
    return (
        <>
            <Layout>
                <LayoutFitter>
                    <MyAccountLayout title={title}>
                        {wishlistId && <WishlistDetailList id={wishlistId} />}
                    </MyAccountLayout>
                </LayoutFitter>
            </Layout>
            <ModalPortal>
                {showOutOfStockModal && (
                    <ModalWishlistItemsToCart
                        outOfStockProducts={
                            list?.products.filter(({ product }) => !product.inStock).map(({ product }) => product) || []
                        }
                        onPressConfirm={mutateAsync}
                        onClose={() => setShowOutOfStockModal(false)}
                    />
                )}
            </ModalPortal>
        </>
    );
};

export default Wishlist;
