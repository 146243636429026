import React, { SVGProps } from 'react';

const FlagVisa = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg
            viewBox="0 0 42 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
            width={42}
            height={26}
        >
            <path
                d="M38.572 0H2.173C.973 0 0 .986 0 2.202v21.525c0 1.216.973 2.202 2.173 2.202h36.399c1.2 0 2.173-.986 2.173-2.202V2.202C40.745.986 39.772 0 38.572 0z"
                fill="#0E4595"
            />
            <path
                d="M15.113 18.4l1.813-10.777h2.899L18.01 18.4h-2.898zM28.484 7.855a7.4 7.4 0 00-2.598-.453c-2.864 0-4.882 1.462-4.9 3.557-.015 1.548 1.441 2.412 2.54 2.928 1.129.528 1.508.865 1.503 1.336-.007.723-.901 1.053-1.734 1.053-1.16 0-1.777-.163-2.729-.566l-.373-.171-.407 2.412c.677.301 1.929.562 3.229.575 3.047 0 5.025-1.445 5.047-3.682.011-1.226-.76-2.159-2.433-2.928-1.014-.498-1.634-.83-1.628-1.336 0-.448.526-.927 1.66-.927a5.287 5.287 0 012.17.413l.26.125.393-2.336zM35.944 7.623h-2.24c-.694 0-1.213.192-1.518.894l-4.305 9.876h3.044s.498-1.328.61-1.62l3.713.005c.086.377.352 1.615.352 1.615h2.69l-2.346-10.77zm-3.554 6.959c.24-.621 1.155-3.013 1.155-3.013-.017.029.238-.624.384-1.028l.196.929.671 3.112H32.39zM2.492 7.622l-.037.224c1.145.281 2.169.688 3.065 1.194l2.572 9.342 3.067-.004 4.564-10.756h-3.071l-2.838 7.349-.302-1.493a4.91 4.91 0 00-.045-.137L8.48 8.53c-.176-.682-.684-.885-1.314-.909H2.492z"
                fill="#fff"
            />
        </svg>
    );
};

export default FlagVisa;
