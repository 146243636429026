import tw, { styled } from 'twin.macro';
import { Image } from '../../atoms';

export const Container = styled.div`
    ${tw`
        flex
        flex-row
        gap-6
    `}
    .swiper {
        ${tw`
        [position: unset]
        `}
    }
    .swiper-pagination-bullet {
        ${tw`
            hidden
            
            cursor-pointer
            !w-2.5
            !h-2.5
            bg-[#D9D9D9]
            rounded-full
        `}
    }
    .swiper-pagination-bullet-active {
        ${tw`
            bg-[#95C230]
        `}
    }
    .swiper-pagination {
        ${tw`
           hidden
           flex-row
           gap-1
           pt-2
        `}
    }
`;

export const Right = styled.div`
    ${tw`
        hidden
        flex-col
        gap-4
        flex-[0.33]
        md:flex
    `}
`;

export const ImgPrimary = styled.img`
    ${tw`
        md:rounded-8
    `};
`;

export const Skeleton = styled.div`
    ${tw`
    animate-pulse 
    bg-gray 
    w-full
    
    h-full
    rounded-5
    `}
`;

export const PaginationItem = styled.div`
    ${tw`
        cursor-pointer
        w-2.5
        h-2.5
        bg-[#D9D9D9]
        rounded-full
    `}
`;

export const ArrowContainer = styled.div<{ left?: boolean }>`
    ${({ left }) => {
        if (left) {
            return tw`
                left-0
                md:left-[-15px]
            `;
        }
        return tw`
            right-0
            md:right-[-15px]
        `;
    }}
    ${tw`
        z-[2]
        /* xl:right-[-16px] */

        top-[calc(50% - 24px)]
        cursor-pointer
        absolute
        flex
        items-center
        justify-center
        transition-all
    `}
`;
