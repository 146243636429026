import tw, { styled } from 'twin.macro';
import { IconTrash, TextLatoRegular15, TextNunitoRegular16 } from '../../atoms';
import Button from '../Button';

export const ListItemContainer = styled.div`
    ${tw`
        shadow-md
        flex
        flex-col
        p-[10px]
        rounded-15
        cursor-pointer
        w-full
        
    `}
`;

export const ListItemHeader = styled.div`
    ${tw`
        flex
        justify-between
        items-center
        border-b-gray
        border-b-[1px]
        pb-[5px]
    `}
`;

export const ListItemTitle = styled(TextNunitoRegular16)`
    ${tw`
        flex-1
    `}
`;

export const TrashIcon = styled(IconTrash)`
    ${tw`
        text-black
        w-6
        h-6
        shrink-0
    `}
`;

export const ListItemContent = styled.div`
    ${tw`
        flex-1
        flex
        justify-evenly
        py-[5px]
        gap-[10px]
    `}
`;

export const ButtonDetail = styled(Button)`
    ${tw`
        flex-1
    `}
`;

export const ListItemInfoContainer = styled.div`
    ${tw`
        flex
        flex-col
        items-center
        border-solid
        border-[1px]
        border-lightGray
        rounded-[5px]
        p-[2px]
        my-[10px]
        w-full
    `}
`;

export const InfoHeader = styled(TextNunitoRegular16)``;

export const InfoData = styled(TextNunitoRegular16)`
    ${tw`
        text-primary
    `}
`;
