import { FC, memo } from 'react';
import SelectCollapse from '../Select-collapse';
import { IkeyExtractor, IRenderLabel, ISelectedExtractor } from '../Select-collapse/types';
import { ICheckoutSelectUnavabilityProps, IValue } from './types';

const CheckoutSelectUnavability: FC<ICheckoutSelectUnavabilityProps> = ({ name }) => {
    const values = [
        { label: 'Entre em contato', value: 'Entre em contato' },
        { label: 'Troca por produto similar', value: 'Troca por produto similar' },
        {
            label: 'Cancelar o produto da compra',
            value: 'Cancelar o produto da compra'
        },
        { label: 'Cancelar o pedido', value: 'Cancelar o pedido' }
    ];
    const keyExtractor: IkeyExtractor<IValue> = (item) => item.value;
    const renderLabel: IRenderLabel<IValue> = (item) => item.label;
    const renderLabelSelected = (value: string) => value;
    const selectedExtractor = (item: IValue, value: string) => value == item.value;
    const setValue = (item: IValue) => item.value;

    return (
        <SelectCollapse<IValue, string>
            name={name}
            label=""
            keyExtractor={keyExtractor}
            renderLabel={renderLabel}
            values={values}
            renderLabelSelected={renderLabelSelected}
            selectedExtractor={selectedExtractor}
            setValue={setValue}
        ></SelectCollapse>
    );
};

export default memo(CheckoutSelectUnavability);
