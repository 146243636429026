import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { CartService } from '../../services';
import { IS_BROWSER } from '../../services/query-client';
import { CART_INSTALLMENTS } from '../../utils/react-query-keys';

export const useInstallments = (value: number, params?: UseQueryOptions<number[]>) => {
    return useQuery<number[]>([CART_INSTALLMENTS, value], () => CartService.getInstallments(value), {
        refetchOnMount: true,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        enabled: IS_BROWSER,
        ...params
    });
};
