import dynamic from 'next/dynamic';
import { RouterService } from '../../../services';
import IconArrowLeftLong from '../../atoms/icons/Arrow-left-long';
import Breadcrumb from '../../organisms/Breadcrumb';
import Layout from '../Layout';
import LayoutFitter from '../Layout-fitter';
import { Container, TitlePage } from './styles';
const WishlistList = dynamic(() => import('../../organisms/Wishlist-list'), { ssr: false });

const WishlistDetail = () => {
    return (
        <Layout>
            <LayoutFitter>
                <div>
                    <Breadcrumb
                        items={[
                            { label: 'Página Inicial', url: '/' },
                            {
                                label: 'Minha Conta',
                                url: '/minha-conta'
                            },
                            { label: 'Minhas listas' }
                        ]}
                    />
                    <TitlePage>
                        <button onClick={() => RouterService.back()}>
                            <IconArrowLeftLong width={32} color="#424242" />
                        </button>
                        <h1 className="w-full text-[22px] font-bold  text-[#424242]">Minhas listas</h1>
                    </TitlePage>
                </div>

                <Container>
                    <WishlistList />
                </Container>
            </LayoutFitter>
        </Layout>
    );
};

export default WishlistDetail;
