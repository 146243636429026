import tw, { styled } from 'twin.macro';

export const Container = styled.div`
    ${tw`
        flex
        flex-row
        justify-start
        items-start
        gap-4
        border-[0.5px]
        border-gray
        animate-pulse
        p-4
        relative
        cursor-pointer
        rounded-12
        md:rounded-16
    `}
`;

export const ImageContainer = styled.div`
    ${tw`
        relative
        bg-gray
        animate-pulse
        h-[80px]
        w-[80px]
        shrink-0
        rounded-8
`}
`;

export const Title = styled.div`
    ${tw`
        bg-gray
        rounded-md
        animate-pulse
        h-6
        my-1
        w-full
        md:my-1.5
        md:h-7

    `}
`;

export const Price = styled.div`
    ${tw`
        h-7
        rounded-md
        animate-pulse
        bg-gray
        w-1/3
    `}
`;

export const SubPrice = styled.div`
    ${tw`
         h-4
         rounded-md
         animate-pulse
         bg-gray
         w-1/2
    `}
`;
