import { FC, forwardRef, HTMLAttributes, memo, Ref, useRef } from 'react';
import 'swiper/css';
import { ElasticService, ReactGa4Service } from '../../../services';
import { IProduct } from '../../../services/product/types';
import { HALL } from '../../../utils/react-query-keys';
import { Error, ProductItem } from '../../molecules';
import HallSkeleton from '../Hall-skeleton';
import { Container, ContainerIcon, ContainerSwiper, ContainerTitle, TextTitle } from './styles';
import { IHallProps } from './types';

import { useQuery } from '@tanstack/react-query';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

interface ICarouselArrowProps extends Partial<HTMLAttributes<HTMLDivElement>> {
    left?: boolean;
}

const CarouselArrow = forwardRef(({ onClick, left = false, ...rest }: ICarouselArrowProps, ref: Ref<any>) => {
    return (
        <ContainerIcon $left={left} ref={ref} {...rest}>
            {left ? (
                <svg xmlns="http://www.w3.org/2000/svg" className="w-10 h-10" viewBox="0 0 40 40" fill="none">
                    <rect width="40" height="40" rx="20" transform="matrix(-1 0 0 1 40 0)" fill="#9FBE43" />
                    <path
                        d="M23.3333 13.3334L16.6666 20.1026L23.3333 26.6667"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            ) : (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-10 h-10 rotate-180"
                    viewBox="0 0 40 40"
                    fill="none"
                >
                    <rect width="40" height="40" rx="20" transform="matrix(-1 0 0 1 40 0)" fill="#9FBE43" />
                    <path
                        d="M23.3333 13.3334L16.6666 20.1026L23.3333 26.6667"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            )}
        </ContainerIcon>
    );
});

const Hall: FC<IHallProps> = ({
    title,
    id,
    filterGroups,
    filterGroups1,
    horizontal = false,
    useTitleInQuery = true,
    skusFirst = [],
    skeletonQty = 8,
    sort
}) => {
    const refArrowLeft = useRef<any>(null);
    const refArrowRight = useRef<any>(null);

    const key = useTitleInQuery ? [HALL, title, id?.toString()] : [HALL, filterGroups, filterGroups1, id?.toString()];

    const { data, isLoading, isError, refetch } = useQuery(
        key,
        async (paramsAxios) => {
            const [items, items1] = await Promise.all([
                ElasticService.getProducts({ filter: filterGroups, limit: 30, page: 1, paramsAxios, sort }),
                filterGroups1
                    ? ElasticService.getProducts({ filter: filterGroups1, limit: 30, page: 1, paramsAxios, sort })
                    : { items: [] }
            ]);

            items.items = items.items || [];
            if (items1.items) {
                const list: IProduct[] = [];
                skusFirst.map((sku) => {
                    const exists = items1.items.find((item) => item.sku == sku);
                    if (exists) list.push(exists);
                });

                items.items = [...list, ...items.items];
            }

            return items;
        },
        {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            staleTime: 1000 * 60,
            onSuccess: (data) => {
                if (data && data.items && data.items.length) {
                    ReactGa4Service.onViewItemList(data.items);
                }
            }
        }
    );

    if (filterGroups == undefined) {
        console.error('informe os filtros ', title);
        return null;
    }

    if (isLoading) {
        return <HallSkeleton qty={skeletonQty} horizontal={horizontal} />;
    }
    if (isError) {
        return <Error onPress={refetch} />;
    }
    if (data?.items.length === 0) {
        return null;
    }

    if (data?.items === null || data?.items === undefined || data?.items.length === 0) return null;
    return (
        <Container className="px-4 md:px-0">
            <ContainerSwiper>
                <Swiper
                    className=""
                    spaceBetween={8}
                    grabCursor={true}
                    slidesPerView={'auto'}
                    breakpoints={undefined}
                    pagination={{
                        clickable: true,

                        renderBullet: function (index, className) {
                            return '<div class="' + className + '">&nbsp;</div>';
                        }
                    }}
                    modules={[Pagination, Navigation]}
                    autoplay={false}
                    speed={500}
                    navigation={{
                        prevEl: refArrowLeft.current || undefined,
                        nextEl: refArrowRight.current || undefined,
                        disabledClass: 'btn-disabled'
                    }}
                    onInit={(swiper: any) => {
                        swiper.params.navigation.prevEl = refArrowLeft.current;

                        swiper.params.navigation.nextEl = refArrowRight.current;
                        swiper.navigation.init();
                        swiper.navigation.update();
                    }}
                >
                    {data.items.map((item, index) => (
                        <SwiperSlide key={index}>
                            <ProductItem forceWidth data={item} />
                        </SwiperSlide>
                    ))}

                    <ContainerTitle>
                        <TextTitle>{title}</TextTitle>
                        <span slot="container-end" className="container-end">
                            <CarouselArrow left ref={refArrowLeft}></CarouselArrow>
                            <CarouselArrow ref={refArrowRight}></CarouselArrow>
                        </span>
                    </ContainerTitle>
                </Swiper>
            </ContainerSwiper>
        </Container>
    );
};

export default memo(Hall);
