import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { CardService } from '../../../services/';
import { CARDS } from '../../../utils/react-query-keys';
import { ucfirst } from '../../../utils/string';
import { BrandContainer, BrandLabel, Container, Flag, InfosContainer, LowerRow, TrashIcon, UpperRow } from './styles';
import { ICreditCardProps } from './types';

const handleDeleteCard = async (id: number) => {
    const data = await CardService.deleteTtxPayCard(id);
};

const CreditCardItem = ({ data }: ICreditCardProps) => {
    const { brand, number, created_at, id } = data;
    const formatedDate = new Date(created_at).toLocaleDateString('pt-BR');
    return (
        <Container>
            <InfosContainer>
                <UpperRow>
                    <BrandContainer>
                        <Flag width={15} height={15} type={brand} />
                        <BrandLabel>{ucfirst(brand)}</BrandLabel>
                    </BrandContainer>
                </UpperRow>
                <LowerRow>
                    <span>{`******${number.substring(8)}`}</span>
                    <span>Criado em: {formatedDate}</span>
                </LowerRow>
            </InfosContainer>
            <TrashIcon onClick={() => handleDeleteCard(id)} />
        </Container>
    );
};

export default CreditCardItem;
