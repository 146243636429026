import * as cookie from 'cookie';
import { Request, Response } from 'express';
import { IncomingMessage, ServerResponse } from 'http';
import { NextApiRequest, NextApiResponse, NextPageContext } from 'next';
import { NextRequest, NextResponse } from 'next/server';
import * as setCookieParser from 'set-cookie-parser';
import { Cookie, parse } from 'set-cookie-parser';

export function isBrowser(): boolean {
    return typeof window !== 'undefined';
}

export type Dict<T = any> = { [key: string]: T };

/**
 * Create an instance of the Cookie interface
 */
export function createCookie(name: string, value: string, options: cookie.CookieSerializeOptions): Cookie {
    let sameSite = options.sameSite;
    if (sameSite === true) {
        sameSite = 'strict';
    }
    if (sameSite === undefined || sameSite === false) {
        sameSite = 'lax';
    }
    const cookieToSet = { ...options, sameSite: sameSite };
    delete cookieToSet.encode;
    return {
        name: name,
        value: value,
        ...cookieToSet
    };
}

/**
 * Tells whether given objects have the same properties.
 */
export function hasSameProperties(a: Dict, b: Dict) {
    const aProps = Object.getOwnPropertyNames(a);
    const bProps = Object.getOwnPropertyNames(b);

    if (aProps.length !== bProps.length) {
        return false;
    }

    for (let i = 0; i < aProps.length; i++) {
        const propName = aProps[i];

        if (a[propName] !== b[propName]) {
            return false;
        }
    }

    return true;
}

/**
 * Compare the cookie and return true if the cookies have equivalent
 * options and the cookies would be overwritten in the browser storage.
 *
 * @param a first Cookie for comparison
 * @param b second Cookie for comparison
 */
export function areCookiesEqual(a: Cookie, b: Cookie) {
    let sameSiteSame = a.sameSite === b.sameSite;
    if (typeof a.sameSite === 'string' && typeof b.sameSite === 'string') {
        sameSiteSame = a.sameSite.toLowerCase() === b.sameSite.toLowerCase();
    }

    return hasSameProperties({ ...a, sameSite: undefined }, { ...b, sameSite: undefined }) && sameSiteSame;
}

export interface ICookieCtx {
    req?: NextApiRequest | NextRequest | Request | IncomingMessage;
    res?: NextApiResponse | NextResponse | Response | ServerResponse;
}

/**
 * Parses cookies.
 *
 * @param ctx NextJS page, middleware or API context, express context, null or undefined.
 * @param options Options that we pass down to `cookie` library.
 */
export function parseCookies(ctx?: ICookieCtx, options?: cookie.CookieParseOptions) {
    if (ctx?.req instanceof NextRequest) {
        return ctx.req.cookies;
    }

    if (ctx?.req?.headers?.cookie) {
        return cookie.parse(ctx.req.headers.cookie as string, options);
    }

    if (isBrowser()) {
        return cookie.parse(document.cookie, options);
    }

    return {};
}
export function getCookie(name: string, ctx?: ICookieCtx, options?: cookie.CookieParseOptions) {
    if (ctx?.req instanceof NextRequest && ctx.res instanceof NextResponse) {
        const exist = ctx.res.cookies.get(name);

        if (!exist) return ctx.req.cookies.get(name)?.value;

        return exist;
    }

    if (ctx?.req && ctx.req instanceof IncomingMessage) {
        if (ctx && ctx.res instanceof ServerResponse) {
            let cookies = ctx.res.getHeader('Set-Cookie') || [];

            if (typeof cookies === 'string') cookies = [cookies];
            if (typeof cookies === 'number') cookies = [];
            const parsedCookies = setCookieParser
                .parse(cookies, {
                    decodeValues: false
                })
                .find((v) => v.name == name);
            if (parsedCookies) return parsedCookies.value;
        }

        if (!ctx?.req?.headers?.cookie) return null;

        const obgReq = cookie.parse(ctx?.req?.headers?.cookie as string, options);
        return obgReq ? obgReq[name] : null;
    }

    const result = parseCookies(ctx, options) as Record<string, string>;
    if (name in result) return result[name];

    return null;
}

/**
 * Sets a cookie.
 *
 * @param ctx NextJS page, middleware or API context, express context, null or undefined.
 * @param name The name of your cookie.
 * @param value The value of your cookie.
 * @param options Options that we pass down to `cookie` library.
 */
export function setCookie(name: string, value: string, ctx?: ICookieCtx, a: cookie.CookieSerializeOptions = {}) {
    const options: cookie.CookieSerializeOptions = {
        path: '/',
        ...a
    };
    if (ctx?.res instanceof NextResponse) {
        ctx.res.cookies.set(name, value, options);
        return {};
    }

    // SSR
    if (ctx?.res?.getHeader && ctx?.res) {
        // Check if response has finished and warn about it.
        if (ctx?.res?.finished) {
            console.warn(`Not setting "${name}" cookie. Response has finished.`);
            console.warn(`You should set cookie before res.send()`);
            return {};
        }

        /**
         * Load existing cookies from the header and parse them.
         */
        let cookies = ctx.res.getHeader('Set-Cookie') || [];

        if (typeof cookies === 'string') cookies = [cookies];
        if (typeof cookies === 'number') cookies = [];

        /**
         * Parse cookies but ignore values - we've already encoded
         * them in the previous call.
         */
        const parsedCookies = setCookieParser.parse(cookies, {
            decodeValues: false
        });

        /**
         * We create the new cookie and make sure that none of
         * the existing cookies match it.
         */
        const newCookie = createCookie(name, value, options);
        let cookiesToSet: string[] = [];

        parsedCookies.forEach((parsedCookie: Cookie) => {
            if (!areCookiesEqual(parsedCookie, newCookie)) {
                /**
                 * We serialize the cookie back to the original format
                 * if it isn't the same as the new one.
                 */
                const serializedCookie = cookie.serialize(parsedCookie.name, parsedCookie.value, {
                    // we prevent reencoding by default, but you might override it
                    encode: (val: string) => val,
                    ...(parsedCookie as cookie.CookieSerializeOptions)
                });

                cookiesToSet.push(serializedCookie);
            }
        });
        cookiesToSet.push(cookie.serialize(name, value, options));

        // Update the header.
        ctx.res.setHeader('Set-Cookie', cookiesToSet);
    }

    // Browser
    if (isBrowser()) {
        if (options && options.httpOnly) {
            throw new Error('Can not set a httpOnly cookie in the browser.');
        }

        document.cookie = cookie.serialize(name, value, options);
    }

    return {};
}

/**
 * Destroys a cookie with a particular name.
 *
 * @param ctx NextJS page or API context, express context, null or undefined.
 * @param name Cookie name.
 * @param options Options that we pass down to `cookie` library.
 */
export function destroyCookie(name: string, ctx?: ICookieCtx, options?: cookie.CookieSerializeOptions) {
    /**
     * We forward the request destroy to setCookie function
     * as it is the same function with modified maxAge value.
     */
    return setCookie(name, '', ctx, { ...(options || {}), maxAge: -1, path: '/' });
}

/* Utility Exports */

export default {
    set: setCookie,
    get: parseCookies,
    destroy: destroyCookie
};
