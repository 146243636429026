import axios from 'axios';
import { AnyObject } from 'yup/lib/types';
import { ICookieCtx } from '../../utils/cookie';
import {
    NEXT_PUBLIC_API_KEY_ADMIN,
    NEXT_PUBLIC_API_KEY_ADMIN_PUBLIC,
    NEXT_PUBLIC_ELASTIC_AUTH,
    NEXT_PUBLIC_STORE_DEFAULT_CODE_ID,
    NEXT_PUBLIC_URL_API,
    NEXT_PUBLIC_URL_API_ELASTIC,
    NEXT_PUBLIC_URL_API_NEXT
} from '../../utils/envs';
import { IGetClientApi } from '../api/types';
import authService from '../auth';
import cookieService from '../cookie';
import { IS_BROWSER } from '../query-client';
import { ConfigsLayoutContainer } from '../../components/organisms/Exhibition-configs/styles';

const getClientApi = (ctx?: IGetClientApi, disableLogin = false) => {
    let api = axios.create({
        baseURL: ``,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    });

    api.interceptors.request.use((config) => interceptorRequest(config, ctx));

    api.defaults.headers.common['Authorization'] = '';
    let token = authService.getUserTokenStorage(ctx);

    if (token) {
        api.defaults.headers.common['Authorization'] = 'Bearer ' + token;

        if (!disableLogin) {
            api.interceptors.response.use(undefined, async (err) => {
                const error = err.response;
                if (error?.status === 401 && error?.config && !error.config?.__isRetryRequest) {
                    try {
                        const token = await authService.requestNewToken(ctx);
                        api.defaults.headers.common['Authorization'] = 'Bearer ' + token;
                        error.config.headers['Authorization'] = 'Bearer ' + token;
                        authService.setUserTokenStorage(token, ctx);
                    } catch (e) {}
                    error.config.__isRetryRequest = true;
                    return api(error.config);
                }
                if (error?.status === 401 && error?.config.__isRetryRequest) {
                    authService.logout();
                }

                return Promise.reject(err);
            });
        }
    }
    return api;
};
const getApiElastic = (options?: ICookieCtx, elasticId?: string) => {
    const api = axios.create({
        baseURL: `${NEXT_PUBLIC_URL_API_ELASTIC}/`,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Basic ${btoa(NEXT_PUBLIC_ELASTIC_AUTH)}`
        }
    });
    api.interceptors.request.use((config: any) => {
        config.url += `matcon*/_search?pretty`;
        return config;
    });
    return api;
};

const getClientApiNext = () => {
    let api = axios.create({
        baseURL: `${NEXT_PUBLIC_URL_API_NEXT}/api/default/`,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    });

    api.defaults.headers.common['Authorization'] = '';
    let token = authService.getUserTokenStorage();

    api.defaults.headers.common['Authorization'] = 'Bearer ' + token;

    api.interceptors.response.use(undefined, async (err) => {
        const error = err.response;
        if (error?.status === 401 && error?.config && !error.config.__isRetryRequest) {
            try {
                const token = await authService.requestNewToken();

                api.defaults.headers.common['Authorization'] = 'Bearer ' + token;
                error.config.headers['Authorization'] = 'Bearer ' + token;
                authService.setUserTokenStorage(token);
            } catch (e) {}
            error.config.__isRetryRequest = true;
            return api(error.config);
        }
        if (error?.status === 401 && error?.config?.__isRetryRequest) {
            authService.logout();
        }

        return Promise.reject(err);
    });
    return api;
};

const getApiAdmin = (establishmentKey: string) => {
    const api = axios.create({
        baseURL: `${NEXT_PUBLIC_URL_API}rest/default`,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            authorization: 'Bearer ' + NEXT_PUBLIC_API_KEY_ADMIN
        }
    });
    // api.interceptors.request.use((config) => interceptorRequest(config, ctx));
    return api;
};
const getApiAdminPublic = (ctx?: IGetClientApi) => {
    const api = axios.create({
        baseURL: `${NEXT_PUBLIC_URL_API}rest/default`,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            authorization: 'Bearer ' + NEXT_PUBLIC_API_KEY_ADMIN_PUBLIC
        }
    });

    api.interceptors.request.use((config) => interceptorRequest(config, ctx));

    return api;
};

const interceptorRequest = async (config: AnyObject, ctx?: IGetClientApi) => {
    const establishment = cookieService.getEstablishmentStorage(ctx);
    const establishmentCodeId = establishment?.list?.[0]?.code || NEXT_PUBLIC_STORE_DEFAULT_CODE_ID;
    config.baseURL = `${NEXT_PUBLIC_URL_API}rest/${establishmentCodeId}`;
    return config;
};
export default { getClientApi, getClientApiNext, getApiAdmin, getApiAdminPublic, getApiElastic };
