import tw, { styled } from 'twin.macro';
import { Modal, TextLatoBlack18 } from '../../atoms';
import { Button, ModalHeader } from '../../molecules';

export const Container = styled(Modal)`
    ${tw`
        md:max-w-modalForceSm
        flex
        flex-col
    `}
`;
export const Header = styled(ModalHeader)`
    ${tw`
        my-[10px]
    `}
`;
export const Card = styled.div`
    ${tw`
    flex
    flex-col
    mt-[32px]
    items-center
    `}
`;

export const Content = styled.div`
    ${tw`
        flex-shrink-0
        flex-col
        flex-1
        flex
        overflow-y-auto
        overflow-x-hidden
    `}
`;
export const Footer = styled.div`
    ${tw`
        p-[20px]
        flex
        flex-row
    `}
`;
export const ButtonAdd = styled(Button)`
    ${tw`
        flex-1
    `}
`;
export const Form = styled.div`
    ${tw`
        pt-[32px]
        px-[20px]
        flex-1
        flex
        flex-col
    `}
`;

export const TextTitle = styled(TextLatoBlack18)`
    ${tw`
        pb-[20px]
    `}
`;
export const InputCvv = styled.div`
    ${tw`
        flex
        flex-row
        items-center
        gap-[24px]
    `}
`;
