import tw, { styled } from 'twin.macro';

export const Container = styled.div`
    ${tw`
        flex-row
        gap-4
        items-center
        text-18
        hidden
        md:flex
    `}
`;
