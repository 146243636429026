import { FC, useRef } from 'react';
import { Autoplay, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ArrowContainer, Container, ImgPrimary, Right } from './styles';
import { IBannerCarouselSlideShowThreeInlineProps } from './types';

import { useQuery } from '@tanstack/react-query';
import 'swiper/css';
import { BannerService } from '../../../services';
import { BANNER } from '../../../utils/react-query-keys';
import { LayoutFitter } from '../../templates';
import useOnClickBanner from '../../../hooks/use-on-click-banner';

const BannerCarouselSlideShowThreeInline: FC<IBannerCarouselSlideShowThreeInlineProps> = ({ id }) => {
    const refArrowLeft = useRef<any>(null);
    const refArrowRight = useRef<any>(null);

    const { data: images } = useQuery([BANNER, id], () => BannerService.getById(id), {
        refetchOnWindowFocus: false,
        refetchOnMount: false
    });

    const lastTwoImages = images?.slice(-2);
    const firstImage = images?.slice(0, images.length - 2);

    const onClickBanner = useOnClickBanner();
    return (
        <LayoutFitter disabledOverflow withoutPaddingMobile>
            <Container>
                <div className="max-w-[887px] flex flex-1 md:flex-[0.676] relative">
                    <Swiper
                        className=""
                        grabCursor={true}
                        slidesPerView={'auto'}
                        breakpoints={undefined}
                        modules={[Pagination, Navigation, Autoplay]}
                        pagination={{
                            clickable: true,

                            renderBullet: function (index, className) {
                                return '<div class="' + className + '">&nbsp;</div>';
                            }
                        }}
                        autoplay={{
                            delay: 2000,
                            disableOnInteraction: true,
                            pauseOnMouseEnter: true,
                            stopOnLastSlide: false
                        }}
                        speed={500}
                        navigation={{
                            prevEl: refArrowLeft.current || undefined,
                            nextEl: refArrowRight.current || undefined,
                            disabledClass: 'btn-disabled'
                        }}
                        onInit={(swiper: any) => {
                            swiper.params.navigation.prevEl = refArrowLeft.current;

                            swiper.params.navigation.nextEl = refArrowRight.current;
                            swiper.navigation.init();
                            swiper.navigation.update();
                        }}
                    >
                        {firstImage?.map((image, index) => (
                            <SwiperSlide key={index} className="cursor-pointer" onClick={() => onClickBanner(image)}>
                                <ImgPrimary src={image.image} alt={`image-banner-${index}`} />
                            </SwiperSlide>
                        ))}

                        {firstImage && firstImage?.length > 1 && (
                            <span slot="container-end">
                                <ArrowContainer ref={refArrowLeft} left>
                                    <svg
                                        width="40"
                                        height="40"
                                        viewBox="0 0 40 40"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <circle
                                            className="hidden md:block"
                                            cx="20"
                                            cy="20"
                                            r="19.5"
                                            fill="white"
                                            stroke="#698122"
                                        />
                                        <path
                                            className="stroke-[#424242] md:stroke-[#698122]"
                                            d="M23 13L17 19.6L23 26"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </ArrowContainer>

                                <ArrowContainer ref={refArrowRight}>
                                    <svg
                                        width="40"
                                        height="40"
                                        viewBox="0 0 40 40"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <circle
                                            className="hidden  md:block"
                                            cx="20"
                                            cy="20"
                                            r="19.5"
                                            fill="white"
                                            stroke="#698122"
                                        />
                                        <path
                                            className="stroke-[#424242] md:stroke-[#698122]"
                                            d="M17 13L23 19.6L17 26"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </ArrowContainer>
                            </span>
                        )}
                    </Swiper>
                </div>
                <Right>
                    {lastTwoImages && lastTwoImages.length && (
                        <>
                            <ImgPrimary
                                src={lastTwoImages?.[0].image}
                                height={275}
                                width={430}
                                alt="image-banner-2"
                                className="cursor-pointer"
                                onClick={() => onClickBanner(lastTwoImages[0])}
                                style={{ aspectRatio: '16/10.2' }}
                            />
                            <ImgPrimary
                                src={lastTwoImages?.[1].image}
                                height={245}
                                width={430}
                                alt="image-banner-3"
                                className="cursor-pointer"
                                onClick={() => onClickBanner(lastTwoImages[1])}
                            />
                        </>
                    )}
                </Right>
            </Container>
        </LayoutFitter>
    );
};

export default BannerCarouselSlideShowThreeInline;
