import React from 'react';
import { useAddress } from '../../../hooks';
import { Container } from './styles';
import { IMyAddressesRegisteredContainerProps } from './types';
import { RegisteredAddress } from '../../molecules';

const MyAddressesRegisteredContainer = ({ onPressDefault }: IMyAddressesRegisteredContainerProps) => {
    const { data } = useAddress();

    return (
        <Container>
            {data?.addressList.map((address) => (
                <RegisteredAddress onPressDefault={onPressDefault} address={address} key={address.id?.toString()} />
            ))}
        </Container>
    );
};

export default MyAddressesRegisteredContainer;
