import { Status, Wrapper } from '@googlemaps/react-wrapper';
import { forwardRef, memo, Ref, useEffect, useImperativeHandle, useRef } from 'react';
import { Container, Pin } from './styles';
import { ICenter, IMapHandle, MapProps } from './types';

const RenderMap = forwardRef(
    (
        {
            center,
            zoom
        }: {
            center: ICenter;
            zoom: number;
        },
        ref: Ref<IMapHandle>
    ) => {
        const refElement = useRef<HTMLDivElement>(null);
        const refMap = useRef<google.maps.Map>();

        useEffect(() => {
            if (refElement.current) {
                refMap.current = new window.google.maps.Map(refElement.current, {
                    center: { lat: parseFloat(center.lat), lng: parseFloat(center.lng) },
                    zoom
                });

                refMap.current.addListener('dragend', () => {
                });
                //Create and open InfoWindow.
            }
        }, []);

        useImperativeHandle(ref, () => ({
            map: refMap.current,
            getCenter: () => ({
                lat: refMap.current?.getCenter()?.lat().toString() as string,
                lng: refMap.current?.getCenter()?.lng().toString() as string
            })
        }));

        return <div ref={refElement} className="h-full w-full bg-darkGray" />;
    }
);

const Map = forwardRef(({ center, ...rest }: MapProps, ref: Ref<IMapHandle>) => {
    const render = (status: Status) => {
        switch (status) {
            case Status.LOADING:
                return <div>carregando mapa</div>;
            case Status.FAILURE:
                return <div>error mapa</div>;
        }
        return <div></div>;
    };

    return (
        <Container {...rest}>
            <Pin />
            <Wrapper apiKey={process.env.NEXT_PUBLIC_API_KEY_GOOGLE_MAPS || ''} render={render}>
                <RenderMap ref={ref} center={center} zoom={18} />
            </Wrapper>
        </Container>
    );
});

export default Map;
