import FilterContent from '../FilterContent';
import { Container } from './styles';

function FilterDesktop() {
    return (
        <Container>
            <FilterContent />
        </Container>
    );
}

export default FilterDesktop;
