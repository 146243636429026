import { useProductSearchFilters } from '../../hooks/useProductSearchFilters';
import SortButton from './SortButton';
import { Container } from './styles';

function SortDesktop() {
    const { setSort, sort } = useProductSearchFilters();
    return (
        <Container>
            <div>Ordenar por:</div>
            {/* <SortButton text="Mais vendidos" /> */}
            <SortButton text="Menor Preço" onClick={() => setSort('price_asc')} selected={sort === 'price_asc'} />
            <SortButton text="Maior Preço" onClick={() => setSort('price_desc')} selected={sort === 'price_desc'} />
            <SortButton text="De A a Z" onClick={() => setSort('name_asc')} selected={sort === 'name_asc'} />
            <SortButton text="De Z a A" onClick={() => setSort('name_desc')} selected={sort === 'name_desc'} />
            <SortButton text="Relevância" onClick={() => setSort('relevance')} selected={sort === 'relevance'} />
            {/* <SortButton text="Promoção" onClick={() => setSort('pct_discount')} selected={sort === 'pct_discount'} /> */}
            {/* <SortButton text="Promoção" /> */}
        </Container>
    );
}

export default SortDesktop;
