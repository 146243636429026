import tw, { styled } from 'twin.macro';

export const Container = styled.li`
    ${tw`
    w-full
    rounded-full
    flex
    justify-center
    border-[1px]
    border-gray
    items-center
    mb-[10px]
    py-[10px]
    px-[30px]
  `}
`;
