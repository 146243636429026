import { UseMutationOptions } from '@tanstack/react-query';
import { WishlistService } from '../../services';
import { useMutationCancelToken } from '../use-mutation-cancel-token';

export const useWishlistDelete = (options?: UseMutationOptions<void, unknown, string>) => {
    return useMutationCancelToken<void, unknown, string>(
        (axiosParams, params) => WishlistService.deleteWishlist(params, axiosParams),
        options
    );
};
