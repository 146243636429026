import tw, { styled } from 'twin.macro';
import { TextNunitoRegular12, TextNunitoRegular14 } from '../../../atoms';
import { motion } from 'framer-motion';
import { variants } from '../../Search-filters/styles';
import Close from '../../../atoms/icons/Close';

export const Container = styled(motion.div).attrs(() => ({
    variants: {
        hidden: { opacity: 0, height: 0 },
        visible: { opacity: 1, height: 'auto' }
    }
}))`
    ${tw`
        bg-[#F2684C]
        overflow-hidden
    `}
`;

export const Content = styled.div`
    ${tw`
        flex
        justify-center
        items-center
        py-3
        px-[14px]
        md:px-0
        md:py-4
    `}
`;

export const TextTitle = styled(TextNunitoRegular14)`
    ${tw`text-center text-white `}
`;

export const TextMobile = styled(TextNunitoRegular12)`
    ${tw`text-center text-white cursor-pointer md:hidden`}
`;

export const CrossIcon = styled(Close)`
    ${tw`
    rounded-[100%]
    bg-white
    h-[14px]
    w-[14px]
    p-[2px]
    fill-[#555]
    m-0
    ml-[5px]
    cursor-pointer
`}
`;
