import tw, { styled } from 'twin.macro';
import { Image } from '../../atoms';

export const Container = styled.div`
    ${tw`
        
    `}
`;

export const Img = styled(Image)`
    ${tw`
        rounded-5
       
    `};
`;

export const Skeleton = styled.div`
    aspect-ratio: 16/8;
    ${tw`
    animate-pulse 
    bg-gray 
    w-full
    
    h-full
    rounded-5
    `}
`;
