import tw, { styled } from 'twin.macro';

export const Container = styled.div`
    ${tw`
        flex
        flex-row
        gap-8
    `}
`;

export const Products = styled.div`
    ${tw`
        grid
        grid-cols-2
        gap-6
        w-full
        md:grid-cols-2
        lg:grid-cols-3
        xl:grid-cols-4
    `}
`;

export const Content = styled.div`
    ${tw`
        flex
        flex-col
        gap-12
        w-full
    `}
`;
