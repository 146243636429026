import Link from 'next/link';
import { Column, Menu, Title } from '../styles';
import dynamic from 'next/dynamic';

const Rescue = dynamic(() => import('./Rescue'), { ssr: false });

interface IAboutUsProps {
    title?: boolean;
}
function AboutUs({ title = false }: IAboutUsProps) {
    return (
        <Column>
            {title && <Title>Institucional</Title>}
            <Menu>
                <Link href="/quem-somos">Quem Somos</Link>
            </Menu>
            <Menu>
                <Link href="/trocas-e-devolucoes">Pedidos, Trocas e Devoluções</Link>
            </Menu>
            {/* <Menu>
                <Link href="/politica-de-entrega">Política de entrega</Link>
            </Menu> */}
            <Menu>
                <Link href="/politica-de-pagamento">Política de Pagamentos</Link>
            </Menu>
            <Menu>
                <Link href="/politica-de-privacidade">Política de Privacidade</Link>
            </Menu>
            <Menu>
                <Link href="/termos-e-condicoes">Termos e Condições de Uso</Link>
            </Menu>
            <Rescue />
        </Column>
    );
}

export default AboutUs;
