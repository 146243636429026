import { useRouter } from 'next/router';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useUserLogged, useWishlist, useWishlistAdd } from '../../../../hooks';
import { useWishlistRemoveItem } from '../../../../hooks/use-wishlist-remove-item';
import { IconSpinner } from '../../../atoms';
import ModalPortal from '../../../atoms/Modal-portal';
import IncludeItemWishlist from '../../Include-item-wishlist';
import { Container, Icon, IconFilled } from './styles';
import { LoginDrawer } from '../../../organisms/Login-drawer';

const WISHLIST_NAME = ['My Wishlist', 'Principal'];

interface IProductShoppingListProps {
    sku: string;
    productId: number;
    onlyIcon?: boolean;
    classNameIcon?: string;
    wishListId?: string;
}

function ProductShoppingList({
    sku,
    productId,
    onlyIcon = false,
    classNameIcon,
    wishListId
}: IProductShoppingListProps) {
    const [showModalWishlist, setShowModalWishlist] = useState<boolean>(false);
    const [showLogin, setShowLogin] = useState<boolean>(false);

    const { data: wishlist } = useWishlist();
    const { mutate: addWishlist, isLoading: isAddingWishlist } = useWishlistAdd({
        onSuccess: () => {
            toast.success(
                <div className="flex flex-row justify-between">
                    <span>Adicionado aos Meus Favoritos</span>
                    <a
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            setShowModalWishlist(true);
                        }}
                    >
                        INCLUIR NA LISTA
                    </a>
                </div>,
                {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: true,
                    className: 'md:w-[500px] text-sm'
                }
            );
        },
        onError: (error: any) => {
            console.log('error', error);
            if (error.response.data.message?.toLowerCase().includes('without stock')) {
                toast.error(
                    'Não foi possível adicionar o produto à lista de compras, pois está sem estoque no momento'
                );
            } else {
                toast.error(error.response.data.message);
            }
        }
    });
    const { mutate: removeWishlist, isLoading: isRemovingWishlist } = useWishlistRemoveItem({
        onSuccess: () => {
            toast.success(
                <div className="flex flex-row justify-between">
                    <span>Removido dos Meus Favoritos</span>
                </div>,
                {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: true,
                    className: 'md:w-[500px] text-sm'
                }
            );
        },
        onError: (error: any) => {
            console.log('error', error);
            toast.error(error.response.data.message);
        }
    });
    const router = useRouter();
    const { data: isLogged } = useUserLogged();

    const isLoading = isAddingWishlist || isRemovingWishlist;

    const isInWishlist = wishlist
        ?.find((item) => (wishListId ? item.wishlist_id == wishListId : WISHLIST_NAME.includes(item.name)))
        ?.item.find((item) => item.sku === sku);

    const handleClick = async (e?: any) => {
        e?.preventDefault();
        e?.stopPropagation();

        if (!isLogged) {
            setShowLogin(true);
            return;
        }

        let wishListToAdd = wishListId;

        if (!wishListId) {
            wishListToAdd = await findDefaultWishlist();
        }
        if (!wishListToAdd) return;

        if (isInWishlist) {
            removeWishlist({
                wishlistId: wishListToAdd,
                sku,
                quantity: 0
            });
            return;
        }

        const wishList = wishlist?.find((item) => item.wishlist_id == wishListToAdd);

        addWishlist({
            wishlistId: wishListToAdd,
            name: wishList?.name,
            item: {
                quantity: 1,
                sku,
                id: productId.toString()
            }
        });
    };

    const findDefaultWishlist = async () => {
        const list = wishlist?.find((item) => WISHLIST_NAME.includes(item.name));
        if (list) {
            return list.wishlist_id;
        }
        wishlist?.find((item) => item.name === null)?.wishlist_id ?? null;
    };

    const getIcon = () => {
        if (isLoading) {
            return <IconSpinner width={20} className="text-primary" />;
        }
        return isInWishlist ? <IconFilled className={classNameIcon} /> : <Icon className={classNameIcon} />;
    };

    return (
        <>
            {onlyIcon ? (
                <button type="button" disabled={isLoading} onClick={handleClick}>
                    {getIcon()}
                </button>
            ) : (
                <Container type="button" disabled={isLoading} onClick={handleClick}>
                    <div className="hover:scale-[1.1] p-3 rounded-full duration-[350ms] hover:bg-[#F9FBF3] transition-all bg-white">
                        {getIcon()}
                    </div>
                </Container>
            )}
            <ModalPortal>
                {showModalWishlist && (
                    <IncludeItemWishlist
                        onClose={() => setShowModalWishlist(false)}
                        onConfirm={() => {}}
                        product={productId.toString()}
                        sku={sku}
                    />
                )}
            </ModalPortal>
            <LoginDrawer
                onClose={() => setShowLogin(false)}
                open={showLogin}
                onLogin={() => {
                    toast.success('Login efetuado com sucesso');
                    handleClick();
                }}
            />
        </>
    );
}

export default ProductShoppingList;
