import { HTMLMotionProps } from 'framer-motion';
import { FC } from 'react';
import { Container, Text } from './styles';

interface IProductTagProps extends HTMLMotionProps<'div'> {
    text: string;
    title?: string;
}

const ProductTag: FC<IProductTagProps> = ({ text, title, ...rest }) => {
    return (
        <Container {...rest}>
            <Text title={title || text}>{text}</Text>
        </Container>
    );
};

export default ProductTag;
