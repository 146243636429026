import React, { FC } from 'react';
import { PageBlock } from '../../organisms';
import Layout from '../Layout';
import LayoutFitter from '../Layout-fitter';
import { Container } from './styles';

const NEXT_PUBLIC_PAGE_POLITICA_DE_PRIVACIDADE_SITE = process.env.NEXT_PUBLIC_PAGE_POLITICA_DE_PRIVACIDADE_SITE || '58';

interface IPoliticaDePrivacidadeProps {}

const PoliticaDePrivacidade: FC<IPoliticaDePrivacidadeProps> = ({}) => {
    return (
        <Layout>
            <LayoutFitter>
                <Container>
                    <PageBlock id={NEXT_PUBLIC_PAGE_POLITICA_DE_PRIVACIDADE_SITE} />
                </Container>
            </LayoutFitter>
        </Layout>
    );
};

export default PoliticaDePrivacidade;
