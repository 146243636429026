import dynamic from 'next/dynamic';
import tw, { styled } from 'twin.macro';

const BannerCarouselSlideShowOne = dynamic(() => import('../../organisms/Banner-carousel-slide-show-one'), {
    ssr: false
});

export const Container = styled.div`
    ${tw`
        md:px-[1%]
        md:mt-6
        grid
        [grid-template-areas:
            'Banner1'
            'MostSearchedCategories'
            'ShippingBanner'
            'SellersCarouselSlide'
            'HomeBannerProducts'
            'HallProductsFeatured'
            'BannerCarouselSlideShowThree'
            'HallProductsFeatured2'
            'HallProductsFeatured3'
            'PartnersCarouselSlide'
            'HallProductsFeatured4'
            'BannerFourImages'
            'restHalls'
            'CouponsBanner'
        ]
            md:[grid-template-areas:
                'Banner1'
                'ShippingBanner'
                'MostSearchedCategories'
                'HomeBannerProducts'
                'HallProductsFeatured'
                'SellersCarouselSlide'
                'BannerCarouselSlideShowThree'
                'HallProductsFeatured2'
                'HallProductsFeatured3'
                'PartnersCarouselSlide'
                'HallProductsFeatured4'
                'BannerFourImages'
                'restHalls'
                'CouponsBanner'
        ]
    `}

    grid-auto-rows: minmax(1px, auto); /* Ajuste conforme necessário */
    grid-auto-columns: minmax(1px, auto); /* Ajuste conforme necessário */
`;

export const BannerTop = styled(BannerCarouselSlideShowOne)``;
