import { motion } from 'framer-motion';
import tw, { styled } from 'twin.macro';

export const Container = styled.div`
    ${tw`
        rounded-8
        relative
        flex
        flex-col
        shrink-0
    `}
`;

export const ContentMobile = styled(motion.button).attrs(() => ({
    variants: {
        selected: {
            color: '#FFFFFF'
        },
        unselected: {
            color: '#000000'
        }
    },
    transition: { ease: 'linear' }
}))`
    ${tw`
    
        rounded-8
        overflow-hidden
        flex
        flex-row
        items-center
        gap-2
        text-16
        text-black
        bg-[#F2F2F2]
        relative
        px-[14px]
        py-2
        shrink-0
        flex-nowrap
        md:hidden
    `}
`;

export const ContentDesktop = styled(motion.button)`
    ${tw`
        hidden
        flex-row
        items-center
        gap-2
        text-16
        shrink-0
        text-black
        py-1
        md:flex
    `}
`;

export const SelectedContainer = styled(motion.div).attrs(() => ({}))`
    ${tw`
    absolute
    inset-0
    bg-primary
    w-full
    z-[2]
    rounded-8
    md:relative
    md:h-1
    md:rounded-[2px]
    `}
`;
