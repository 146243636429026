import tw, { styled } from 'twin.macro';
import { TextLatoRegular13, TextLatoRegular15 } from '../../atoms';

export const Container = styled.div`
    ${tw`
        flex
        flex-row
        items-center
    `}
`;

export const TextDayWeek = styled(TextLatoRegular13)`
    ${tw`
        mb-[5px]
    `}
`;
export const TextDay = styled(TextLatoRegular15)``;
export const TextTitle = styled(TextLatoRegular15)`
    ${tw`
        mb-[3px]
    `}
`;
export const TextSubTitle = styled(TextLatoRegular15)`
    ${tw`
        text-darkGray
    `}
`;

export const Content = styled.div`
    ${tw`
        flex
        flex-col
        flex-1
        justify-center
        ml-[20px]
    `}
`;
