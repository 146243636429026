import React, { FC, forwardRef } from 'react';
import { EInputType } from '../types';
import InputText from './Text';
import InputSelect from './Select';
import { TypeProps } from './types';

function Type<T>(props: TypeProps<T>, ref: any) {
    const types: any = {
        [EInputType.DEFAULT]: InputText,
        [EInputType.PASSWORD]: InputText,
        [EInputType.SELECT]: InputSelect
    };
    if (props.type && props.type in types) {
        const Input: any = types[props.type];

        return <Input ref={ref} {...props} />;
    }
    return null;
}

export default forwardRef(Type);
