export enum BadgeColor {
    PRIMARY = 'primary',
    MOSS_GREEN = 'moss-green',
    GRAPE_GREEN = 'grape-green',
    GRAPE_GREEN_LIGHT = 'grape-green-light',
    RED = 'red',
    CUSTOM = 'custom'
}

export enum BadgeSize {
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large'
}

export interface BadgeProps {
    children?: React.ReactNode;
    color: BadgeColor;
    size: BadgeSize;
    customColor?: string;
}
