export enum IButtonVariant {
    PRIMARY_BIG = 'big-primary',
    PRIMARY_MEDIUM = 'medium-primary',
    BLACK_MEDIUM = 'black-medium',
    BLACK_MEDIUM_OUTLINE = 'black-medium-outline',
    BLACK_BIG_OUTLINE = 'black-big-outline',
    OUTLINE = 'outline'
}
export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    text: string;
    isLoading?: boolean;
    disabled?: boolean;
    onClick?: () => void;
    onMouseEnter?: () => void;
    variant?: IButtonVariant;
    style?: any;
    icon?: React.FC<React.SVGProps<SVGSVGElement>>;
}

export interface ICustomStyleProps {
    css?: string;
    variant: IButtonVariant;
}
